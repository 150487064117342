import { Spin } from 'antd';
import { useAppSelector } from 'src/redux/hook';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;

const Loading = () => {
  const { loading } = useAppSelector((state) => state.global);

  return loading ? (
    <div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50 bg-black bg-opacity-20'>
      <Spin indicator={antIcon} />
    </div>
  ) : (
    <></>
  );
};

export default Loading;
