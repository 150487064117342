import { Col, Form, Image, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { formatDateTime } from 'src/utils';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { useState } from 'react';
import ModalPreview from 'src/components/modals/ModalPreview';

function Attachment() {
  const form = Form.useFormInstance();
  useWatch(['updated_date'], form);
  const [isPreview, setIsPreview] = useState(false);
  const [previewFileName, setPreviewFileName] = useState('');

  const handlePreview = (fieldName: string) => {
    setIsPreview(true);
    setPreviewFileName(fieldName);
  };

  return (
    <>
      <BoxWithLabel title='Ảnh đính kèm' classNames='mt-4'>
        <div className='flex flex-wrap gap-10'>
          <div className=' flex flex-col justify-between w-[200px] '>
            <Form.Item name={'frontDocumentFile'}>
              {['png', 'jpg', 'jpeg'].includes(
                form
                  .getFieldValue('frontDocumentFile')
                  ?.split('.')
                  [form.getFieldValue('frontDocumentFile')?.split('.').length - 1]?.toLowerCase(),
              ) || form.getFieldValue('frontDocumentFile').includes('image') ? (
                <Image src={form.getFieldValue('frontDocumentFile')} width={200} height={300} />
              ) : (
                <div className='h-[150px] overflow-hidden cursor-pointer'>
                  <Document
                    file={form.getFieldValue('frontDocumentFile')}
                    onClick={() => handlePreview('frontDocumentFile')}
                  >
                    <Page width={200} height={300} key={`page_${1 + 1}`} pageNumber={1} />
                  </Document>
                </div>
              )}
            </Form.Item>
            <div className='flex justify-center '>Mặt trước giấy tờ</div>
          </div>
          <div className='flex flex-col justify-between w-[200px]'>
            <Form.Item name={'backDocumentFile'}>
              {['png', 'jpg', 'jpeg'].includes(
                form
                  .getFieldValue('backDocumentFile')
                  ?.split('.')
                  [form.getFieldValue('backDocumentFile')?.split('.').length - 1]?.toLowerCase(),
              ) || form.getFieldValue('backDocumentFile').includes('image') ? (
                <Image src={form.getFieldValue('backDocumentFile')} width={200} height={300} />
              ) : (
                <div className='h-[150px] overflow-hidden cursor-pointer'>
                  <Document
                    file={form.getFieldValue('backDocumentFile')}
                    onClick={() => handlePreview('backDocumentFile')}
                  >
                    <Page width={200} height={300} key={`page_${1 + 1}`} pageNumber={1} />
                  </Document>
                </div>
              )}
              {/* <Image src={form.getFieldValue('backDocumentFile')} width={200} height={300} /> */}
            </Form.Item>
            <div className='flex justify-center '>Mặt sau giấy tờ</div>
          </div>
          <div className='flex flex-col justify-between w-[200px]'>
            <Form.Item name={'enterprisePermitFile'}>
              {['png', 'jpg', 'jpeg'].includes(
                form
                  .getFieldValue('enterprisePermitFile')
                  ?.split('.')
                  [
                    form.getFieldValue('enterprisePermitFile')?.split('.').length - 1
                  ]?.toLowerCase(),
              ) || form.getFieldValue('enterprisePermitFile').includes('image') ? (
                <Image src={form.getFieldValue('enterprisePermitFile')} width={200} height={300} />
              ) : (
                <div className='h-[150px] overflow-hidden cursor-pointer'>
                  <Document
                    file={form.getFieldValue('enterprisePermitFile')}
                    onClick={() => handlePreview('enterprisePermitFile')}
                  >
                    <Page width={200} height={300} key={`page_${1 + 1}`} pageNumber={1} />
                  </Document>
                </div>
              )}
            </Form.Item>
            <div className='flex justify-center '>Giấy phép DKKD</div>
          </div>
          {form.getFieldValue('contactSelfieImage') && (
            <div className='flex flex-col justify-between w-[200px]'>
              <Form.Item name={'contactSelfieImage'}>
                {['png', 'jpg', 'jpeg'].includes(
                  form
                    .getFieldValue('contactSelfieImage')
                    ?.split('.')
                    [
                      form.getFieldValue('contactSelfieImage')?.split('.').length - 1
                    ]?.toLowerCase(),
                ) || form.getFieldValue('contactSelfieImage').includes('image') ? (
                  <Image src={form.getFieldValue('contactSelfieImage')} width={200} height={300} />
                ) : (
                  <div className='h-[150px] overflow-hidden cursor-pointer'>
                    <Document
                      file={form.getFieldValue('contactSelfieImage')}
                      onClick={() => handlePreview('contactSelfieImage')}
                    >
                      <Page width={200} height={300} key={`page_${1 + 1}`} pageNumber={1} />
                    </Document>
                  </div>
                )}
              </Form.Item>
              <div className='flex justify-center '>Ảnh chụp người đại diện</div>
            </div>
          )}
          {form.getFieldValue('contactSignatureImage') && (
            <div className='flex flex-col justify-between w-52'>
              <Form.Item name={'contactSignatureImage'}>
                {['png', 'jpg', 'jpeg'].includes(
                  form
                    .getFieldValue('contactSignatureImage')
                    ?.split('.')
                    [
                      form.getFieldValue('contactSignatureImage')?.split('.').length - 1
                    ]?.toLowerCase(),
                ) || form.getFieldValue('contactSignatureImage').includes('image') ? (
                  <Image
                    src={form.getFieldValue('contactSignatureImage')}
                    width={200}
                    height={300}
                  />
                ) : (
                  <div className='h-[150px] overflow-hidden cursor-pointer'>
                    <Document
                      file={form.getFieldValue('contactSignatureImage')}
                      onClick={() => handlePreview('contactSignatureImage')}
                    >
                      <Page width={200} height={400} key={`page_${1 + 1}`} pageNumber={1} />
                    </Document>
                  </div>
                )}
              </Form.Item>
              <div className='flex justify-center '>Chữ ký người đại diện</div>
            </div>
          )}
          <div className='flex flex-col justify-between w-[200px]'>
            <Form.Item name={'legalPeopleFile'}>
              {['png', 'jpg', 'jpeg'].includes(
                form
                  .getFieldValue('legalPeopleFile')
                  ?.split('.')
                  [form.getFieldValue('legalPeopleFile')?.split('.').length - 1]?.toLowerCase(),
              ) || form.getFieldValue('legalPeopleFile').includes('image') ? (
                <Image src={form.getFieldValue('legalPeopleFile')} width={200} height={300} />
              ) : (
                <div className='h-[150px] overflow-hidden cursor-pointer'>
                  <Document
                    file={form.getFieldValue('legalPeopleFile')}
                    onClick={() => () => handlePreview('legalPeopleFile')}
                  >
                    <Page width={200} height={300} key={`page_${1 + 1}`} pageNumber={1} />
                  </Document>
                </div>
              )}
            </Form.Item>
            <div className='flex justify-center '>Danh sách nhân sự hợp pháp</div>
          </div>
          <div className='flex flex-col justify-between w-[200px]'>
            <Form.Item name={'powerOfAttorneyFile'}>
              {['png', 'jpg', 'jpeg'].includes(
                form
                  .getFieldValue('powerOfAttorneyFile')
                  ?.split('.')
                  [form.getFieldValue('powerOfAttorneyFile')?.split('.').length - 1]?.toLowerCase(),
              ) ? (
                // || form.getFieldValue('powerOfAttorneyFile').includes('image')
                <Image src={form.getFieldValue('powerOfAttorneyFile')} width={200} height={300} />
              ) : (
                <div className='h-[150px] overflow-hidden cursor-pointer'>
                  <Document
                    file={
                      // form.getFieldValue('powerOfAttorneyFile') ??
                      'https://s3.hn-1.cloud.cmctelecom.vn/asim-fileservice-uat/private-data/BSS/customer/2024/06/29/DS200_TB_38d2754c44dd_20240629151625.xlsx'
                    }
                    onClick={() => handlePreview('powerOfAttorneyFile')}
                  >
                    <Page width={200} height={300} key={`page_${1 + 1}`} pageNumber={1} />
                  </Document>
                </div>
              )}
            </Form.Item>
            <div className='flex justify-center '>Giấy uỷ quyền</div>
          </div>
          <div className='flex flex-col justify-between w-[200px] '>
            <Form.Item name={'negotiatorImgFile'}>
              {['png', 'jpg', 'jpeg'].includes(
                form
                  .getFieldValue('negotiatorImgFile')
                  ?.split('.')
                  [form.getFieldValue('negotiatorImgFile')?.split('.').length - 1]?.toLowerCase(),
              ) || form.getFieldValue('negotiatorImgFile').includes('image') ? (
                <Image src={form.getFieldValue('negotiatorImgFile')} width={200} height={300} />
              ) : (
                <div className='h-[150px] overflow-hidden cursor-pointer'>
                  <Document
                    file={form.getFieldValue('negotiatorImgFile')}
                    onClick={() => handlePreview('negotiatorImgFile')}
                  >
                    <Page width={200} height={300} key={`page_${1 + 1}`} pageNumber={1} />
                  </Document>
                </div>
              )}
              {/* <Image src={form.getFieldValue('negotiatorImgFile')} width={200} height={300} /> */}
            </Form.Item>
            <div className='flex justify-center'>Ảnh chụp người đến giao kết hợp đồng</div>
          </div>
          <div className='flex flex-col justify-between w-[200px]'>
            <Form.Item name={'serviceQualityFile'}>
              <Image
                src={`https://assets.mylocal.vn/common-conditions/quality-service-form/v1.png`}
                width={200}
                height={300}
              />
            </Form.Item>
            <div className='flex justify-center '>Thông tin về chất lượng dịch vụ</div>
          </div>
          <div className='flex flex-col justify-between w-[200px]'>
            <Form.Item name={'commonConditionFile'}>
              {['png', 'jpg', 'jpeg'].includes(
                form
                  .getFieldValue('commonConditionFile')
                  ?.split('.')
                  [form.getFieldValue('commonConditionFile')?.split('.').length - 1]?.toLowerCase(),
              ) || form.getFieldValue('commonConditionFile').includes('image') ? (
                <Image src={form.getFieldValue('commonConditionFile')} width={200} height={300} />
              ) : (
                <div className='h-[150px] overflow-hidden cursor-pointer'>
                  <Document
                    file={form.getFieldValue('commonConditionFile')}
                    onClick={() => handlePreview('commonConditionFile')}
                  >
                    <Page width={200} height={300} key={`page_${1 + 1}`} pageNumber={1} />
                  </Document>
                </div>
              )}
            </Form.Item>
            <div className='flex justify-center '>Điều kiện giao dịch chung</div>
          </div>
          <div className='flex flex-col justify-between w-52'>
            <Form.Item name={'contractTermFile'}>
              {['png', 'jpg', 'jpeg'].includes(
                form
                  .getFieldValue('contractTermFile')
                  ?.split('.')
                  [form.getFieldValue('contractTermFile')?.split('.').length - 1]?.toLowerCase(),
              ) || form.getFieldValue('contractTermFile').includes('image') ? (
                <Image src={form.getFieldValue('contractTermFile')} width={200} height={300} />
              ) : (
                <div className='h-[150px] overflow-hidden cursor-pointer'>
                  <Document
                    file={form.getFieldValue('contractTermFile')}
                    onClick={() => handlePreview('contractTermFile')}
                  >
                    <Page width={200} height={400} key={`page_${1 + 1}`} pageNumber={1} />
                  </Document>
                </div>
              )}
            </Form.Item>
            <div className='flex justify-center '>Điều khoản chung của hợp đồng</div>
          </div>
          <div className='flex flex-col justify-between w-[200px]'>
            <Form.Item name={'signatureImgFile'}>
              <Image src={form.getFieldValue('signatureImgFile')} width={200} height={300} />
            </Form.Item>
            <div className='flex justify-center '>Chữ ký người đến giao dịch</div>
          </div>
        </div>
      </BoxWithLabel>
      <BoxWithLabel title='' classNames='mt-4'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item labelCol={{ span: 12 }} label='Người tạo' name={'profileCreatedBy'}>
              {form.getFieldValue('profileCreatedBy')}
            </Form.Item>
            <Form.Item labelCol={{ span: 12 }} label='Thời gian tạo' name={'reg_date'}>
              {form.getFieldValue('reg_date') ? formatDateTime(form.getFieldValue('reg_date')) : ''}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Người cập nhật gần nhất'
              labelCol={{ span: 12 }}
              name='profileUpdatedBy'
            >
              {form.getFieldValue('profileUpdatedBy')}
            </Form.Item>
            <Form.Item
              label='Thời gian cập nhật gần nhất'
              labelCol={{ span: 12 }}
              name='updated_date'
            >
              {form.getFieldValue('updated_date')
                ? formatDateTime(form.getFieldValue('updated_date'))
                : ''}
            </Form.Item>
          </Col>
        </Row>
      </BoxWithLabel>
      <ModalPreview
        open={isPreview}
        handleCancel={() => setIsPreview(false)}
        content={
          <Document file={form.getFieldValue(previewFileName)}>
            <Page key={`page_${1 + 1}`} pageNumber={1} />
          </Document>
        }
        props={{ width: 'fit-content' }}
      />
    </>
  );
}

export default Attachment;
