import { Badge } from 'antd';
import { EnterpriseStatusEnum } from 'src/shared/enum/business_profile';
import {
  getStatusBusinessProfile,
  getStatusColorBusinessProfile,
} from 'src/pages/management/activeSim/utils';

type StatusBusinessProfileProps = {
  status?: EnterpriseStatusEnum;
};

export default function StatusBusinessProfile(props: StatusBusinessProfileProps) {
  return (
    <>
      <Badge
        color={getStatusColorBusinessProfile(props.status)}
        text={getStatusBusinessProfile(props.status)}
      />
    </>
  );
}
