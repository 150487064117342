import {
  mbfCardTypeIds,
  SimStatus,
  JointSubject,
  SubscriptionStatus,
  TypeCardID, ucmCardTypeIds,
} from '../constants';
import { EnterpriseStatusEnum, EnterpriseTypeEnum } from 'src/shared/enum/business_profile';

export const getSubscriptionStatus = (status: any) => {
  switch (status) {
    case SubscriptionStatus.All:
      return 'Tất cả';
    case SubscriptionStatus.BLOCK_1_WAY:
      return 'Khóa 1 chiều';
    case SubscriptionStatus.BLOCK_2_WAYS:
      return 'Khóa 2 chiều';
    case SubscriptionStatus.UNSUBSCRIPTION:
      return 'Hủy';
    case SubscriptionStatus.UNLOCK:
      return 'Hoạt động';
    default:
      return '';
  }
};

export const getSubscriptionStatusOption = (): any => {
  const subscriptionStatusOption = Object.values(SubscriptionStatus).filter(
    (item) => !isNaN(Number(item)),
  );
  return subscriptionStatusOption?.map((status) => ({
    value: status,
    label: getSubscriptionStatus(status),
  }));
};

export const getTypeCardID = (type: any) => {
  switch (type) {
    case TypeCardID.CMND:
      return 'CMND';
    case TypeCardID.CCCD:
      return 'CCCD';
    case TypeCardID.Passport:
      return 'Passport';
    default:
      return '';
  }
};

export const getTypeCardIDOption = (): any => {
  const options = Object.values(TypeCardID).filter((item) => !isNaN(Number(item)));
  return options?.map((type) => ({
    value: type,
    label: getTypeCardID(type),
  }));
};

export const isValidUcmCardTypeID = (type: any) => {
  return Object.values(ucmCardTypeIds).includes(parseInt(type));
};

export const getUcmCardTypeID = (type: any) => {
  switch (type) {
    case ucmCardTypeIds.cmnd_cccd:
      return 'CMND/CCCD';
    case ucmCardTypeIds.passport:
      return 'Passport';
    default:
      return 'Unknown';
  }
};

export const getUcmCardTypeIDOption = (): any => {
  return Object.values(ucmCardTypeIds).map((type) => ({
    value: type.toString(),
    label: getUcmCardTypeID(type),
  }));
};

export const getSimStatus = (status: any) => {
  switch (status) {
    case SimStatus.All:
      return 'Tất cả';
    case SimStatus.ACTIVATION_SUCCESS:
      return 'Đang kích hoạt';
    case SimStatus.EXPIRED:
      return 'Huỷ';
    default:
      return '';
  }
};

export const getSimStatusOption = (): any => {
  const simStatusOption = Object.values(SimStatus).filter((item) => !isNaN(Number(item)));
  return simStatusOption?.map((status) => ({
    value: status,
    label: getSimStatus(status),
  }));
};

export const getJointSubject = (data: any) => {
  switch (data) {
    case JointSubject.All:
      return 'Tất cả';
    case JointSubject.PERSONAL:
      return 'Thành viên doanh nghiệp';
    case JointSubject.ENTERPRISE:
      return 'Doanh nghiệp';
    default:
      return '';
  }
};

export const getJointSubjectOption = (): any => {
  const subscriberOwnerOption = Object.values(JointSubject).filter((item) => String(item));
  return subscriberOwnerOption?.map((status) => ({
    value: status,
    label: getJointSubject(status),
  }));
};

export const getTypeBusiness = (typeBusiness: any) => {
  switch (typeBusiness) {
    case EnterpriseTypeEnum.DN:
      return 'DN';
    case EnterpriseTypeEnum.HKDL:
      return 'HKDL';
    case EnterpriseTypeEnum.CovinienceStore:
      return 'CovinienceStore';
    case EnterpriseTypeEnum.M2M:
      return 'M2M';
    default:
      return '';
  }
};

export const genEnterpriseTypeOptions = (): any => {
  const enterpriseTypeOptions = Object.values(EnterpriseTypeEnum).filter((v) => !isNaN(Number(v)));
  return enterpriseTypeOptions.map((type) => ({
    value: type.toString(),
    label: getTypeBusiness(type),
  }));
};

export const getStatusBusinessProfile = (statusBusiness?: EnterpriseStatusEnum) => {
  switch (statusBusiness) {
    case EnterpriseStatusEnum.NEW:
      return 'NEW';
    case EnterpriseStatusEnum.WAITING:
      return 'WAITING';
    case EnterpriseStatusEnum.EDITED:
      return 'EDITED';
    case EnterpriseStatusEnum.APPROVED:
      return 'APPROVED';
    case EnterpriseStatusEnum.REJECTED:
      return 'REJECTED';
    default:
      return 'DEFAULT';
  }
};

export const getStatusColorBusinessProfile = (statusBusiness?: EnterpriseStatusEnum) => {
  switch (statusBusiness) {
    case EnterpriseStatusEnum.NEW:
      return 'cyan';
    case EnterpriseStatusEnum.WAITING:
      return 'geekblue';
    case EnterpriseStatusEnum.EDITED:
      return 'orange';
    case EnterpriseStatusEnum.APPROVED:
      return 'green';
    case EnterpriseStatusEnum.REJECTED:
      return 'red';
    default:
      return 'cyan';
  }
};
