import { Col, Form, Radio, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { formatDate } from 'src/utils';

function AuthorizeInfo() {
  const form = Form.useFormInstance();
  useWatch('authorSubname', form);

  const renderType = () => {
    switch (form.getFieldValue('authorOption')) {
      case '0':
        return <div>CMND/CCCD</div>;
      case '1':
        return <div>PASSPORT</div>;

      default:
        return '';
    }
  };

  return (
    <BoxWithLabel title='Thông tin người uỷ quyền (nếu có)' classNames='mt-4'>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item labelCol={{ span: 12 }} label='Họ và tên' name={'authorSubname'}>
            {form.getFieldValue('authorSubname')}
          </Form.Item>
          <Form.Item labelCol={{ span: 12 }} label='Loại giấy tờ' name={'authorOption'}>
            {renderType()}
          </Form.Item>
          <Form.Item label='Số giấy tờ' labelCol={{ span: 12 }} initialValue={''} name='authorIdNo'>
            {form.getFieldValue('authorIdNo')}
          </Form.Item>
          <Form.Item label='Ngày cấp' labelCol={{ span: 12 }} name='authorIdIssueDate'>
            {form.getFieldValue('authorIdIssueDate')
              ? formatDate(form.getFieldValue('authorIdIssueDate'))
              : ''}
          </Form.Item>
          <Form.Item
            label='Nơi cấp'
            labelCol={{ span: 12 }}
            initialValue={''}
            name='authorIdIssuePlace'
          >
            {form.getFieldValue('authorIdIssuePlace')}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Số giấy tờ uỷ quyền' labelCol={{ span: 12 }} name='authorNo'>
            {form.getFieldValue('authorNo')}
          </Form.Item>
          <Form.Item
            label='Ngày cấp giấy tờ uỷ quyền'
            labelCol={{ span: 12 }}
            name='authorStartDate'
          >
            {form.getFieldValue('authorStartDate')
              ? formatDate(form.getFieldValue('authorStartDate'))
              : ''}
          </Form.Item>
          <Form.Item
            label='Ngày hết hạn giấy tờ uỷ quyền'
            labelCol={{ span: 12 }}
            name='authorEndDate'
          >
            {form.getFieldValue('authorEndDate')
              ? formatDate(form.getFieldValue('authorEndDate'))
              : ''}
          </Form.Item>
          <Form.Item
            label='Người ký giấy tờ uỷ quyền'
            labelCol={{ span: 12 }}
            name='authorPowerOfAttorneyGrantor'
          >
            {form.getFieldValue('authorPowerOfAttorneyGrantor')}
          </Form.Item>
          <Form.Item label='Chức vụ' labelCol={{ span: 12 }} name='authorRole'>
            {form.getFieldValue('authorRole')}
          </Form.Item>
          <Form.Item label='Email' labelCol={{ span: 12 }} name='emailMbf'>
            {form.getFieldValue('emailMbf')}
          </Form.Item>
          <Form.Item label='Số điện thoại' labelCol={{ span: 12 }} name='authorPhoneNumber'>
            {form.getFieldValue('authorPhoneNumber')}
          </Form.Item>
          <Form.Item label='Liên hệ khác' labelCol={{ span: 12 }} name='authorOtherContact'>
            {form.getFieldValue('authorOtherContact')}
          </Form.Item>
          <Form.Item
            label='Là người đến giao dịch'
            labelCol={{ span: 12 }}
            name='authorIsNegotiator'
          >
            <Radio.Group disabled>
              <Radio value={'true'}>Yes</Radio>
              {form.getFieldValue('authorIsNegotiator') === 'false' ? (
                <Radio value={'false'}>No</Radio>
              ) : (
                <Radio value={''}>No</Radio>
              )}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </BoxWithLabel>
  );
}

export default AuthorizeInfo;
