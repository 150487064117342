import { Button, Checkbox, Form, Input, Modal, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import { useMemo, useEffect, useState } from 'react';
import { repickedNumber, reserveNumber } from 'src/api/connection';
import useAllPhoneNumber from 'src/hooks/useAllPhoneNumber';
import { IHKDLPhoneNumber } from 'src/shared/interface/connection';
import {formatPhoneNumber, formatPhoneNumberV2} from 'src/utils';
import PaginationConfig from 'src/utils/panigationConfig';

interface ModalReChooseNumberProps {
  isOpen: boolean;
  onCancel: () => void;
  onOk: any;
  pool?: number;
}

const ModalReChooseNumber = ({ isOpen, onCancel, pool, onOk }: ModalReChooseNumberProps) => {
  return (
    <Modal
      open={isOpen}
      okText='Xác nhận'
      cancelText='Thoát'
      footer={null}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <ModalReChooseNumberContent pool={pool} onCancel={onCancel} onOk={onOk} />
    </Modal>
  );
};

const ModalReChooseNumberContent = ({
  pool,
  onCancel,
  onOk,
}: {
  pool?: number;
  onCancel: () => void;
  onOk: (selectedNumber: string) => void;
}) => {
  const [selectedNumber, setSelectedNumber] = useState<string>();
  const [searchText, setSearchText] = useState('');
  const { isLoading: isListNumberLoading, listNumber: listNumberDefault } = useAllPhoneNumber(pool);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: any) => {
    e.preventDefault();

    if (e.target.value.length <= 10) {
      form.setFieldValue('searchText', e.target.value);
    } else {
      form.setFieldValue('searchText', e.target.value.substring(0, 10));
    }
  };

  const handleCheckboxChange = (value: string) => {
    if (selectedNumber === value) {
      setSelectedNumber('');
    } else {
      setSelectedNumber(value);
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const filteredEvents = useMemo(() => {
    if (searchText === '') {
      return listNumberDefault;
    }

    return listNumberDefault?.filter(({ simNumber }) => {
      simNumber = simNumber.toLowerCase().replace('84', '0');
      return simNumber.includes(searchText);
    });
  }, [searchText, JSON.stringify(listNumberDefault)]);

  const handleFinish = async () => {
    if (!selectedNumber) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await repickedNumber(selectedNumber, pool);

      if (response) {
        onOk(selectedNumber);
      } else {
        message.error('Số này đã được chọn , xin vui lòng thử lại số khác!');
      }
    } catch (error) {
      message.error('Chọn số thất bại , xin vui lòng thử lại!');
    } finally {
      setIsLoading(false);
    }
  };

  const columns: ColumnsType<IHKDLPhoneNumber> = [
    {
      title: 'STT',
      key: 'stt',
      render: (v: any, r: any, index: number) => index + 1,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'simNumber',
      key: 'simNumber',
      render: (v: any) => formatPhoneNumberV2(v),
    },
    {
      title: 'Chọn',
      dataIndex: 'simNumber',
      key: 'simNumber',
      render: (v: string) => {
        return (
          <Checkbox
            checked={selectedNumber === v}
            onChange={() => handleCheckboxChange(v)}
          ></Checkbox>
        );
      },
    },
  ];

  return (
    <Form className='pt-10' form={form} onFinish={handleFinish}>
      <Form.Item
        name='searchText'
        rules={[
          {
            pattern: /^(?:\d*)$/,
            message: 'Giá trị chỉ được nhập số',
          },
        ]}
      >
        <Input.Search
          type='number'
          placeholder='Nhập số cần tìm'
          onSearch={handleSearch}
          onPressEnter={(e) => handleSearch(e.currentTarget.value)}
          onChange={handleInputChange}
          minLength={10}
          maxLength={10}
        />
      </Form.Item>
      <div className='max-h-[500px] overflow-auto'>
        <Table
          loading={isListNumberLoading}
          dataSource={filteredEvents}
          columns={columns}
          pagination={PaginationConfig(false)}
          locale={{ emptyText: 'Không tìm thấy kết quả' }}
        />
      </div>
      <div className='flex justify-end gap-2 mt-5'>
        <Button disabled={isLoading} onClick={onCancel}>
          Thoát
        </Button>
        <Form.Item noStyle>
          <Button htmlType='submit' disabled={!selectedNumber} loading={isLoading} type='primary'>
            Xác nhận
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default ModalReChooseNumber;
