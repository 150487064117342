/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Tabs,
  Upload,
  UploadProps,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IListColumn } from 'src/@types/search-params';
import {
  checkEsimExpired,
  checkImportExcelSerial,
  createOrderSim,
  doCancleOrder,
  doChangeSim,
  doDeleOrder,
  getOrderDetail,
  updateChangeSim,
  validateSim,
} from 'src/api/changeSim';
import { uploadConnectionImportExcelSerial } from 'src/api/connection';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import Loading from 'src/components/CLoading';
import ConfirmModal from 'src/components/ConfirmModal';
import TableContent from 'src/components/CTable';
import ModalEsimInfo from 'src/components/ModalEsimInfo';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { setChangeSimState } from 'src/redux/slice/changeSimSlice';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { setModalState } from 'src/redux/slice/modal.store';
import { OpenNewTab } from 'src/shared/common/openNewTab';
import { REGEX_COMMON } from 'src/shared/common/regex';
import { findLable, itemOrderStatus, SimStatusEnum } from 'src/shared/enum/change_sim';
import { SimType } from 'src/shared/enum/sim.enum';
import { InputChangeSim, SimError, SimSuccess } from 'src/shared/interface/changeSim.interface';
import {
  exportExcel,
  exportExcelPost,
  formatDate,
  formatDateTime,
  formatMoney,
  formatPhoneNumberV2,
  parseQueryString,
} from 'src/utils';
import { simTypeToText } from 'src/utils/sim.enum';
import ModalConfirm from '../../../components/modals/ModalComfirm';
import { getStatusEsimLotTxt } from '../esimManagement/utils';
import './index.scss';
import ModalCheckEsimExpired from './ModalCheckEsimExpired';

const CreateChangeSim = () => {
  const { Search } = Input;
  const { listSuccessStore, listErrorsStore } = useAppSelector((state) => state.changeSim);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [form] = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { openTabs, isOpenPayment, loading } = useAppSelector((state) => state.global);
  const params = parseQueryString(location.search);

  const [typeInput, setTypeInput] = useState(1);
  const [fileInfo, setFileInfo] = useState<any>(null);
  const [fileImport, setFileImport] = useState<any>(null);
  const [listSuccess, setListSuccess] = useState<SimSuccess[]>(
    location.search ? [] : listSuccessStore,
  );
  const [listErrors, setListErros] = useState<SimError[]>(location.search ? [] : listErrorsStore);
  const [valueSearch, setValueSearch] = useState('');
  const [activeTab, setActiveTab] = useState('1');
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteOrder, setOpenDeleteOrder] = useState(false);
  const [openCancleOrder, setOpenCancleOrder] = useState(false);
  const [openChangeSim, setOpenChangeSim] = useState(false);
  const [openModalConfirmSimType, setOpenModalConfirmSimType] = useState(false);
  const [simType, setSimType] = useState<SimType>(SimType.PHYSICAL_SIM);

  const [disabledSave, setDisabledSave] = useState(true);
  const [listBtn, setListBtn] = useState(['save']);
  const [currentClick, setCurrentClick] = useState('');
  const [oriErros, setOriErros] = useState('');
  const [oriSuccess, setOriSuccess] = useState('');
  const [selectStatus, setSelectStatus] = useState<any>(null);
  const [activePhone, setActivePhone] = useState<any>(null);
  const [order, setOrder] = useState<any>(null);
  const [pathFile, setPathFile] = useState<any>(null);
  const [isOpenEsimInfo, setIsOpenEsimInfo] = useState(false);
  const [detailEsim, setDetailEsim] = useState<any>(null);
  const { isFetchDetailChangeSim } = useAppSelector((state) => state.changeSim);
  const [isOpenModalCheckEsimExpired, setIsOpenModalCheckEsimExpired] = useState(false);
  const [listEsimExpired, setListEsimExpired] = useState([]);
  const [listEsimValid, setListEsimValid] = useState([]);

  const onchangeTypeInput = (e: RadioChangeEvent) => {
    setTypeInput(e.target.value);
  };
  const userStore = localStorage.getItem('merchantInfo');
  let merchantInfo: any;
  if (userStore !== null) {
    try {
      merchantInfo = JSON.parse(userStore);
    } catch (error) {
      console.log(error);
    }
  }
  const props: UploadProps = {
    beforeUpload: (file) => {
      const isPNG =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isPNG) {
        message.error(`${file.name} không đúng định dạng`);
      }
      return isPNG;
    },
    onChange: ({ file }) => {
      if (file.status === 'removed') {
        setFileImport(null);
      } else {
        file.status = 'done';
        setFileImport(file);
      }
    },
    customRequest: async (info) => {
      uploadFile(info);
      setFileInfo(info);
    },
  };
  useEffect(() => {
    if (params?.pageIndex) {
      return;
    }
    if (params.id) {
      setListBtn(['edit', 'save']);
      const id = params.id;
      fetchData(id);
    } else if (!location.search && !params.id) {
      setOrder({});
      form.resetFields();
      setListSuccess([]);
      setListErros([]);
    } else if (order && order.id) {
      fetchData(order.id);
    }
  }, [location.search, isOpenPayment]);

  useEffect(() => {
    if (isFetchDetailChangeSim) {
      fetchData(params.id);
      dispatch(
        setChangeSimState({
          isFetchDetailChangeSim: false,
        }),
      );
    }
  }, [isFetchDetailChangeSim]);

  const fetchData = async (id: string) => {
    // params.pageIndex
    try {
      dispatch(setGlobalState({ loading: true }));
      await getOrderDetail(id).then((res) => {
        if (res) {
          setOrder(res);
          res.createdAt = res.createdAt ? formatDateTime(new Date(res.createdAt)) : '';
          res.updatedAt = res.updatedAt ? formatDateTime(new Date(res.updatedAt)) : '';
          form.setFieldsValue(res);
          form.setFieldValue(
            'toTalAmount',
            res?.toTalAmount ? formatMoney(res?.toTalAmount, 'đ') : '',
          );
          setSimType(parseInt(res?.simType));
          setListSuccess(
            res.overFlowOrderDetailResponses?.map((item: any, index: number) => {
              return {
                ...item,
                key: index,
              };
            }),
          );
          setListErros([]);
          setOriSuccess(JSON.stringify(res.overFlowOrderDetailResponses));
          if (res.fileUrl) {
            setTypeInput(2);
          }

          const listEsimValidTmp: any = [];
          res.overFlowOrderDetailResponses?.forEach((item: any) => {
            if (item?.statusString !== 'Failed') {
              listEsimValidTmp?.push(item);
            }
          });
          setListEsimValid(listEsimValidTmp);
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setGlobalState({ loading: false }));
    }
  };

  const operations = (
    <div>
      <Search
        placeholder='Số thuê bao, tên thuê bao, Sim serial cũ, Sim serial mới'
        onChange={(e) => {
          setValueSearch(e.target.value);
        }}
        style={{ width: 460 }}
      />

      <span className='mx-[12px]'>Trạng thái </span>
      <Select
        onSelect={(e) => {
          setSelectStatus(e);
        }}
        className='w-[120px]'
        placeholder='Vui lòng chọn'
        options={itemOrderStatus}
        defaultValue={''}
      />
      <Button className='w-[120px] mx-[12px] ' onClick={() => handleSearchItem()}>
        Tìm kiếm
      </Button>
      <Button className='w-[120px] mr-[12px]' onClick={() => handleExportPhone()}>
        Export
      </Button>
    </div>
  );
  const handleSearchItem = () => {
    if (!valueSearch && !selectStatus) {
      if (activeTab === '2' && oriErros) {
        try {
          setListErros(JSON.parse(oriErros));
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          setListSuccess(JSON.parse(oriSuccess));
        } catch (error) {
          console.log(error);
        }
      }
    } else if (activeTab === '2' && oriErros) {
      const dataError = JSON.parse(oriErros);
      console.log('dataError', dataError);

      setListErros(
        dataError.filter(function (item: any) {
          if (
            valueSearch &&
            (item.phoneNumber?.includes(valueSearch) ||
              item.userName?.includes(valueSearch) ||
              item.serial?.includes(valueSearch) ||
              item.serialNew?.includes(valueSearch))
          ) {
            return true;
          }
          if (selectStatus) {
            console.log('selectStatus');
          } else {
            return false;
          }
        }),
      );
    } else if (activeTab === '1' && oriSuccess) {
      const dataSuccess = JSON.parse(oriSuccess);
      console.log('dataSuccess', dataSuccess);
      console.log('selectStatus', selectStatus);
      setListSuccess(
        dataSuccess.filter(function (item: any) {
          if (
            valueSearch &&
            (item.phoneNumber.includes(valueSearch) ||
              item.userName.includes(valueSearch) ||
              item.serial.includes(valueSearch) ||
              item.serialNew.includes(valueSearch))
          ) {
            return true;
          }
          if (selectStatus && selectStatus === item.statusString) {
            return true;
          } else {
            return false;
          }
        }),
      );
    }
  };
  const renderAction = (data: any) => {
    if (currentClick === 'edit' || !order?.id) {
      return (
        <button
          onClick={() => {
            setOpenDelete(true);
            setActivePhone(data);
          }}
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
        >
          Xóa
        </button>
      );
    } else {
      return data?.statusString === SimStatusEnum.Success && order?.simType === SimType.ESIM ? (
        <button
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
          onClick={() => {
            setIsOpenEsimInfo(true);
            setDetailEsim(data);
          }}
        >
          Xem chi tiết
        </button>
      ) : (
        <></>
      );
    }
  };
  const columnsEsim: IListColumn[] = [
    {
      name: 'STT',
      fieldName: 'index',
    },
    {
      name: 'Số điện thoại',
      fieldName: 'phoneNumber',
      align: 'center',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return <span>{formatPhoneNumberV2(record?.phoneNumber)}</span>;
      },
    },
    {
      name: 'Sim serial',
      fieldName: 'serial',
      align: 'center',
      isHideSort: true,
    },
    { name: 'Tên thuê bao', fieldName: 'userName', isHideSort: true },
    {
      name: 'Số serial mới',
      fieldName: 'serialNew',
      isHideSort: true,
      align: 'center',
      // width: 150,
    },
    {
      name: 'Thành tiền',
      fieldName: 'price',
      isHideSort: true,
      renderSpecialCard: (data: any) => (
        <span className='whitespace-nowrap'>{formatMoney(data.price)}</span>
      ),
    },
    {
      name: 'Trạng thái',
      fieldName: 'status',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (data) => (
        <span className='whitespace-nowrap'>{findLable(data?.statusString)}</span>
      ),
    },
    {
      name: 'Trạng thái eSIM',
      fieldName: 'statusEsim',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (data) => (
        <span className='whitespace-nowrap'>
          {getStatusEsimLotTxt(data?.statusEsim?.toString())}
        </span>
      ),
    },
    {
      name: 'Thời gian hết hạn',
      fieldName: 'expiredAt',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (data) => (
        <span className='whitespace-nowrap'>{formatDate(data?.expiredAt)}</span>
      ),
    },
    {
      name: 'Mã lỗi',
      fieldName: 'errorCode',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Mô tả lỗi',
      fieldName: 'errorMessage',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (data) => (
        <span className='whitespace-nowrap'>
          {data?.errorMessage ? data?.errorMessage : data.message}
        </span>
      ),
    },
    { name: 'Thao tác', fieldName: 'id', renderSpecialCard: renderAction, isHideSort: true },
  ];

  const columnsPhysicalSim: IListColumn[] = [
    {
      name: 'STT',
      fieldName: 'index',
    },
    {
      name: 'Số điện thoại',
      fieldName: 'phoneNumber',
      align: 'center',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return <span>{formatPhoneNumberV2(record?.phoneNumber)}</span>;
      },
    },
    {
      name: 'Sim serial',
      fieldName: 'serial',
      align: 'center',
      isHideSort: true,
    },
    { name: 'Tên thuê bao', fieldName: 'userName', isHideSort: true },
    {
      name: 'Số serial mới',
      fieldName: 'serialNew',
      isHideSort: true,
      align: 'center',
      // width: 150,
    },
    {
      name: 'Thành tiền',
      fieldName: 'price',
      isHideSort: true,
      renderSpecialCard: (data: any) => (
        <span className='whitespace-nowrap'>{formatMoney(data.price)}</span>
      ),
    },
    {
      name: 'Trạng thái',
      fieldName: 'status',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (data) => (
        <span className='whitespace-nowrap'>{findLable(data?.statusString)}</span>
      ),
    },
    {
      name: 'Mã lỗi',
      fieldName: 'errorCode',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Mô tả lỗi',
      fieldName: 'errorMessage',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (data) => (
        <span className='whitespace-nowrap'>
          {data?.errorMessage ? data?.errorMessage : data.message}
        </span>
      ),
    },
    { name: 'Thao tác', fieldName: 'id', renderSpecialCard: renderAction, isHideSort: true },
  ];

  const addPhoneNumber = (phoneNumber: string, serialNumber: string) => {
    let isDuplicate;
    if (pathFile) {
      setFileImport(null);
      setPathFile('');
      setFileInfo(null);
      setListSuccess([]);
      setListErros([]);
      setOriSuccess('');
      setOriErros('');
      isDuplicate = false;
    } else {
      isDuplicate = checkDuplicate(phoneNumber, serialNumber);
    }
    // if (phoneNumber && phoneNumber[0].toString() === '0') {
    //   phoneNumber = phoneNumber.replace('0', '84');
    // }
    const data: InputChangeSim = {
      phoneNumber: phoneNumber,
      serialNumber: serialNumber,
      distributorId: merchantInfo.merchantId,
      orderId: form.getFieldValue('id'),
      simType,
    };

    if (!isDuplicate) {
      validateSim(data).then((res) => {
        if (res) {
          setListSuccess(pathFile ? res.simSuccess : res.simSuccess.concat(listSuccess));
          setListErros(pathFile ? res.simError : res.simError.concat(listErrors));
          setOriSuccess(
            pathFile
              ? JSON.stringify(res.simSuccess)
              : JSON.stringify(res.simSuccess.concat(listSuccess)),
          );
          setOriErros(
            pathFile
              ? JSON.stringify(res.simError)
              : JSON.stringify(res.simError.concat(listErrors)),
          );
          console.log('res.simError', res.simError);

          dispatch(
            setChangeSimState({
              listErrorsStore: listErrors,
              listSuccessStore: listSuccess,
            }),
          );
        }
      });
    } else {
      message.error('Số thuê bao hoặc serial đã tồn tại');
    }
  };

  const onDeletePhoneNumber = () => {
    if (activeTab === '1') {
      setListSuccess(listSuccess.filter((item) => item?.phoneNumber !== activePhone.phoneNumber));
      setOpenDelete(false);
      setOriSuccess(
        JSON.stringify(listSuccess.filter((item) => item?.phoneNumber !== activePhone.phoneNumber)),
      );
    } else {
      setListErros(listErrors.filter((item) => item?.phoneNumber !== activePhone.phoneNumber));
      setOriErros(
        JSON.stringify(listErrors.filter((item) => item?.phoneNumber !== activePhone.phoneNumber)),
      );
      setOpenDelete(false);
    }
    dispatch(
      setChangeSimState({
        listErrorsStore: listErrors,
        listSuccessStore: listSuccess,
      }),
    );
  };
  const updateOrder = () => {
    if (form.getFieldValue('id')) {
      // update Order
      const data = {
        orderId: form.getFieldValue('id'),
        updatedBy: merchantInfo.merchantName,
        phoneSerials: listSuccess,
        fileUrl: pathFile,
        fileName: fileInfo?.file?.name,
      };
      updateChangeSim(data).then((res) => {
        if (res) {
          fetchData(data.orderId);
          setFileImport(null);
          setFileInfo(null);
        }
      });
    }
  };
  const uploadFile = (file: any) => {
    uploadConnectionImportExcelSerial(file).then((res) => {
      setPathFile(res);
      console.log('fileInfo', fileInfo);
    });
  };

  const createOrder = () => {
    setCurrentClick('save');
    setListBtn(['edit', 'save']);
    // create order
    if (form.getFieldValue('id')) {
      updateOrder();
    } else {
      const data = {
        distributorId: merchantInfo.merchantId,
        createdBy: merchantInfo.merchantName,
        phoneSerials: listSuccess,
        fileUrl: pathFile,
        fileName: fileInfo?.file?.name,
        simType,
      };
      try {
        createOrderSim(data).then((res: any) => {
          if (res && res.id) {
            form.setFieldsValue(res);
            setOrder(res);
            location.search = res?.id;
            OpenNewTab(
              'changeSim/createChangeSim' + '?id=' + res?.id,
              'Xem chi tiết đổi sim ' + res?.id,
            );
            setFileImport(null);
            setFileInfo(null);
          } else {
            setListErros([]);
            setListSuccess([]);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    if (form.getFieldValue('phoneNumber') && form.getFieldValue('serialNumber') && !hasErrors) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  };

  const deleteOrder = () => {
    const orderId = form.getFieldValue('id');
    doDeleOrder(orderId).then((res) => {
      if (res) {
        setOpenDeleteOrder(false);
        dispatch(
          setGlobalState({
            activeKeyMenu: 'changeSim',
          }),
        );

        dispatch(
          setGlobalState({
            openTabs: [
              ...openTabs.filter(
                (tab) => tab.key !== `changeSim/createChangeSim?id=${orderId ?? ''}`,
              ),
            ],
          }),
        );

        navigate('/management/changeSim');
      }
    });
  };

  const cancleOrder = () => {
    const orderId = form.getFieldValue('id');
    doCancleOrder(orderId).then((res) => {
      if (res) {
        fetchData(orderId);
        setOpenCancleOrder(false);
      }
    });
  };
  const changeSim = () => {
    /// change sim
    setOpenChangeSim(false);
    const data = {
      orderId: form.getFieldValue('id'),
      updatedBy: merchantInfo.merchantName,
    };
    doChangeSim(data).then((res) => {
      if (res) {
        fetchData(order.id);
        message.success('Chuyển sim thành công');
      } else {
        message.error('Chuyển sim không thành công');
      }
    });
  };

  const handleExportTemplate = () => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `mau_import_sim.xlsx`;
    const url = process.env.REACT_APP_API_URL + '/api/Provider/export-excel-template?type=3';
    exportExcel(url).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
    });
  };
  const handleExportPhone = () => {
    // eslint-disable-next-line prefer-const
    let data = activeTab === '1' ? listSuccess : listErrors;
    const newData = data.map((item) =>
      Object.assign({}, item, !item.errorMessage ? { errorMessage: item.message } : ''),
    );
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `danh_sach_thue_bao.xlsx`;
    const url = '/api/Provider/physical-sim/export-detail';
    exportExcelPost(url, newData).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
    });
  };
  // const checkHasDelete = () => {
  //   if (oriSuccess === JSON.stringify(listSuccess)) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };
  const isDisableSave = (buttonStr: string) => {
    switch (buttonStr) {
      case 'save':
        if (order && order.id && order.statusString === 'New') {
          if (currentClick === 'edit') {
            return false;
          } else {
            return true;
          }
        } else if (JSON.stringify(order) === JSON.stringify({})) {
          return false;
        }
        //  else if (JSON.stringify(order) === JSON.stringify({})) {
        //   if (listSuccess.length > 0) {
        //     return false;
        //   } else {
        //     return true;
        //   }
        // }
        else {
          return true;
        }
        break;
      case 'edit':
        if (order && order.id && order.statusString === 'New') {
          return false;
        } else {
          return true;
        }
      case 'delete':
        if (order && order.id && order.statusString === 'New') {
          if (currentClick === 'edit') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      case 'payment':
        if (
          (order &&
            order.id &&
            order.statusString === 'New' &&
            listSuccess?.length > 0 &&
            listEsimValid?.length > 0) | (order && order.id && order.statusString === 'PaymentFail')
        ) {
          return false;
        } else if (
          order &&
          order.id &&
          order.statusString === 'New' &&
          listSuccess?.length > 0 &&
          listEsimValid?.length <= 0
        ) {
          return true;
        } else {
          return true;
        }
      case 'changeSim':
        if (order && order.id && order?.statusString === 'Paid' && order?.toTalAmount !== 0) {
          return false;
        } else if (
          order &&
          order.id &&
          order?.statusString === 'New' &&
          order?.toTalAmount === 0 &&
          listEsimValid?.length > 0
        ) {
          return false;
        } else if (
          order &&
          order.id &&
          order?.statusString === 'New' &&
          order?.toTalAmount === 0 &&
          listEsimValid?.length <= 0
        ) {
          return true;
        } else {
          return true;
        }
      case 'cancel':
        if (order && order.id && order.statusString === 'PaymentFail') {
          return false;
        } else {
          return true;
        }
      default:
        break;
    }
  };
  const checkDuplicate = (phoneNumber: string, serial: string) => {
    const suc = !!listSuccess.find(
      (success: any) =>
        success.serialNew === serial ||
        success.phoneNumber ===
          (phoneNumber[0] === '0' ? phoneNumber.replace('0', '84') : phoneNumber),
    );
    const failed = !!listErrors.find(
      (errorr: any) =>
        errorr.serialNew === serial ||
        errorr.phoneNumber ===
          (phoneNumber[0] === '0' ? phoneNumber.replace('0', '84') : phoneNumber),
    );
    if (suc) {
      return true;
    }
    if (failed) {
      return true;
    } else {
      return false;
    }
  };

  const isNumBer = (e: any) => {
    console.log('e', e.target.value);
    const valu = e.target.value;

    if (!Number(valu)) {
      return;
    }

    form.setFieldValue('phoneNumber', valu);
  };

  const filterDataSource = (data: any) => {
    let cloneData = JSON.parse(JSON.stringify(data));
    const count = activeTab === '1' ? listSuccess.length : listErrors.length;
    if (
      count == params.pageSize * (params.pageIndex > 1 ? params.pageIndex - 1 : 1) &&
      params.pageIndex > 1
    ) {
      const data = params;
      data.pageIndex = data.pageIndex > 1 ? data.pageIndex - 1 : 1;
      data.pageSize = 10;
      setSearchParams(data);
    }
    cloneData = cloneData.filter((_error: any, index: any) => {
      // debugger
      const pageIndex = params.pageIndex ? parseInt(params.pageIndex) : 1;
      const pageSize = params.pageSize ? parseInt(params.pageSize) : 10;
      if (pageIndex === 1 && index < pageIndex * pageSize) {
        return true;
      } else if (index < pageIndex * pageSize && index >= (pageIndex - 1) * pageSize) {
        return true;
      }
      // else if() {

      // }
      else return false;
    });

    return cloneData;
  };

  const handlePrePayment = async () => {
    if (order?.id) {
      if (order?.simType === SimType.ESIM) {
        try {
          await checkEsimExpired(order?.id).then((res) => {
            if (res?.esimExpired?.length > 0) {
              setListEsimExpired(res?.esimExpired);
              setListEsimValid(res?.esimValid);
              setIsOpenModalCheckEsimExpired(true);
            } else if (res?.esimExpired?.length <= 0 && res?.esimValid?.length > 0) {
              onClickConfirmEsimExpired();
            }
          });
        } catch (error: any) {
          console.log(error);
        }
      } else {
        onClickConfirmEsimExpired();
      }
    } else {
      console.log('error');
    }
  };

  const onClickConfirmEsimExpired = () => {
    setIsOpenModalCheckEsimExpired(false);
    // fetchData(params?.id);
    dispatch(
      setModalState({
        open: true,
        content: 'change-sim-info',
        selectedChangeSim: order?.id,
        width: '70%',
      }),
    );
    dispatch(
      setGlobalState({
        isOpenPayment: true,
      }),
    );
  };

  const onClickCancelEsimExpired = () => {
    setIsOpenModalCheckEsimExpired(false);
    fetchData(params?.id);
  };
  return (
    <>
      <Loading />
      <div>
        {/* <Button
          onClick={() => {
            navigate('/management/order-assigned-package');
          }}
          danger
        >
          Quay lại
        </Button> */}
        <Row className='pb-[6px]' justify='end'>
          <Button
            type={currentClick === 'cancle' ? 'primary' : 'default'}
            onClick={() => {
              setOpenChangeSim(true);
              setCurrentClick('cancle');
            }}
            disabled={isDisableSave('changeSim')}
            className='mx-[6px] w-[120px]'
            htmlType='submit'
          >
            Chuyển SIM
          </Button>
          {JSON.stringify(order) === JSON.stringify({}) || (order && order?.toTalAmount <= 0) ? (
            ''
          ) : (
            <Button
              disabled={isDisableSave('payment')}
              className='mx-[6px]  w-[120px]'
              htmlType='submit'
              onClick={() => {
                handlePrePayment();
              }}
            >
              Thanh toán
            </Button>
          )}

          {form.getFieldValue('id') && form.getFieldValue('statusString') === 'PaymentFail' ? (
            <Button
              type={currentClick === 'cancel' ? 'primary' : 'default'}
              disabled={isDisableSave('cancel')}
              onClick={() => {
                setCurrentClick('cancel');
                setOpenCancleOrder(true);
              }}
              className={`mx-[6px]  w-[120px]`}
            >
              Hủy
            </Button>
          ) : (
            ''
          )}
          <Button
            disabled={isDisableSave('delete')}
            className='mx-[6px]  w-[120px]'
            onClick={() => {
              setOpenDeleteOrder(true);
            }}
            htmlType='submit'
          >
            Xóa
          </Button>
          <Button
            type={currentClick === 'edit' ? 'primary' : 'default'}
            disabled={isDisableSave('edit') || currentClick === 'edit'}
            onClick={() => {
              setCurrentClick('edit');
              // updateOrder();
            }}
            className={`mx-[6px]  w-[120px]`}
          >
            Sửa
          </Button>
          <Button
            type={currentClick === 'save' ? 'primary' : 'default'}
            disabled={isDisableSave('save') || currentClick === 'save'}
            onClick={() => {
              if (listSuccess.length > 0) {
                setCurrentClick('edit');
                createOrder();
              } else {
                message.error('Vui lòng import số!');
              }
            }}
            className={`ml-[12px]  w-[120px] `}
          >
            Lưu
          </Button>
        </Row>
        <BoxWithLabel title='Thông tin đơn hàng'>
          <Form
            onFieldsChange={handleFormChange}
            form={form}
            initialValues={{
              typeOrder: 'Đổi sim',
              toTalAmount: order?.toTalAmount ? formatMoney(order?.toTalAmount, 'đ') : '',
            }}
            onFinish={async () => {
              const res = await checkImportExcelSerial({
                distributorId: merchantInfo.merchantId,
                excelFile: fileInfo.file,
                orderId: form.getFieldValue('id'),
                simType,
              });
              if (res) {
                const unique = res.simError.filter(
                  (obj, index) =>
                    res.simError.findIndex(
                      (item) =>
                        item.serialNew === obj.serialNew && item.phoneNumber === obj.phoneNumber,
                    ) === index,
                );
                setListSuccess(res.simSuccess);
                setListErros(unique);
                setOriSuccess(JSON.stringify(res.simSuccess));
                setOriErros(JSON.stringify(unique));
                dispatch(
                  setChangeSimState({
                    listErrorsStore: unique,
                    listSuccessStore: res.simSuccess,
                  }),
                );
              }
            }}
            className='block'
          >
            <Row gutter={[12, 0]}>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='id'
                  label='Mã đơn hàng'
                >
                  <Input title={form.getFieldValue('id')} readOnly={true} className='w-full' />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='countSerial'
                  label='Tổng cộng'
                >
                  <Input readOnly={true} className='w-full' placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='toTalAmount'
                  label='Thành tiền'
                >
                  <Input readOnly={true} className='w-full' placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='createdBy'
                  label='Người tạo'
                >
                  <Input readOnly={true} className='w-full' placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='typeOrder'
                  label='Loại đơn hàng'
                >
                  <Input readOnly={true} className='w-full' />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='quantitySuccess'
                  label='Thành công'
                >
                  <Input readOnly={true} className='w-full' placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={0} sm={0} xl={0} xxl={6}>
                {/* <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='transactionId'
                  label='Số giao dịch thanh toán'
                >
                  <Input readOnly={true} className='w-full' placeholder='' />
                </Form.Item> */}
              </Col>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='createdAt'
                  label='Thời gian tạo'
                >
                  <Input
                    title={form.getFieldValue('createdAt')}
                    readOnly={true}
                    className='w-full'
                    placeholder=''
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='simType'
                  label='Loại SIM'
                >
                  <Select
                    options={[
                      {
                        value: SimType.PHYSICAL_SIM,
                        label: simTypeToText(SimType.PHYSICAL_SIM),
                      },
                      {
                        value: SimType.ESIM,
                        label: simTypeToText(SimType.ESIM),
                      },
                    ]}
                    defaultValue={SimType.PHYSICAL_SIM}
                    onChange={(value) => {
                      if (listSuccess?.length > 0) {
                        setOpenModalConfirmSimType(true);
                      } else {
                        setSimType(value);
                      }
                    }}
                    disabled={order?.id ?? false}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='quantityFailed'
                  label='Thất bại'
                >
                  <Input readOnly={true} className='w-full' placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='transactionId'
                  label={
                    JSON.stringify(order) === JSON.stringify({}) ||
                    (order && order?.toTalAmount === 0)
                      ? ''
                      : 'Mã giao dịch'
                  }
                >
                  {/* <Input readOnly={true} className='w-full' placeholder=''> */}
                  {JSON.stringify(order) === JSON.stringify({}) ||
                  (order && order?.toTalAmount === 0) ? (
                    ''
                  ) : (
                    <span
                      onClickCapture={() => {
                        dispatch(
                          setModalState({
                            open: true,
                            content: 'change-sim-info',
                            selectedChangeSim: order?.id,
                            width: '70%',
                          }),
                        );
                        dispatch(
                          setGlobalState({
                            isOpenPayment: true,
                          }),
                        );
                      }}
                      className='underline text-blue-700 cursor-pointer'
                    >
                      {order?.transactionId}
                    </span>
                  )}

                  {/* </Input> */}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='updatedBy'
                  label='Người cập nhật gần nhất'
                >
                  <Input readOnly={true} className='w-full' placeholder='' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 0]} justify='space-between'>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='statusString'
                  label='Trạng thái đơn hàng'
                >
                  <Input readOnly={true} className='w-full' placeholder='' />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} xl={8} xxl={6}>
                <Form.Item
                  labelCol={{ style: { width: 180, textAlign: 'start' } }}
                  name='updatedAt'
                  label='Thời gian cập nhật'
                >
                  <Input
                    title={form.getFieldValue('updateAt')}
                    readOnly={true}
                    className='w-full'
                    placeholder=''
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='start'>
              <Col span={24} className='place-items-start flex'>
                <Radio.Group
                  disabled={
                    (order?.id && currentClick !== 'edit') ||
                    order?.statusString === 'Paid' ||
                    order?.statusString === 'Cancel' ||
                    order?.statusString === 'Completed'
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    onchangeTypeInput(e);
                  }}
                  value={typeInput}
                >
                  <Radio value={1}>Nhập số thuê bao</Radio>
                  <Radio value={2}>Import file</Radio>
                </Radio.Group>
              </Col>
            </Row>
            {typeInput === 2 ? (
              <Row justify='start' className='mt-[12px]'>
                <Col span={24} className='place-items-start flex'>
                  {!fileImport && order?.fileUrl && (
                    <Col>
                      <a href={order?.fileUrl} download>
                        <Button type='link'>{order?.fileName || 'Excel file'}</Button>
                      </a>
                    </Col>
                  )}
                  <Upload
                    disabled={
                      (order?.id && currentClick !== 'edit') ||
                      order?.statusString === 'Paid' ||
                      order?.statusString === 'Cancel' ||
                      order?.statusString === 'Completed'
                        ? true
                        : false
                    }
                    {...props}
                    fileList={fileImport ? [fileImport] : []}
                  >
                    <Button
                      disabled={
                        (order?.id && currentClick !== 'edit') ||
                        order?.statusString === 'Paid' ||
                        order?.statusString === 'Cancel' ||
                        order?.statusString === 'Completed'
                          ? true
                          : false
                      }
                    >
                      Chọn file
                    </Button>
                  </Upload>
                  <Button
                    disabled={!fileImport ? true : false}
                    className='mx-[6px] w-[120px]'
                    htmlType='submit'
                  >
                    Import
                  </Button>
                  <Button
                    disabled={
                      (order?.id && currentClick !== 'edit') ||
                      order?.statusString === 'Paid' ||
                      order?.statusString === 'Cancel' ||
                      order?.statusString === 'Completed'
                        ? true
                        : false
                    }
                    className='mx-[6px] w-[160px]'
                    onClick={() => handleExportTemplate()}
                  >
                    Export template
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row gutter={[12, 0]} className='pt-[18px]'>
                <Col>
                  <Form.Item
                    rules={[
                      { required: true, message: 'Vui lòng nhập số điện thoại!' },
                      {
                        pattern: REGEX_COMMON.phoneNumber,
                        message: 'Số điện thoại không hợp lệ!',
                      },
                    ]}
                    labelCol={{ style: { width: 180, textAlign: 'start' } }}
                    name='phoneNumber'
                    label='Số thuê bao'
                  >
                    <Input
                      type='number'
                      onChange={(e) => {
                        isNumBer(e);
                        if (e.target.value.length <= 11) {
                          form.setFieldValue('phoneNumber', e.target.value);
                        } else {
                          form.setFieldValue('phoneNumber', e.target.value.substring(0, 11));
                        }
                        e.preventDefault();
                      }}
                      onKeyDown={(evt) => {
                        if (!evt.ctrlKey && !evt.metaKey) {
                          if (
                            evt.key === 'e' ||
                            (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                            evt.key === '+' ||
                            evt.key === '.' ||
                            evt.key === '-' ||
                            Number(evt.key) > 9 ||
                            Number(evt.key) < 0
                          ) {
                            setTimeout(() => {
                              form.setFieldValue(
                                'phoneNumber',
                                form.getFieldValue('phoneNumber').replace(evt.key, ''),
                              );
                            }, 0);
                            evt.preventDefault();
                          }
                        }
                      }}
                      disabled={
                        (order?.id && currentClick !== 'edit') ||
                        order?.statusString === 'Paid' ||
                        order?.statusString === 'Cancel' ||
                        order?.statusString === 'Completed'
                          ? true
                          : false
                      }
                      maxLength={11}
                      className='w-full'
                      placeholder=''
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    rules={[
                      { required: true, message: 'Vui lòng nhập số serial!' },
                      {
                        pattern: REGEX_COMMON.serialNumber,
                        message: 'Số serial không hợp lệ!',
                      },
                    ]}
                    labelCol={{ style: { width: 180, textAlign: 'start' } }}
                    name='serialNumber'
                    label='Sim serial mới'
                  >
                    <Input
                      type='number'
                      onChange={(e) => {
                        if (e.target.value.length <= 20) {
                          form.setFieldValue('serialNumber', e.target.value);
                        } else {
                          form.setFieldValue('serialNumber', e.target.value.substring(0, 20));
                        }
                        e.preventDefault();
                      }}
                      onKeyDown={(evt) => {
                        if (!evt.ctrlKey && !evt.metaKey) {
                          if (
                            evt.key === 'e' ||
                            (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                            evt.key === '+' ||
                            evt.key === '.' ||
                            evt.key === '-' ||
                            Number(evt.key) > 9 ||
                            Number(evt.key) < 0
                          ) {
                            setTimeout(() => {
                              form.setFieldValue(
                                'serialNumber',
                                form.getFieldValue('serialNumber').replace(evt.key, ''),
                              );
                            }, 0);
                            evt.preventDefault();
                          }
                        }
                      }}
                      disabled={
                        (order?.id && currentClick !== 'edit') ||
                        order?.statusString === 'Paid' ||
                        order?.statusString === 'Cancel' ||
                        order?.statusString === 'Completed'
                          ? true
                          : false
                      }
                      maxLength={20}
                      className='w-full'
                      placeholder=''
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Form.Item shouldUpdate>
                  <Button
                    disabled={
                      (order?.id && currentClick !== 'edit') ||
                      order?.statusString === 'Paid' ||
                      order?.statusString === 'Cancel' ||
                      order?.statusString === 'Completed'
                        ? true
                        : false || disabledSave
                    }
                    type='primary'
                    onClick={() => {
                      addPhoneNumber(
                        form.getFieldValue('phoneNumber'),
                        form.getFieldValue('serialNumber'),
                      );
                    }}
                    className='ml-[12px]  w-[120px]'
                  >
                    Thêm
                  </Button>
                </Form.Item>
              </Row>
            )}
            {/* row two */}
          </Form>
        </BoxWithLabel>
        {/* Danh sách thuê bao */}
        <BoxWithLabel classNames='mt-[24px]' title='Danh sách thuê bao'>
          <Tabs
            tabBarExtraContent={operations}
            defaultActiveKey='1'
            type='card'
            size='middle'
            onTabClick={(activeKey: string) => {
              setActiveTab(activeKey);
              const data = params;
              data.pageIndex = 1;
              data.pageSize = 10;
              setSearchParams(data);
            }}
            items={new Array(2).fill(null).map((_, i) => {
              const id = String(i + 1);
              return {
                label: id === '1' ? `Danh sách đúng` : 'Danh sách sai',
                key: id,
                children: (
                  <TableContent
                    scroll={{
                      x: 'max-content',
                    }}
                    locale={{ emptyText: 'Không tìm thấy kết quả' }}
                    totalRecord={id === '1' ? listSuccess?.length : listErrors?.length}
                    dataSource={
                      id === '1' ? filterDataSource(listSuccess) : filterDataSource(listErrors)
                    }
                    columns={order?.simType === SimType.ESIM ? columnsEsim : columnsPhysicalSim}
                    onChange={(pagination) => {
                      console.log('pagination', pagination);
                    }}
                    onPageChange={(pageNumber, pageSize) => {
                      console.log('pageNumber', pageNumber);
                      console.log('pageSize', pageSize);
                    }}
                  />
                ),
              };
            })}
          />
        </BoxWithLabel>
      </div>
      <Modal
        open={openDelete}
        title='Bạn có muốn xóa thuê bao khỏi danh sách?'
        onOk={() => onDeletePhoneNumber()}
        onCancel={() => {
          setOpenDelete(false);
        }}
        closable={false}
        centered
        maskClosable={false}
        okText='Đồng ý'
        cancelText='Không'
        // confirmLoading={loading}
      />
      <ConfirmModal
        isOpen={openDeleteOrder}
        title='Bạn có muốn xóa Đơn hàng này không?'
        onOK={() => deleteOrder()}
        onCancel={() => {
          setOpenDeleteOrder(false);
        }}
      />
      <ConfirmModal
        isOpen={openCancleOrder}
        title='Bạn có muốn hủy Đơn hàng này không?'
        onOK={() => cancleOrder()}
        onCancel={() => {
          setOpenCancleOrder(false);
        }}
      />
      <ConfirmModal
        isOpen={openChangeSim}
        title={`Hệ thống sẽ chuyển đổi ${listSuccess.length} sim. Bạn đồng ý không`}
        onOK={() => changeSim()}
        onCancel={() => {
          setOpenChangeSim(false);
        }}
      />
      {isOpenEsimInfo && (
        <ModalEsimInfo
          open={isOpenEsimInfo}
          setIsOpenEsimInfo={setIsOpenEsimInfo}
          data={detailEsim}
        />
      )}
      {openModalConfirmSimType && (
        <ModalConfirm
          open={openModalConfirmSimType}
          content={
            <div className='pb-0 p-8'>
              <div className='font-black text-3xl mb-4'>Đổi loại SIM?</div>
              <p>Bạn có muốn thực hiện đổi loại SIM?</p>
              <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
                <Button
                  className='h-full w-full'
                  onClick={() => {
                    if (simType === SimType.PHYSICAL_SIM) {
                      setSimType(SimType.ESIM);
                    } else {
                      setSimType(SimType.PHYSICAL_SIM);
                    }
                    setOpenModalConfirmSimType(false);
                  }}
                >
                  XÁC NHẬN
                </Button>
                <Button
                  className='h-full w-full'
                  onClick={() => {
                    form.setFieldValue('simType', simType);
                    setOpenModalConfirmSimType(false);
                  }}
                >
                  THOÁT
                </Button>
              </div>
            </div>
          }
        />
      )}
      {isOpenModalCheckEsimExpired && (
        <ModalCheckEsimExpired
          isOpen={isOpenModalCheckEsimExpired}
          setIsOpenModal={setIsOpenModalCheckEsimExpired}
          listEsimExpired={listEsimExpired}
          listEsimValid={listEsimValid}
          onClickConfirm={onClickConfirmEsimExpired}
          onClickCancel={onClickCancelEsimExpired}
        />
      )}
    </>
  );
};

export default CreateChangeSim;
