export enum TransactionTypeLot {
  ALL = "ALL",
  None = "None",
  DataPackage = "DataPackage"
}

export enum StatusEsimLot {
  ALL = "ALL",
  NOT_SALE = "1",
  ERROR = "2",
  EXPIRED = "4",
  ACTIVE = "6",
  INACTIVE = "5",
  CANCEL = "7"
}

export enum ValueAll {
  ALL = "ALL"
}