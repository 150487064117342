import { SearchOutlined } from '@ant-design/icons';
import {Button, DatePicker, Form, Input, Select, Typography} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IListColumn, ISortColumn, PaginationResponse } from 'src/@types/search-params';
import { getConnectionHistory } from 'src/api/connection';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { ConnectionType, historyStatus } from 'src/shared/enum/connection.enum';
import { IConnectionBatch } from 'src/shared/interface/connection';
import {exportExcel, formatPhoneNumber, formatPhoneNumberV2, parseQueryString} from 'src/utils';
import { connectionTypeToString } from 'src/utils/connection';
import {simTypeToText} from "../../../../utils/sim.enum";
import {SimType} from "../../../../shared/enum/sim.enum";

const HistoryConnection = () => {
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  const [data, setData] = useState<PaginationResponse<[IConnectionBatch]>>();
  const [sortColumn] = useState<ISortColumn>();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const params = location.search;
  const dispatch = useDispatch();
  const { openTabs } = useAppSelector((state) => state.global);
  const navigate = useNavigate();

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current >= moment().endOf('day');
  };

  const handleFormSearch = (value: any) => {
    let values = { ...value };
    values = {
      ...values,
      dateFrom:
        value.dateRange && value?.dateRange[0]
          ? value?.dateRange[0].format('YYYY-MM-DD')
          : undefined,
      dateTo:
        value.dateRange && value?.dateRange[1]
          ? value?.dateRange[1].format('YYYY-MM-DD')
          : undefined,
      dateRange: undefined,
    };
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        delete values[key];
      }
    });

    setSearchParams({ ...searchParams, ...values });
  };

  const fetchListConnectionHistory = async () => {
    const params = parseQueryString(location.search);
    const res = await getConnectionHistory({
      ...params,
      DistributorId: JSON.parse(merchantInfo as string)?.merchantId,
    });
    if (res) {
      setData(res);
    }
  };

  const handleDetailOrder = (order: any) => {
    dispatch(
      setGlobalState({
        activeKeyMenu: `connection/history/list?id=${order.serial}&orderId=${order.orderId}`,
      }),
    );
    if (
      !openTabs.find(
        (tab) => tab.key === `connection/history/list?id=${order.serial}&orderId=${order.orderId}`,
      )
    ) {
      dispatch(
        setGlobalState({
          openTabs: [
            ...openTabs,
            {
              label: 'Xem lịch sử đấu nối ' + order.serial,
              children: '',
              key: `connection/history/list?id=${order.serial}&orderId=${order.orderId}`,
              closable: true,
            },
          ],
        }),
      );
    }
    navigate(`/management/connection/history/list?id=${order.serial}&orderId=${order.orderId}`);
  };

  useEffect(() => {
    fetchListConnectionHistory();
  }, [location.search]);

  const handleExport = () => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `lich_su_dau_noi_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    const url =
      process.env.REACT_APP_API_URL +
      '/api/Provider/history/export?DistributorId=' +
      JSON.parse(merchantInfo as string)?.merchantId +
      params.replace('?', '&');
    exportExcel(url).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
    });
  };

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    {
      name: 'Mã lô',
      fieldName: 'orderId',
      renderSpecialCard: (record) => {
        return (
          (record?.type === 'UserProfileActiveLot' || record?.type === 'ActiveLot') && (
            <button
              className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
              onClick={() => handleDetailOrder(record)}
            >
              {record.orderId ?? ''}
            </button>
          )
        );
      },
      isHideSort: true,
    },
    { name: 'Mã DN', fieldName: 'enterpriseId', isHideSort: true },
    { name: 'Tên DN', fieldName: 'enterpriseName', isHideSort: true },
    {
      name: 'Loại đấu nối',
      fieldName: 'connectionType',
      renderSpecialCard: (record) => {
        return (
          <span>{record.connectionType && connectionTypeToString(record.connectionType)}</span>
        );
      },
      isHideSort: true,
    },
    {
      name: 'Loại SIM',
      fieldName: 'simType',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text>{simTypeToText(record?.simType)}</Typography.Text>
        );
      }
    },
    {
      name: 'SIM serial',
      fieldName: 'serial',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return <p>{record.serial ?? ''}</p>;
      },
    },
    {
      name: 'Số điện thoại',
      fieldName: 'phoneNumber',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return (
          <span>{formatPhoneNumberV2(record?.phoneNumber)}</span>
        );
      }
    },
    { name: 'Trạng thái', fieldName: 'statusString', isHideSort: true },
    { name: 'Mã lỗi', fieldName: 'errorCode', isHideSort: true },
    { name: 'Mô tả lỗi', fieldName: 'errorMessage', isHideSort: true },
    { name: 'Nguời tạo', fieldName: 'createdBy', isHideSort: true },
    {
      name: 'Ngày tạo',
      fieldName: 'createdAt',
      renderSpecialCard: (record) => {
        return (
          <>
            <p>
              <p>{record.createdAt}</p>
            </p>
          </>
        );
      },
    },
    { name: 'Nguời cập nhật', fieldName: 'updatedBy' },
    {
      name: 'Ngày cập nhật',
      fieldName: 'updatedAt',
      renderSpecialCard: (record) => {
        return <p>{record?.updatedAt}</p>;
      },
    },
    {
      name: 'Thao tác',
      fieldName: 'id',
      renderSpecialCard: (order) => {
        return (
          <button
            className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
            onClick={() => handleDetailOrder(order)}
          >
            Chi tiết
          </button>
        );
      },
      isHideSort: true,
    },
  ];

  return (
    <div>
      <Form onFinish={handleFormSearch} layout='inline' className='gap-2'>
        <Form.Item name='search'>
          <Input
            prefix={<SearchOutlined />}
            placeholder='Mã lô, Sim Serial, Số điện thoại, Mã DN'
            className='w-[400px]'
          />
        </Form.Item>
        <Form.Item name='simType' label='Loại SIM'>
          <Select
            showSearch
            className='min-w-[150px]'
            placeholder='Vui lòng chọn'
            options={[
              {
                value: null,
                label: 'Tất cả',
              },
              {
                value: SimType.PHYSICAL_SIM,
                label: simTypeToText(SimType.PHYSICAL_SIM),
              },
              {
                value: SimType.ESIM,
                label: simTypeToText(SimType.ESIM),
              },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item name='type' label='Loại đấu nối'>
          <Select
            showSearch
            className='min-w-[150px]'
            placeholder='Vui lòng chọn'
            options={[
              {
                value: null,
                label: 'Tất cả',
              },
              {
                value: ConnectionType.ENTERPRISE,
                label: connectionTypeToString(ConnectionType.ENTERPRISE),
              },
              {
                value: ConnectionType.INDIVIDUAL,
                label: connectionTypeToString(ConnectionType.INDIVIDUAL),
              },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item name='StatusString' label='Trạng thái'>
          <Select
            showSearch
            className='min-w-[150px]'
            placeholder='Vui lòng chọn'
            options={historyStatus}
          ></Select>
        </Form.Item>
        <Form.Item name='dateRange' label='Ngày tạo'>
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            placeholder={['Từ ngày', 'đến ngày']}
            format={['DD/MM/YYYY', 'DD/MM/YYYY']}
          />
        </Form.Item>
        <div className='row-button' style={{ justifyContent: 'flex-end', margin: '1.5rem 0' }}>
          <Button type='primary' htmlType='submit'>
            Tìm kiếm
          </Button>
          <Button onClick={handleExport}>Export</Button>
        </div>
      </Form>
      <div className='mt-6'>
        <TableContent
          bordered
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: 'Không tìm thấy kết quả' }}
          dataSource={data?.results ?? []}
          columns={columns}
          totalRecord={data?.rowCount ?? 0}
          sortColumn={sortColumn}
          onSort={(sort: ISortColumn) => {
            const pagination = parseQueryString(location.search);
            handleFormSearch({
              ...pagination,
              pageIndex: pagination.pageIndex ?? 1,
              pageSize: pagination.pageSize ?? 10,
              sortField: sort.fieldName,
              asc:
                sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? 'false' : undefined,
            });
          }}
        />
      </div>
    </div>
  );
};

export default HistoryConnection;
