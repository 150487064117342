import React from 'react';

const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='20' fill='white' />
      <path
        d='M17.5181 12.0801H23.9384C26.0052 12.0801 27.6803 13.7562 27.6803 15.822V23.6966C27.6803 25.7633 26.0052 27.4394 23.9384 27.4394H17.5181M16.0274 16.3176L12.32 19.76M12.32 19.76L16.0274 23.2024M12.32 19.76L21.324 19.7604'
        stroke='black'
        strokeWidth='1.44'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default LogoutIcon;
