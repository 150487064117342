import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import SimAttachmentItem from './SimAttachmentItem';
import { dataGet } from 'src/utils';

export default function SimImageProfileBox({ accountAttribute = undefined }: any): JSX.Element {
  return (
    <BoxWithLabel title={'Hình ảnh'} classNames='mt-4'>
      <div className='grid grid-cols-5 gap-5'>
        <SimAttachmentItem
          file={dataGet(accountAttribute, 'frontDocumentFile')}
          title={'Mặt trước giấy tờ'}
        />
        <SimAttachmentItem
          file={dataGet(accountAttribute, 'backDocumentFile')}
          title={'Mặt sau giấy tờ'}
        />
        <SimAttachmentItem
          file={dataGet(accountAttribute, 'enterprisePermitFile')}
          title={'Giấy phép kinh doanh'}
        />
        <SimAttachmentItem
          file={dataGet(accountAttribute, 'contactSelfieImage')}
          title={'Ảnh chụp người đại diện'}
        />
        <SimAttachmentItem
          file={dataGet(accountAttribute, 'contactSignatureImage')}
          title={'Chữ ký người đại diện'}
        />
        <SimAttachmentItem
          file={dataGet(accountAttribute, 'legalPeopleFile')}
          title={'Danh sách cá nhân hợp pháp'}
        />
        <SimAttachmentItem
          file={dataGet(accountAttribute, 'powerOfAttorneyFile')}
          title={'Giấy ủy quyền'}
        />
        <SimAttachmentItem
          file={dataGet(accountAttribute, 'negotiatorImgFile')}
          title={'Ảnh chụp người đến giao kết hợp đồng'}
        />
        <SimAttachmentItem
          file={dataGet(accountAttribute, 'contractTermFile')}
          title={'Điều kiện giao dịch chung'}
        />
        <SimAttachmentItem
          file={dataGet(accountAttribute, 'commonConditionFile')}
          title={'Điều khoản chung của hợp đồng'}
        />
        <SimAttachmentItem
          file={dataGet(accountAttribute, 'signatureImgFile')}
          title={'Chữ ký người đến giao dịch'}
        />
      </div>
    </BoxWithLabel>
  );
}
