import { Image, Space, Table, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { getSimHistoryChange } from 'src/api/activeSim';
import { dataGet, parseQueryString } from 'src/utils';
import dayjs from 'dayjs';

const columns = [
  {
    title: 'STT',
    render: (text: string, record: any, index: number) => {
      return index + 1;
    },
  },
  {
    title: 'Thời gian',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 120,
    render: (text: string) => {
      return (
        <Typography.Text>{text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : ''}</Typography.Text>
      );
    },
  },
  {
    title: 'Người thực hiện',
    dataIndex: 'update_by',
    render: (text: string) => {
      return <Typography.Text className={'text-center'}>{text}</Typography.Text>;
    },
  },
  {
    title: 'Hành động',
    width: 300,
    dataIndex: 'extra_data.message',
    render: (_: any, record: any) => {
      return (
        <Typography.Text className={'text-center'}>
          {dataGet(record, 'extra_data.message')}
        </Typography.Text>
      );
    },
  },
  {
    title: 'Nội dung',
    width: 400,
    dataIndex: 'extra_data.content',
    render: (_: any, record: any) => {
      return (
        <Space direction={'vertical'} size={'small'}>
          {dataGet(record, 'extra_data.content', '')
            .split(';')
            ?.map((item: string, index: number) => (
              <Typography.Text key={index}>{item}</Typography.Text>
            ))}
        </Space>
      );
    },
  },
  {
    title: 'Hình ảnh',
    dataIndex: 'extra_data.images',
    render: (_: any, record: any) => {
      return (
        <Space>
          {dataGet(record, 'extra_data.images', []).map((image: any, index: number) => (
            <Image width={75} height={75} key={index} src={image?.to} alt={'Image change'} />
          ))}
        </Space>
      );
    },
  },
];

export default function SimHistoryChangeTab() {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchSimHistoryChangeData = async (serial: string) => {
    setIsLoading(true);
    const res = await getSimHistoryChange(serial);
    setHistory(res?.data);
    setIsLoading(false);
  };

  useEffect(() => {
    const { serial } = parseQueryString(location.search);
    if (serial) {
      fetchSimHistoryChangeData(serial);
    }
  }, [location.search]);

  return (
    <div className={'sim-history-change-tab'}>
      <Table
        loading={isLoading}
        bordered
        columns={columns}
        dataSource={history}
        rowKey={(record) => {
          return record.id;
        }}
      />
    </div>
  );
}
