export enum SimStatus {
  NOT_SALE = 1,
  IN_ACTIVE = 2,
  ACTIVATION_REQUEST = 3,
  ACTIVATION_SUCCESS = 4,
  ACTIVATION_FAIL = 5,
  EXPIRED = 6,
}

export enum StatusProgressSim {
  SUCCESS = 0,
  FAILED = 1,
  WAITING = 2,
  PROCESSING = 3,
  PHONENUMBERFAILED = 4,
  SIMFAILED = 5,
}

export enum SimType {
  PHYSICAL_SIM = 1,
  ESIM = 2,
}
