import { Col, Radio, Row } from 'antd';
import { Fragment } from 'react';
import { RxCross2 } from 'react-icons/rx';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { useAppDispatch } from 'src/redux/hook';
import { resetModalState } from 'src/redux/slice/modal.store';

function TransactionInfoModal() {
  const dispatch = useAppDispatch();

  return (
    <Fragment>
      <div className='flex justify-end'>
        <button
          onClick={() => dispatch(resetModalState())}
          className=' text-3xl cursor-pointer border-transparent bg-transparent'
        >
          <RxCross2 />
        </button>
      </div>
      <BoxWithLabel title='Thông tin giao dịch' classNames='mb-[1.5rem]'>
        <Row gutter={24} className='mb-[1rem]'>
          <Col span='12'>
            <Row>
              <Col span='12'>Mã giao dịch</Col>
              <Col span='12'>-</Col>
              <Col span='12'>Ngày</Col>
              <Col span='12'>-</Col>
              <Col span='12'>Diễn giải</Col>
              <Col span='12'>-</Col>
            </Row>
          </Col>
          <Col span='12'>
            <Row>
              <Col span='12'>Tình trạng</Col>
              <Col span='12'>-</Col>
              <Col span='12'>Người thanh toán</Col>
              <Col span='12'>-</Col>
              <Col span='12'>Đơn hàng tham chiếu</Col>
              <Col span='12'>-</Col>
            </Row>
          </Col>
        </Row>
        <p className='font-bold mb-[0.5rem]'>Chi tiết hóa đơn:</p>
        <div className='normal-table'>
          <table width='100%' className='normal-table'>
            <thead>
              <tr>
                <th>STT</th>
                <th>Số lượng</th>
                <th>Mã hàng</th>
                <th>Thành tiền</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Tổng cộng</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BoxWithLabel>
      <BoxWithLabel title='Hình thức thanh toán' classNames='mb-[1.5rem]'>
        <Radio checked className='mb-[0.5rem]'>
          Localpay
        </Radio>
        <Row gutter={24}>
          <Col span='4'>LocalPay ID:</Col>
          <Col span='4'>-</Col>
          <Col span='4'>Tên chủ tài khoản:</Col>
          <Col span='4'>-</Col>
        </Row>
      </BoxWithLabel>
      <Row gutter={24}>
        <Col span='3'>Người tạo:</Col>
        <Col span='3'>-</Col>
      </Row>
      <Row gutter={24}>
        <Col span='3'>Ngày tạo:</Col>
        <Col span='3'>-</Col>
      </Row>
    </Fragment>
  );
}

export default TransactionInfoModal;
