import { Modal } from 'antd';
import ChangeSimInfoModal from 'src/pages/management/changeSim/ChangeSimInfo';
import BillingInfoModal from 'src/pages/management/package_assigned/modals/BillingInfo';
import TransactionInfoModal from 'src/pages/management/transaction/modals/TransactionInfo';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { resetModalState } from 'src/redux/slice/modal.store';
import ModalTopUp from './modals/ModalTopUp';

const CModal = () => {
  const { open, content, className, selectedHistory, selectedChangeSim, ...props } = useAppSelector(
    (state) => state.modal,
  );
  const dispatch = useAppDispatch();
  const renderContent = () => {
    switch (content) {
      case 'top-up':
        return <ModalTopUp selectedHistory={selectedHistory!} />;
      case 'billing-info':
        return <BillingInfoModal />;
      case 'transaction-info':
        return <TransactionInfoModal />;
      case 'change-sim-info':
        return <ChangeSimInfoModal selectedChangeSim={selectedChangeSim!} />;
      default:
        return <></>;
    }
  };
  return (
    <Modal
      {...props}
      destroyOnClose
      open={open}
      maskClosable={false}
      className={`${className ?? ''} custom-modal`}
      onCancel={() => {
        dispatch(resetModalState());
      }}
      footer={false}
      closable={false}
      centered
    >
      {renderContent()}
    </Modal>
  );
};

export default CModal;
