import {
  Avatar,
  Button,
  Dropdown,
  Image,
  Input,
  List,
  Menu,
  message,
  Modal,
  Spin, Typography,
} from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { debounce } from 'lodash';
import QrScanner from 'modern-react-qr-reader';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineHistory, AiOutlineStepBackward, AiOutlineUser } from 'react-icons/ai';
import { IoArrowBack, IoSettingsOutline } from 'react-icons/io5';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import request from 'src/api/request';
import { LogoutIcon, RetryIcon, SimIcon } from 'src/components/icons';
import { SimType, StatusProgressSim } from 'src/shared/enum/sim.enum';
import { statusProgressSimToText } from 'src/utils/sim.enum';
import {
  formatDateTime,
  formatPhoneNumber, formatPhoneNumberV2,
  handleDownloadImage,
} from '../../../utils';
import ModalHistoryActiveSim from 'src/components/modals/ModalHistoryActiveSim';
import {DownloadOutlined} from '@ant-design/icons';
dayjs.extend(isBetween);

type currentProgressSimType = {
  id: string;
  phoneNumber: string;
  serialNumber: string;
};

export default function ActiveSim(): JSX.Element {
  const [dataList, setDataList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [showScanner, setShowScanner] = useState(true);
  const [serial, setSerial] = useState('');
  const [successSim, setSuccessSim] = useState(['']);
  const [phoneNumber, setPhoneNumber] = useState('');
  const qrReader = useRef<any>();
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();
  const [isShowProgressList, setIsShowProgressList] = useState(false);
  const [listProgress, setListProgress] = useState([]);
  const [isReChoose, setIsReChoose] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('all');
  const [simType, setSimType] = useState<SimType>()
  const [currentProgressSim, setCurrentProgressSim] = useState<currentProgressSimType>();
  const [idSimActived, setIdSimActived] = useState('');
  const [simActived, setSimActived] = useState<any>([]);
  const [isTimeActive, setIsTimeActive] = useState(false);
  const [isShowHistory, setIsShowHistory] = useState(false);

  const checkActiveTime = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/Provider/get-time-active-available`)
      .then((res) => {
        const timeStart = res?.data?.data.timeFrom;
        const timeEnd = res?.data?.data.timeTo;
        const currentDate = dayjs().format('YYYY-MM-DD');

        const isTimeBetween = dayjs().isAfter(dayjs(`${currentDate} ${timeStart}`)) &&
            dayjs().isBefore(dayjs(`${currentDate} ${timeEnd}`));
        console.log("isTimeBetween", isTimeBetween);
        setIsTimeActive(isTimeBetween);
      });
  };

  useEffect(() => {
    checkActiveTime();
    // handleScan(' ');
  }, []);

  const activeSerial = async () => {
    if (!isTimeActive) {
      message.error('Chưa trong thời gian hoạt động! Vui lòng thử lại sau');
      localStorage.clear();
      navigate('/login');
      return;
    }

    if (isReChoose) {
      handleRetry(null, phoneNumber);
      return;
    }

    try {
      const payload = {
        phoneNumber,
        serialNumber: serial,
        simType
      };
      setLoading(true);

      const activeRes = await request('post', 'api/Provider/check-phone-number', payload);

      if (activeRes) {
        if (activeRes?.isSucceeded) {
          message.success(activeRes?.data?.message);
          setSuccessSim((prev) => [...prev, phoneNumber]);
          getListProgress();
        } else {
          message.error(activeRes?.errorMessage);
        }
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setShowScanner(true);
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (idSimActived) {
      setIsShowProgressList(true);
      setCurrentFilter('active');

      const getSimActitve = async () => {
        const response = await request('post', 'api/Provider/get-active-status', {
          ids: [idSimActived],
        });

        setSimActived(response?.data);
      };

      getSimActitve();
    }
  }, [idSimActived]);

  const handleScan = async (data: any) => {
    if (data) {
      setLoading(true);
      if (data?.match(/[\d\W]+/g)) {
        try {
          const serialData = await request(
            'get',
            `api/Provider/check-sim-status?serial_number=${
              data?.startsWith('http') ? data : encodeURIComponent(data)
            }`,
          );

          setLoading(false);

          // const serialData = await request(
          //   'get',
          //   `api/Provider/check-sim-status?serial_number=https://dev-app.mylocal.vn?emvqr_code=esim_a8c67ed8-9657-4ee3-96c3-fdeac8c8fe13`,
          // );

          if (serialData && serialData?.data?.data?.serial_number) {
            setIsReChoose(false);
            const checkSerialSim = async () => {
              try {
                const checkRes = await request('post', 'api/Provider/check-serial-sim', {
                  serialNumber: serialData?.data?.data?.serial_number,
                  simType: serialData?.data?.data?.sim_type
                });
                setSimType(serialData?.data?.data?.sim_type)
                setLoading(false);
                if (checkRes) {
                  loadMoreData('');
                  if (checkRes?.data?.isSuccess) {
                    setSerial(serialData?.data?.data?.serial_number);
                    setShowScanner(false);
                  } else {
                    setIdSimActived(checkRes?.data?.id);
                  }
                }
              } catch (error) {
                console.log(error, 'err');
              } finally {
                setLoading(false);
              }
            };
            checkSerialSim();
          } else {
            setLoading(false);
            setShowScanner(true);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        setShowScanner(true);
      }
      setLoading(false);
    }
  };

  function handleError(err: any) {
    console.error(err);
  }
  const loadMoreData = async (keyword: string) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const response = await request(
      'get',
      `api/Provider/get-all-phoneNumber?Status=2&Limit=250&Alias=${keyword}`,
    );
    if (response) {
      /*eslint no-unsafe-optional-chaining: "error"*/
      const responseList = response?.data;
      setDataList([...dataList, ...responseList]);
      setLoading(false);
    }
  };

  const handleChange = debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (e.target.value.length === 10) {
      value = `${e.target.value.slice(1)}`;
    }
    setSearchParams({ keyword: value });
    setLoading(true);
    const response = await request(
      'get',
      `api/Provider/get-all-phoneNumber?Status=2&Limit=250&Alias=${value}`,
    );

    if (response) {
      setDataList(response?.data);
      setLoading(false);
      if (!response?.data.length) {
        message.error('Không tìm thấy kết quả');
      }
    }
  }, 300);

  const getListProgress = async () => {
    const response = await request('get', 'api/Provider/get-active-status-by-distributor', {
      status: [],
      pageIndex: null,
      pageSize: 100,
    });

    if (response) {
      setListProgress(response?.data?.results);
    }
  };

  useEffect(() => {
    const listProgressInterval = setInterval(() => {
      getListProgress();
    }, 30000);
    return () => clearInterval(listProgressInterval);
  }, []);

  const handleReChoose = (data: any) => {
    setCurrentProgressSim(data);
    setIsReChoose(true);
    setIsShowProgressList(false);
    setShowScanner(false);
    loadMoreData('');
  };

  const handleReScan = () => {
    setIsShowProgressList(false);
    setShowScanner(true);
  };

  const handleRetry = async (data: any, newPhoneNumber = '', index = 0) => {
    setLoading(true);

    const { id, phoneNumber, serialNumber } = currentProgressSim || data;

    try {
      const response = await request('post', 'api/Provider/retry-active-sim', {
        id,
        serialNumber,
        phoneNumber: newPhoneNumber || phoneNumber,
      });

      if (response?.data?.status) {
        if (currentFilter !== 'active') {
          setListProgress((prev: any) => {
            prev[index] = response?.data;
            return prev;
          });
        } else {
          const arr = [];
          arr.push(response?.data);
          setSimActived(arr);
        }

        if (
          response?.data?.status === StatusProgressSim.FAILED ||
          response?.data?.status === StatusProgressSim.PHONENUMBERFAILED ||
          response?.data?.status === StatusProgressSim.SIMFAILED
        ) {
          message.error(response?.data?.description);
        }
      }
    } catch (error: any) {
      // message.error(error?.response.data.errorMessage);
    } finally {
      setCurrentProgressSim(undefined);
      setLoading(false);
      setOpen(false);
      setShowScanner(true);
    }
  };

  const handleBtnProgressClick = () => {
    setIsShowProgressList(true);
    setCurrentFilter('all');
    getListProgress();
  };

  const renderCurrentList = () => {
    let newList = [];
    if (currentFilter !== 'active') {
      newList = listProgress;
    } else {
      newList = simActived;
    }

    const lastList = newList?.map((item: any, index: any) => {
      const { phoneNumber, status, serialNumber, description, createdAt, updatedAt, linkQrText } = item;
      const isError =
        status !== StatusProgressSim.FAILED &&
        status !== StatusProgressSim.PHONENUMBERFAILED &&
        status !== StatusProgressSim.SIMFAILED;
      return (
        <li
          key={index}
          className={`items-center py-2 px-4 bg-white rounded-xl ${
            currentFilter === 'error' && isError ? 'hidden' : 'flex'
          }`}
        >
          <div className='flex justify-between items-start w-full'>
            <div>
              <p className='text-base font-semibold m-0'>{phoneNumber}</p>
              <p className='m-0 text-[#446AF9] text-xs mt-2'>{serialNumber}</p>
              <p className='text-[#3A3A3A] text-xs'>{description}</p>
              <p className='text-[#959595] text-xs mb-0'>
                {formatDateTime(updatedAt || createdAt)}
              </p>
            </div>
            <div className={"block"}>
              {isError && (
                  <p
                      className={`p-2 flex justify-center items-center gap-2 text-xs rounded-full border-none font-semibold m-0 
                  ${status === StatusProgressSim.SUCCESS && 'bg-[#edf1e8] text-[#608f31]'} 
                  ${status === StatusProgressSim.WAITING && 'bg-[#FFFFFF] text-[#8C8C8C]'} 
                  ${status === StatusProgressSim.PROCESSING && 'bg-[#F8F9FF] text-[#446AF9]'} 
                  `}
                  >
                    {statusProgressSimToText(status)}
                  </p>
              )}
              {status === StatusProgressSim.FAILED && (
                  <Button
                      className='flex items-center gap-2 text-xs bg-[#FEF6F8] rounded-full text-[#EE284A] border-none font-semibold pr-2'
                      onClick={() => handleRetry(item, '', index)}
                  >
                    {statusProgressSimToText(status)}
                    <RetryIcon />
                  </Button>
              )}
              {status === StatusProgressSim.PHONENUMBERFAILED && (
                  <Button
                      className='flex items-center gap-2 text-xs bg-[#FEF6F8] rounded-full text-[#EE284A] border-none font-semibold pr-2'
                      onClick={() => handleReChoose(item)}
                  >
                    {statusProgressSimToText(status)}
                    <RetryIcon />
                  </Button>
              )}
              {status === StatusProgressSim.SIMFAILED && (
                  <Button
                      className='flex items-center gap-2 text-xs bg-[#FEF6F8] rounded-full text-[#EE284A] border-none font-semibold pr-2'
                      onClick={() => handleReScan()}
                  >
                    {statusProgressSimToText(status)}
                    <RetryIcon />
                  </Button>
              )}
              {linkQrText && status === StatusProgressSim.SUCCESS && (
                  <div className={"flex items-center flex-col mt-1"}>
                    <p className='m-0 mb-2 mt-1 text-xs mr-2'>Mã QR eSim:</p>
                    <div className={"flex items-center gap-2 w-full"}>
                      <Image width={45} height={45} src={linkQrText} alt={"Mã QR eSim"} />
                      <button
                          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent font-semibold`}
                          onClick={() => handleDownloadImage(linkQrText)}
                      >
                        <Typography.Text className={'mr-2 text-blue-400'}>Tải về</Typography.Text>
                        <DownloadOutlined color={"black"} />
                      </button>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </li>
      );
    });

    return lastList;
  };

  const menu = (
    <Menu
      className='rounded-xl'
      items={[
        {
          key: '1',
          className: 'rounded-xl',
          label: (
            <button className='bg-transparent border-transparent cursor-pointer'>
              <Link to={'/change-password'}>
                <p className='text-center text-sm'>Đổi mật khẩu</p>
              </Link>
            </button>
          ),
        },
      ]}
    />
  );

  return (
    <Spin spinning={loading}>
      <Button
        className='p-0 bg-[transparent] shadow-none border-none absolute top-2 left-4'
        onClick={() => {
          localStorage.clear();
          navigate('/login');
        }}
      >
        <LogoutIcon />
      </Button>
      <Dropdown trigger={['click']} overlay={menu} className='absolute top-2 right-4'>
        <Avatar
          className='cursor-pointer'
          style={{ backgroundColor: '#fff', verticalAlign: 'middle', color: 'black' }}
          size='large'
        >
          <AiOutlineUser />
        </Avatar>
      </Dropdown>
      <div className='h-screen w-screen flex flex-col justify-center items-center gap-8 overflow-hidden'>
        {!showScanner ? (
          <>
            <div className='w-3/4 shadow-xl'>
              <Input
                onKeyDown={(evt) => {
                  if (!evt.ctrlKey && !evt.metaKey) {
                    if (
                      evt.key === 'e' ||
                      evt.key === '+' ||
                      evt.key === '-' ||
                      (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                      Number(evt.key) > 9 ||
                      Number(evt.key) < 0
                    ) {
                      evt.preventDefault();
                    }
                  }
                }}
                type='tel'
                maxLength={10}
                onChange={handleChange}
                placeholder='Nhập số cần tìm...'
              />
            </div>
            <div className='ant-list-items-wrapper w-3/4'>
              <div className='h-[400px] w-full overflow-auto'>
                <List
                  locale={{
                    emptyText: (
                      <span className='text-lg font-semibold'>Không tìm thấy kết quả</span>
                    ),
                  }}
                  dataSource={dataList?.filter((x: any) => !successSim.includes(x.simNumber)) ?? []}
                  renderItem={(item: any) => (
                    <List.Item className='item shadow-md bg-[#DEEEFF]' key={item.simNumber}>
                      <Button
                        onClick={() => {
                          setOpen(true);
                          setPhoneNumber(item.simNumber);
                        }}
                        type='primary'
                        className='w-full flex justify-start shadow-xl font-medium'
                      >
                        {formatPhoneNumberV2(item.simNumber)}
                      </Button>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </>
        ) : (
          <QrScanner
            constraints={{
              facingMode: 'environment',
            }}
            ref={qrReader}
            key='environment'
            onLoad={() => setLoading(false)}
            style={{ width: '80%' }}
            onError={handleError}
            onScan={handleScan}
          />
        )}
        <Button
          className='w-full bg-white text-lg p-3 text-center font-semibold h-auto rounded-none border-none flex items-center justify-center gap-2'
          onClick={handleBtnProgressClick}
        >
          <SimIcon />
          Tiến trình kích hoạt
          {listProgress?.length > 0 && (
            <span className='w-3 h-3 rounded-[50%] bg-[red] ml-2'></span>
          )}
        </Button>
        <Modal
          open={isShowProgressList}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          title={<p className='text-xl m-0'>Tiến trình kích hoạt</p>}
          className='h-screen w-screen modal-progress-list modal-progress-connect'
          onCancel={() => {
            setIsShowProgressList(false);
            setIdSimActived('');
          }}
        >
          <Button
            onClick={() => setIsShowHistory(true)}
            className='absolute top-5 right-5 text-xl border-none p-0'
          >
            <AiOutlineHistory />
          </Button>
          <Spin spinning={loading}>
            <div className='modal-filter'>
              <Button
                className={`h-auto rounded-full border-[2px] font-semibold  ${
                  currentFilter === 'all' ? 'bg-[#446AF9] text-white border-[#446AF9]' : 'bg-[#fff]'
                }`}
                onClick={() => setCurrentFilter('all')}
              >
                Tất cả
              </Button>
              <Button
                className={`h-auto rounded-full border-[2px] font-semibold  ${
                  currentFilter === 'error'
                    ? 'bg-[#446AF9] text-white border-[#446AF9]'
                    : 'bg-[#fff]'
                }`}
                onClick={() => setCurrentFilter('error')}
              >
                Kích hoạt lỗi
              </Button>
              <div>
                {idSimActived && (
                  <Button
                    className={`h-auto rounded-full border-[2px] font-semibold  ${
                      currentFilter === 'active'
                        ? 'bg-[#446AF9] text-white border-[#446AF9]'
                        : 'bg-[#fff]'
                    }`}
                    onClick={() => setCurrentFilter('active')}
                  >
                    SIM đã kích hoạt
                  </Button>
                )}
              </div>
            </div>
            <ul className='list-none p-0 m-0 flex flex-col gap-2'>{renderCurrentList()}</ul>
          </Spin>
        </Modal>
        <ModalHistoryActiveSim open={isShowHistory} handleCancel={() => setIsShowHistory(false)} />
        <Modal
          open={open}
          title='Bạn có muốn kích hoạt số thuê bao này?'
          onOk={() => activeSerial()}
          onCancel={() => {
            setOpen(false);
          }}
          closable={false}
          centered
          maskClosable={false}
          okText='Đồng ý'
          cancelText='Không'
          confirmLoading={loading}
        />
        <Modal
          open={openError}
          title='Tiến trình đang được thực hiện. Vui lòng kiểm tra lại sau.'
          onOk={() => {
            setOpenError(false);
            setShowScanner(true);
          }}
          closable={false}
          centered
          maskClosable={false}
          okText='Đồng ý'
          cancelButtonProps={{ style: { display: 'none' } }}
          confirmLoading={loading}
        />
      </div>
    </Spin>
  );
}
