import { useEffect, useState } from 'react';
import { Button, Space, Tabs } from 'antd';
import SimInfoTab from '../components/SimInfoTab';
import SimHistoryChangeTab from '../components/SimHistoryChangeTab';
import SimContractInfoTab from '../components/SimContractInfoTab';
import { getInfoActiveSimDetail } from 'src/api/activeSim';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from 'src/utils';

export default function ActiveSimDetailPage() {
  const location = useLocation();
  const [simDetail, setSimDetail] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchActiveSimInfoDetailData = async (serial: string, phone: string) => {
    const res = await getInfoActiveSimDetail(serial, phone);
    setSimDetail(res?.data);
  };

  useEffect(() => {
    const { serial, phone } = parseQueryString(location.search);
    if (serial) {
      setIsLoading(true);
      fetchActiveSimInfoDetailData(serial, phone).then(() => {
        setIsLoading(false);
      });
    }
  }, [location.search]);

  return (
    <>
      {!isLoading && !!simDetail && (
        <div>
          <div className={'text-right hidden'}>
            <Space>
              <Button size={'middle'}>Đổi SIM</Button>
              <Button size={'middle'}>Nạp tiền</Button>
              <Button size={'middle'}>Đổi NSD</Button>
            </Space>
          </div>
          <Tabs
            type='card'
            items={[
              {
                label: `Thông tin thuê bao`,
                key: '1',
                children: <SimInfoTab simDetail={simDetail} />,
              },
              {
                label: `Lịch sử thay đổi`,
                key: '2',
                children: <SimHistoryChangeTab />,
              },
              {
                label: `Thông tin hợp đồng`,
                key: '3',
                children: <SimContractInfoTab simDetail={simDetail} />,
              },
            ]}
          />
        </div>
      )}
    </>
  );
}
