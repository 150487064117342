import { Button, message, Modal, Spin } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { activeSimSingle, checkStatusSerial, historyRetry } from 'src/api/connection';
import { useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { ConnectionType } from 'src/shared/enum/connection.enum';
import { IPhoneInfo, IUserProfile } from 'src/shared/interface/connection';

type Props = {
  isOpen: boolean;
  handleCancel: () => void;
  userProfile?: Partial<IUserProfile>;
  phoneInfo?: Partial<IPhoneInfo>;
  setIsConnectFail: any;
  connectionType: string;
};

const ModalConfirmConnect = (props: Props) => {
  const { isOpen, handleCancel, setIsConnectFail, userProfile, phoneInfo, connectionType } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<string>();
  const [fail, setFail] = useState<boolean>(false);
  const [serialId, setSerialId] = useState<''>('');
  const [orderId, setOrderId] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openTabs } = useAppSelector((state) => state.global);
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  let intervalID: any;
  const countDown = Date.now();

  const responseSingle = async (isRetry?: boolean) => {
    setIsLoading(true);

    let bodyRequest: any;
    if (connectionType === ConnectionType.INDIVIDUAL) {
      bodyRequest = {
        userProfile,
        phoneInfo: { ...phoneInfo, createdBy: JSON.parse(merchantInfo as string)?.merchantName },
      };
    } else {
      bodyRequest = {
        ...userProfile,
        ...phoneInfo,
      };
    }
    let response;
    console.log('body', bodyRequest);

    if (isRetry) {
      const body = {
        distributorId:
          connectionType === ConnectionType.INDIVIDUAL
            ? bodyRequest?.phoneInfo?.distributorId
            : bodyRequest?.distributorId,
        serialNumber:
          connectionType === ConnectionType.INDIVIDUAL
            ? bodyRequest?.phoneInfo?.serialNumber
            : bodyRequest?.serialNumber,
        serialId: serialId,
      };
      response = await historyRetry(connectionType, body);
    } else {
      response = await activeSimSingle(connectionType, bodyRequest);
    }

    if (response) {
      if (response.isSucceeded) {
        setResult('Thành công');
        if (response?.data?.orderId) {
          setOrderId(response?.data?.orderId);
        }
      } else {
        message.error(response?.data?.errorMessage);
        setResult(response?.data?.errorMessage);
        setIsConnectFail(true);
        setFail(true);
      }
      setIsLoading(false);
    } else {
      if (countDown + 2 * 60 * 1000 > Date.now()) {
        intervalID = setInterval(checkStatus, 5000);
      } else {
        clearInterval(intervalID);
      }
    }
  };

  const handleConnect = async (isRetry?: boolean) => {
    setFail(false);
    responseSingle(isRetry);
  };

  const handleReConnect = () => {
    navigate(0);
  };

  function checkStatus() {
    checkStatusSerial(phoneInfo?.serialNumber || '').then((res) => {
      if (res?.statusString === 'Success') {
        setSerialId(res?.id);
        setResult('Thành công');
        setOrderId(res?.orderId);
        setIsLoading(false);
        clearInterval(intervalID);
      } else if (res?.statusString === 'Failed') {
        setSerialId(res?.id);
        message.error('Đấu nối không thành công');
        setResult('Đấu nối không thành công');
        setIsConnectFail(true);
        setFail(true);
        setOrderId(res?.orderId);
        setIsLoading(false);
        clearInterval(intervalID);
      }
    });
  }

  const handleDetailOrder = () => {
    dispatch(
      setGlobalState({
        activeKeyMenu: `connection/history/list?id=${phoneInfo?.serialNumber}&orderId=${orderId}`,
      }),
    );
    if (
      !openTabs.find(
        (tab) =>
          tab.key === `connection/history/list?id=${phoneInfo?.serialNumber}&orderId=${orderId}`,
      )
    ) {
      dispatch(
        setGlobalState({
          openTabs: [
            ...openTabs,
            {
              label: 'Xem lịch sử đấu nối ' + phoneInfo?.serialNumber,
              children: '',
              key: `connection/history/list?id=${phoneInfo?.serialNumber}&orderId=${orderId}`,
              closable: true,
            },
          ],
        }),
      );
    }

    navigate(
      `/management/connection/history/list?id=${phoneInfo?.serialNumber}&orderId=${orderId}`,
    );
  };

  return (
    <div>
      <Modal open={isOpen} footer={null} onCancel={handleCancel} closable={!result}>
        <Spin spinning={isLoading}>
          <div className='pt-10 pb-5'>
            <div className='text-xl text-center'>
              {result ? (
                <div>
                  {fail && <p>Thất bại</p>}
                  <p>{result}</p>
                </div>
              ) : (
                'Bạn có muốn đấu nối?'
              )}
            </div>
          </div>
          <div className='flex justify-end gap-2'>
            {!result && (
              <>
                <Button disabled={isLoading} onClick={handleCancel}>
                  Thoát
                </Button>
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    handleConnect();
                  }}
                  type='primary'
                >
                  Tiếp tục
                </Button>
              </>
            )}
            {result && (
              <>
                <Button disabled={isLoading} onClick={handleDetailOrder}>
                  Xem chi tiết
                </Button>
                {fail ? (
                  <Button disabled={isLoading} onClick={() => handleConnect(true)}>
                    Retry
                  </Button>
                ) : (
                  <Button disabled={isLoading} onClick={handleReConnect}>
                    Tiếp tục đấu nối
                  </Button>
                )}
              </>
            )}
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
export default ModalConfirmConnect;
