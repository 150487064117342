export enum EnterpriseStatusEnum {
  NEW = '1',
  WAITING = '2',
  EDITED = '3',
  APPROVED = '4',
  REJECTED = '5',
}
export enum EnterpriseTypeEnum {
  DN = 1,
  HKDL = 2,
  CovinienceStore = 3,
  M2M = 4,
}
export enum AccountRole {
  'MERCHANT_KHDN' = 'MERCHANT_KHDN',
  'MERCHANT_NPP' = 'MERCHANT_NPP',
}
