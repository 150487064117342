import { Form, Radio, Tabs } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getDetailBusinessProfile } from 'src/api/business_profile';
import { parseQueryString } from 'src/utils';
import { DEFAULT_VALUE_BUSINESS_PROFILE } from 'src/utils/businessProfile.data';
import AgentInfo from '../component/AgentInfo';
import Attachment from '../component/Attachment';
import AuthorizeInfo from '../component/AuthorizeInfo';
import BusinessInfo from '../component/BusinessInfo';
import TraderInfo from '../component/TraderInfo';

function DetailProfilePage() {
  const [formBusiness] = useForm();
  const location = useLocation();
  const { id } = parseQueryString(location.search);

  useEffect(() => {
    const fetchBusinessProfile = async () => {
      const res = await getDetailBusinessProfile();
      if (res) {
        let dataBusiness: any = {
          updated_date: res.updated_date,
          customer_name: res.customer_name,
          reg_date: res.reg_date,
          status: res.status,
          id: res.id,
        };
        Object.keys(DEFAULT_VALUE_BUSINESS_PROFILE).forEach((fieldName) => {
          const valueItem = res?.account_profile_values?.find(
            (item: any) => item.table_attribute_field_name === fieldName,
          );
          const value = (valueItem && valueItem?.value) ?? '';
          dataBusiness = { ...dataBusiness, [fieldName]: value };
        });
        formBusiness.setFieldsValue(dataBusiness);
      }
    };
    fetchBusinessProfile();
  }, [id]);

  return (
    <Tabs
      type='card'
      items={[
        {
          label: `Thông tin doanh nghiệp`,
          key: '1',
          children: (
            <Form form={formBusiness} initialValues={DEFAULT_VALUE_BUSINESS_PROFILE}>
              <BusinessInfo />
              <AgentInfo />
              {formBusiness.getFieldValue('authorizedName') && (
                <div className='my-4 flex gap-4 text-base font-semibold'>
                  Có thông tin người ủy quyền
                  <Radio.Group
                    name='userType'
                    value={formBusiness.getFieldValue('authorizedName') ? 1 : 2}
                  >
                    <Radio value={1}>Yes</Radio>
                    <Radio value={2}>No</Radio>
                  </Radio.Group>
                </div>
              )}
              <AuthorizeInfo />
              <TraderInfo />
              <Attachment />
            </Form>
          ),
        },
        {
          label: `Lịch sử thay đổi`,
          key: '2',
          children: <></>,
        },
      ]}
    />
  );
}

export default DetailProfilePage;
