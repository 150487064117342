import {Col, Form, Modal, Row, Image, Button} from "antd";
import {formatDateTime, handleDownloadImage} from "src/utils";
import {DownloadOutlined} from "@ant-design/icons";

interface ModalProps {
  open: boolean,
  setIsOpenEsimInfo: any,
  data: any
}
export default function ModalEsimInfo({open, setIsOpenEsimInfo, data}: ModalProps) {
  return <>
    <Modal
      open={open}
      onCancel={() => setIsOpenEsimInfo(false)}
      title={'Thông tin eSIM'}
      width={'50%'}
      footer={false}
    >
      <Form className={'mt-5'}>
        <Row>
          <Col span={12}>
            <Form.Item
              label={'Số SIM serial'}
              labelCol={{
                span: 10
              }}
            >
              {data?.serialNew ?? ''}
            </Form.Item>
            <Form.Item
              label={'Trạng thái eSIM'}
              labelCol={{
                span: 10
              }}
            >
              {data?.statusString ?? ''}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={'Số thuê bao'}
              labelCol={{
                span: 10
              }}
            >
              {data?.phoneNumber ?? ''}
            </Form.Item>
            <Form.Item
              label={'Thời gian hết hạn'}
              labelCol={{
                span: 10
              }}
            >
              {formatDateTime(data?.expiredAt ?? '')}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={'Mã SM+DP-'}
              labelCol={{
                span: 5
              }}
            >
              {data?.smdpCode ?? ''}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={'Mã kích hoạt'}
              labelCol={{
                span: 5
              }}
            >
              {data?.qrText ?? ''}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={'Mã QR'}
              labelCol={{
                span: 5
              }}
            >
              {data?.linkQrText && <div className={'flex items-start'}>
                <Image
                  src={data?.linkQrText ?? ''}
                  alt={'QR'}
                  width={150}
                  height={150}
                />
                <DownloadOutlined
                  className={'ml-3 text-2xl cursor-pointer'}
                  onClick={() => handleDownloadImage(data?.linkQrText)}
                />
              </div>}
            </Form.Item>
          </Col>
        </Row>
        <Row className={'mt-5'}>
          <Col span={24}>
            <Form.Item>
              <div className={'flex justify-end'}>
                <Button
                  onClick={() => setIsOpenEsimInfo(false)}
                >
                  Thoát
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  </>
}