import { Form, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { dataGet } from 'src/utils';
import {type} from "@testing-library/user-event/dist/type";

const columns: ColumnsType<any> = [
  {
    title: 'Gói',
    dataIndex: 'mbfCodes',
    key: 'mbfCodes',
    render: (_, record) =>
      !!record && (
        <Space size={'small'} wrap>
          {dataGet(record, 'mbfCodes', []).map((code: string, index: number) => (
            <Typography.Text key={index}>{code}</Typography.Text>
          ))}
        </Space>
      ),
  },
];

export default function ListPackageEsimTable() {
  const form = Form.useFormInstance();

  const getEnterpriseSubscriptionPlan = () => {
    let plans = [];
    if (form.getFieldValue('enterpriseSubscriptionPlanEsim')) {
      plans = JSON.parse(form.getFieldValue('enterpriseSubscriptionPlanEsim'));
    }
    return plans.filter((plan: any) => !!plan);
  };

  return (
    <>
      <Table
        size={'small'}
        columns={columns}
        dataSource={getEnterpriseSubscriptionPlan()}
        pagination={false}
        rowKey={(record) => {
          return record?.simType;
        }}
      />
    </>
  );
}