import { PaginationResponse } from 'src/@types/search-params';
import { ICheckImportExcelParamsRequest } from 'src/shared/interface/package_assigned';
import {
  DetailUserProfileBatch,
  InsertActionEditUserProfileBody,
  MerchantProfile,
  UserProfileBatchResponse,
  UserProfileBodyData,
  UserProfileResponse,
} from 'src/shared/interface/user_profile';
import request from '../request';

export const getAllUserProfile = async (params: any, enterpriseId: string) => {
  const url = '/api/user-profile/get-all';
  const res = await request<PaginationResponse<UserProfileResponse>>('get', url, {
    ...params,
    EnterpriseId: enterpriseId,
  });
  if (res.isSucceeded) {
    return res.data;
  }
};

export const getDetailUserProfile = async (id: string) => {
  const url = '/api/user-profile/get-by-id?id=' + id;
  const res = await request<UserProfileResponse>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const uploadFile = async (data: File) => {
  const url = '/uploadImages';
  const formData = new FormData();
  formData.append('file', data);
  const res = await request<UserProfileResponse>('post', url, formData);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const createUserProfile = async (data: UserProfileBodyData, userId: string) => {
  const url = '/api/user-profile/insert?userId=' + userId;
  const res = await request<UserProfileResponse>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const updateUserProfile = async (data: UserProfileBodyData, userId: string) => {
  const url = '/api/user-profile/update-user-profile?userId=' + userId;
  const res = await request<UserProfileResponse>('put', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const approvedUserProfile = async (data: UserProfileResponse, userId: string) => {
  const url = '/api/user-profile/send-approved?userId=' + userId;
  const res = await request<UserProfileResponse>('put', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const updateActiveUserProfile = async (data: UserProfileResponse, userId: string) => {
  const url = '/api/user-profile/update-active-inactive?userId=' + userId;
  const res = await request<UserProfileResponse>('put', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const insertHistoryEditActiveUserProfile = async (data: InsertActionEditUserProfileBody) => {
  const url = '/api/user-profile/insert-detail-history';
  const res = await request<UserProfileResponse>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getAllUserProfileBatch = async (params?: any) => {
  const url = '/api/user-profile/get-all-lot-user-profile';
  const res = await request<PaginationResponse<UserProfileBatchResponse[]>>('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getMerchant = async (params?: any) => {
  const url = '/api/Provider/merchant';
  const res = await request<PaginationResponse<MerchantProfile>>('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const inserExportUserProfileBatch = async (params: ICheckImportExcelParamsRequest) => {
  const formData = new FormData();
  if (params) {
    Object.keys(params).map((item) => {
      formData.append(`${item}`, params[`${item as keyof typeof params}`]);
    });
  }
  const url = '/api/user-profile/insert-excel';
  const res = await request<PaginationResponse<UserProfileBatchResponse[]>>('post', url, formData);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getDetailUserProfileBatch = async (lotId?: string) => {
  const url = '/api/user-profile/get-lot-Id?lotId=' + lotId;
  const res = await request<DetailUserProfileBatch>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getListHistory = async (id: string) => {
  const url = '/api/user-profile/get-detail-history/' + id;
  const res = await request<InsertActionEditUserProfileBody[]>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const updateApprovedUserProfileBatch = async (data: UserProfileBodyData[]) => {
  const url = '/api/user-profile/send-approved-lot-user-profile';
  const res = await request<UserProfileBatchResponse[]>('put', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const updateApprovedUserProfileBatchNoneList = async (lotId: string) => {
  const url = '/api/user-profile/update-status-lot-user-profile?lotId=' + lotId;
  const res = await request<UserProfileBatchResponse[]>('put', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const deleteApprovedUserProfileBatch = async (lotId: string) => {
  const url = '/api/user-profile/delete-lot-all-user-profile?lotId=' + lotId;
  const res = await request<UserProfileBatchResponse[]>('delete', url);
  if (res.isSucceeded) {
    return res;
  }
};
export const deleteApprovedUserProfile = async (data: { id: string }[]) => {
  const url = '/api/user-profile/delete-lot-user-profile';
  const res = await request<UserProfileBatchResponse[]>('delete', url, data);
  if (res.isSucceeded) {
    return res;
  }
};
