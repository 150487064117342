export const DEFAULT_VALUE_BUSINESS_PROFILE = {
  enterprieDistributorId: '', //Mã HS DN
  enterpriseDistributorName: '',

  enterpriseName: '', // Tên doanh nghiệp

  enterpriseCustomerType: '', // Loại khách hàng

  foundedPermNo: '', // Số giấy chứng nhận pháp nhân (Mã số thuế DN)

  enterpriseTotalPhoneNumbers: '', // Số lượng thuê bao

  enterpriseTotalUsers: '', // Số lượng user

  enterpriseType: '', // Loại doanh nghiệp

  enterpriseSubscriptionPlan: '', // Danh sách gói SIM vật lý

  enterpriseSubscriptionPlanEsim: '', // Danh sách gói eSIM

  enterpriseLevel: '', // Cấp doanh nghiệp

  parentEnterprise: '', // Doanh nghiệp quản lý

  foundedPermDate: '', // Ngày cấp GPKD

  enterpriseFoundingPermPlace: '', // Nơi cấp GPKD

  userStreetName: '', // Địa chỉ doanh nghiệp

  enterpriseDistributorId: '', // Distributor Id

  enterpriseStaffId: '', // Mã giao dịch viên
  enterpriseStaffName: '', // Tên giao dịch vieen

  contactName: '', // Họ tên

  contactIdType: '', // Loại giấy tờ

  contactIdNumber: '', // Số giấy tờ

  contactIssueDate: '', // Ngày cấp

  contactIssuePlace: '', // Nơi cấp
  contactIssuePlaceCode: '', // Nơi cấp

  contactEmail: '', // 'Email'

  contactPhoneNumber: '', // Số điện thoại

  contactIsNegotiator: '', // Là người đến giao dịch

  contactDob: '', // Ngày sinh

  contactGender: '', // Giới tính

  contactNationality: '', // Quốc tịch người đại diện
  contactNationalityCode: '', // Quốc tịch người đại diện

  contactAddress: '', // Địa chỉ thường trú

  contactEmployment: '', // Nghề nghiệp

  contactRole: '', // Chức vụ

  contactNote: '', // Ghi chú

  authorSubname: '', // Họ tên

  authorOption: '', // Loại giấy tờ

  authorIdNo: '', // Số giấy tờ

  authorIdIssueDate: '', // Ngày cấp

  authorIdIssuePlace: '', // Nơi cấp
  authorizedIssuePlaceCode: '', // Nơi cấp

  emailMbf: '', // 'Email'

  authorPhoneNumber: '', // Số điện thoại

  authorIsNegotiator: '', // Là người đến giao dịch

  authorNo: '', // Số giấy ủy quyền

  authorStartDate: '', // Ngày cấp giấy ủy quyền
  authorEndDate: '', // Ngày hết hạn giấy ủy quyền

  authorPowerOfAttorneyGrantor: '', // Người kí giấy ủy quyền

  authorOtherContact: '', // Liên hệ khác

  referralName: '', // Họ tên

  referralIdType: '', // Loại giấy tờ

  referralIdNumber: '', // Số giấy tờ

  referralIssueDate: '', // Ngày cấp

  referralIssuePlace: '', // Nơi cấp
  referralIssuePlaceCode: '', // Nơi cấp

  referralEmail: '', // Email

  referralPhoneNumber: '', // Số điện thoại

  referralCoverLetterNo: '', // Số giấy giới thiệu

  referralCoverLetterDate: '', // Ngày cấp giấy giới thiệu

  referralOtherContact: '', // Liên hệ khác,
  contactIdFrontImage: '', // mặt trước
  contactIdBackImage: '', // mặt sau
  enterprisePermitFile: '',
  legalPeopleFile: '',
  powerOfAttorneyFile: '',
  negotiatorImgFile: '',
  serviceQualityFile: '',
  contractTermFile: '',
  commonConditionFile: '',
  signatureImgFile: '',
  contactSelfieImage: '',
  contactSignatureImage: '',
  enterpriseStatus: '',
  localShopAccount: '',
  profileUpdatedBy: '',
  profileCreatedBy: '',
  frontDocumentFile: '',
  backDocumentFile: '',
  authorizedPowerOfAttorneyExpireDate: '', // ngày hết hạn giấy uỷ quyền,
  enterpriseTaxCode: '', //Số giấy chứng nhận pháp nhân
  userProvince: '',
  userDistrict: '',
  userPrecinct: '',
  contactProvince: '',
  contactDistrict: '',
  contactWard: '',
  contactOtherContact: '',
  authorRole: '',
};
