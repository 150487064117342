import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import { getAllIssuePlace, getAllLocation, getAllNationality } from 'src/api/location';
import { convertUTCDate } from 'src/utils';
import { IDTypeEnum, IDTypeNumberEnum, ProfileBatchEnum, ProfileEnum } from '../enum/user_profile';
import { ILocation } from '../interface/location.interface';
import { UserProfileBodyData, UserProfileResponse, ValueCode } from '../interface/user_profile';

export const IDTypeStatus: ValueCode[] = [
  {
    value: IDTypeNumberEnum.CMND,
    label: IDTypeEnum.CMND,
  },
  {
    value: IDTypeNumberEnum.CCCD,
    label: IDTypeEnum.CCCD,
  },
  {
    value: IDTypeNumberEnum.PASSPORT,
    label: IDTypeEnum.PASSPORT,
  },
];

export const IDTypeStatusFilter: ValueCode[] = [
  {
    value: ' ',
    label: 'Tất cả',
  },
  {
    value: '1',
    label: IDTypeEnum.CMND,
  },
  {
    value: '2',
    label: IDTypeEnum.CCCD,
  },
  {
    value: '3',
    label: IDTypeEnum.PASSPORT,
  },
];
export const UserProfileStatusActiveFilter: ValueCode[] = [
  {
    value: ' ',
    label: 'Tất cả',
  },
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Inactive',
    label: 'Inactive',
  },
];
export const UserProfileStatusActive: ValueCode[] = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Inactive',
    label: 'Inactive',
  },
];
export const ProfileStatusFilter: ValueCode[] = [
  {
    value: ' ',
    label: 'Tất cả',
  },
  {
    value: ProfileEnum.New,
    label: ProfileEnum.New,
  },
  {
    value: ProfileEnum.Waiting,
    label: 'Waiting For Approval',
  },
  {
    value: ProfileEnum.EDIT,
    label: 'Edited',
  },
  {
    value: ProfileEnum.Approved,
    label: ProfileEnum.Approved,
  },
  {
    value: ProfileEnum.Rejected,
    label: ProfileEnum.Rejected,
  },
];

export const ProfileStatus: ValueCode[] = [
  {
    value: ProfileEnum.New,
    label: ProfileEnum.New,
  },
  {
    value: ProfileEnum.Waiting,
    label: ProfileEnum.Waiting,
  },
  {
    value: ProfileEnum.EDIT,
    label: ProfileEnum.EDIT,
  },
  {
    value: ProfileEnum.Approved,
    label: ProfileEnum.Approved,
  },
  {
    value: ProfileEnum.Rejected,
    label: ProfileEnum.Rejected,
  },
];
export const ProfileBatchStatus: ValueCode[] = [
  {
    value: ' ',
    label: 'Tất cả',
  },
  {
    value: ProfileBatchEnum.New,
    label: ProfileBatchEnum.New,
  },
  {
    value: ProfileBatchEnum.Waiting,
    label: 'Waiting For Approval',
  },
  {
    value: ProfileBatchEnum.Uncompleted,
    label: ProfileBatchEnum.Uncompleted,
  },
  {
    value: ProfileBatchEnum.Completed,
    label: ProfileBatchEnum.Completed,
  },
];
const merchantInfo: string | null = localStorage.getItem('merchantInfo');
export const DEFAULT_USER_PROFILE: UserProfileResponse = {
  enterpriseId: merchantInfo ? JSON.parse(merchantInfo as string)?.merchantId : '',

  nationality: { label: 'Viet Nam', value: 'VNM' },
};
const renderIdNumber = (itemData: UserProfileResponse) => {
  switch (itemData.typeIdNumber + '') {
    case '1':
      return IDTypeEnum.CMND;
    case '2':
      return IDTypeEnum.CCCD;
    case '3':
      return IDTypeEnum.PASSPORT;

    default:
      return '';
  }
};
export const handleMapFormValueUserProfile = async (
  form: FormInstance<any>,
  res: UserProfileResponse,
  issuePlaceData: ILocation[],
) => {
  const getProvinces = await getAllLocation({ level: 1 });
  const province = getProvinces?.find((item) => item.code.toString() === res.province?.toString());

  const getDistricts = await getAllLocation({ parentId: province?.id ?? '' });
  const district = getDistricts?.find((item) => item.code.toString() === res.district?.toString());

  const getWards = await getAllLocation({ parentId: district?.id ?? '' });
  const ward = getWards?.find((item) => item.code.toString() === res.ward?.toString());

  const issuePlace = issuePlaceData?.find(
    (item) => item.code.toString() === res.issuePlace?.toString(),
  );
  const getNationality = await getAllNationality();
  const nationality = getNationality?.find(
    (item) => item.code.toString() === res.nationality?.toString(),
  );

  form.setFieldsValue({
    ...res,
    birthDay: res.birthDay ? dayjs(res.birthDay) : '',
    issueDate: res.issueDate ? dayjs(res.issueDate) : '',
    isActive: res.isActive ? 'Active' : 'Inactive',
    typeIdNumber: res.typeIdNumber ? renderIdNumber(res) : '',
    enterpriseName: res.enterpriseName,
    enterpriseId: res.enterpriseId ? res.enterpriseId : '',
    gender: res.gender ? 'Nữ' : 'Nam',
    issuePlace: nationality
      ? nationality.code === 'VNM'
        ? issuePlace
          ? { label: issuePlace?.name, value: issuePlace.code }
          : ''
        : { label: nationality.name, value: nationality.code }
      : '',
    district: district ? { label: district?.name, value: district.code } : '',
    province: province ? { label: province?.name, value: province.code } : '',
    ward: ward ? { label: ward?.name, value: ward.code } : '',
    nationality: nationality ? { label: nationality.name, value: nationality.code } : '',
    statusString: res.statusString
      ? res.statusString !== ProfileEnum.Waiting
        ? res.statusString
        : 'Waiting For Approval'
      : '',
  });
};

export const handleCheckUpdateProfile = async (
  data: UserProfileResponse,
  userProfile: UserProfileResponse,
): Promise<{ data: UserProfileBodyData | undefined; note: string } | undefined> => {
  const tempData = JSON.stringify(data);
  const tempUserProfile = JSON.stringify(userProfile);
  let note = '';
  const getProvinces = await getAllLocation({ level: 1 });

  const getDistricts = await getAllLocation({ parentId: userProfile.province?.id ?? '' });

  const getWards = await getAllLocation({ parentId: userProfile.district?.id ?? '' });

  const issuePlaceData = await getAllIssuePlace();

  const getNationality = await getAllNationality();

  Object.entries(userProfile).map((entry) => {
    switch (entry[0]) {
      case 'userName':
        if (entry[1] !== data[entry[0]]) {
          note = note + ' ' + `cập nhật tên từ ${entry[1]} thành ${data[entry[0]]} `;
        }
        break;
      case 'birthDay':
        if (!dayjs(entry[1]).isSame(data[entry[0]])) {
          note = note + ' ' + `cập nhật ngày sinh từ ${entry[1]} thành ${data[entry[0]]} `;
        }
        break;
      case 'gender':
        if (data[entry[0]] && entry[1].toString() !== data[entry[0]]?.toString()) {
          note =
            note +
            ' ' +
            `cập nhật giới tính từ ${entry[1] === 0 ? 'Nam' : 'Nữ'} thành ${
              data[entry[0]] === 0 ? 'Nam' : 'Nữ'
            } `;
        }
        break;
      case 'idNumber':
        if (data[entry[0]] && entry[1] !== data[entry[0]]) {
          note = note + ' ' + `cập nhật số giấy tờ từ ${entry[1]} thành ${data[entry[0]]} `;
        }
        break;
      case 'typeIdNumber':
        if (data[entry[0]] && entry[1].toString() !== data[entry[0]]?.toString()) {
          note =
            note +
            ' ' +
            `cập nhật loại giấy tờ từ ${
              entry[1] === 1 ? 'CMND' : entry[1] === 2 ? 'CCCD' : 'Passport'
            } thành ${
              data[entry[0]] === '1' ? 'CMND' : data[entry[0]] === '2' ? 'CCCD' : 'Passport'
            } `;
        }
        break;
      case 'issuePlace':
        // eslint-disable-next-line no-case-declarations
        const issuePlace = issuePlaceData?.find(
          (item) => item.code.toString() === userProfile.issuePlace?.toString(),
        );
        if (data[entry[0]] && entry[1] !== data[entry[0]]?.value) {
          note =
            note +
            ' ' +
            `cập nhật nơi cấp từ ${issuePlace ? issuePlace.name ?? entry[1] : ''} thành ${
              data[entry[0]] ? data[entry[0]]?.label ?? data[entry[0]] : ''
            } `;
        }
        break;
      case 'issueDate':
        if (!dayjs(entry[1]).isSame(data[entry[0]])) {
          note = note + ' ' + `cập nhật ngày cấp từ ${entry[1]} thành ${data[entry[0]]} `;
        }
        break;
      case 'nationality':
        // eslint-disable-next-line no-case-declarations
        const nationality = getNationality?.find(
          (item) => item.code.toString() === userProfile.nationality?.toString(),
        );
        if (data[entry[0]] && entry[1] !== data[entry[0]]?.value) {
          note =
            note +
            ' ' +
            `cập nhật quốc tịch từ ${nationality ? nationality.name ?? entry[1] : ''} thành ${
              data[entry[0]]?.label ? data[entry[0]]?.label ?? data[entry[0]] : ''
            },`;
        }
        break;
      case 'province':
        // eslint-disable-next-line no-case-declarations
        const province = getProvinces?.find((item) => item.code.toString() === entry[1].toString());

        if (data[entry[0]] && entry[1] !== data[entry[0]]?.value) {
          note =
            note +
            ' ' +
            `cập nhật tỉnh/ thành phố từ ${province ? province.name ?? '' : ''} thành ${
              data[entry[0]]?.label ? data[entry[0]]?.label ?? '' : ''
            } `;
        }
        break;
      case 'district':
        // eslint-disable-next-line no-case-declarations
        const district = getDistricts?.find(
          (item) => item.code.toString() === entry[1]?.toString(),
        );

        if (data[entry[0]] && entry[1] !== data[entry[0]]?.value) {
          note =
            note +
            ' ' +
            `cập nhật quận/ huyện từ ${district ? district.name ?? '' : ''} thành ${
              data[entry[0]]?.label ? data[entry[0]]?.label ?? '' : ''
            } `;
        }
        break;
      case 'ward':
        // eslint-disable-next-line no-case-declarations
        const ward = getWards?.find((item) => item.code.toString() === entry[1]?.toString());

        if (data[entry[0]] && entry[1] !== data[entry[0]]?.value) {
          note =
            note +
            ' ' +
            `cập nhật xã/ phường từ ${ward ? ward.name ?? '' : ''} thành ${
              data[entry[0]]?.label ? data[entry[0]]?.label ?? '' : ''
            } `;
        }
        break;
      case 'address':
        if (data[entry[0]] && entry[1] !== data[entry[0]]) {
          note = note + ' ' + `cập nhật địa chỉ từ ${entry[1]} thành ${data[entry[0]]} `;
        }
        break;
      case 'frontImage':
        if (data[entry[0]] && entry[1] !== data[entry[0]]) {
          note = note + ' ' + `cập nhật ảnh mặt trước từ ${entry[1]} thành ${data[entry[0]]} `;
        }
        break;
      case 'backImage':
        if (data[entry[0]] && entry[1] !== data[entry[0]]) {
          note = note + ' ' + `cập nhật ảnh mặt sau từ ${entry[1]} thành ${data[entry[0]]} `;
        }
        break;
      case 'selfieImage':
        if (data[entry[0]] && entry[1] !== data[entry[0]]) {
          note = note + ' ' + `cập nhật ảnh chân dung từ ${entry[1]} thành ${data[entry[0]]} `;
        }
        break;
      case 'signature':
        if (data[entry[0]] && entry[1] !== data[entry[0]]) {
          note = note + ' ' + `cập nhật ảnh chữ ký từ ${entry[1]} thành ${data[entry[0]]} `;
        }
        break;

      default:
        break;
    }
  });
  if (tempData === tempUserProfile) {
    return undefined;
  } else {
    const body: UserProfileBodyData = {
      ...data,
      ward: data.ward ? data.ward.value : '',
      province: data.province ? data.province.value : '',
      district: data.district ? data.district.value : '',
      nationality: data.nationality ? data.nationality.value : '',
      issuePlace: data.issuePlace ? data.issuePlace.value : '',
    };
    return { data: body, note: note };
  }
};
export const handleCheckNewProfile = (data: UserProfileResponse): UserProfileBodyData => {
  const body: UserProfileBodyData = {
    ...data,
    enterpriseId: JSON.parse(merchantInfo as string)?.merchantId ?? '',
    enterpriseName: JSON.parse(merchantInfo as string)?.merchantName ?? '',
    nationality: data.nationality && data.nationality.value ? data.nationality.value : '',
    typeIdNumber: data.typeIdNumber === 'CMND' ? '1' : data.typeIdNumber,
    birthDay: data.birthDay ? convertUTCDate(data.birthDay) : '',
    issueDate: data.issueDate ? convertUTCDate(data.issueDate) : '',
    ward: data.ward ? data.ward.value : '',
    province: data.province ? data.province.value : '',
    district: data.district ? data.district.value : '',
    issuePlace: data.issuePlace ? data.issuePlace.value : '',
  };
  return body;
};
export const IDTypeCodeParseNumber = (value: string) => {
  switch (value) {
    case IDTypeNumberEnum.CMND:
      return IDTypeNumberEnum.CMND;
    case IDTypeNumberEnum.CCCD:
      return IDTypeNumberEnum.CCCD;
    case IDTypeNumberEnum.PASSPORT:
      return IDTypeNumberEnum.PASSPORT;

    default:
      return '';
  }
};
export const StatusUserProfile = (value: string) => {
  switch (value) {
    case ProfileEnum.New:
      return ProfileEnum.New;
    case ProfileEnum.Waiting:
      return 'Waiting For Approval';
    case ProfileEnum.EDIT:
      return ProfileEnum.EDIT;
    case ProfileEnum.Approved:
      return ProfileEnum.Approved;
    case ProfileEnum.Rejected:
      return ProfileEnum.Rejected;

    default:
      return '';
  }
};
export const cookCode = (codeNumber: number, prefix: string): string => {
  if (!codeNumber || codeNumber < 0) return '';
  const MIN_CODE_LENGTH = 6;

  const idLength = String(codeNumber).length;
  if (idLength >= MIN_CODE_LENGTH) return prefix + codeNumber;

  const middle = Array(MIN_CODE_LENGTH)
    .fill(0)
    .join('')
    .slice(0, MIN_CODE_LENGTH - idLength);

  return prefix + middle + codeNumber;
};
