import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { IOverFlowOrderDetailResponse } from 'src/shared/interface/package_assigned';

export interface PackageState {
  loading: boolean;
  dataSuccess: IOverFlowOrderDetailResponse[];
  dataFailed: IOverFlowOrderDetailResponse[];
}
const initialState: PackageState = {
  loading: false,
  dataSuccess: [],
  dataFailed: [],
};
export const packageAssignedSlice = createSlice({
  name: 'packageAssigned',
  initialState,
  reducers: {
    setPackageAssignedState(state, action: PayloadAction<Partial<PackageState>>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { actions: packageAssignedAction, reducer: packageAssignedReducer } =
  packageAssignedSlice;
export const { setPackageAssignedState } = packageAssignedSlice.actions;
