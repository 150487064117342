import { useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import withClearCache from './components/ClearCache';
import { routes } from './routers';
import { Providers } from './providers';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

function MainApp() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const location = useLocation();
  const appRoutes = useRoutes(routes);
  const isKHDN = localStorage.getItem('isKHDN');

  useEffect(() => {
    if (
      token &&
      localStorage.getItem('isKHDN') === 'KHDN' &&
      ['/login', '/'].includes(location.pathname)
    ) {
      navigate('/management');
    } else if (
      token &&
      localStorage.getItem('isKHDN') === 'NPP' &&
      ['/login', '/'].includes(location.pathname)
    ) {
      navigate('/home');
    }
  }, [location, token]);

  return appRoutes;
}

const ClearCacheComponent = withClearCache(MainApp);

const client = new ApolloClient({
  uri: process.env.REACT_APP_HASURA_API_URL,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Providers>
        <ClearCacheComponent />
      </Providers>
    </ApolloProvider>
  );
}

export default App;
