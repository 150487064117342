import React, { useEffect, useState } from 'react';
import { getAllPhoneNumber } from 'src/api/connection';
import { IHKDLPhoneNumber } from 'src/shared/interface/connection';

const useAllPhoneNumber = (pool: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listNumber, setListNumber] = useState<IHKDLPhoneNumber[]>();

  const getListNumber = async () => {
    setIsLoading(true);
    try {
      const response = await getAllPhoneNumber({
        Limit: 200,
        Pool: pool,
      });

      if (response) {
        const listNumberFilter = response.map((item: any) => {
          return { simNumber: item.simNumber };
        });

        setListNumber(listNumberFilter);
        setIsLoading(false);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const refetch = () => {
    getListNumber();
  };

  useEffect(() => {
    getListNumber();
  }, []);

  return { isLoading, listNumber, refetch };
};

export default useAllPhoneNumber;
