import { Button, Form, message, Tabs, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useForm } from 'antd/lib/form/Form';
import { cloneDeep, forEach } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllIssuePlace, getAllNationality } from 'src/api/location';
import {
  deleteApprovedUserProfile,
  deleteApprovedUserProfileBatch,
  getDetailUserProfileBatch,
  updateApprovedUserProfileBatch,
  updateApprovedUserProfileBatchNoneList,
} from 'src/api/user_profile';
import ModalConfirm from 'src/components/modals/ModalComfirm';
import { useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { setUserProfileState } from 'src/redux/slice/userProfile';
import { ProfileBatchEnum, ProfileEnum } from 'src/shared/enum/user_profile';
import { ILocation } from 'src/shared/interface/location.interface';
import { UserProfileBodyData } from 'src/shared/interface/user_profile';
import { exportExcel, parseQueryString } from 'src/utils';
import { DEFAULT_VALUE_BUSINESS_PROFILE } from 'src/utils/businessProfile.data';
import GeneralInfo from '../../components/generalInfo';
import TabFail from '../../components/Tabs/TabFail';
import TabSuccess from '../../components/Tabs/TabSuccess';

function UserProfileBatchDetailPage() {
  const [formBusiness] = useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lotId } = parseQueryString(location.search);
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  const { openTabs } = useAppSelector((state) => state.global);
  const { isRefresh, userProfileBatch, listSuccessBatch } = useAppSelector(
    (state) => state.userProfile,
  );

  const [data, setData] = useState<UserProfileBodyData[]>([]);
  const [activeKey, setActiveKey] = useState('1');
  const [userBatchStatus, setUserBatchStatus] = useState('');
  const [openModalConfirmDelete, setOpenModalConfirmDelelte] = useState(false);
  const [openModalConfirmApproved, setOpenModalConfirmApproved] = useState(false);
  const [openModalConfirmError, setOpenModalConfirmError] = useState(false);
  const [listNationality, setListNationality] = useState<ILocation[]>([]);
  const [listIssuePlace, setListIssuePlace] = useState<ILocation[]>([]);
  const [userError, setUserError] = useState<UserProfileBodyData[]>([]);
  const [checkBatchLine, setCheckBatchLine] = useState(false);

  useEffect(() => {
    const fetchPlace = async () => {
      const getNationality = await getAllNationality();
      const issuePlaceData = await getAllIssuePlace();
      if (issuePlaceData) {
        setListIssuePlace(issuePlaceData);
      }
      if (getNationality) {
        setListNationality(getNationality);
      }
    };
    fetchPlace();
  }, []);

  useEffect(() => {
    listSuccessBatch && setData(listSuccessBatch);
  }, [listSuccessBatch]);

  useEffect(() => {
    const fetchGetPackageAssigned = async () => {
      const res = await getDetailUserProfileBatch(lotId);
      const listSuccess: UserProfileBodyData[] = [];
      const listFail: UserProfileBodyData[] = [];

      if (res && res.data) {
        res?.data.map((item) => {
          if (item.note && item.statusString === 'Draft' && item.note) {
            listFail.push(item);
          } else {
            listSuccess.push(item);
          }
        });
      }
      setUserBatchStatus(res?.status ?? '');
      const checkStatusSucess = listSuccess.findIndex(
        (item) => item.statusString !== ProfileEnum.New,
      );
      if (checkStatusSucess === -1) {
        setCheckBatchLine(false);
      } else {
        setCheckBatchLine(true);
      }
      dispatch(
        setUserProfileState({
          listSuccessBatch: listSuccess,
          listFailBatch: listFail,
          userProfileBatch: res,
        }),
      );
    };
    fetchGetPackageAssigned();
  }, [lotId, isRefresh]);

  function handleExport() {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `danh_sach_chi_tiet_HSND_theo_lo_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    exportExcel(
      process.env.REACT_APP_API_URL +
        `/api/user-profile/overflow-user-profile/export?Type=3&EnterpriseId=${
          JSON.parse(merchantInfo as string)?.merchantId ?? ''
        }&LotId=${lotId}&IsImportSuccess=${activeKey === '1' ? true : false}`,
    ).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
    });
  }

  const handleApproved = async () => {
    setOpenModalConfirmApproved(false);
    setOpenModalConfirmError(false);
    if (data) {
      const dataApproved = cloneDeep(data.filter((value) => !value.note));
      const dataAccept: UserProfileBodyData[] = [];
      forEach(dataApproved, (item) => {
        if (
          item.backImage &&
          item.frontImage &&
          item.selfieImage &&
          item.signature &&
          item.statusString === ProfileEnum.New
        ) {
          dataAccept.push(item);
        }
      });
      try {
        if (dataAccept.length > 0) {
          const res = await updateApprovedUserProfileBatch(dataAccept);
          res && message.success('Submit lô thành công!');
        } else {
          const res = await updateApprovedUserProfileBatchNoneList(userProfileBatch?.lotId ?? '');
          res && message.success('Submit lô thành công!');
        }
      } catch (error) {
        console.log(error);
      }
      dispatch(
        setUserProfileState({
          isRefresh: !isRefresh,
        }),
      );
    }
  };

  const handleDelete = async () => {
    if (userBatchStatus === ProfileBatchEnum.New) {
      const res = await deleteApprovedUserProfileBatch(lotId ?? userProfileBatch?.lotId);
      if (res) {
        message.success('Xoá hồ sơ người sử dụng theo lô thành công!');
        const newPanes = openTabs.filter(
          (pane) => pane.key !== `user-profile/batch-detail?lotId=${lotId}`,
        );
        dispatch(
          setGlobalState({
            activeKeyMenu: `user-profile/batch-create`,
          }),
        );
        if (!newPanes.find((tab) => tab.key === 'user-profile/batch-create')) {
          dispatch(
            setGlobalState({
              openTabs: [
                ...newPanes,
                {
                  label: 'Tạo mới người sử dụng theo lô',
                  children: '',
                  key: `user-profile/batch-create`,
                  closable: true,
                },
              ],
            }),
          );
        }
        navigate(`/management/user-profile/batch-create`);
      }
    }
    setOpenModalConfirmDelelte(false);
  };

  const handleSave = async () => {
    try {
      if (listSuccessBatch && data.length !== listSuccessBatch.length) {
        const idDelete: { id: string }[] = [];
        listSuccessBatch.forEach((item) => {
          const findIndexItemDelete = data.findIndex((value) => value.id === item.id);
          if (item.id && findIndexItemDelete === -1) {
            idDelete.push({ id: item.id });
          }
        });
        if (idDelete.length > 0) {
          const res = await deleteApprovedUserProfile(idDelete);
          if (res) {
            message.success('Cập nhật hồ sơ NSD thành công');
            dispatch(setUserProfileState({ isRefresh: !isRefresh }));
          }
        }
      } else {
        dispatch(setUserProfileState({ isRefresh: !isRefresh }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkListError = () => {
    let showIdError = '';

    forEach(userError, (item, index) => {
      if (index < userError.length - 1) {
        if (item.id) {
          showIdError += item.id + ', ';
        }
      } else {
        if (item.id) {
          showIdError += item.id;
        }
      }
    });
    return showIdError;
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Chỉ có thể chọn ảnh định dạng JPG/PNG!');
    }
    const isLt25M = file.size / 1024 / 1024 < 2;
    if (!isLt25M) {
      message.error(
        'File ảnh phải có dung lượng bé hơn hoặc bằng 2Mb, vui lòng chọn hình ảnh khác!',
      );
    }
    return isJpgOrPng && isLt25M;
  };

  return (
    <div>
      <Form form={formBusiness} initialValues={DEFAULT_VALUE_BUSINESS_PROFILE} className='mb-8'>
        <div className='flex justify-end gap-4'>
          <Button
            type='dashed'
            danger
            disabled={userBatchStatus !== ProfileBatchEnum.New || checkBatchLine}
            onClick={() => setOpenModalConfirmDelelte(true)}
          >
            Xoá
          </Button>
          {listSuccessBatch && listSuccessBatch?.length > 0 && (
            <Button
              disabled={userBatchStatus !== ProfileBatchEnum.New}
              onClick={() => setUserBatchStatus('Edit')}
            >
              Sửa
            </Button>
          )}
          {listSuccessBatch && listSuccessBatch?.length > 0 && (
            <Button type='primary' disabled={userBatchStatus !== 'Edit'} onClick={handleSave}>
              Lưu
            </Button>
          )}
          {listSuccessBatch && listSuccessBatch?.length > 0 && (
            <Button
              type='primary'
              disabled={userBatchStatus !== ProfileBatchEnum.New}
              onClick={() => {
                if (userProfileBatch?.data && userProfileBatch?.data.length > 0) {
                  const findIndex = userProfileBatch?.data?.findIndex((item) => !item.note?.trim());
                  if (findIndex !== -1) {
                    const dataError: UserProfileBodyData[] = [];
                    const dataClone = cloneDeep(
                      userProfileBatch.data.filter((value) => !value.note?.trim()),
                    );
                    forEach(dataClone, (item) => {
                      if (
                        !(item.backImage && item.frontImage && item.selfieImage && item.signature)
                      ) {
                        dataError.push(item);
                      }
                    });

                    if (dataError.length > 0) {
                      setOpenModalConfirmError(true);
                      setUserError(dataError);
                    } else {
                      setOpenModalConfirmApproved(true);
                    }
                  } else {
                    message.error('Vui lòng import hồ sơ NSD!');
                  }
                } else {
                  message.error('Vui lòng import hồ sơ NSD!');
                }
              }}
            >
              Submit
            </Button>
          )}
        </div>
        <GeneralInfo />
      </Form>
      <div className='relative'>
        <div className='flex gap-2 absolute top-0 right-0  z-50'>
          <div className='flex gap-4'>
            <Upload
              beforeUpload={beforeUpload}
              maxCount={1}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              showUploadList
              disabled
              fileList={[]}
            >
              <Button className='ml-2' type='dashed' disabled>
                Chọn File hình
              </Button>
            </Upload>
          </div>
          <Button className='ml-2' type='primary' disabled>
            Gán ảnh
          </Button>
          <Button onClick={handleExport}>Export</Button>
        </div>
        <Tabs
          type='card'
          activeKey={activeKey}
          onChange={(activeKey) => setActiveKey(activeKey)}
          items={[
            {
              label: `Tab import đúng`,
              key: '1',
              children: (
                <TabSuccess
                  userBatchStatus={userBatchStatus}
                  data={data}
                  setData={setData}
                  listNationality={listNationality}
                  listIssuePlace={listIssuePlace}
                />
              ),
            },
            {
              label: `Thông tin import sai`,
              key: '2',
              children: (
                <>
                  <TabFail listNationality={listNationality} listIssuePlace={listIssuePlace} />
                </>
              ),
            },
          ]}
        />
      </div>
      <ModalConfirm
        open={openModalConfirmApproved}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Submit?</div>
            <p>Bạn có chắc chắn muốn thực hiện thao tác này?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleApproved} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmApproved(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmDelete}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Xoá hồ sơ người dùng theo lô?</div>
            <p>Bạn có chắc chắn muốn thực hiện thao tác này?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleDelete} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmDelelte(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmError}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Submit?</div>
            <p>{checkListError()} chưa gắn ảnh bạn có muốn bỏ qua và tiếp tục?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleApproved} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmError(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default UserProfileBatchDetailPage;
