import { Image, Typography } from 'antd';
import { DEFAULT_IMAGE_FALLBACK } from 'src/shared/constants';
import { isImageUrl } from 'src/utils';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { useState } from 'react';
import ModalPreview from 'src/components/modals/ModalPreview';

export default function SimAttachmentItem({ file = '', title = '' }: any) {
  return (
    <div className='flex flex-col items-center'>
      {!file || isImageUrl(file) ? (
        <Image
          rootClassName={'w-full'}
          className='aspect-[3/2]'
          src={file}
          alt={title}
          fallback={DEFAULT_IMAGE_FALLBACK}
        />
      ) : (
        <PdfAttachment file={file} />
      )}
      <Typography.Text className={'text-center mt-2'}>{title}</Typography.Text>
    </div>
  );
}

const PdfAttachment = ({ file }: { file: string }) => {
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const handlePreview = () => {
    setIsPreview(true);
  };

  return (
    <div className='h-[150px] overflow-hidden cursor-pointer'>
      <Document file={file} onClick={() => handlePreview()}>
        <Page width={200} height={300} key={`page_${1 + 1}`} pageNumber={1} />
      </Document>

      <ModalPreview
        open={isPreview}
        handleCancel={() => setIsPreview(false)}
        content={
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        }
        props={{ width: 'fit-content' }}
      />
    </div>
  );
};
