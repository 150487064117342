import { Button, message, Upload } from 'antd';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IListColumn } from 'src/@types/search-params';
import { updateUserProfile, uploadFile } from 'src/api/user_profile';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { IDTypeEnum, ProfileEnum } from 'src/shared/enum/user_profile';
import { ILocation } from 'src/shared/interface/location.interface';
import { UserProfileBodyData } from 'src/shared/interface/user_profile';
import { formatDate } from 'src/utils';

interface IPropsTabSuccess {
  userBatchStatus: string;
  data: UserProfileBodyData[];
  setData: React.Dispatch<React.SetStateAction<UserProfileBodyData[]>>;
  listNationality: ILocation[];
  listIssuePlace: ILocation[];
}
function TabSuccess({
  userBatchStatus,
  data,
  setData,
  listNationality,
  listIssuePlace,
}: IPropsTabSuccess) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openTabs } = useAppSelector((state) => state.global);

  const renderIdNumber = (itemData: UserProfileBodyData) => {
    switch (itemData.typeIdNumber + '') {
      case '1':
        return <div>{IDTypeEnum.CMND}</div>;
      case '2':
        return <div>{IDTypeEnum.CCCD}</div>;
      case '3':
        return <div>{IDTypeEnum.PASSPORT}</div>;

      default:
        return <div></div>;
    }
  };

  const handleUploadImage = async (
    record: UserProfileBodyData,
    fileName: string,
    nameImage: string,
    file: any,
  ) => {
    const fileUrl = await uploadFile(file.originFileObj ? file.originFileObj : file);
    const dataUpdate: UserProfileBodyData = {
      id: record.id,
      [fileName]: fileUrl,
    };

    const res = await updateUserProfile(dataUpdate, record.id ?? '');
    if (res) {
      const tempData: any = cloneDeep(data);
      const indexData = tempData.findIndex((item: any) => item.id === record.id);
      if (indexData !== -1) {
        tempData[indexData][`${fileName}`] = record.id + nameImage;
      }
      setData([...tempData]);
    }
  };

  const renderIssuePlace = (record: UserProfileBodyData) => {
    const issuePlace = listIssuePlace?.find(
      (item) => item.code.toString() === record.issuePlace?.toString(),
    );
    const getName = listNationality.find((item) => item.code === record.nationality);
    if (issuePlace) {
      return <div>{issuePlace?.name}</div>;
    } else {
      return getName ? getName.name : '';
    }
  };

  const beforeUpload = (file: { type: string; size: number }) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Chỉ có thể chọn ảnh định dạng JPG/PNG!');
      return isJpgOrPng;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(
        'File ảnh phải có dung lượng bé hơn hoặc bằng 2Mb, vui lòng chọn hình ảnh khác!',
      );
      return isLt2M;
    }
    return isJpgOrPng && isLt2M;
  };

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    {
      name: 'ID hồ sơ',
      fieldName: 'id',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileBodyData) => (
        <div className='flex'>
          <Button
            type='ghost'
            className={`underline whitespace-nowrap bg-transparent border-transparent text-blue-400 font-semibold`}
            onClick={() => handleDetailLine(record)}
          >
            {record.id}
          </Button>
        </div>
      ),
    },
    {
      name: 'Họ và tên',
      fieldName: 'userName',
      isHideSort: true,
    },
    {
      name: 'Loại giấy tờ',
      fieldName: 'typeIdNumber',
      isHideSort: true,
      renderSpecialCard: renderIdNumber,
    },
    {
      name: 'Số giấy tờ',
      fieldName: 'idNumber',
      isHideSort: true,
    },
    {
      name: 'Ngày cấp',
      fieldName: 'issueDate',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileBodyData) => {
        return <span>{record.issueDate ? formatDate(record.issueDate) : ''}</span>;
      },
    },
    {
      name: 'Nơi cấp',
      fieldName: 'issuePlace',
      isHideSort: true,
      renderSpecialCard: renderIssuePlace,
    },
    {
      name: 'Mặt trước giấy tờ',
      fieldName: 'frontImage',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileBodyData) => {
        return (
          <Upload
            key={record.id}
            listType='picture'
            disabled={userBatchStatus !== 'Edit'}
            fileList={[]}
            maxCount={1}
            beforeUpload={beforeUpload}
            accept='image/png, image/jpeg'
            onChange={({ file }) => {
              const isLt2M = file.size ? file.size / 1024 / 1024 < 2 : false;
              if (isLt2M) {
                file.status = 'done';
                handleUploadImage(record, 'frontImage', '/mattruoc', file);
              }
            }}
          >
            {record.frontImage ? (
              <div
                className={`underline whitespace-nowrap ${
                  userBatchStatus === 'Edit' ? 'cursor-pointer' : ''
                } bg-transparent border-transparent text-blue-400 font-semibold`}
              >
                {record.id + '/mattruoc'}
              </div>
            ) : (
              <div
                className={`underline whitespace-nowrap ${
                  userBatchStatus === 'Edit' ? 'cursor-pointer' : ''
                } bg-transparent border-transparent text-blue-400 font-semibold`}
              >
                Chọn file
              </div>
            )}
          </Upload>
        );
      },
    },
    {
      name: 'Mặt sau giấy tờ',
      fieldName: 'backImage',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileBodyData) => {
        return (
          <Upload
            key={record.id}
            listType='picture'
            fileList={[]}
            disabled={userBatchStatus !== 'Edit'}
            maxCount={1}
            beforeUpload={beforeUpload}
            accept='image/png, image/jpeg'
            onChange={({ file }) => {
              const isLt2M = file.size ? file.size / 1024 / 1024 < 2 : false;
              if (isLt2M) {
                file.status = 'done';
                handleUploadImage(record, 'backImage', '/matsau', file);
              }
            }}
          >
            {record.backImage ? (
              <div
                className={`underline whitespace-nowrap ${
                  userBatchStatus === 'Edit' ? 'cursor-pointer' : ''
                } bg-transparent border-transparent text-blue-400 font-semibold`}
              >
                {record.id + '/matsau'}
              </div>
            ) : (
              <div
                className={`underline whitespace-nowrap ${
                  userBatchStatus === 'Edit' ? 'cursor-pointer' : ''
                } bg-transparent border-transparent text-blue-400 font-semibold`}
              >
                Chọn file
              </div>
            )}
          </Upload>
        );
      },
    },
    {
      name: 'Chữ ký',
      fieldName: 'signature',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileBodyData) => {
        return (
          <Upload
            key={record.id}
            listType='picture'
            disabled={userBatchStatus !== 'Edit'}
            maxCount={1}
            accept='image/png, image/jpeg'
            beforeUpload={beforeUpload}
            fileList={[]}
            onChange={({ file }) => {
              const isLt2M = file.size ? file.size / 1024 / 1024 < 2 : false;
              if (isLt2M) {
                file.status = 'done';
                handleUploadImage(record, 'signature', '/chuky', file);
              }
            }}
          >
            {record.signature ? (
              <div
                className={`underline whitespace-nowrap ${
                  userBatchStatus === 'Edit' ? 'cursor-pointer' : ''
                } bg-transparent border-transparent text-blue-400 font-semibold`}
              >
                {record.id + '/chuky'}
              </div>
            ) : (
              <div
                className={`underline whitespace-nowrap ${
                  userBatchStatus === 'Edit' ? 'cursor-pointer' : ''
                } bg-transparent border-transparent text-blue-400 font-semibold`}
              >
                Chọn file
              </div>
            )}
          </Upload>
        );
      },
    },
    {
      name: 'Chân dung',
      fieldName: 'selfieImage',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileBodyData) => {
        return (
          <Upload
            key={record.id}
            listType='picture'
            disabled={userBatchStatus !== 'Edit'}
            maxCount={1}
            beforeUpload={beforeUpload}
            accept='image/png, image/jpeg'
            fileList={[]}
            onChange={({ file }) => {
              const isLt2M = file.size ? file.size / 1024 / 1024 < 2 : false;
              if (isLt2M) {
                file.status = 'done';
                handleUploadImage(record, 'selfieImage', '/chandung', file);
              }
            }}
          >
            {record.selfieImage ? (
              <div
                className={`underline whitespace-nowrap ${
                  userBatchStatus === 'Edit' ? 'cursor-pointer' : ''
                } bg-transparent border-transparent text-blue-400 font-semibold`}
              >
                {record.id + '/chandung'}
              </div>
            ) : (
              <div
                className={`underline whitespace-nowrap ${
                  userBatchStatus === 'Edit' ? 'cursor-pointer' : ''
                } bg-transparent border-transparent text-blue-400 font-semibold`}
              >
                Chọn file
              </div>
            )}
          </Upload>
        );
      },
    },
    {
      name: 'Trạng thái hồ sơ',
      fieldName: 'statusString',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileBodyData) => {
        return <span>{record.statusString}</span>;
      },
    },
    {
      name: 'Trạng thái hoạt động',
      fieldName: 'createdAt',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileBodyData) => {
        return <span>{record.isActive ? 'Active' : 'InActive'}</span>;
      },
    },
    {
      name: 'Thao tác',
      fieldName: 'id',
      renderSpecialCard: (record: UserProfileBodyData) => (
        <div className='flex'>
          <Button
            type='ghost'
            className={`underline whitespace-nowrap bg-transparent border-transparent text-blue-400 font-semibold`}
            onClick={() => handleDetailLine(record)}
          >
            Chi tiết
          </Button>
          {record.statusString === ProfileEnum.New ? (
            <Button
              type='ghost'
              disabled={userBatchStatus !== 'Edit'}
              className={`underline whitespace-nowrap ${
                userBatchStatus === 'Edit' ? 'cursor-pointer' : 'cursor-not-allowed'
              } bg-transparent border-transparent text-blue-400 font-semibold`}
              onClick={() => handleDeleteLine(record)}
            >
              Xoá
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
      isHideSort: true,
    },
  ];

  const handleDeleteLine = (record: UserProfileBodyData) => {
    const tempData: any = cloneDeep(data.filter((item: any) => item.id !== record.id));

    setData([...tempData]);
  };

  const handleDetailLine = (record: UserProfileBodyData) => {
    if (record.id) {
      dispatch(
        setGlobalState({
          activeKeyMenu: `user-profile/detail?id=${record.id}`,
        }),
      );
      if (!openTabs.find((tab) => tab.key === `user-profile/detail?id=${record.id}`)) {
        dispatch(
          setGlobalState({
            openTabs: [
              ...openTabs,
              {
                label: 'Chi tiết hồ sơ cá nhân ' + record.id,
                children: '',
                key: `user-profile/detail?id=${record.id}`,
                closable: true,
              },
            ],
          }),
        );
      }
      navigate(`/management/user-profile/detail?id=${record.id}`);
    }
  };

  return (
    <TableContent
      bordered
      scroll={{ x: 'max-content' }}
      locale={{ emptyText: 'Không tìm thấy kết quả' }}
      dataSource={data ?? []}
      columns={columns}
      isHidePaging
      totalRecord={data?.length ?? 0}
    />
  );
}

export default TabSuccess;
