import {Image, Modal, ModalProps, Spin, Typography} from 'antd';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import request from 'src/api/request';
import {formatDateTime, handleDownloadImage} from 'src/utils';
import {DownloadOutlined} from '@ant-design/icons';
import {StatusProgressSim} from '../../../shared/enum/sim.enum';

interface IModalHistoryActiveSim {
  open: boolean;
  content?: ReactNode;
  props?: ModalProps;
  handleCancel(): void;
}

const ModalHistoryActiveSim = ({ open, content, handleCancel, props }: IModalHistoryActiveSim) => {
  const [historyData, setHistoryData] = useState<any>();
  const listRef = useRef<HTMLUListElement>(null);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [historyList, setHistoryList] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [pageSize] = useState<number>(10);

  const getHistoryData = async () => {
    setIsLoading(true);
    const response = await request('get', 'api/Provider/get-history-active-by-distributor', {
      PageSize: pageSize,
      PageIndex: page,
    });

    if (response?.data) {
      setPage(prev => prev + 1);
      setHistoryData(response?.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (historyData) {
      const history = historyData?.results || [];
      setHistoryList([...historyList, ...history])
      setTotalItems(historyData?.rowCount);
    }
  }, [historyData]);

  const handleScroll = (e: any) => {
    if (totalItems < (pageSize * (page - 1))) {
      return;
    }

    if (listRef?.current) {
      const isBottom = listRef?.current?.scrollHeight - listRef?.current?.scrollTop - 100 < listRef?.current?.clientHeight;
      if (isBottom && !fetchingData) {
        setFetchingData(true);
        setIsLoading(true);
        getHistoryData();

        setTimeout(() => {
          setIsLoading(false);
          setFetchingData(false);
        }, 1000);
        return false;
      }

    }
  }

  useEffect(() => {
    if (open) {
      getHistoryData();
    }

    return () => {
      setPage(1);
      setHistoryData({});
      setHistoryList([]);
    }
  }, [open])

  return (
    <Modal
      open={open}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      title={<p className='text-xl m-0'>Lịch sử kích hoạt</p>}
      className='h-screen w-screen modal-progress-list modal-progress-connect'
      closeIcon={<IoArrowBack />}
      onCancel={handleCancel}
    >
      <div className='flex justify-between gap-3'>
        <p className='bg-[#F5FAF8] text-[#008B55] text-base flex-1 py-2 px-3'>
          Thành công: {historyData?.simSucess}
        </p>
        <p className='bg-[#FEF6F8] text-[#EE284A] text-base flex-1 py-2 px-3'>
          Thất bại: {historyData?.simFailed}
        </p>
      </div>
      <Spin spinning={isLoading}>
        <ul className='list-none m-0 p-0 flex flex-col gap-2 list-inside-modal overflow-auto' ref={listRef} onScroll={handleScroll} >
          {historyList &&
            historyList?.map((item: any, index: number) => {
              const { serialNumber, phoneNumber, description, updatedAt, status, linkQrText } = item;
              return (
                <li key={index} className='flex items-center py-2 px-4 bg-white rounded-xl'>
                  <div className='flex justify-between items-center w-full flex-1'>
                    <div className='w-full'>
                      <div className='flex justify-between w-full relative'>
                        {phoneNumber && (
                          <p className='text-base font-semibold m-0 flex-1'>{phoneNumber}</p>
                        )}
                        {status === 0 ? (
                          <p className='bg-[#F5FAF8] text-[#008B55] flex-1 py-1 px-2 rounded-xl text-xs m-0'>
                            Kích hoạt thành công
                          </p>
                        ) : (
                          <span className='absolute right-0 bg-[#FEF6F8] text-[#EE284A] flex-1 py-1 px-2 rounded-xl text-xs m-0'>
                            Thất bại
                          </span>
                        )}
                      </div>
                      <p className='m-0 mb-2 mt-1 text-[#446AF9] text-xs'>{serialNumber}</p>
                      <div className={"flex items-center gap-4 mt-2"}>
                        {linkQrText && status === StatusProgressSim.SUCCESS && (
                            <div className={"flex items-center w-full"}>
                              <p className='m-0 mb-2 mt-1 text-xs mr-2 w-[48%]'>Mã QR eSim:</p>
                              <div className={"flex items-center gap-2"}>
                                <Image width={45} height={45} src={linkQrText} alt={"Mã QR eSim"} />
                                <button
                                    className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent font-semibold`}
                                    onClick={() => handleDownloadImage(linkQrText)}
                                >
                                  <Typography.Text className={'mr-2 text-blue-400'}>Tải về</Typography.Text>
                                  <DownloadOutlined color={"black"} />
                                </button>
                              </div>
                            </div>
                        )}
                      </div>
                      <p className='text-[#3A3A3A] text-xs'>{description}</p>
                      <p className='text-[#959595] text-xs'>{formatDateTime(updatedAt)}</p>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </Spin>
    </Modal>
  );
}

export default ModalHistoryActiveSim;
