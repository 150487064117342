import { Col, Form, Radio, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { formatDate } from 'src/utils';

function AgentInfo() {
  const form = Form.useFormInstance();
  useWatch('contactIsNegotiator', form);

  const renderType = () => {
    switch (form.getFieldValue('contactIdType')) {
      case '0':
        return <div>CMND/CCCD</div>;
      case '1':
        return <div>PASSPORT</div>;

      default:
        return '';
    }
  };
  return (
    <BoxWithLabel title='Thông tin người đại diện' classNames='mt-4'>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item labelCol={{ span: 12 }} label='Họ và tên' name={'contactName'}>
            {form.getFieldValue('contactName')}
          </Form.Item>
          <Form.Item labelCol={{ span: 12 }} label='Ngày sinh' name={'contactDob'}>
            {form.getFieldValue('contactDob') ? formatDate(form.getFieldValue('contactDob')) : ''}
          </Form.Item>
          <Form.Item label='Giới tính' labelCol={{ span: 12 }} name='contactGender'>
            {form.getFieldValue('contactGender') === '1' ? 'Nữ' : 'Nam'}
          </Form.Item>
          <Form.Item label='Quốc tịch' labelCol={{ span: 12 }} name='contactNationality'>
            {form.getFieldValue('contactNationality')}
          </Form.Item>
          <Form.Item label='Loại giấy tờ' labelCol={{ span: 12 }} name='contactIdType'>
            {renderType()}
          </Form.Item>
          <Form.Item label='Số giấy tờ' labelCol={{ span: 12 }} name='contactIdNumber'>
            {form.getFieldValue('contactIdNumber')}
          </Form.Item>
          <Form.Item label='Ngày cấp' labelCol={{ span: 12 }} name='contactIssueDate'>
            {form.getFieldValue('contactIssueDate')
              ? formatDate(form.getFieldValue('contactIssueDate'))
              : ''}
          </Form.Item>
          <Form.Item label='Nơi cấp' labelCol={{ span: 12 }} name='contactIssuePlace'>
            {form.getFieldValue('contactIssuePlace')}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Tỉnh/ Thành phố' labelCol={{ span: 12 }} name='contactProvince'>
            {form.getFieldValue('contactProvince')}
          </Form.Item>
          <Form.Item label='Quận/ Huyện' labelCol={{ span: 12 }} name='contactDistrict'>
            {form.getFieldValue('contactDistrict')}
          </Form.Item>
          <Form.Item label='Phường/ Xã' labelCol={{ span: 12 }} name='contactWard'>
            {form.getFieldValue('contactWard')}
          </Form.Item>
          <Form.Item label='Địa chỉ thường trú' labelCol={{ span: 12 }} name='contactAddress'>
            {form.getFieldValue('contactAddress')}
          </Form.Item>
          <Form.Item label='Nghề nghiệp' labelCol={{ span: 12 }} name='contactEmployment'>
            {form.getFieldValue('contactEmployment')}
          </Form.Item>
          <Form.Item label='Chức vụ' labelCol={{ span: 12 }} name='contactRole'>
            {form.getFieldValue('contactRole')}
          </Form.Item>
          <Form.Item label='Email' labelCol={{ span: 12 }} name='contactEmail'>
            {form.getFieldValue('contactEmail')}
          </Form.Item>
          <Form.Item label='Số điện thoại' labelCol={{ span: 12 }} name='contactPhoneNumber'>
            {form.getFieldValue('contactPhoneNumber')}
          </Form.Item>
          <Form.Item label='Ghi chú' labelCol={{ span: 12 }} name='contactNote'>
            {form.getFieldValue('contactNote')}
          </Form.Item>
          <Form.Item label='Liên hệ khác' labelCol={{ span: 12 }} name='contactOtherContact'>
            {form.getFieldValue('contactOtherContact')}
          </Form.Item>
          <Form.Item
            label='Là người đến giao dịch'
            labelCol={{ span: 12 }}
            name='contactIsNegotiator'
          >
            <Radio.Group disabled>
              <Radio value={'true'}>Yes</Radio>
              <Radio value={'false'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </BoxWithLabel>
  );
}

export default AgentInfo;
