import { Col, Form, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { useAppSelector } from 'src/redux/hook';
import { formatDateTime } from 'src/utils';

function GeneralInfo() {
  const form = Form.useFormInstance();
  const { userProfileBatch } = useAppSelector((state) => state.userProfile);
  useWatch('enterpriseName', form);

  return (
    <BoxWithLabel title='Thông tin chung' classNames='mt-4'>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item labelCol={{ span: 10 }} label='Số lô' name={'lotId'}>
            {userProfileBatch?.lotId}
          </Form.Item>
          <Form.Item labelCol={{ span: 10 }} label='Tên file' name={'fileName'}>
            {userProfileBatch?.fileName}
          </Form.Item>
          <Form.Item label='Ngày import' labelCol={{ span: 10 }} name='' className='col-span-6'>
            {userProfileBatch?.createdAt ? formatDateTime(userProfileBatch?.createdAt) : ''}
          </Form.Item>
          <Form.Item label='Trạng thái' labelCol={{ span: 10 }} name='enterpriseFoundingPermNo'>
            {userProfileBatch?.status}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label='Tổng cộng'
            labelCol={{ span: 16 }}
            name='enterpriseLevel'
            className='col-span-6'
          >
            {userProfileBatch?.total}
          </Form.Item>
          <Form.Item
            label='Imported thành công'
            labelCol={{ span: 16 }}
            name='transactionId'
            className='truncate col-span-6'
          >
            {userProfileBatch?.importSuccess}
          </Form.Item>
          <Form.Item
            label='Imported thất bại'
            labelCol={{ span: 16 }}
            name='enterpriseFoundingPermDate'
          >
            {userProfileBatch?.importFailed}
          </Form.Item>
          <Form.Item label='Chờ duyệt' labelCol={{ span: 16 }} name='enterpriseFoundingPermPlace '>
            {userProfileBatch?.waiting}
          </Form.Item>
          <Form.Item label='Đã duyệt' labelCol={{ span: 16 }} name='enterpriseFoundingPermPlace '>
            {userProfileBatch?.approved}
          </Form.Item>
          <Form.Item label='Từ chối' labelCol={{ span: 16 }} name='enterpriseAddress'>
            {userProfileBatch?.reject}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            label='Người tạo'
            labelCol={{ span: 12 }}
            name='enterpriseLevel'
            className='col-span-6'
          >
            {userProfileBatch?.createdBy}
          </Form.Item>
          <Form.Item
            label='Thời gian tạo'
            labelCol={{ span: 12 }}
            name='transactionId'
            className='truncate col-span-6'
          >
            {userProfileBatch?.createdAt ? formatDateTime(userProfileBatch?.createdAt) : ''}
          </Form.Item>
          <Form.Item
            label='Người cập nhật gần nhất'
            labelCol={{ span: 12 }}
            name='enterpriseFoundingPermDate'
          >
            {userProfileBatch?.updatedBy}
          </Form.Item>
          <Form.Item
            label='Thời gian cập nhật gần nhất'
            labelCol={{ span: 12 }}
            name='enterpriseFoundingPermPlace '
          >
            {userProfileBatch?.updatedAt && userProfileBatch?.createdAt
              ? new Date(userProfileBatch?.updatedAt).getTime() ===
                new Date(userProfileBatch?.createdAt).getTime()
                ? ''
                : formatDateTime(userProfileBatch?.updatedAt)
              : ''}
          </Form.Item>
        </Col>
      </Row>
    </BoxWithLabel>
  );
}

export default GeneralInfo;
