import { SimStatus, SimType, StatusProgressSim } from 'src/shared/enum/sim.enum';

export function simStatusToText(type: any) {
  switch (type) {
    case SimStatus.NOT_SALE:
      return 'Khởi tạo, đang nằm trong kho';
    case SimStatus.IN_ACTIVE:
      return 'Đã xuất lô, sẵn sàng kích hoạt';
    case SimStatus.ACTIVATION_REQUEST:
      return 'Đang trong quá trình kích hoạt SIM';
    case SimStatus.ACTIVATION_SUCCESS:
      return 'Kích hoạt SIM thành công';
    case SimStatus.ACTIVATION_FAIL:
      return 'Kích hoạt SIM thất bại';
    case SimStatus.EXPIRED:
      return 'Hết hạn';
  }
  return '';
}

export function statusProgressSimToText(type: number) {
  switch (type) {
    case StatusProgressSim.SUCCESS:
      return 'Thành công';
    case StatusProgressSim.FAILED:
      return 'Thử lại';
    case StatusProgressSim.WAITING:
      return 'Đợi xử lý';
    case StatusProgressSim.PROCESSING:
      return 'Đang xử lý';
    case StatusProgressSim.PHONENUMBERFAILED:
      return 'Chọn số khác';
    case StatusProgressSim.SIMFAILED:
      return 'Quét Sim';
  }
  return '';
}

export function simTypeToText(type: any) {
  switch (type) {
    case SimType.PHYSICAL_SIM:
      return 'Sim vật lý';
    case SimType.ESIM:
      return 'eSIM';
  }
  return '';
}
