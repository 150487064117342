import { theme, Col, Row, Descriptions, Typography, Space } from 'antd';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { dataGet } from 'src/utils';
import {
  getSimStatus,
  getJointSubject,
  getSubscriptionStatus,
} from 'src/pages/management/activeSim/utils';
import dayjs from 'dayjs';
import {simTypeToText} from 'src/utils/sim.enum';

export default function SimInfoBox({ simDetail }: any) {
  const { token } = theme.useToken();

  const getDataUsedValue = () => {
    let dataUsed = 0;
    const totalTraffic = dataGet(simDetail, 'packageInfo.total_traffic');
    const availableTraffic = dataGet(simDetail, 'packageInfo.available_traffic');
    if (totalTraffic && availableTraffic) {
      dataUsed = parseInt(totalTraffic) - parseInt(availableTraffic);
    }
    return dataUsed;
  };

  const getCalledMinuteValue = () => {
    let calledMinute = 0;
    const totalVoiceCall = dataGet(simDetail, 'packageInfo.total_voice_call');
    const availableVoiceCall = dataGet(simDetail, 'packageInfo.available_voice_call');
    if (totalVoiceCall && availableVoiceCall) {
      calledMinute = parseInt(totalVoiceCall) - parseInt(availableVoiceCall);
    }
    return calledMinute;
  };
  
  return (
    <BoxWithLabel title='Thông tin thuê bao' classNames='mt-4'>
      <Row gutter={16}>
        <Col span={12}>
          <Descriptions
            column={1}
            labelStyle={{
              minWidth: '185px',
              color: token.colorText,
            }}
          >
            <Descriptions.Item label='SĐT'>{dataGet(simDetail, 'phoneNumber')}</Descriptions.Item>
            <Descriptions.Item label='Số SIM Serial'>
              {dataGet(simDetail, 'serialNumber')}
            </Descriptions.Item>
            <Descriptions.Item label='Gói cước đang sử dụng'>
              {dataGet(simDetail, 'packageInfo.package_name')}
            </Descriptions.Item>
            <Descriptions.Item label='Ngày gán gói'>
              {dataGet(simDetail, 'packageInfo.attach_at')}
            </Descriptions.Item>
            <Descriptions.Item label='Người gán gói'>
              {dataGet(simDetail, 'packageInfo.attach_by')}
            </Descriptions.Item>
            <Descriptions.Item label='Ngày TB kích hoạt'>
              <Typography.Text>
                {simDetail?.activatedAt
                  ? dayjs(simDetail.activatedAt).format('DD/MM/YYYY HH:mm:ss')
                  : ''}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Ngày TB đổi SIM'>
              <Typography.Text>
                {simDetail?.changeSimAt
                  ? dayjs(simDetail.changeSimAt).format('DD/MM/YYYY HH:mm:ss')
                  : ''}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Zone'>
              <Typography.Text>
                {dataGet(simDetail, 'custProfile.serviceModel.serviceValue.currentZone')}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <Descriptions
            column={1}
            labelStyle={{
              minWidth: '185px',
              color: token.colorText,
            }}
          >
            <Descriptions.Item label='Ngày kích hoạt gói cước'>
              {dataGet(simDetail, 'packageInfo.start_time')}
            </Descriptions.Item>
            <Descriptions.Item label='Ngày hết hạn gói cước'>
              {dataGet(simDetail, 'packageInfo.end_time')}
            </Descriptions.Item>
            <Descriptions.Item label='Data sử dụng'>
              <Space size={'small'}>
                <Typography.Text>{getDataUsedValue()}</Typography.Text>
                <Typography.Text>{dataGet(simDetail, 'packageInfo.unit')}</Typography.Text>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label='Số phút đã gọi'>
              <Space size={'small'}>
                <Typography.Text>{getCalledMinuteValue()}</Typography.Text>
                <Typography.Text>
                  {dataGet(simDetail, 'packageInfo.voice_call_unit')}
                </Typography.Text>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label='Số dư tài khoản chính'>
              <Typography.Text>{dataGet(simDetail, 'balance')}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Trạng thái TB'>
              <Typography.Text>
                {simDetail?.custProfile?.serviceModel?.status &&
                  getSubscriptionStatus(simDetail?.custProfile?.serviceModel?.status)}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Trạng thái SIM'>
              <Typography.Text>{getSimStatus(dataGet(simDetail, 'sim.status'))}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Đối tượng'>
              <Typography.Text>
                {getJointSubject(dataGet(simDetail, 'jointSubject'))}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Loại SIM'>
              <Typography.Text>
                {simTypeToText(dataGet(simDetail, 'sim.type'))}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </BoxWithLabel>
  );
}
