export enum TransactionHistoryStatus {
  Success = 'SUCCESS',
  Pending = 'PENDING',
  Error = 'ERROR',
  Failed = 'FAILED',
}
export enum TransactionHistoryType {
  Topup = 'Topup',
  Recharge = 'Recharge',
  RechargeAsimPortal = 'RechargeAsimPortal',
  Active = 'Active',
  ChangeSim = 'ChangeSim',
  RegisterPackage = 'RegisterPackage',
}
