import { Button, Form, Input, message, Radio, RadioChangeEvent, Select, Spin } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  checkPickedSerialSimUserProfile,
  getUserProfiles,
  reserveNumber,
} from 'src/api/connection';
import { IPhoneInfo, IUserProfile } from 'src/shared/interface/connection';
import {formatPhoneNumber, formatPhoneNumberV2} from 'src/utils';
import { simStatusToText, simTypeToText } from 'src/utils/sim.enum';
import ModalReChooseNumber from '../../ModalReChooseNumber';
import UserInforEmptyForm from './UserInforEmptyForm';
import UserInforForm from './UserInforForm';
import ModalConfirmConnect from '../../ModalConfirmConnect';
import { ConnectionType } from 'src/shared/enum/connection.enum';
import {getStatusEsimLotTxt} from "../../../../pages/management/esimManagement/utils";
import {REGEX_COMMON} from "../../../../shared/common/regex";

const ConnectionIndividual = () => {
  const [form] = Form.useForm();
  const [serialNumber, setSerialNumber] = useState('');
  const [isVerify, setIsVerify] = useState<boolean>(false);
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  const [currNumber] = useState<string>();
  const [isOpenNumberModal, setIsOpenNumberModal] = useState<boolean>(false);
  const [isOpenConnectModal, setIsOpenConnectModal] = useState<boolean>(false);
  const [phoneInfo, setPhoneInfo] = useState<IPhoneInfo>();
  const [isConnectFail, setIsConnectFail] = useState<boolean>(false);
  const navigate = useNavigate();
  const [currUserType, setCurrUserType] = useState<number>(1);
  const [currUser, setCurrUser] = useState<IUserProfile>();
  const [userProfile, setUserProfile] = useState<IUserProfile>();
  const [listUsersProfile, setListUsersProfile] = useState<IUserProfile[]>();

  const handleCancel = () => {
    setIsOpenNumberModal(false);
  };

  const handleCancelConnectModal = () => {
    setIsOpenConnectModal(false);
    if (isConnectFail) {
      handleReset();
    }
  };

  const handleReset = async () => {
    try {
      const response = await reserveNumber(currNumber, phoneInfo?.pool);

      if (response) {
        navigate(0);
      }
    } catch (error) {
      console.log(error);
      navigate(0);
    }
  };

  const handleCheckSerial = async () => {
    if (!serialNumber || serialNumber.length < 16 || !!phoneInfo || isVerify) {
      return;
    }

    setIsVerify(true);

    try {
      const respon = await checkPickedSerialSimUserProfile({
        distributorId: JSON.parse(merchantInfo as string)?.merchantId,
        serialNumber: serialNumber,
      });

      if (respon) {
        setCurrUser(respon?.userProfile);
        setUserProfile(respon?.userProfile);
        setPhoneInfo(respon?.phoneInfo);
        setIsVerify(false);
        fetchUserProfiles();
      }
    } catch (error: any) {
      setIsVerify(false);
    }
  };

  const fetchUserProfiles = async () => {
    const response = await getUserProfiles();

    setListUsersProfile(response.results);
  };

  const handleInputChange = (e: any) => {
    if (e.target.value.length <= 20) {
      form.setFieldValue('serialNumber', e.target.value);
      setSerialNumber(e.target.value);
    } else {
      form.setFieldValue('serialNumber', e.target.value.substring(0, 20));
      setSerialNumber(e.target.value.substring(0, 20));
    }
    e.preventDefault();
  };

  const handleSelectChange = (value: any) => {
    const user = listUsersProfile?.find((user) => user.id === value);
    setCurrUser(user);
  };

  const handleOk = async (selectedNumber: string) => {
    try {
      setIsOpenNumberModal(false);

      const response = await reserveNumber(phoneInfo?.phoneNumber, phoneInfo?.pool);

      if (response) {
        const newPhoneInfo = { ...phoneInfo, phoneNumber: selectedNumber };
        setPhoneInfo(newPhoneInfo as IPhoneInfo);
      }
    } catch (error) {
      message.error('Đá số thất bại , xin vui lòng thử lại!');
    } finally {
      console.log('error');
    }
  };

  const handleBtnReChooseClick = () => {
    setIsOpenNumberModal(true);
  };

  const onRadioUserChange = ({ target: { value } }: RadioChangeEvent) => {
    setCurrUserType(value);
  };

  return (
    <>
      <div className='flex flex-col gap-5 mb-6'>
        <div className='flex items-center gap-3'>
          <Form.Item
            name='serialNumber'
            label={<span className='font-semibold'>Số SIM serial</span>}
            className='my-0'
            rules={[
              {
                min: 16,
                message: 'Vui lòng nhập đủ số serial',
              }
            ]}
          >
            <Input
              type='number'
              maxLength={20}
              onChange={(e) => handleInputChange(e)}
              onKeyDown={(evt: any) => {
                if (!evt.ctrlKey && !evt.metaKey) {
                  if (
                    evt.key === 'e' ||
                    evt.key === '+' ||
                    evt.key === '-' ||
                    (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                    Number(evt.key) > 9 ||
                    Number(evt.key) < 0
                  ) {
                    evt.preventDefault();
                  } else if (evt.target.value?.length >= 20 && evt.key !== 'Backspace') {
                    evt.preventDefault();
                  }
                }
              }}
              disabled={!!phoneInfo}
              style={{ minWidth: '200px' }}
              onPressEnter={handleCheckSerial}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              onClick={handleCheckSerial}
              disabled={!serialNumber || serialNumber.length < 16 || !!phoneInfo || isVerify}
            >
              Kiểm tra
            </Button>
          </Form.Item>
          <Spin spinning={isVerify} />
        </div>
        {phoneInfo && (
          <>
            <div className='flex items-center gap-10'>
              <p className='my-0 font-semibold'>
                Loại:{' '}
                <span className='font-normal'>{simTypeToText(phoneInfo?.simType)}</span>
              </p>
              <p className='my-0 font-semibold'>
                NPP: <span className='font-normal'>{phoneInfo?.distributorName}</span>
              </p>
              <p className='my-0 font-semibold'>
                Trạng thái:{' '}
                <span className='font-normal'>
                  {phoneInfo?.status && getStatusEsimLotTxt(phoneInfo?.status.toString())}
                </span>
              </p>
            </div>
            <p className='flex items-center m-0'>
              <span className='block min-w-[150px] font-semibold'>Mã gói: </span>
              <span>{phoneInfo?.mbfCode}</span>
            </p>
            <div className='m-0 flex gap-10 items-center'>
              <p className='my-0 min-w-[300px] flex'>
                <span className='block min-w-[150px] font-semibold'>Số thuê bao: </span>
                <span>{phoneInfo?.phoneNumber && formatPhoneNumberV2(phoneInfo?.phoneNumber)}</span>
              </p>
              <Button onClick={handleBtnReChooseClick}>Chọn lại số</Button>
            </div>
            <div className='mb-10'>
              <Radio.Group onChange={onRadioUserChange} name='userType' defaultValue={currUserType}>
                <Radio value={1}>Chọn người sử dụng từ danh sách</Radio>
                <Radio value={2}>Nhập thủ công thông tin người sử dụng</Radio>
              </Radio.Group>
              <div className='my-5 max-w-[50%]'>
                <Form.Item label='Người sử dụng'>
                  <Select
                    className='w-auto flex-grow-0'
                    placeholder='Xin vui lòng chọn người sử dụng'
                    defaultValue={currUser?.id}
                    disabled={currUserType === 2}
                    options={(listUsersProfile || []).map((userProfile: IUserProfile) => {
                      const { id, userName } = userProfile;
                      return {
                        value: id,
                        label: userName,
                      };
                    })}
                    onChange={handleSelectChange}
                  ></Select>
                </Form.Item>
              </div>
            </div>
            {currUserType === 1 && currUser && (
              <UserInforForm userProfile={currUser} phoneInfo={phoneInfo} />
            )}

            {currUserType === 2 && <UserInforEmptyForm phoneInfo={phoneInfo} />}
          </>
        )}
      </div>
      <ModalReChooseNumber
        isOpen={isOpenNumberModal}
        onCancel={handleCancel}
        onOk={handleOk}
        pool={phoneInfo?.pool}
      />
      <ModalConfirmConnect
        isOpen={isOpenConnectModal}
        connectionType={ConnectionType.INDIVIDUAL}
        handleCancel={handleCancelConnectModal}
        setIsConnectFail={setIsConnectFail}
        userProfile={currUser}
        phoneInfo={phoneInfo}
      />
    </>
  );
};

export default ConnectionIndividual;
