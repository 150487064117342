const NotAllow = () => {
  return (
    <div className='flex justify-center items-center h-screen w-screen'>
      <div className='p-16 bg-white rounded-3xl shadow-2xl'>
        <h1>:&#8217;) We&#8217;re sooo sorry</h1>
        <h1>Currently, we haven&#8217;t support Desktop browsers yet.</h1>
        <p>
          Please use web browsers on your mobile device,or you can download app myLocal.vn (support
          both iOS and Android) to activate your SIM card with better experience
        </p>
      </div>
    </div>
  );
};

export default NotAllow;
