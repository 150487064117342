import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

const rQConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    },
  },
}

const queryClient = new QueryClient(rQConfig);

export function Providers({children}: {
  children: React.ReactNode;
}) {
  return (
      <QueryClientProvider client={queryClient}>
        {children}
      </QueryClientProvider>
  );
}