import { Button, DatePicker, Form, message, Select, Upload, UploadProps } from 'antd';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IListColumn, ISortColumn, PaginationResponse } from 'src/@types/search-params';
import { getAllUserProfileBatch, inserExportUserProfileBatch } from 'src/api/user_profile';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { setUserProfileState } from 'src/redux/slice/userProfile';
import { ProfileBatchStatus } from 'src/shared/common/userProfile';
import { UserProfileBatchResponse } from 'src/shared/interface/user_profile';
import { exportExcel, formatDateTime, parseQueryString } from 'src/utils';

function UsersProfileBatchList() {
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = location.search;
  const { openTabs } = useAppSelector((state) => state.global);
  const { isRefresh } = useAppSelector((state) => state.userProfile);
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');

  const [data, setData] = useState<PaginationResponse<UserProfileBatchResponse[]>>();
  const [sortColumn, setSortColumn] = useState<ISortColumn>();
  const [file, setFile] = useState<any>();

  useEffect(() => {
    fetchListUserProfileBatch();
  }, [isRefresh]);

  useEffect(() => {
    fetchListUserProfileBatch();
    setSortColumn(undefined);
  }, [location.search]);

  const fetchListUserProfileBatch = async () => {
    const params = parseQueryString(location.search);
    const res = await getAllUserProfileBatch(params);
    if (res) {
      setData(res);
    }
  };
  function handleExport() {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `danh_sach_HSNSD_theo_lo_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    const paramsExport = params
      ? params + `&Type=2&EnterpriseId=${JSON.parse(merchantInfo as string)?.merchantId ?? ''}`
      : `?Type=2&EnterpriseId=${JSON.parse(merchantInfo as string)?.merchantId ?? ''}`;

    exportExcel(
      process.env.REACT_APP_API_URL +
        '/api/user-profile/overflow-user-profile/export' +
        paramsExport,
    ).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
    });
  }

  function handleFormSearch(value: any) {
    let values = { ...value };
    values = {
      ...values,
      dateFrom:
        value.dateRange && value?.dateRange[0]
          ? value?.dateRange[0].format('YYYY-MM-DD')
          : undefined,
      dateTo:
        value.dateRange && value?.dateRange[1]
          ? value?.dateRange[1].format('YYYY-MM-DD')
          : undefined,
      dateRange: undefined,
    };
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        delete values[key];
      }
    });
    const pagination = parseQueryString(location.search);
    setSearchParams({
      ...pagination,
      pageIndex: 1,
      pageSize: 10,
      ...values,
    });
  }

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    {
      name: 'Số lô',
      fieldName: 'lotId',
      renderSpecialCard: (userBatch: UserProfileBatchResponse) => (
        <button
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
          onClick={() => openDetailUserProfileBatchPage(userBatch.lotId)}
        >
          {userBatch.lotId ?? ''}
        </button>
      ),
    },
    {
      name: 'Tên file',
      fieldName: 'fileName',
      isHideSort: true,
    },
    {
      name: 'Trạng thái',
      fieldName: 'status',
      isHideSort: true,
    },
    {
      name: 'Tổng cộng',
      fieldName: 'total',
    },
    {
      name: 'Imported thành công',
      fieldName: 'importSuccess',
      // renderSpecialCard: (record: UserProfileBatchResponse) => {
      //   return <span>{record.toTalAmount ? formatMoney(record.toTalAmount, 'đ') : 0}</span>;
      // },
    },
    {
      name: 'Imported thất bại',
      fieldName: 'importFailed',
    },
    {
      name: 'Chờ duyệt',
      fieldName: 'waiting',
      width: '5%',
    },
    {
      name: 'Đã duyệt',
      fieldName: 'approved',
      width: '5%',
    },
    {
      name: 'Từ chối',
      fieldName: 'reject',
      width: '5%',
    },
    {
      name: 'Ngày import',
      fieldName: 'createdAt',
      renderSpecialCard: (record: UserProfileBatchResponse) => {
        return <span>{record.createdAt ? formatDateTime(record.createdAt) : ''}</span>;
      },
    },
    {
      name: 'Thao tác',
      fieldName: 'id',
      renderSpecialCard: (userBatch: UserProfileBatchResponse) => (
        <button
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
          onClick={() => openDetailUserProfileBatchPage(userBatch.lotId)}
        >
          Xem chi tiết
        </button>
      ),
      isHideSort: true,
    },
  ];

  function openDetailUserProfileBatchPage(id?: string) {
    if (id) {
      dispatch(
        setGlobalState({
          activeKeyMenu: `user-profile/batch-detail?lotId=${id}`,
        }),
      );
      if (!openTabs.find((tab) => tab.key === `user-profile/batch-detail?lotId=${id}`)) {
        dispatch(
          setGlobalState({
            openTabs: [
              ...openTabs,
              {
                label: 'Chi tiết tạo lô hồ sơ người sử dụng ' + id,
                children: '',
                key: `user-profile/batch-detail?lotId=${id}`,
                closable: true,
              },
            ],
          }),
        );
      }
      dispatch(setGlobalState({ order: undefined }));
      navigate(`/management/user-profile/batch-detail?lotId=${id}`);
    }
  }

  const propsUpload: UploadProps = {
    beforeUpload: (file) => {
      const isExcel =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        message.error(`Định dạng file không đúng`);
      }
      return isExcel || Upload.LIST_IGNORE;
    },
    onChange: ({ file }) => {
      if (file.status === 'removed') {
        setFile(null);
      } else {
        file.status = 'done';
        setFile(file);
      }
    },
    //eslint-disable-next-line
    customRequest: () => {},
  };

  const handleImportFile = async () => {
    if (file) {
      const res = await inserExportUserProfileBatch({
        excelFile: file.originFileObj ? file.originFileObj : file,
      });
      if (res) {
        dispatch(setUserProfileState({ isRefresh: !isRefresh }));
      }
    } else {
      message.error('Import không thành công. Vui lòng thử lại!');
    }
  };
  return (
    <Fragment>
      <Form onFinish={handleFormSearch} layout='inline'>
        {/* <Row className='gap-4'> */}
        <div className='flex justify-between gap-4 w-full'>
          <div className='flex gap-2'>
            <Upload
              {...propsUpload}
              maxCount={1}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              showUploadList
              fileList={file ? [file] : []}
            >
              <Button className='ml-2' type='dashed'>
                Chọn File
              </Button>
            </Upload>
            <Button className='ml-2' type='primary' onClick={handleImportFile} disabled={!file}>
              Import
            </Button>
          </div>
          <div className='flex'>
            <Form.Item name='dateRange' label='Ngày tạo'>
              <DatePicker.RangePicker
                disabledDate={(current) => current && current >= moment().endOf('day')}
                placeholder={['từ', 'đến']}
                format={'DD/MM/YYYY'}
              />
            </Form.Item>
            <Form.Item name='status' label='Trạng thái'>
              <Select
                showSearch
                className='min-w-[200px]'
                defaultValue={'Tất cả'}
                placeholder='Vui lòng chọn'
                options={ProfileBatchStatus}
              ></Select>
            </Form.Item>
          </div>
          <div className='flex my-12 mx-0 gap-4'>
            <Button type='primary' htmlType='submit'>
              Tìm kiếm
            </Button>
            <Button>
              <a
                href={`${process.env.REACT_APP_API_URL}/api/Provider/export-excel-template?type=4`}
              >
                Export template
              </a>
            </Button>

            <Button onClick={handleExport}>Export</Button>
          </div>
        </div>
      </Form>

      <TableContent
        bordered
        scroll={{ x: 'max-content' }}
        locale={{ emptyText: 'Không tìm thấy kết quả' }}
        dataSource={data?.results ?? []}
        columns={columns}
        totalRecord={data?.rowCount ?? 0}
        sortColumn={sortColumn}
        onSort={(sort: ISortColumn) => {
          const pagination = parseQueryString(location.search);
          handleFormSearch({
            ...pagination,
            pageIndex: pagination.pageIndex ?? 1,
            pageSize: pagination.pageSize ?? 10,
            sortField: sort.fieldName,
            asc: sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? 'false' : undefined,
          });
        }}
      />
    </Fragment>
  );
}

export default UsersProfileBatchList;
