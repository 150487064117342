import {Modal, Image} from "antd";
type ModalPreviewQrCode = {
  open: boolean,
  setIsOpenQrCode?: any,
  isQrCodeActive?: boolean,
  selectedQrCode?: any,
  setSelectedQrCode?: any
}

export default function ModalPreviewQrCode({open, setIsOpenQrCode, isQrCodeActive, selectedQrCode, setSelectedQrCode}: ModalPreviewQrCode) {
  return (
    <>
      <Modal
        title={isQrCodeActive ? 'QR kích hoạt' : 'QR eSIM'}
        footer={false}
        open={open}
        onCancel={() => {
          setIsOpenQrCode(false)
          setSelectedQrCode(null)
        }}
      >
        <div className={'flex justify-center my-5'}>
          <Image
            alt={'qr-code'}
            preview={false}
            src={selectedQrCode}
            fallback={'https://s3-hn-2.cloud.cmctelecom.vn/asim-fileservice-dev/public-data/merchant-images/oms-images/esim_5719d390-e219-4402-9cb6-401aeb865d61.png'}
          />
        </div>
      </Modal>
    </>
  );
}