import { Col, Form, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import CUpload from 'src/components/CUpload';
import { formatDateTime } from 'src/utils';

function AttachmentUserProfile() {
  const form = Form.useFormInstance();
  useWatch('signature', form);
  useWatch('updatedAt', form);

  return (
    <>
      <BoxWithLabel title='Ảnh đính kèm' classNames='mt-4'>
        <div className='flex flex-wrap '>
          <div className=' flex flex-col justify-between w-1/4 h-60'>
            <div className='flex justify-center items-center h-full'>
              <Form.Item
                rules={[{ required: true, message: 'Trường này không được để trống' }]}
                className='custom-label text-center'
                name='frontImage'
              >
                <CUpload />
              </Form.Item>
              {/* <Upload
                name='file'
                listType='picture-card'
                className='avatar-uploader text-center'
                showUploadList={false}
                action={process.env.REACT_APP_UPLOAD_LINK}
                beforeUpload={beforeUpload}
                onChange={(info) => handleChange(info, 'frontImage')}
              >
                {form.getFieldValue('frontImage') ? (
                  <img
                    src={form.getFieldValue('frontImage')}
                    alt='avatar'
                    className='object-scale-down'
                  />
                ) : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload> */}
            </div>
            <div className='flex justify-center '>
              Mặt trước giấy tờ <span className='text-red-700'>*</span>
            </div>
          </div>
          <div className='flex flex-col justify-between w-1/4 h-60'>
            <div className='flex justify-center items-center h-full'>
              <Form.Item
                rules={[{ required: true, message: 'Trường này không được để trống' }]}
                className='custom-label text-center'
                name='backImage'
              >
                <CUpload />
              </Form.Item>
            </div>
            <div className='flex justify-center '>
              Mặt sau giấy tờ <span className='text-red-700'>*</span>
            </div>
          </div>
          <div className='flex flex-col justify-between w-1/4 h-60'>
            <div className='flex justify-center items-center h-full'>
              <Form.Item
                rules={[{ required: true, message: 'Trường này không được để trống' }]}
                className='custom-label text-center'
                name='signature'
              >
                <CUpload />
              </Form.Item>
            </div>
            <div className='flex justify-center '>
              Chữ ký <span className='text-red-700'>*</span>
            </div>
          </div>
          <div className='flex flex-col justify-between w-1/4 h-60'>
            <div className='flex justify-center items-center h-full'>
              <Form.Item
                className='custom-label text-center'
                name='selfieImage'
                rules={[{ required: true, message: 'Trường này không được để trống' }]}
              >
                <CUpload />
              </Form.Item>
            </div>
            <div className='flex justify-center '>
              Chân dung <span className='text-red-700'>*</span>
            </div>
          </div>
        </div>
      </BoxWithLabel>
      <BoxWithLabel title='' classNames='mt-4'>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item labelCol={{ span: 12 }} label='Người tạo' name={'createdBy'}>
              {form.getFieldValue('createdBy')}
            </Form.Item>
            <Form.Item labelCol={{ span: 12 }} label='Thời gian tạo' name={'createdAt'}>
              {form.getFieldValue('createdAt')
                ? formatDateTime(form.getFieldValue('createdAt'))
                : ''}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Người cập nhật gần nhất' labelCol={{ span: 12 }} name='updatedBy'>
              {form.getFieldValue('updatedBy')}
            </Form.Item>
            <Form.Item label='Thời gian cập nhật gần nhất' labelCol={{ span: 12 }} name='updatedAt'>
              {form.getFieldValue('updatedAt')
                ? new Date(form.getFieldValue('updatedAt')).getTime() ===
                  new Date(form.getFieldValue('createdAt')).getTime()
                  ? ''
                  : formatDateTime(form.getFieldValue('updatedAt'))
                : ''}
            </Form.Item>
          </Col>
        </Row>
      </BoxWithLabel>
    </>
  );
}

export default AttachmentUserProfile;
