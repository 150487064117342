import { ConnectionType } from 'src/shared/enum/connection.enum';

export const connectionTypeToString = (type: string) => {
  switch (type) {
    case ConnectionType.ENTERPRISE:
      return 'Doanh nghiệp';
    case ConnectionType.INDIVIDUAL:
      return 'Cá nhân';
  }
};
