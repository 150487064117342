import { store } from 'src/redux';
import { setChangeSimState } from 'src/redux/slice/changeSimSlice';
import { setGlobalState } from 'src/redux/slice/globalSlice';
export const OpenNewTab = (activeKeyMenu: string, label: string, isClear?: boolean) => {
  const { openTabs } = store.getState().global;
  store.dispatch(
    setGlobalState({
      activeKeyMenu: activeKeyMenu,
    }),
  );
  if (!openTabs.find((tab) => tab.key === activeKeyMenu)) {
    if (isClear) {
      store.dispatch(
        setChangeSimState({
          listErrorsStore: [],
          listSuccessStore: [],
        }),
      );
    }
    store.dispatch(
      setGlobalState({
        openTabs: [
          ...openTabs,
          {
            label: label,
            children: '',
            key: activeKeyMenu,
            closable: true,
          },
        ],
      }),
    );
  }

  return null;
};
