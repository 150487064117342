/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Tabs,
  Upload,
  UploadProps,
} from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBalance } from 'src/api/localpay';
import {
  cancelPackageAssigned,
  checkImportExcelSerial,
  checkInputListSerial,
  checkMbfCodePackageAssigned,
  createOrderAssigned,
  deletePackageAssigned,
  getDetailPackageAssigned,
  getPackageAssigned, getPackageAssignedV2,
  kittingPackageAssigned,
  retryPackageAssigned,
  updatePackageAssigned,
  uploadFilePackageAssigned,
} from 'src/api/package_assigned';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import ModalConfirm from 'src/components/modals/ModalComfirm';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { setModalState } from 'src/redux/slice/modal.store';
import { setPackageAssignedState } from 'src/redux/slice/packageAssigned';
import { OrderStatusEnum } from 'src/shared/enum/package_assigned';
import {
  ICheckInputSerialParamsRequest,
  ICreateOrder,
  IOrderStatus,
  IOverFlowOrderDetailResponse,
  OrderRequest,
  OrderUpdate,
} from 'src/shared/interface/package_assigned';
import { formatDateTime, formatMoney, parseQueryString } from 'src/utils';
import InputCorrect from '../components/InputCorrect';
import InputError from '../components/InputError';
import { SimType } from 'src/shared/enum/sim.enum';
import { simTypeToText } from 'src/utils/sim.enum';
import {debounce} from "lodash";
import ModalCheckEsimExpired from "../../changeSim/ModalCheckEsimExpired";
import {checkEsimExpired} from "../../../../api/changeSim";

function CreateOrderPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const watchCode = useWatch('mbfCode', form);
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  const location = useLocation();
  const { id } = parseQueryString(location.search);
  const { refresh, order, openTabs } = useAppSelector((state) => state.global);
  const { dataSuccess, dataFailed } = useAppSelector((state) => state.packageAssigned);

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalConfirmKitting, setOpenModalConfirmKitting] = useState(false);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);
  const [openModalConfirmChange, setOpenModalConfirmChange] = useState(false);
  const [openModalConfirmCancel, setOpenModalConfirmCancel] = useState(false);
  const [simType, setSimType] = useState<number>(SimType.PHYSICAL_SIM);
  const [radio, setRadio] = useState<number>(1);
  const [file, setFile] = useState<any>();
  const [isImportFile, setIsImportFile] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusSim, setStatusSim] = useState<string>(OrderStatusEnum.New);
  const [dataPackage, setDataPackage] = useState<IOrderStatus[]>([]);
  const [infoPackageInput, setInfoPackageInput] = useState<IOverFlowOrderDetailResponse>();
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isOpenModalCheckEsimExpired, setIsOpenModalCheckEsimExpired] = useState(false);
  const [listEsimExpired, setListEsimExpired] = useState([]);
  const [listEsimValid, setListEsimValid] = useState([]);

  const items = [
    {
      label: `${radio === 1 ? 'Nhập đúng' : 'Import đúng'}`,
      key: '1',
      children: (
        <InputCorrect
          dataSuccess={dataSuccess}
          statusSim={statusSim}
          infoPackageInput={infoPackageInput}
        />
      ),
    },
    {
      label: `${radio === 1 ? 'Nhập sai' : 'Import sai'}`,
      key: '2',
      children: <InputError dataFailed={dataFailed} />,
    },
  ];

  useEffect(() => {
    const fetchBalance = async () => {
      const balanceRes = await getBalance();
      if (balanceRes) {
        dispatch(setGlobalState({ balance: balanceRes.balance }));
      }
    };
    dispatch(setPackageAssignedState({ dataSuccess: id ? dataSuccess : [], dataFailed: id ? dataFailed : [] }));
    dispatch(setGlobalState({ order: undefined }));
    fetchBalance();
  }, []);

  const fetchGetPackageAssigned = async () => {
    await getPackageAssignedV2().then((res: any) => {
      if (res) {
        const dataPackage: IOrderStatus[] = [];
        res?.map((item: any) => {
          dataPackage?.push({
            label: item,
            value: item
          })
        });
        setDataPackage(dataPackage);
      }
    })
  };

  useEffect(() => {
    fetchGetPackageAssigned();
  }, []);

  useEffect(() => {
    const fetchDetailOrder = async () => {
      const res = await getDetailPackageAssigned(id);
      if (res) {
        if (res.fileUrl) {
          setRadio(2);
        }
        form.setFieldsValue(res);
        setSimType(res?.simType);
        const listCheckSuccess: IOverFlowOrderDetailResponse[] = [];
        const listCheckFail: IOverFlowOrderDetailResponse[] = [];
        const listEsimValidTmp: any = [];
        res.overFlowOrderDetailResponses?.map((item: any, index: number) => {
          if (res.statusString === OrderStatusEnum.New) {
            listCheckSuccess.push(item);
          } else if (item.statusString === OrderStatusEnum.Failed) {
            if (item.id) {
              const errorItem = {
                code: item.errorCode,
                message: item.errorMessage,
                serial: item.serial,
              };
              listCheckFail.push(errorItem);
            } else {
              listCheckFail.push(item);
            }
          } else {
            listCheckSuccess.push(item);
          }

          if (item?.statusString === 'New') {
            listEsimValidTmp.push(item);
          }
        });
        dispatch(setPackageAssignedState({ dataSuccess: id ? listCheckSuccess : [] }));
        if (res.statusString !== OrderStatusEnum.New) {
          dispatch(setPackageAssignedState({ dataFailed: id ? listCheckFail : [] }));
        }
        dispatch(setGlobalState({ order: res }));
        setStatusSim(res.statusString ? res.statusString.toString() : '');
        setInfoPackageInput(undefined);
        setListEsimValid(listEsimValidTmp);
      }
    };
    if (id) {
      fetchDetailOrder();
    } else {
      dispatch(setGlobalState({ order: undefined }));
      dispatch(setPackageAssignedState({ dataSuccess: [], dataFailed: [] }));
      form.resetFields();
    }

    return () => {
      setStatusSim(OrderStatusEnum.New);
    };
  }, [id, refresh]);

  const onChangeCountSerialNumber = (e: any) => {
    if (e?.target?.value > 1000) {
      message.error('Vượt quá số lượng cho phép!');
      form.setFieldValue('countSerialNumber', null);
    }
  }

  const handleAddInput = async (isChangeData = false) => {
    if (form.getFieldValue('mbfCode')) {
      setIsImportFile(false);
      const params: ICheckInputSerialParamsRequest = {
        distributorId: merchantInfo ? JSON.parse(merchantInfo).merchantId : 0,
        startSerialNumber: form.getFieldValue('serial'),
        countSerialNumber: Number(form.getFieldValue('countSerialNumber')),
        mbfCode: form.getFieldValue('mbfCode') ?? '',
        orderId: id ? id : undefined,
        simType
      };
      const res = await checkInputListSerial(params);
      if (res) {
        setInfoPackageInput({ mbfCode: res.mbfCode, price: res.price });
        if (isChangeData) {
          setRadio(1);
          dispatch(
            setPackageAssignedState({
              dataSuccess: res.simSuccess ?? [],
              dataFailed: res.simError ?? [],
            }),
          );
          setOpenModalConfirm(false);
        } else {
          if (isImportFile) {
            dispatch(
              setPackageAssignedState({
                dataSuccess: res.simSuccess ?? [],
                dataFailed: res.simError ?? [],
              }),
            );
          } else {
            //Xử lí duplicate
            const newDataSuccess: IOverFlowOrderDetailResponse[] = [...dataSuccess];
            const newDataFailed: IOverFlowOrderDetailResponse[] = [...dataFailed];
            [...res.simSuccess].forEach((item) => {
              //Trường hợp không trùng bất cứ thằng nào.
              if (!dataSuccess.some((obj) => obj.serial === item.serial)) {
                newDataSuccess.push(item);
              }
            });
            [...res.simError].forEach((item) => {
              //Trường hợp không trùng bất cứ thằng nào.
              if (!dataFailed.some((obj) => obj.serial === item.serial)) {
                newDataFailed.push(item);
              }
            });
            dispatch(
              setPackageAssignedState({
                dataSuccess: newDataSuccess,
                dataFailed: newDataFailed,
              }),
            );
          }
        }
      }
    } else {
      message.warning(`Vui lòng chọn ${!simType ? 'loại SIM' : 'mã gói'}!`);
    }
  };

  const handleImport = async () => {
    if (radio === 2) {
      if (form.getFieldValue('mbfCode') && file) {
        const res = await checkImportExcelSerial({
          distributorId: merchantInfo ? JSON.parse(merchantInfo).merchantId : '',
          excelFile: file.originFileObj ? file.originFileObj : file,
          mbfCode: form.getFieldValue('mbfCode') ?? '',
          orderId: id ? id : undefined,
          simType
        });
        if (res) {
          dispatch(
            setPackageAssignedState({
              dataSuccess: res.simSuccess ?? [],
              dataFailed: res.simError ?? [],
            }),
          );
        }
        setOpenModalConfirm(false);
      } else {
        message.warning(`Vui lòng chọn ${!simType ? 'loại SIM' : 'mã gói'}!`);
        // message.error('Import không thành công. Vui lòng thử lạ asd!');
      }
    } else {
      setOpenModalConfirm(true); // Hiển thị modal xác nhận
    }
    setIsImportFile(true);
  };

  const propsUpload: UploadProps = {
    beforeUpload: (file) => {
      const isExcel =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (form.getFieldValue('mbfCode')) {
        if (!isExcel) {
          message.error(`Định dạng file không đúng`);
        }
        return isExcel || Upload.LIST_IGNORE;
      } else {
        message.warning(`Vui lòng chọn ${!simType ? 'loại SIM' : 'mã gói'}!`);
        return false;
      }
    },
    onChange: ({ file }) => {
      if (file.status === 'removed') {
        setFile(null);
      } else {
        file.status = 'done';
        setFile(file);
      }
    },
    //eslint-disable-next-line
    customRequest: () => { },
  };

  const handleSubmit = async () => {
    if (statusSim === OrderStatusEnum.New) {
      if ([...dataSuccess].length === 0) {
        message.error('Vui lòng import số!');
      } else {
        try {
          let getFileUrl = '';
          if (radio === 2) {
            getFileUrl = await uploadFilePackageAssigned(
              file.originFileObj ? file.originFileObj : file,
            );
          }
          const listSerial = dataSuccess.map((item) => {
            return {
              serial: item.serial ?? '',
            };
          });
          const params: ICreateOrder = {
            merchantId: merchantInfo ? JSON.parse(merchantInfo).merchantId : '',
            mbfCode: form.getFieldValue('mbfCode') ?? '',
            overFlowOrderDetailRequest: listSerial,
            createdBy: merchantInfo ? JSON.parse(merchantInfo).merchantName : '',
            fileUrl: getFileUrl ? getFileUrl : undefined,
            fileName: file ? file.name : undefined,
            simType
          };

          const res = await createOrderAssigned(params);
          if (res) {
            message.success('Lưu đơn hàng thành công!');
          }
          const newPanes = openTabs.filter((pane) => pane.key !== 'order-assigned-package/create');

          dispatch(
            setGlobalState({
              activeKeyMenu: 'order-assigned-package/detail' + '?id=' + res.id,
              refresh: !refresh,
            }),
          );
          if (
            !newPanes.find((tab) => tab.key === 'order-assigned-package/detail' + '?id=' + res.id)
          ) {
            dispatch(
              setGlobalState({
                openTabs: [
                  ...newPanes,
                  {
                    label: 'Xem chi tiết gán gói',
                    children: '',
                    key: 'order-assigned-package/detail' + '?id=' + res.id,
                    closable: true,
                  },
                ],
              }),
            );
          }
          navigate(`/management/order-assigned-package/detail?id=${res.id}`);
        } catch (error) {
          navigate(`/management/order-assigned-package`);
        }
      }
    }
    if (statusSim === OrderStatusEnum.EDIT) {
      if (form.getFieldValue('mbfCode') && dataSuccess.length > 0) {
        const listSerial = dataSuccess.map((item) => {
          return { serial: item.serial ?? '' };
        });
        const data: OrderUpdate = {
          mbfCode: form.getFieldValue('mbfCode'),
          orderId: id,
          overFlowOrderDetailResponses: listSerial,
          updatedBy: merchantInfo ? JSON.parse(merchantInfo).merchantName : '',
        };
        await updatePackageAssigned(data);
        dispatch(setGlobalState({ refresh: !refresh }));
        setStatusSim(OrderStatusEnum.New);
      } else {
        message.error('Vui lòng nhập danh sách sim gán gói!');
      }
    }
  };

  const handleKittingSim = async () => {
    setIsLoadingModal(true);
    const data: OrderRequest = {
      orderId: id,
      distibutorId: merchantInfo ? JSON.parse(merchantInfo).merchantId : '',
      updatedBy: merchantInfo ? JSON.parse(merchantInfo).merchantName : '',
      simType: form.getFieldValue('simType')
    };
    try {
      const res = await kittingPackageAssigned(data);
      if (res) {
        message.success('Gán gói thành công!');
        dispatch(setGlobalState({ refresh: !refresh }));
        setOpenModalConfirmKitting(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoadingModal(false);
    }
  };

  const handleDeleteOrder = async () => {
    if (id) {
      try {
        const res = await deletePackageAssigned(id);
        if (res) {
          message.success('Xoá đơn hàng thành công!');
          const newPanes = openTabs.filter((pane) => pane.key !== 'order-assigned-package');
          dispatch(
            setGlobalState({
              activeKeyMenu: 'order-assigned-package',
            }),
          );
          if (!newPanes.find((tab) => tab.key === 'order-assigned-package')) {
            dispatch(
              setGlobalState({
                openTabs: [
                  ...newPanes,
                  {
                    label: 'Đơn hàng gán gói',
                    children: '',
                    key: 'order-assigned-package',
                    closable: true,
                  },
                ],
              }),
            );
          }
          navigate(`/management/order-assigned-package`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const renderTypePackage = () => {
    switch (form.getFieldValue('type')) {
      case 'RegisterPackage':
        return <div>Gán gói</div>;
      case 'ActiveSingle':
        return <div>Đấu nối đơn lẻ</div>;
      case 'ActiveLot':
        return <div>Đấu nối theo lô</div>;
      case 'ChangeSim':
        return <div>Đổi sim</div>;

      default:
        return <div>Gán gói</div>;
    }
  };

  const handleRetryKitting = async () => {
    if (id) {
      try {
        const res = await retryPackageAssigned({
          orderId: id,
          updatedBy: merchantInfo ? JSON.parse(merchantInfo).merchantName : '',
        });
        if (res) {
          let isStatusKitting = false;
          if (res.statusString === OrderStatusEnum.Completed) {
            isStatusKitting = true;
          }
          if (isStatusKitting) {
            message.success('Retry gán gói thành công');
            dispatch(setGlobalState({ refresh: !refresh }));
          } else {
            message.error('Retry gán gói thất bại');
            dispatch(setGlobalState({ refresh: !refresh }));
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChangeMbfCode = async () => {
    if (dataSuccess.length > 0 || dataFailed.length > 0) {
      try {
        const checkOldData: string[] = [];
        dataSuccess.forEach((item) => {
          if (item.serial) {
            checkOldData.push(item.serial);
          }
        });
        dataFailed.forEach((item) => {
          if (item.serial) {
            checkOldData.push(item.serial);
          }
        });
        const res = await checkMbfCodePackageAssigned({
          mbfCode: form.getFieldValue('mbfCode') ?? '',
          serials: checkOldData,
          distributorId: merchantInfo ? JSON.parse(merchantInfo).merchantId : '',
          orderId: id,
          simType: simType
        });
        if (res) {
          dispatch(
            setPackageAssignedState({
              dataSuccess: res.simSuccess ?? [],
              dataFailed: res.simError ?? [],
            }),
          );
          setInfoPackageInput({ mbfCode: res.mbfCode, price: res.price });
          if (res.simSuccess.length > 0) {
            message.success('Danh sách sim phù hợp với gói!');
          } else {
            message.error('Danh sách sim không phù hợp với gói!');
          }
        }
      } catch (error) {
        form.setFieldValue('mbfCode', order?.mbfCode);
      }
    }
    setRadio(1);
    setOpenModalConfirmChange(false);
  };

  const handleCancel = async () => {
    try {
      const res = await cancelPackageAssigned(id);
      if (res) {
        setOpenModalConfirmCancel(false);
        dispatch(setGlobalState({ refresh: !refresh }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrePayment = async () => {
    if (id) {
      if (order?.simType === SimType.ESIM) {
        try {
          await checkEsimExpired(id).then((res) => {
            if (res?.esimExpired?.length > 0) {
              setIsOpenModalCheckEsimExpired(true);
              setListEsimExpired(res?.esimExpired);
              setListEsimValid(res?.esimValid);
            } else if (res?.esimExpired?.length <= 0 && res?.esimValid?.length > 0) {
              onClickConfirmEsimExpired();
            }
          });
        } catch (error: any) {
          console.log(error);
        }
      } else {
        onClickConfirmEsimExpired();
      }
    } else {
      console.log('error');
    }
  }

  const onClickConfirmEsimExpired = () => {
    dispatch(
      setGlobalState({
        refresh: !refresh,
      }),
    );
    setIsOpenModalCheckEsimExpired(false);
    dispatch(setModalState({ open: true, content: 'billing-info', width: '50%' }));
  }

  const onCancelEsimExpired = () => {
    setIsOpenModalCheckEsimExpired(false);
    dispatch(
      setGlobalState({
        refresh: !refresh,
      }),
    );
  }

  return (
    <Fragment>
      <div className='flex justify-between'>
        <div className='row-button justify-end mb-[1.5rem]'>
          <Button
            type='dashed'
            disabled={order?.statusString === OrderStatusEnum.PAYMENT_FAIL ? false : true}
            onClick={() => setOpenModalConfirmCancel(true)}
          >
            Huỷ
          </Button>
          <Button
            disabled={order?.statusString === OrderStatusEnum.Uncompleted ? false : true}
            onClick={handleRetryKitting}
          >
            Retry
          </Button>
          <Button
            type='primary'
            disabled={order?.statusString === OrderStatusEnum.Paid ? false : true}
            onClick={() => setOpenModalConfirmKitting(true)}
          >
            Gán gói
          </Button>
          <Button
            type='primary'
            disabled={
              order?.statusString === OrderStatusEnum.New && listEsimValid?.length > 0 ||
              (statusSim === OrderStatusEnum.PAYMENT_FAIL && order?.statusString)
                ? false
                : true
            }
            onClick={() => {
              if (dataSuccess.length > 0) {
                handlePrePayment();
              } else {
                message.error('Danh sách SIM gán gói rỗng');
              }
            }}
          >
            Thanh toán
          </Button>
          <Button
            disabled={statusSim === OrderStatusEnum.New && id ? false : true}
            onClick={() => setStatusSim(OrderStatusEnum.EDIT)}
          >
            Sửa
          </Button>
          <Button
            type='primary'
            loading={isLoading}
            onClick={() => {
              if (dataSuccess.length > 0) {
                form.validateFields(['mbfCode']).then(() => {
                  handleSubmit();
                });
              } else {
                message.error('Danh sách SIM gán gói rỗng');
              }
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
              }, 3000);
            }}
            disabled={
              statusSim === OrderStatusEnum.EDIT || (statusSim === OrderStatusEnum.New && !id)
                ? false
                : true
            }
          >
            Lưu
          </Button>
          <Button
            disabled={statusSim === OrderStatusEnum.New && id ? false : true}
            onClick={() => setOpenModalConfirmDelete(true)}
            danger
            type='primary'
          >
            Xóa
          </Button>
        </div>
      </div>
      <BoxWithLabel title='Thông tin đơn hàng' classNames='mb-[1.5rem]'>
        <Form form={form}>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item labelCol={{ span: 12 }} label='Mã đơn' name={'id'}>
                {form.getFieldValue('id')}
              </Form.Item>
              <Form.Item labelCol={{ span: 12 }} label='Loại đơn hàng' name={'type'}>
                {renderTypePackage()}
              </Form.Item>
              <Form.Item
                label='Loại SIM'
                labelCol={{ span: 12 }}
                rules={[{ required: true, message: 'Vui lòng chọn loại SIM' }]}
                name='simType'
              >
                <Select
                  showSearch
                  placeholder='Vui lòng chọn'
                  defaultValue={SimType.PHYSICAL_SIM}
                  options={[
                    {
                      value: SimType.PHYSICAL_SIM,
                      label: simTypeToText(SimType.PHYSICAL_SIM)
                    },
                    {
                      value: SimType.ESIM,
                      label: simTypeToText(SimType.ESIM)
                    }
                  ]}
                  disabled={
                    (statusSim === OrderStatusEnum.New && !id)
                      ? false
                      : true
                  }
                  onChange={(value) => {
                    setSimType(value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Mã gói'
                labelCol={{ span: 12 }}
                rules={[{ required: true, message: 'Vui lòng chọn gói' }]}
                name='mbfCode'
              >
                <Select
                  showSearch
                  placeholder='Vui lòng chọn'
                  options={dataPackage}
                  disabled={
                    statusSim === OrderStatusEnum.EDIT || (statusSim === OrderStatusEnum.New && !id) || !simType
                      ? false
                      : true
                  }
                  onChange={() => {
                    if (dataSuccess.length > 0 || dataFailed.length > 0) {
                      setOpenModalConfirmChange(true);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Trạng thái'
                labelCol={{ span: 12 }}
                initialValue={''}
                name='statusString'
              >
                {form.getFieldValue('statusString')}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Tổng cộng' labelCol={{ span: 12 }} name='countSerial'>
                {form.getFieldValue('countSerial')}
              </Form.Item>
              <Form.Item label='Thành công' labelCol={{ span: 12 }} name='quantitySuccess'>
                {form.getFieldValue('quantitySuccess')}
              </Form.Item>
              <Form.Item label='Thất bại' labelCol={{ span: 12 }} name='quantityFailed'>
                {form.getFieldValue('quantityFailed')}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Thành tiền' labelCol={{ span: 12 }} name='toTalAmount'>
                {form.getFieldValue('toTalAmount')
                  ? formatMoney(form.getFieldValue('toTalAmount'), 'đ')
                  : ''}
              </Form.Item>
              <Form.Item
                label='Mã GD thanh toán'
                labelCol={{ span: 15 }}
                name='transactionId'
                className='truncate'
              >
                {form.getFieldValue('transactionId') && (
                  <div
                    className='text-blue-600 underline cursor-pointer'
                    onClick={() =>
                      dispatch(setModalState({ open: true, content: 'billing-info', width: '50%' }))
                    }
                  >
                    <span>{form.getFieldValue('transactionId')}</span>
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Người tạo' labelCol={{ span: 12 }} name='createdBy'>
                {form.getFieldValue('createdBy')}
              </Form.Item>
              <Form.Item
                label='Thời gian tạo'
                labelCol={{ span: 12 }}
                name='createdAt'
                valuePropName={
                  form.getFieldValue('createdAt')
                    ? formatDateTime(form.getFieldValue('createdAt'))
                    : ''
                }
              >
                {form.getFieldValue('createdAt')
                  ? formatDateTime(form.getFieldValue('createdAt'))
                  : ''}
              </Form.Item>
              <Form.Item label='Người cập nhật' labelCol={{ span: 12 }} name='updatedBy'>
                {form.getFieldValue('updatedBy')}
              </Form.Item>
              <Form.Item label='Ngày cập nhật' labelCol={{ span: 12 }} name='updatedAt'>
                {form.getFieldValue('updatedAt')
                  ? new Date(form.getFieldValue('updatedAt')).getTime() ===
                    new Date(form.getFieldValue('createdAt')).getTime()
                    ? ''
                    : formatDateTime(form.getFieldValue('updatedAt'))
                  : ''}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className='mb-[1rem]'>
            <Col span={6}>
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  setRadio(e.target.value);
                }}
                disabled={
                  statusSim === OrderStatusEnum.EDIT || (statusSim === OrderStatusEnum.New && !id)
                    ? false
                    : true
                }
                value={radio}
              >
                <Radio value={1}>Nhập serial</Radio>
                <Radio value={2}>Import file</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row gutter={16}>
            {radio === 1 ? (
              <Fragment>
                <Col span={8}>
                  <Form.Item
                    labelCol={{ span: 12 }}
                    name='serial'
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập số serial',
                      },
                      {
                        min: 16,
                        message: 'Vui lòng nhập đủ số serial',
                      },
                    ]}
                  >
                    <Input
                      type='number'
                      maxLength={20}
                      onChange={(e) => {
                        if (e.target.value.length <= 20) {
                          form.setFieldValue('serial', e.target.value);
                        } else {
                          form.setFieldValue('serial', e.target.value.substring(0, 20));
                        }
                        e.preventDefault();
                      }}
                      onKeyDown={(evt) => {
                        if (!evt.ctrlKey && !evt.metaKey) {
                          if (
                            evt.key === 'e' ||
                            (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                            evt.key === '+' ||
                            evt.key === '-' ||
                            evt.key === '.' ||
                            Number(evt.key) > 9 ||
                            Number(evt.key) < 0
                          ) {
                            evt.preventDefault();
                          }
                        }
                      }}
                      disabled={
                        statusSim === OrderStatusEnum.EDIT ||
                        (statusSim === OrderStatusEnum.New && !id)
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={'Số lượng'}
                    labelCol={{ span: 5 }}
                    name='countSerialNumber'
                    rules={[
                      { required: true, message: 'Vui lòng nhập số lượng' },
                      { max: 1000, message: 'Vượt quá số lượng cho phép.' }
                    ]}
                  >
                    <Input
                      type={'number'}
                      onKeyDown={(evt) => {
                        if (!evt.ctrlKey && !evt.metaKey) {
                          if (
                            evt.key === 'e' ||
                            (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                            evt.key === '+' ||
                            evt.key === '.' ||
                            evt.key === '-' ||
                            Number(evt.key) > 9 ||
                            Number(evt.key) < 0
                          ) {
                            evt.preventDefault();
                          }
                        }
                      }}
                      maxLength={5}
                      disabled={
                        statusSim === OrderStatusEnum.EDIT ||
                        (statusSim === OrderStatusEnum.New && !id)
                          ? false
                          : true
                      }
                      onChange={(value) => onChangeCountSerialNumber(value)}
                    />
                  </Form.Item>
                </Col>
                <Button
                  onClick={() => {
                    form
                      .validateFields(['serial', 'countSerialNumber'])
                      .then(() => {
                        if (radio === 1) {
                          handleAddInput();
                        } else {
                          setOpenModalConfirm(true);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }}
                  disabled={
                    statusSim === OrderStatusEnum.EDIT || (statusSim === OrderStatusEnum.New && !id)
                      ? false
                      : true
                  }
                >
                  Thêm
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <div className='flex gap-4'>
                  <Upload
                    {...propsUpload}
                    maxCount={1}
                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    showUploadList
                    fileList={file ? [file] : []}
                  >
                    <Button
                      className='ml-2'
                      type='dashed'
                      disabled={
                        statusSim === OrderStatusEnum.EDIT ||
                        (statusSim === OrderStatusEnum.New && !id)
                          ? false
                          : true
                      }
                    >
                      Chọn File
                    </Button>
                  </Upload>
                </div>
                <Button
                  className='ml-2'
                  type='primary'
                  onClick={handleImport}
                  disabled={
                    (file && watchCode && statusSim === OrderStatusEnum.EDIT) ||
                    (statusSim === OrderStatusEnum.New && !id && file)
                      ? false
                      : true
                  }
                >
                  Import
                </Button>
                <Button className='ml-2'>
                  <a
                    href={`${process.env.REACT_APP_API_URL}/api/Provider/export-excel-template?type=2`}
                  >
                    Export template
                  </a>
                </Button>
                {order && order.fileUrl && (
                  <Button className='ml-2'>
                    <a href={`${order.fileUrl}`} download>
                      Download file import
                    </a>
                  </Button>
                )}
              </Fragment>
            )}
          </Row>
        </Form>
      </BoxWithLabel>
      <BoxWithLabel title='Danh sách SIM gán gói'>
        <Tabs type='card' items={items} />
      </BoxWithLabel>
      <ModalConfirm
        open={openModalConfirm}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Bạn chắc chưa?</div>
            <p>Sau khi thêm sẽ xóa dữ liệu trước đó</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button
                onClick={() => {
                  // Import serial
                  if (radio === 1) {
                    handleAddInput(true);
                  }
                  //import excel
                  else {
                    handleImport();
                  }
                }}
                className='h-full w-full'
              >
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirm(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmKitting}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Gán gói?</div>
            <p>Bạn có chắc chắn muốn gán gói đơn hàng này?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button
                onClick={handleKittingSim}
                className='h-full w-full'
                disabled={isLoadingModal}
              >
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmKitting(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmDelete}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Xoá đơn hàng?</div>
            <p>Bạn có chắc chắn muốn xoá đơn hàng gán gói này?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleDeleteOrder} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmDelete(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmChange}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Đổi mã gói?</div>
            <p>
              Danh sách SIM có thể không còn phù hợp với mã gói mà bạn muốn thay đổi. Bạn có muốn
              thực hiện?
            </p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleChangeMbfCode} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  form.setFieldValue('mbfCode', order?.mbfCode);
                  setOpenModalConfirmChange(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmCancel}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Huỷ đơn hàng?</div>
            <p>Bạn có muốn thực hiện thay đổi?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleCancel} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  form.setFieldValue('mbfCode', order?.mbfCode);
                  setOpenModalConfirmCancel(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      {isOpenModalCheckEsimExpired && <ModalCheckEsimExpired
        isOpen={isOpenModalCheckEsimExpired}
        setIsOpenModal={setIsOpenModalCheckEsimExpired}
        listEsimExpired={listEsimExpired}
        listEsimValid={listEsimValid}
        onClickConfirm={onClickConfirmEsimExpired}
        onClickCancel={onCancelEsimExpired}
      />}
    </Fragment>
  );
}

export default CreateOrderPage;
