import { PaginationResponse } from 'src/@types/search-params';
import {
  CheckMbfCodePackageAssigned,
  ICheckImportExcelParamsRequest,
  ICheckInputListSerial,
  ICheckInputSerialParamsRequest,
  IPackageAssigned,
  OrderRequest,
  OrderUpdate,
} from 'src/shared/interface/package_assigned';
import request from '../request';

export const getAllPackageAssigned = async (params: any) => {
  const url = '/api/Provider/register-package/get-all';
  const res = await request<PaginationResponse<IPackageAssigned[]>>('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const checkInputListSerial = async (params: ICheckInputSerialParamsRequest) => {
  const url = '/api/Provider/register-package/check-input-list-serial-sim';
  const res = await request<ICheckInputListSerial>('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const checkImportExcelSerial = async (params: ICheckImportExcelParamsRequest) => {
  const formData = new FormData();
  Object.keys(params).map((item) => {
    formData.append(`${item}`, params[`${item as keyof typeof params}`]);
  });
  const url = '/api/Provider/register-package/check-import-list-serial-sim';
  const res = await request<ICheckInputListSerial>('post', url, formData);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const createOrderAssigned = async (params: any) => {
  const url = '/api/Provider/register-package/create-order';
  const res = await request('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getDetailPackageAssigned = async (id: string) => {
  const url = `/api/Provider/register-package/get-detail/${id}`;
  const res = await request<IPackageAssigned>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const paymentPackageAssigned = async (data: OrderRequest) => {
  const url = `/api/Provider/payment-localpay`;
  const res = await request<IPackageAssigned>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const kittingPackageAssigned = async (data: OrderRequest) => {
  const url = `/api/Provider/register-package/confirm`;
  const res = await request<IPackageAssigned>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const deletePackageAssigned = async (orderId: string) => {
  const url = `/api/Provider/delete-order?OrderId=${orderId}`;
  const res = await request<IPackageAssigned>('post', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getPackageAssigned = async (simType?: number) => {
  const url = `api/Provider/register-package/data-package/${simType}`;
  const res = await request<string[]>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getPackageAssignedV2 = async () => {
  const url = `api/Provider/register-package/data-package/0`;
  const res = await request<string[]>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const updatePackageAssigned = async (data: OrderUpdate) => {
  const url = `/api/Provider/register-package/update-order`;
  const res = await request<OrderUpdate>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const retryPackageAssigned = async (data: OrderUpdate) => {
  const url = `/api/Provider/retry-order`;
  const res = await request<IPackageAssigned>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const checkMbfCodePackageAssigned = async (data: CheckMbfCodePackageAssigned) => {
  const url = `/api/Provider/register-package/check-list-serial-sim`;
  const res = await request<ICheckInputListSerial>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const exportTemplatePackageAssigned = async (type: number) => {
  const url = `/api/Provider/export-excel-template?type=${type}`;
  const res = await request<ICheckInputListSerial>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const cancelPackageAssigned = async (orderId: string) => {
  const url = `/api/Provider/cancel-order/` + orderId;
  const res = await request<ICheckInputListSerial>('post', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const uploadFilePackageAssigned = async (data: any) => {
  const formData = new FormData();
  formData.append('file', data);
  const url = `/api/filestore/uploadFileExcel`;
  const res = await request<any>('post', url, formData);
  if (res.isSucceeded) {
    return res.data;
  }
};
