import { OrderStatusEnum } from '../enum/package_assigned';
import { IOrderStatus } from '../interface/package_assigned';

export const listPackageCode = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: 'A30',
    label: 'A30',
  },
  {
    value: 'A45',
    label: 'A45',
  },
];

export const orderStatus: IOrderStatus[] = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: OrderStatusEnum.New,
    label: 'New',
  },
  {
    value: OrderStatusEnum.Processing,
    label: 'Processing',
  },
  {
    value: OrderStatusEnum.Completed,
    label: 'Completed',
  },
  {
    value: OrderStatusEnum.Uncompleted,
    label: 'Uncompleted',
  },
  {
    value: OrderStatusEnum.Cancel,
    label: 'Canceled',
  },
  {
    value: OrderStatusEnum.Paid,
    label: 'Paid',
  },
  {
    value: OrderStatusEnum.PAYMENT_FAIL,
    label: 'Payment Fail',
  },
];
