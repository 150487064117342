import { Form, Radio, RadioChangeEvent, Row, Spin } from 'antd';
import { useState } from 'react';
import ConnectionEnterprise from 'src/components/connection/single/enterprise/ConnectionEnterprise';
import ConnectionIndividual from 'src/components/connection/single/individual/ConnectionIndividual';
import { ConnectionType } from 'src/shared/enum/connection.enum';
import { connectionTypeToString } from 'src/utils/connection';

const SingleConnection = () => {
  const [currType, setCurrType] = useState<ConnectionType>(ConnectionType.INDIVIDUAL);

  const onRadioConnectionChange = ({ target: { value } }: RadioChangeEvent) => {
    setCurrType(value);
  };

  return (
    <>
      <div className='text-left'>
        <Row>
          <Form.Item label={<span className='font-semibold'>Loại đấu nối</span>}>
            <Radio.Group
              onChange={onRadioConnectionChange}
              name='connectionType'
              defaultValue={ConnectionType.INDIVIDUAL}
            >
              <Radio
                value={ConnectionType.INDIVIDUAL}
                checked={currType === ConnectionType.INDIVIDUAL}
              >
                {connectionTypeToString(ConnectionType.INDIVIDUAL)}
              </Radio>
              <Radio
                value={ConnectionType.ENTERPRISE}
                checked={currType === ConnectionType.ENTERPRISE}
              >
                {connectionTypeToString(ConnectionType.ENTERPRISE)}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Row>
        {currType === ConnectionType.INDIVIDUAL && <ConnectionIndividual />}
        {currType === ConnectionType.ENTERPRISE && <ConnectionEnterprise />}
      </div>
    </>
  );
};

export default SingleConnection;
