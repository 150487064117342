import { BrowserView, MobileView, TabletView } from 'react-device-detect';
import NotAllow from '../../components/NotAllow';
import ActiveSim from './ActiveSim';

const HomePage = () => {
  return (
    <div className='home'>
      <MobileView>
        <ActiveSim />
      </MobileView>
      <TabletView>
        <ActiveSim />
      </TabletView>
      <BrowserView>
        <NotAllow />
      </BrowserView>
    </div>
  );
};

export default HomePage;
