export enum OverFlowOrderEditStatus {
  Create = 0,
  Edit = 1,
}

export enum OverFlowOrderDetaiEditStatus {
  Remove = 0,
  Add = 1,
}

export enum OverFlowOrderType {
  ActiveSingle = 0,
  ActiveLot = 1,
  RegisterPackage = 2,
  ChangeSim = 3,
}

export enum ConnectionType {
  ENTERPRISE = 'Enterprise',
  INDIVIDUAL = 'Individual',
}

export const connectionStatus = [
  {
    value: null,
    label: 'All',
  },
  {
    value: 'New',
    label: 'New',
  },
  {
    value: 'Processing',
    label: 'Processing',
  },
  {
    value: 'Completed',
    label: 'Completed',
  },
  {
    value: 'Uncompleted',
    label: 'Uncompleted',
  },
];

export const historyStatus = [
  {
    value: null,
    label: 'All',
  },
  {
    value: 'Success',
    label: 'Success',
  },
  {
    value: 'Failed',
    label: 'Fail',
  },
];

export function documentTypeToText(type: number) {
  switch (type) {
    case 1:
      return 'CMND';
    case 2:
      return 'CCCD';
    case 3:
      return 'Passport';
  }
  return '';
}
