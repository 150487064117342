import { includes } from 'lodash';
import { IListColumn } from 'src/@types/search-params';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { IDTypeEnum } from 'src/shared/enum/user_profile';
import { ILocation } from 'src/shared/interface/location.interface';
import { UserProfileBodyData } from 'src/shared/interface/user_profile';
import { formatDate } from 'src/utils';

interface IPropsTabFail {
  listNationality: ILocation[];
  listIssuePlace: ILocation[];
}
function TabFail({ listNationality, listIssuePlace }: IPropsTabFail) {
  const { listFailBatch } = useAppSelector((state) => state.userProfile);

  const renderIdNumber = (itemData: UserProfileBodyData) => {
    switch (itemData.typeIdNumber + '') {
      case '1':
        return <div>{IDTypeEnum.CMND}</div>;
      case '2':
        return <div>{IDTypeEnum.CCCD}</div>;
      case '3':
        return <div>{IDTypeEnum.PASSPORT}</div>;

      default:
        return <div></div>;
    }
  };

  const renderIssuePlace = (record: UserProfileBodyData) => {
    const issuePlace = listIssuePlace?.find(
      (item) => item.code.toString() === record.issuePlace?.toString(),
    );

    const getName = listNationality.find((item) => item.code === record.nationality);
    if (!record.issuePlace) {
      return '';
    } else if (issuePlace) {
      return <div>{issuePlace?.name}</div>;
    } else {
      return getName ? getName.name : '';
    }
  };

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    {
      name: 'ID hồ sơ DN',
      fieldName: 'id',
      isHideSort: true,
      renderSpecialCard: (order: UserProfileBodyData) => (
        <button
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
          // onClick={() => openDetailUserProfileBatchPage()}
        >
          {order.id ?? ''}
        </button>
      ),
    },
    {
      name: 'Họ và tên',
      fieldName: 'userName',
      isHideSort: true,
    },
    {
      name: 'Ngày sinh',
      fieldName: 'birthDay',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileBodyData) => {
        return (
          <span>
            {record.birthDay && !includes(record.birthDay + '', '0001-01-01T00:00:00Z')
              ? formatDate(record.birthDay)
              : ''}
          </span>
        );
      },
    },
    {
      name: 'Giới tính',
      fieldName: 'gender',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileBodyData) => {
        return <span>{record.gender === 1 ? 'Nữ' : record.gender === 0 ? 'Nam' : ''}</span>;
      },
    },
    {
      name: 'Loại giấy tờ',
      fieldName: 'typeIdNumber',
      isHideSort: true,
      renderSpecialCard: renderIdNumber,
    },
    {
      name: 'Số giấy tờ',
      fieldName: 'idNumber',
      isHideSort: true,
    },
    {
      name: 'Ngày cấp',
      fieldName: 'issueDate',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileBodyData) => {
        return (
          <span>
            {record.issueDate
              ? record.issueDate === '0001-01-01T00:00:00Z'
                ? ''
                : formatDate(record.issueDate)
              : ''}
          </span>
        );
      },
    },
    {
      name: 'Nơi cấp',
      fieldName: 'issuePlace',
      isHideSort: true,
      renderSpecialCard: renderIssuePlace,
    },
    {
      name: 'Địa chỉ',
      fieldName: 'address',
      isHideSort: true,
    },
    {
      name: 'Lý do thất bại',
      fieldName: 'note',
      isHideSort: true,
    },
  ];

  return (
    <TableContent
      bordered
      scroll={{ x: 'max-content' }}
      locale={{ emptyText: 'Không tìm thấy kết quả' }}
      dataSource={listFailBatch ?? []}
      columns={columns}
      isHidePaging
      totalRecord={listFailBatch?.length ?? 0}
    />
  );
}

export default TabFail;
