import { Button, Modal, Spin } from 'antd';

type Props = {
  isOpen: boolean;
  handleCancel: () => void;
  handleDelete: () => void;
  isLoading: boolean;
};

const ModalConfirmBatchDelete = (props: Props) => {
  const { isOpen, handleCancel, handleDelete, isLoading } = props;

  return (
    <div>
      <Modal open={isOpen} footer={null} onCancel={handleCancel}>
        <Spin spinning={isLoading}>
          <div className='pt-10 pb-5'>
            <p className='text-xl text-center'>Bạn có muốn xoá lô ?</p>
          </div>
          <div className='flex justify-end gap-2'>
            <Button disabled={isLoading} onClick={handleCancel}>
              Thoát
            </Button>
            <Button disabled={isLoading} onClick={handleDelete} type='primary'>
              Tiếp tục
            </Button>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
export default ModalConfirmBatchDelete;
