import { Col, Form, Input, message, ModalProps, Radio, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import { getDetailPackageAssigned } from 'src/api/package_assigned';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { useAppDispatch } from 'src/redux/hook';
import { resetModalState } from 'src/redux/slice/modal.store';
import { TransactionHistoryType } from 'src/shared/enum/localpay';
import { TransactionHistory } from 'src/shared/interface/localpay.interface';
import { IPackageAssigned } from 'src/shared/interface/package_assigned';
import { formatDateTime, formatMoney, formatMoneyNoCurrency, removeMoneyFormat } from 'src/utils';
import { simTypeToText } from "src/utils/sim.enum";

interface ModalTopUpProps extends ModalProps {
  selectedHistory: TransactionHistory;
}

interface FormData {
  transactionAmount: string;
  description?: string;
}

const ModalTopUp = ({ selectedHistory }: ModalTopUpProps) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [showMessage, setShowMessage] = useState('');
  const [inputStatus, setInputStatus] = useState<'' | 'error'>('');
  const [loading, setLoading] = useState(false);
  const [detailOrder, setDetailOrder] = useState<IPackageAssigned>();
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');

  const renderType = () => {
    switch (selectedHistory.type) {
      case TransactionHistoryType.Topup:
        return <span>Nạp tiền vào ví LCS App</span>;
      case TransactionHistoryType.Recharge:
        return <span>Nạp tiền vào ví LCS App</span>;
      case TransactionHistoryType.RechargeAsimPortal:
        return <span>Nạp tiền vào ví Asim Portal</span>;
      case TransactionHistoryType.ChangeSim:
        return <span>Thanh toán đơn hàng đổi SIM</span>;
      case TransactionHistoryType.RegisterPackage:
        return <span>Thanh toán đơn hàng gán gói</span>;
      default:
        return <span>Nạp tiền ví</span>;
    }
  };

  const handleTopUp = async ({ transactionAmount }: FormData) => {
    try {
      setLoading(true);
      const validated = await form.validateFields();
      if (!validated.transactionAmount) {
        setShowMessage('Số tiền nạp tối thiểu là 1 VND');
        setInputStatus('error');
      }
      if (validated.transactionAmount) {
        if (Number(removeMoneyFormat(transactionAmount.toString())) <= 0) {
          message.error('Số tiền nạp không hợp lệ');
          return;
        }
        if (Number(removeMoneyFormat(transactionAmount.toString())) > 999999999) {
          message.error('Số tiền nạp tối đa là 999,999,999 VND');
          return;
        }
        // const topUpRes = await topUp({
        //   distibutorId: selectedId,
        //   transactionAmount: Number(removeMoneyFormat(transactionAmount)),
        //   description,
        // });
        // if (topUpRes.isSucceeded) {
        //   message.success('Nạp tiền thành công');
        //   form.resetFields();
        //   onCancel();
        // }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataDetail = async () => {
    const detailRes = await getDetailPackageAssigned(selectedHistory.paymentId);
    if (detailRes) {
      setDetailOrder(detailRes);
    }
  };

  useEffect(() => {
    if (
      ![TransactionHistoryType.Topup, TransactionHistoryType.Recharge, TransactionHistoryType.RechargeAsimPortal].includes(
        selectedHistory.type as TransactionHistoryType,
      )
    ) {
      fetchDataDetail();
    }
  }, [selectedHistory]);

  return (
    <>
      <div className='flex justify-end'>
        <button
          onClick={() => dispatch(resetModalState())}
          className=' text-3xl cursor-pointer border-transparent bg-transparent'
        >
          <RxCross2 />
        </button>
      </div>
      <Form
        initialValues={{
          amount: formatMoney(selectedHistory.amount, 'đ'),
          paymentMethod: selectedHistory.paymentMethod,
        }}
        requiredMark={false}
        form={form}
        onFinish={handleTopUp}
        colon={false}
      >
        <BoxWithLabel title='Thông tin giao dịch'>
          <div className='flex justify-start'>
            <Row gutter={[0, 24]} className='w-full text-left'>
              <Col span={14}>
                <Row gutter={[0, 12]} className='w-full'>
                  <Col span={6}>Mã giao dịch</Col>
                  <Col span={16}>{selectedHistory.transactionId}</Col>
                  <Col span={6}>Ngày</Col>
                  <Col span={16}>{formatDateTime(selectedHistory.transactionDate)}</Col>
                  {[TransactionHistoryType.Topup, TransactionHistoryType.Recharge, TransactionHistoryType.RechargeAsimPortal].includes(
                    selectedHistory.type as TransactionHistoryType,
                  ) ? (
                    <>
                      <Col span={6}>Mã ví</Col>
                      <Col span={16}>{selectedHistory.accountNumber}</Col>
                    </>
                  ) : (
                    <>
                      <Col span={6}>Diễn giải</Col>
                      <Col span={16}>{renderType()}</Col>
                    </>
                  )}
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={[0, 12]} className='w-full'>
                  {[TransactionHistoryType.Topup, TransactionHistoryType.Recharge, TransactionHistoryType.RechargeAsimPortal].includes(
                    selectedHistory.type as TransactionHistoryType,
                  ) ? (
                    <>
                      <Col span={8}>Tình trạng</Col>
                      <Col span={14}>{selectedHistory.status}</Col>
                    </>
                  ) : (
                    <>
                      <Col span={8}>Người thanh toán</Col>
                      <Col span={14}>{selectedHistory.createdBy}</Col>
                    </>
                  )}
                  {[TransactionHistoryType.Topup, TransactionHistoryType.Recharge, TransactionHistoryType.RechargeAsimPortal].includes(
                    selectedHistory.type as TransactionHistoryType,
                  ) ? (
                    <>
                      {' '}
                      <Col span={8}>Người thanh toán</Col>
                      <Col span={14}>{selectedHistory.createdBy}</Col>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Col span={8}>Đơn hàng tham chiếu</Col>
                      <Col span={14}>
                        {' '}
                        <Link
                          onClick={() => dispatch(resetModalState())}
                          className='hover:underline underline'
                          to={
                            selectedHistory.type === TransactionHistoryType.RegisterPackage
                              ? `/management/order-assigned-package/detail?id=${selectedHistory.paymentId}`
                              : `changeSim/createChangeSim?id=${selectedHistory.paymentId}`
                          }
                        >
                          {selectedHistory.paymentId}
                        </Link>
                      </Col>
                    </>
                  )}
                  {[TransactionHistoryType.Topup, TransactionHistoryType.Recharge, TransactionHistoryType.RechargeAsimPortal].includes(
                    selectedHistory.type as TransactionHistoryType,
                  ) ? (
                    <>
                      <Col span={8}>Diễn giải</Col>
                      <Col span={14}>{renderType()}</Col>
                    </>
                  ) : (
                    <>
                      <Col span={8}>Tình trạng</Col>
                      <Col span={14}>{selectedHistory.status}</Col>
                    </>
                  )}
                </Row>
              </Col>{' '}
              {[TransactionHistoryType.Topup, TransactionHistoryType.Recharge, TransactionHistoryType.RechargeAsimPortal].includes(
                selectedHistory.type as TransactionHistoryType,
              ) ? (
                <>
                  {' '}
                  <Col span={12}>
                    <Form.Item
                      validateStatus={inputStatus}
                      labelCol={{ span: 24 }}
                      help={showMessage ? showMessage : ''}
                      name='amount'
                      label='Số tiền cần nạp'
                      className='mb-0'
                    >
                      <Input
                        disabled
                        onBlur={(e) => {
                          let { value } = e.target;
                          if (value.includes(',')) {
                            value = removeMoneyFormat(e.target.value);
                          }
                          if (value !== '') {
                            if (Number(value) > 999999999) {
                              form.setFieldsValue({
                                amount: formatMoneyNoCurrency(999999999),
                              });
                              setShowMessage('Số tiền nạp tối đa là 999,999,999 VND');
                              setInputStatus('error');
                            } else {
                              form.setFieldsValue({
                                amount: formatMoneyNoCurrency(Number(value)),
                              });
                              setShowMessage('');
                              setInputStatus('');
                            }
                          } else {
                            form.setFieldsValue({ amount: '' });
                            setShowMessage('Số tiền nạp tối thiểu là 1 VND');
                            setInputStatus('error');
                          }
                        }}
                        onChange={async (e) => {
                          const { value: inputValue } = e.target;
                          const reg = /^(\d+)$/;

                          if (reg.test(inputValue) || inputValue === '') {
                            form.setFieldsValue({ amount: inputValue });
                          } else {
                            form.setFieldsValue({
                              amount: inputValue.replace(
                                /[a-zA-zÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\- ]/,
                                '',
                              ),
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}></Col>
                </>
              ) : (
                <Col span={24}>
                  <p className='font-semibold mb-[0.5rem]'>Chi tiết giao dịch:</p>
                  <div className='normal-table'>
                    <table width='100%' className='normal-table'>
                      <thead>
                        <tr>
                          <th>STT</th>
                          <th>Số lượng</th>
                          <th>Mã hàng</th>
                          <th>Thành tiền</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>{detailOrder?.quantitySuccess ?? 0}</td>
                          <td>
                            {detailOrder?.type === 'ChangeSim'
                              ? simTypeToText(detailOrder?.simType)
                              : detailOrder?.overFlowOrderDetailResponses
                              ? detailOrder?.overFlowOrderDetailResponses[0].mbfCode
                              : ''}
                          </td>
                          <td>
                            {detailOrder?.toTalAmount
                              ? formatMoney(detailOrder?.toTalAmount, 'đ')
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>Tổng cộng</td>
                          <td>
                            {detailOrder?.toTalAmount
                              ? formatMoney(detailOrder?.toTalAmount, 'đ')
                              : '-'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </BoxWithLabel>
        <BoxWithLabel classNames='mt-4' title='Hình thức thanh toán'>
          <Form.Item name='paymentMethod' className='mb-0'>
            <Radio.Group disabled value={selectedHistory.paymentId}>
              {[TransactionHistoryType.Topup, TransactionHistoryType.Recharge, TransactionHistoryType.RechargeAsimPortal].includes(
                selectedHistory.type as TransactionHistoryType,
              ) ? (
                <Space direction='vertical'>
                  <Radio value={1}>Thẻ nội địa</Radio>
                  <Radio value={2}>Thẻ quốc tế</Radio>
                  <Radio value={'VNPay'}>VNPay</Radio>
                </Space>
              ) : (
                <Radio value={'LocalPay'}>Localpay</Radio>
              )}
            </Radio.Group>
          </Form.Item>
          {![TransactionHistoryType.Topup, TransactionHistoryType.Recharge, TransactionHistoryType.RechargeAsimPortal].includes(
            selectedHistory.type as TransactionHistoryType,
          ) && (
            <div className='flex gap-6'>
              <Form.Item label='Localpay ID'>
                <Input
                  value={merchantInfo ? JSON.parse(merchantInfo).payAccountId : '-'}
                  disabled
                />
              </Form.Item>
              <Form.Item label='Tên chủ tài khoản'>
                <Input
                  value={merchantInfo ? JSON.parse(merchantInfo).merchantName : '-'}
                  disabled
                />{' '}
              </Form.Item>
            </div>
          )}
        </BoxWithLabel>
        {[TransactionHistoryType.Topup, TransactionHistoryType.Recharge, TransactionHistoryType.RechargeAsimPortal].includes(
          selectedHistory.type as TransactionHistoryType,
        ) ? (
          <Row className='my-4'>
            <Col span={12}>
              <Row gutter={[0, 12]} className='w-full'>
                <Col span={8}>Người tạo</Col>
                <Col span={16}>{selectedHistory.createdBy}</Col>
              </Row>
            </Col>
            <Col span={12}>
              {' '}
              <Row gutter={[0, 12]} className='w-full'>
                <Col span={8}>Ngày tạo</Col>
                <Col span={16}>{formatDateTime(selectedHistory.createdAt)}</Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row className='my-4 border-solid border px-4'>
            <Col span={24}>
              <Row gutter={[0, 12]} className='w-full'>
                <Col span={8}>Người tạo</Col>
                <Col span={16}>{selectedHistory.createdBy}</Col>
              </Row>
            </Col>
            <Col span={24}>
              {' '}
              <Row gutter={[0, 12]} className='w-full'>
                <Col span={8}>Ngày tạo</Col>
                <Col span={16}>{formatDateTime(selectedHistory.createdAt)}</Col>
              </Row>
            </Col>
          </Row>
        )}
        {/* <Form.Item>
          <Row>
            <Col span={24} className='flex justify-end'>
              <Button loading={loading} htmlType='submit'>
                Xác nhận
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  dispatch(resetModalState());
                }}
              >
                Hủy
              </Button>
            </Col>
          </Row>
        </Form.Item> */}
      </Form>
    </>
  );
};

export default ModalTopUp;
