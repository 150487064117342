import { Modal } from 'antd';
export interface ConfirmProps {
  title: string;
  onOK: () => void;
  onCancel: () => void;
  isOpen: boolean;
}
const ConfirmModal = (props: ConfirmProps) => {
  return (
    <Modal
      open={props.isOpen}
      title={props.title}
      onOk={props.onOK}
      onCancel={props.onCancel}
      closable={false}
      centered
      maskClosable={false}
      okText='Đồng ý'
      cancelText='Không'
      // confirmLoading={loading}
    />
  );
};

export default ConfirmModal;
