import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal } from 'antd';
import { exportInfoActiveSim } from 'src/api/activeSim';
import { useForm } from 'antd/lib/form/Form';

type PropsEmailReceiveExportFile = {
  open: boolean;
  onCancel: (handleCancelModalEmails: boolean) => void;
  filterParams?: any;
};
export const ModalEmailReceiveExportFile = (props: PropsEmailReceiveExportFile) => {
  const [form] = useForm();

  const onFinish = (values: any) => {
    const searchParams = props?.filterParams;
    if (values) {
      searchParams.Emails = values?.users?.map((user: any) => user?.email);
      exportInfoActiveSim(searchParams).then((res) => {
        if (res?.isSucceeded) {
          onCancel();
          message.success('Gửi file export thành công. Vui lòng kiểm tra email của bạn');
          form.resetFields();
        }
      });
    }
  };

  const onCancel = () => {
    props?.onCancel(false);
  };

  const handleSendExport = () => {
    if (form.getFieldValue('users') === undefined) {
      message.error('Vui lòng thêm email');
    } else {
      form.submit();
    }
  };

  return (
    <>
      <Modal
        title={'Danh sách Email nhận file export'}
        open={props?.open}
        okText={'Gửi'}
        cancelText={'Đóng'}
        onCancel={() => props?.onCancel(false)}
        onOk={handleSendExport}
        width={'30vw'}
      >
        <Form onFinish={onFinish} autoComplete='off' className={'mt-5'} form={form}>
          <Form.List name='users'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'space-between',
                      marginBottom: 8,
                    }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'email']}
                      rules={[
                        { required: true, message: 'Vui lòng nhập email' },
                        { type: 'email', message: 'Email không đúng đinh dạng' },
                      ]}
                      className={'w-full mr-2'}
                    >
                      <Input placeholder='Email' />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                    Thêm email
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};
