import React from 'react';

const RetryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='24' height='24' rx='12' fill='white' />
      <path
        d='M4.71326 8.79245L6 11.8358M6 11.8358L9.02654 10.5562M6 11.8358C6.0821 8.59977 8.7366 6 12 6C15.3113 6 18 8.68871 18 12C18 15.3181 15.3113 18 12 18C9.94757 18 8.14139 16.9738 7.05359 15.407'
        stroke='#EE284A'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default RetryIcon;
