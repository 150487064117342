import { LoadingOutlined, PictureFilled } from '@ant-design/icons';
import { message, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useEffect, useState } from 'react';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Chỉ có thể chọn ảnh định dạng JPG/PNG!');
  }
  const isLt25M = file.size / 1024 / 1024 < 2;
  if (!isLt25M) {
    message.error('File ảnh phải có dung lượng bé hơn hoặc bằng 2Mb, vui lòng chọn hình ảnh khác!');
  }
  return isJpgOrPng && isLt25M;
};

interface CustomUploadProps extends UploadProps {
  value?: string;
  onChange?: any;
}

const CUpload = ({ value, onChange, ...props }: CustomUploadProps) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>(value);

  useEffect(() => {
    setImageUrl(value);
  }, [value]);

  const handleChange: UploadProps['onChange'] = async (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
        onChange(info.file.response?.data);
      });
    }
  };

  const uploadButton = (
    <div>{loading ? <LoadingOutlined /> : <PictureFilled className='text-3xl' />}</div>
  );

  return (
    <Upload
      {...props}
      accept='image/*'
      name='file'
      listType={imageUrl ? 'picture' : 'picture-card'}
      className='avatar-uploader'
      showUploadList={false}
      action={process.env.REACT_APP_UPLOAD_LINK}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? (
        loading ? (
          <LoadingOutlined />
        ) : (
          <img src={imageUrl} alt='avatar' className='h-[200px] w-[200px] object-scale-down' />
        )
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default CUpload;
