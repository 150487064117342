import { Modal } from 'antd';
import { ReactNode } from 'react';

interface IConfirmModal {
  open: boolean;
  content: ReactNode;
}
function ModalConfirm({ open, content }: IConfirmModal) {
  return (
    <Modal open={open} maskClosable={false} footer={false} closable={false}>
      {content}
    </Modal>
  );
}

export default ModalConfirm;
