import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './tailwind.css';
import './styles/antd-custom.scss';
import './index.scss';
import { store } from './redux';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider theme={{ hashed: false }}>
        <App />
      </ConfigProvider>
    </Provider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
