import { Col, Row, Form, Input, Select, DatePicker } from 'antd';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import {
  getUcmCardTypeIDOption,
  isValidUcmCardTypeID
} from 'src/pages/management/activeSim/utils';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from 'src/shared/constants';

export default function TraderProfileBox({ accountAttribute }: any) {
  const [form] = Form.useForm();

  const defaultValue = {
    ...accountAttribute,
    referralIdType: isValidUcmCardTypeID(accountAttribute?.referralIdType)
      ? accountAttribute?.referralIdType
      : null,
    referralIssueDate: accountAttribute?.referralIssueDate
      ? dayjs(accountAttribute?.referralIssueDate)
      : null,
    referralCoverLetterDate: accountAttribute?.referralCoverLetterDate
      ? dayjs(accountAttribute?.referralCoverLetterDate)
      : null,
  };

  return (
    <BoxWithLabel title='Thông tin người đến giao dịch' classNames='mt-4'>
      <Form
        form={form}
        labelWrap={true}
        initialValues={defaultValue}
        disabled={true}
        labelCol={{ span: 6 }}
      >
        <Row gutter={[14, 14]}>
          <Col span={12}>
            <Form.Item
              label='Họ và tên'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              required={true}
              name={'referralName'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Loại giấy tờ'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              required={true}
              name={'referralIdType'}
            >
              <Select options={getUcmCardTypeIDOption()} />
            </Form.Item>
            <Form.Item
              label='Số giấy tờ'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              required={true}
              name={'referralIdNumber'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Ngày cấp'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              required={true}
              name={'referralIssueDate'}
            >
              <DatePicker className={'w-full'} format={DEFAULT_DATE_FORMAT} placeholder={''} />
            </Form.Item>
            <Form.Item
              label='Nơi cấp'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              required={true}
              name={'referralIssuePlaceName'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Số giấy giới thiệu'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'referralCoverLetterNo'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Ngày cấp giấy giới thiệu'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'referralCoverLetterDate'}
            >
              <DatePicker className={'w-full'} format={DEFAULT_DATE_FORMAT} placeholder={''} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Email'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              required={true}
              name={'referralEmail'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Số điện thoại'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              required={true}
              name={'referralPhoneNumber'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Liên hệ khác'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'referralOtherContact'}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BoxWithLabel>
  );
}
