import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { useForm } from 'antd/lib/form/Form';
import moment, { Moment } from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IListColumn, ISortColumn, TransactionHistoryParams } from 'src/@types/search-params';
import { getOrderSims } from 'src/api/changeSim';
import TableContent from 'src/components/CTable';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { setChangeSimState } from 'src/redux/slice/changeSimSlice';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { OpenNewTab } from 'src/shared/common/openNewTab';
import { ChangeSimStatus } from 'src/shared/enum/change_sim';
import { OrderSim } from 'src/shared/interface/changeSim.interface';
import { TransactionHistory } from 'src/shared/interface/localpay.interface';
import { exportExcel, formatDateTime, formatMoney, parseQueryString } from 'src/utils';
import { simTypeToText } from 'src/utils/sim.enum';
import { SimType } from 'src/shared/enum/sim.enum';

interface FormData {
  status?: string;
  dateRange?: [Moment, Moment];
  search?: string;
  simType?: string;
}
const listStatus = [
  { name: 'Tất cả', value: '-1' },
  { name: 'New', value: ChangeSimStatus.New },
  { name: 'Processing', value: ChangeSimStatus.Processing },
  // { name: 'Failed', value: ChangeSimStatus.Failed },
  { name: 'Completed', value: ChangeSimStatus.Completed },
  { name: 'Uncompleted', value: ChangeSimStatus.Uncompleted },
  { name: 'Paid', value: ChangeSimStatus.Paid },
  { name: 'Payment fail', value: ChangeSimStatus.PaymentFail },
  { name: 'Canceled', value: ChangeSimStatus.Canceled },
];
const listType = [
  { name: 'Tất cả', value: '-1' },
  { name: simTypeToText(SimType.PHYSICAL_SIM), value: SimType.PHYSICAL_SIM },
  { name: simTypeToText(SimType.ESIM), value: SimType.ESIM },
];

const ChangeSim = () => {
  const [form] = useForm();
  const { openTabs } = useAppSelector((state) => state.global);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<TransactionHistory[]>([]);
  const [sortColumn, setSortColumn] = useState<ISortColumn>();
  const [, setSearchParams] = useSearchParams();

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current >= moment().endOf('day');
  };

  const renderDate = (date: string) => {
    return <div>{date ? formatDateTime(new Date(date)) : ''}</div>;
  };
  const goToDetail = (data: any) => {
    OpenNewTab('changeSim/createChangeSim' + '?id=' + data.id, 'Xem chi tiết đổi sim ' + data.id);
  };
  const renderAction = (data: OrderSim) => {
    return (
      <button
        onClick={() => goToDetail(data)}
        className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
      >
        Xem chi tiết
      </button>
    );
  };

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', align: 'center', isHideSort: true, width: 20 },
    {
      name: 'Mã đơn',
      fieldName: 'id',
      align: 'center',
      isHideSort: true,
      renderSpecialCard: (data) => (
        <span
          onClickCapture={() => goToDetail(data)}
          className='underline text-blue-700 cursor-pointer'
        >
          {data.id}
        </span>
      ),
    },
    {
      name: 'Loại sim',
      fieldName: 'simType',
      align: 'center',
      isHideSort: true,
      renderSpecialCard: (data) => (
        <span
        >
          {simTypeToText(data?.simType)}
        </span>
      ),
    },
    { name: 'Trạng thái', fieldName: 'statusString', isHideSort: true, width: 100 },
    {
      name: 'Số giao dịch thanh toán',
      fieldName: 'transactionId',
      isHideSort: true,
      align: 'center',
      width: 150,
      renderSpecialCard: (data) => (
        <span className='whitespace-nowrap'>
          {data.toTalAmount !== 0 ? data.transactionId : ''}
        </span>
      ),
    },
    {
      name: 'Thành tiền',
      fieldName: 'toTalAmount',
      isHideSort: true,
      renderSpecialCard: (data) => (
        <span className='whitespace-nowrap'>{formatMoney(data.toTalAmount)}</span>
      ),
    },
    {
      name: 'Tổng cộng',
      fieldName: 'quantity',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (data) => <span className='whitespace-nowrap'>{data.countSerial}</span>,
    },
    {
      name: 'Thành công',
      fieldName: 'quantitySuccess',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Thất bại',
      fieldName: 'quantityFailed',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Người tạo',
      fieldName: 'createdBy',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Ngày tạo',
      fieldName: 'createdAt',
      isHideSort: true,
      renderSpecialCard: (data) => renderDate(data.createdAt),
    },
    {
      name: 'Ngày cập nhật',
      fieldName: 'updatedAt',
      isHideSort: true,
      renderSpecialCard: (data) => renderDate(data.updatedAt),
    },
    {
      name: 'Người cập nhật',
      fieldName: 'updatedBy',
      isHideSort: true,
      align: 'center',
    },
    { name: 'Thao tác', fieldName: 'id', renderSpecialCard: renderAction, isHideSort: true },
  ];

  const userStore = localStorage.getItem('merchantInfo');
  let merchantInfo: any;
  if (userStore !== null) {
    try {
      merchantInfo = JSON.parse(userStore);
    } catch (error) {
      console.log(error);
    }
  }

  const renderOptions = (listOpts: any[]) => {
    return listOpts.map((item) => {
      return (
        <Select.Option value={item.value} key={item.value}>
          {item.name}
        </Select.Option>
      );
    });
  };

  const handleSearch = (formValues: FormData) => {
    const { status, search, dateRange, simType } = formValues;
    const searchParams = queryString.stringify({
      status: status === '-1' ? undefined : status,
      simType:
        simType === '-1'
          ? undefined
          : listType.find((x) => x.value === simType)?.value ||
            listType.find((x) => x.name === simType)?.value,
      search,
      dateFrom: dateRange ? dateRange[0].format('YYYY-MM-DD') : undefined,
      dateTo: dateRange ? dateRange[1].format('YYYY-MM-DD') : undefined,
    });
    setSearchParams(searchParams);
  };
  const handleExport = () => {
    const pagination = parseQueryString(location.search);
    const params = queryString.stringify({ ...pagination, distributorId: merchantInfo.merchantId });
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `danh_sach_doi_sim_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    const url = process.env.REACT_APP_API_URL + '/api/Provider/physical-sim/export?' + params;
    exportExcel(url).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
    });
  };

  const fetchData = async (searchParams: TransactionHistoryParams) => {
    const orderChangeSim = await getOrderSims(searchParams);

    if (orderChangeSim) {
      setData(
        orderChangeSim.results.map((item: any, index: number) => {
          return { ...item, key: index };
        }),
      );
      setTotal(orderChangeSim.rowCount);
    }
  };

  useEffect(() => {
    const pagination = parseQueryString(location.search);

    fetchData({
      ...pagination,
      pageIndex: pagination.pageIndex ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
    setSortColumn(undefined);
    form.setFieldsValue({
      ...pagination,
      simType: listType.find((x) => x.value === Number(pagination.simType))?.name ?? listType[0].name,
    });
  }, [location.search]);

  return (
    <>
      <div>
        <Form
          form={form}
          onFinish={handleSearch}
          initialValues={{ status: '-1', type: '-1' }}
          layout='inline'
          className='gap-2'
        >
          <Form.Item name='search'>
            <Input
              allowClear
              className='min-w-[300px]'
              prefix={<SearchOutlined />}
              placeholder='Mã đơn hàng, Mã giao dịch'
            />
          </Form.Item>
          <Form.Item name='simType' label='Loại sim'>
            <Select showSearch className='min-w-[150px]'>
              {renderOptions(listType)}
            </Select>
          </Form.Item>
          <Form.Item name='status' label='Trạng thái'>
            <Select showSearch className='min-w-[150px]'>
              {renderOptions(listStatus)}
            </Select>
          </Form.Item>
          <Form.Item name='dateRange' label='Ngày tạo'>
            <DatePicker.RangePicker
              disabledDate={disabledDate}
              placeholder={['từ', 'đến']}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
            />
          </Form.Item>

          <Form.Item>
            <Button htmlType='submit'>Tìm kiếm</Button>
            <Button
              type='primary'
              onClick={() => {
                OpenNewTab('changeSim/createChangeSim', 'Tạo mới đơn hàng đổi sim', true);
              }}
              className='ml-2'
            >
              Tạo mới
            </Button>
            <Button type='primary' onClick={handleExport} className='ml-2'>
              Export
            </Button>
          </Form.Item>
        </Form>
        <div className='mt-8'>
          <TableContent
            locale={{ emptyText: 'Không tìm thấy kết quả' }}
            totalRecord={total}
            dataSource={data}
            columns={columns}
            sortColumn={sortColumn}
            onSort={(sort: ISortColumn) => {
              const pagination = parseQueryString(location.search);
              fetchData({
                ...pagination,
                pageIndex: pagination.pageIndex ?? 1,
                pageSize: pagination.pageSize ?? 10,
                sortField: sort.fieldName,
                asc:
                  sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? false : undefined,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ChangeSim;
