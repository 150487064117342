import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IListColumn, ISortColumn } from 'src/@types/search-params';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { OpenNewTab } from 'src/shared/common/openNewTab';
import { SimType } from 'src/shared/enum/sim.enum';
import {
  dataGet,
  formatDateTime,
  formatPhoneNumber,
  getDataQueryGraphql,
  getQueryExportGraphql,
  parseQueryString,
} from 'src/utils';
import { simTypeToText } from 'src/utils/sim.enum';
import { JointSubject, SubscriptionStatus } from '../../constants';
import {
  getJointSubject,
  getJointSubjectOption,
  getSimStatus,
  getSimStatusOption,
  getSubscriptionStatus,
  getSubscriptionStatusOption,
  getUcmCardTypeID,
} from '../../utils';
import { ModalEmailReceiveExportFile } from '../components/ModalEmailReceiveExportFile';
import { useActiveSimInfo, useAllPackage } from '../hooks';
const { Option } = Select;

interface FormData {
  sim_type?: number | string;
  sim_status?: number | string;
  dateRange?: [Moment, Moment];
  search?: string;
  current_package?: string;
  party_role?: string;
  sub_status?: string | number;
}

const initFilters = {
  offset: 0,
  limit: 10,
};

const ActiveSim = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const { dataEnterprise } = useAppSelector((state) => state.userProfile);
  const { data: allPackageData } = useAllPackage();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterParams, setFilterParams] = useState<any>(initFilters);
  const [queryParam, setQueryParam] = useState<string>(getDataQueryGraphql(filterParams));

  const { isLoading, data: dataSource, refetch } = useActiveSimInfo(queryParam);

  const [sortColumn, setSortColumn] = useState<ISortColumn>();
  const [isOpenModalEmails, setIsOpenModalEmails] = useState(false);

  useEffect(() => {
    refetch();
  }, [queryParam]);

  useEffect(() => {
    const valueItem = dataEnterprise?.account_profile_values?.find(
      (item: any) => item.table_attribute_field_name === 'companyCode',
    );
    const value = (valueItem && valueItem?.value) ?? '';

    const param = {
      offset: filterParams?.pageIndex
        ? filterParams.pageIndex > 0
          ? filterParams.pageIndex - 1
          : 0
        : 0,
      limit: filterParams?.pageSize ? filterParams.pageSize : 10,
      where: {
        // enterprise_code: { eq: 'DN670778' },
        enterprise_code: { eq: value },
        or: !filterParams?.search
          ? undefined
          : [
              {
                phone_number: {
                  like:
                    filterParams?.search.length === 10 && filterParams?.search.startsWith('0')
                      ? filterParams?.search.replace('0', '84')
                      : filterParams?.search,
                },
              },
              {
                sim_serial: {
                  like: filterParams?.search,
                },
              },
              {
                subscriber_name: {
                  like: filterParams?.search,
                },
              },
              {
                id_number: {
                  like: filterParams?.search,
                },
              },
            ],
        sim_status:
          !filterParams?.sim_status || filterParams?.sim_status === -1
            ? undefined
            : { eq: filterParams?.sim_status },
        sim_type:
          !filterParams?.sim_type || filterParams?.sim_type === '-1'
            ? undefined
            : { eq: filterParams?.sim_type },
        active_datetime: filterParams?.active_datetime
          ? {
              gte: filterParams?.active_datetime.split(',')[0],
              lte: filterParams?.active_datetime.split(',')[1],
            }
          : undefined,
        party_role:
          !filterParams?.party_role || filterParams?.party_role === '-1'
            ? undefined
            : { eq: filterParams?.party_role },
        sub_status:
          !filterParams?.sub_status || filterParams?.sub_status === -1
            ? undefined
            : { eq: filterParams?.sub_status },
        current_package:
          !filterParams?.current_package || filterParams?.current_package === '-1'
            ? undefined
            : { eq: filterParams?.current_package },
        pageIndex: undefined,
        pageSize: undefined,
      },
      orderBy: {
        active_datetime: 'desc',
      },
    };
    setQueryParam(getDataQueryGraphql(param));
  }, [filterParams]);

  useEffect(() => {
    const params = parseQueryString(location.search);
    const pageIndex = dataGet(params, 'pageIndex', 1);
    const pageSize = dataGet(params, 'pageSize', 10);
    setFilterParams({ ...filterParams, pageIndex, pageSize });
  }, [location.search]);

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', align: 'center', isHideSort: true, width: 50 },
    {
      name: 'SĐT',
      fieldName: 'phone_number',
      align: 'center',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {formatPhoneNumber(record.phone_number)}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Số SIM Serial',
      fieldName: 'sim_serial',
      align: 'center',
      width: 150,
      isHideSort: true,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text
            className={
              'underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold'
            }
            onClick={() => handleDetailActiveSim(record)}
          >
            {record?.sim_serial}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Loại SIM',
      fieldName: 'sim_type',
      isHideSort: true,
      align: 'center',
      width: 150,
      renderSpecialCard: (record) => {
        return <Typography.Text className='Text'>{record.sim_type}</Typography.Text>;
      },
    },
    {
      name: 'Gói cước đang sử dụng',
      fieldName: 'current_package',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>{dataGet(record, 'current_package')}</Typography.Text>
        );
      },
    },
    {
      name: 'Ngày gán gói',
      fieldName: 'grant_packaage_date',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'grant_packaage_date')
              ? formatDateTime(dataGet(record, 'grant_packaage_date'))
              : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Người gán gói',
      fieldName: 'grant_package_staff',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'grant_package_staff')}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Ngày TB kích hoạt',
      fieldName: 'active_datetime',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'active_datetime')
              ? formatDateTime(dataGet(record, 'active_datetime'))
              : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Ngày TB đổi sim',
      fieldName: 'changed_sim_datetime',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'changed_sim_datetime')
              ? formatDateTime(dataGet(record, 'changed_sim_datetime'))
              : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Ngày kích hoạt gói cước',
      fieldName: 'package_reg_date',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'package_reg_date')
              ? formatDateTime(dataGet(record, 'package_reg_date'))
              : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Ngày hết hạn gói cước',
      fieldName: 'package_exp_date',
      isHideSort: true,
      width: 120,
      align: 'center',
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'package_exp_date')
              ? formatDateTime(dataGet(record, 'package_exp_date'))
              : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Zone',
      fieldName: 'zone',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Data sử dụng',
      fieldName: 'g_volume_usage',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (record) => {
        return (
          <Typography.Text>
            {parseInt(dataGet(record, 'g_volume_usage', 0)) > 0
              ? parseInt(dataGet(record, 'g_volume_usage', 0))
              : null}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Số phút đã gọi',
      fieldName: 'v_duration',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Số dư tài khoản',
      fieldName: 'balance',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Mã hồ sơ DN',
      fieldName: 'enterprise_code',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Tên hồ sơ DN',
      fieldName: 'enterprise_name',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Đối tượng',
      fieldName: 'party_role',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Tên thuê bao',
      fieldName: 'subscriber_name',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Loại giấy tờ',
      fieldName: 'id_option',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {getUcmCardTypeID(dataGet(record, 'id_option'))}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Số giấy tờ',
      fieldName: 'id_number',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Trạng thái TB',
      fieldName: 'sub_status',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (record) => {
        return <Typography.Text className='Text'>{record.sub_status}</Typography.Text>;
      },
    },
    {
      name: 'Trạng thái SIM',
      fieldName: 'sim_status',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (record) => {
        return <Typography.Text className='Text'>{record.sim_status}</Typography.Text>;
      },
    },
    {
      name: 'Thao tác',
      fieldName: '',
      renderSpecialCard: (record) => {
        return (
          <Typography.Text
            className={
              'underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold'
            }
            onClick={() => handleDetailActiveSim(record)}
          >
            Chi tiết
          </Typography.Text>
        );
      },
    },
  ];

  const handleSearch = (formValues: FormData) => {
    const currentQueryStr = parseQueryString(location.search);
    delete currentQueryStr.pageIndex;
    delete currentQueryStr.pageSize;
    const { sim_type, sim_status, dateRange, search, current_package, party_role, sub_status } =
      formValues;
    const searchParams = {
      sim_status: sim_status === -1 ? undefined : getSimStatus(sim_status),
      sim_type: sim_type === '-1' ? undefined : simTypeToText(sim_type),
      active_datetime: dateRange
        ? `${dateRange[0].format('YYYY-MM-DD')},${dateRange[1].format('YYYY-MM-DD')}`
        : undefined,
      search: search,
      party_role: party_role === '-1' ? undefined : getJointSubject(party_role),
      sub_status: sub_status === -1 ? undefined : getSubscriptionStatus(sub_status),
      current_package: current_package === '-1' ? undefined : current_package,
      pageIndex: 1,
      pageSize: dataGet(currentQueryStr, 'pageSize', 10),
    };
    setFilterParams(searchParams);
    setSearchParams({ ...currentQueryStr });
  };

  const defaultValues = {
    current_package: '-1',
    party_role: JointSubject.All,
    sub_status: SubscriptionStatus.All,
    sim_type: '-1',
    sim_status: -1,
  };

  const handleDetailActiveSim = (data: any) => {
    OpenNewTab(
      `active-sim/detail?serial=${data?.sim_serial}&phone=${data?.phone_number}`,
      `Chi tiết thuê bao ${data?.sim_serial}`,
    );
  };

  const handleDeleteFilter = () => {
    setFilterParams(undefined);
    form.resetFields();
    refetch();
  };

  const handleExport = () => {
    const valueItem = dataEnterprise?.account_profile_values?.find(
      (item: any) => item.table_attribute_field_name === 'companyCode',
    );
    const value = (valueItem && valueItem?.value) ?? '';
    const param = {
      offset: filterParams?.pageIndex
        ? filterParams.pageIndex > 0
          ? filterParams.pageIndex - 1
          : 0
        : 0,
      limit: filterParams?.pageSize ? filterParams.pageSize : 10,
      where: {
        // enterprise_code: { eq: 'DN670778' },
        enterprise_code: { eq: value },
        or: !filterParams?.search
          ? undefined
          : [
              {
                phone_number: {
                  like:
                    filterParams?.search.length === 10 && filterParams?.search.startsWith('0')
                      ? filterParams?.search.replace('0', '84')
                      : filterParams?.search,
                },
              },
              {
                sim_serial: {
                  like: filterParams?.search,
                },
              },
              {
                subscriber_name: {
                  like: filterParams?.search,
                },
              },
              {
                id_number: {
                  like: filterParams?.search,
                },
              },
            ],
        sim_status:
          !filterParams?.sim_status || filterParams?.sim_status === -1
            ? undefined
            : { eq: filterParams?.sim_status },
        sim_type:
          !filterParams?.sim_type || filterParams?.sim_type === '-1'
            ? undefined
            : { eq: filterParams?.sim_type },
        active_datetime: filterParams?.active_datetime
          ? {
              gte: filterParams?.active_datetime.split(',')[0],
              lte: filterParams?.active_datetime.split(',')[1],
            }
          : undefined,
        party_role:
          !filterParams?.party_role || filterParams?.party_role === '-1'
            ? undefined
            : { eq: filterParams?.party_role },
        sub_status:
          !filterParams?.sub_status || filterParams?.sub_status === -1
            ? undefined
            : { eq: filterParams?.sub_status },
        current_package:
          !filterParams?.current_package || filterParams?.current_package === '-1'
            ? undefined
            : { eq: filterParams?.current_package },
        pageIndex: undefined,
        pageSize: undefined,
      },
      orderBy: {
        active_datetime: 'desc',
      },
    };
    setFilterParams({ ...filterParams, query: getQueryExportGraphql(dataSource, param) });
    setIsOpenModalEmails(true);
  };

  const handleCancelModalEmails = () => {
    setIsOpenModalEmails(false);
  };

  return (
    <>
      <div>
        <Form
          form={form}
          onFinish={handleSearch}
          initialValues={defaultValues}
          layout='inline'
          className='gap-2'
        >
          <Row gutter={[5, 15]} className={'w-full'}>
            <Col xs={7}>
              <Form.Item name={'search'}>
                <Input
                  allowClear
                  prefix={<SearchOutlined />}
                  placeholder='Tìm kiếm theo SĐT, Sim serial, Họ và tên, Số giấy tờ'
                ></Input>
              </Form.Item>
            </Col>
            <Col xs={7}>
              <Form.Item name='sim_type' label='Loại SIM'>
                <Select>
                  <Option key={'-1'} value={'-1'}>
                    {'Tất cả'}
                  </Option>
                  <Option key={SimType.PHYSICAL_SIM} value={SimType.PHYSICAL_SIM}>
                    {simTypeToText(SimType.PHYSICAL_SIM)}
                  </Option>
                  <Option key={SimType.ESIM} value={SimType.ESIM}>
                    {simTypeToText(SimType.ESIM)}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={7}>
              <Form.Item name='current_package' label='Gói cước sử dụng'>
                <Select>
                  <Option key={'-1'} value={'-1'}>
                    {'Tất cả'}
                  </Option>
                  {allPackageData?.map((item: any) => (
                    <Option key={item + 1} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={10}>
              <Form.Item name='dateRange' label='Ngày kích hoạt'>
                <DatePicker.RangePicker
                  placeholder={['từ', 'đến']}
                  format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                  className={'w-full'}
                  placement={'bottomRight'}
                  disabledDate={(current) => {
                    return current.isAfter(dayjs());
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item name='party_role' label='Đối tượng'>
                <Select options={getJointSubjectOption()}></Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item name='sub_status' label='Trạng thái thuê bao'>
                <Select options={getSubscriptionStatusOption()}></Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item name='sim_status' label='Trạng thái SIM'>
                <Select options={getSimStatusOption()}></Select>
              </Form.Item>
            </Col>
          </Row>
          <div className={'row-button justify-end flex-wrap-reverse mt-3'}>
            <Button onClick={() => handleDeleteFilter()} className={'m-1'}>
              Xóa tìm kiếm
            </Button>
            <Button type={'primary'} htmlType='submit' className={'m-1'}>
              Tìm kiếm
            </Button>
            <Button onClick={() => handleExport()} className={'m-1'}>
              Export
            </Button>
          </div>
        </Form>
      </div>
      <div className='mt-4'>
        <TableContent
          bordered
          loading={isLoading}
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: 'Không tìm thấy kết quả' }}
          totalRecord={dataGet(
            dataSource,
            'report_enterprise.report_enterprise_aggregate.aggregate.count',
            0,
          )}
          dataSource={dataGet(dataSource, 'report_enterprise.report_enterprise', [])}
          columns={columns}
          sortColumn={sortColumn}
          onSort={(sort: ISortColumn) => {
            const pagination = parseQueryString(location.search);
            handleSearch({
              ...pagination,
              pageIndex: pagination.pageIndex ?? 1,
              pageSize: pagination.pageSize ?? 10,
              sortField: sort.fieldName,
              asc: sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? false : undefined,
            });
          }}
        />
      </div>

      <ModalEmailReceiveExportFile
        open={isOpenModalEmails}
        onCancel={() => handleCancelModalEmails()}
        filterParams={filterParams}
      />
    </>
  );
};

export default ActiveSim;
