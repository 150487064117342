import request from '../request';

export const getDetailBusinessProfile = async () => {
  const url = `/api/BusinessCustomer/GetInfoCustomerBylocalShopAccountV3`;
  const res = await request<any>('get', url);

  if (res.isSucceeded) {
    return res.data.data[0];
  }
};
