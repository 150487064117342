import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Select } from 'antd';
import { Fragment } from 'react';
import { IListColumn } from 'src/@types/search-params';
import TableContent from 'src/components/CTable';
import { useAppDispatch } from 'src/redux/hook';
import { setModalState } from 'src/redux/slice/modal.store';

function TransactionListPage() {
  const dispatch = useAppDispatch();

  function handleExport() {
    console.log('Export...');
  }

  function handleFormSearch() {
    console.log('Search...');
  }

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20 },
    { name: 'Mã giao dịch' },
    {
      name: 'Loại',
    },
    {
      name: 'Mã đơn hàng',
    },
    {
      name: 'PTTT',
    },
    {
      name: 'Thành tiền',
    },
    { name: 'Trạng thái' },
    {
      name: 'Người tạo',
    },
    {
      name: 'Ngày tạo',
    },
    {
      name: 'Thao tác',
      fieldName: 'id',
      renderSpecialCard: () => (
        <button
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
          onClick={() =>
            dispatch(setModalState({ open: true, content: 'transaction-info', width: '50%' }))
          }
        >
          Xem chi tiết
        </button>
      ),
      isHideSort: true,
    },
  ];

  const data = [
    {
      index: 1,
    },
  ];

  return (
    <Fragment>
      <Form onFinish={handleFormSearch} layout='inline'>
        <Row>
          <Form.Item name=''>
            <Input prefix={<SearchOutlined />} placeholder='Mã giao dịch' />
          </Form.Item>
          <Form.Item name='' label='Loại đơn hàng'>
            <Select showSearch className='min-w-[150px]' placeholder='Vui lòng chọn'>
              <Select.Option value=''>Tất cả</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='' label='Trạng thái'>
            <Select showSearch className='min-w-[150px]' placeholder='Vui lòng chọn'>
              <Select.Option value=''>Tất cả</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='' label='Người tạo'>
            <Select showSearch className='min-w-[150px]' placeholder='Vui lòng chọn'>
              <Select.Option value=''>Tất cả</Select.Option>
            </Select>
          </Form.Item>
        </Row>

        <div className='row-button' style={{ justifyContent: 'flex-end', margin: '1.5rem 0' }}>
          <Button type='primary' htmlType='submit'>
            Tìm kiếm
          </Button>
          <Button onClick={handleExport}>Export</Button>
        </div>
      </Form>

      <TableContent
        bordered
        scroll={{ x: 'max-content' }}
        locale={{ emptyText: 'Không tìm thấy kết quả' }}
        dataSource={data}
        columns={columns}
      />
    </Fragment>
  );
}

export default TransactionListPage;
