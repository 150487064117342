import { useDispatch } from 'react-redux';
import { IListColumn } from 'src/@types/search-params';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { setPackageAssignedState } from 'src/redux/slice/packageAssigned';
import { OrderStatusEnum } from 'src/shared/enum/package_assigned';
import { IOverFlowOrderDetailResponse } from 'src/shared/interface/package_assigned';
import { formatMoney } from 'src/utils';

interface InputCorrect {
  dataSuccess: IOverFlowOrderDetailResponse[];
  statusSim: string;
  infoPackageInput: IOverFlowOrderDetailResponse | undefined;
}

function InputCorrect({ dataSuccess, statusSim, infoPackageInput }: InputCorrect) {
  const { order } = useAppSelector((state) => state.global);
  const dispatch = useDispatch();

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20 },
    { name: 'SIM serial', fieldName: 'serial', isHideSort: true },
    {
      name: 'Mã gói',
      fieldName: 'mbfCode',
      isHideSort: true,
      renderSpecialCard: (item: IOverFlowOrderDetailResponse) => {
        return (
          <span>
            {item && item?.mbfCode
              ? item?.mbfCode
              : infoPackageInput && infoPackageInput?.mbfCode
              ? infoPackageInput?.mbfCode
              : ''}
          </span>
        );
      },
    },
    {
      name: 'Thành tiền',
      fieldName: 'price',
      isHideSort: true,
      renderSpecialCard: (item: IOverFlowOrderDetailResponse) => {
        return (
          <span>
            {item.price
              ? formatMoney(item?.price, 'đ')
              : infoPackageInput && infoPackageInput?.price
              ? formatMoney(infoPackageInput?.price, 'đ')
              : ''}
          </span>
        );
      },
    },
    { name: 'Trạng thái', fieldName: 'statusString', isHideSort: true },
    { name: 'Mã lỗi', fieldName: 'errorCode', isHideSort: true },
    { name: 'Mô tả lỗi', fieldName: 'errorMessage', isHideSort: true },
    {
      name: 'Thao tác',
      renderSpecialCard: (item) => (
        <button
          className={`underline whitespace-nowrap bg-transparent border-transparent text-blue-500 font-semibold ${
            statusSim === OrderStatusEnum.EDIT || (statusSim === OrderStatusEnum.New && !order?.id)
              ? 'cursor-pointer'
              : 'opacity-30'
          }`}
          disabled={
            statusSim === OrderStatusEnum.EDIT || (statusSim === OrderStatusEnum.New && !order?.id)
              ? false
              : true
          }
          onClick={() => handleDeleteSim(item)}
        >
          Xóa
        </button>
      ),
      isHideSort: true,
    },
  ];

  const handleDeleteSim = (item: IOverFlowOrderDetailResponse) => {
    const filter = dataSuccess.filter((i) => i.serial !== item.serial);
    dispatch(setPackageAssignedState({ dataSuccess: filter ?? [] }));
  };

  return (
    <TableContent
      bordered
      scroll={{ x: 'max-content' }}
      locale={{ emptyText: 'Không tìm thấy kết quả' }}
      dataSource={dataSuccess ?? []}
      columns={columns}
      isHidePaging={true}
    />
  );
}

export default InputCorrect;
