import { Form, Select } from 'antd';
import { useEffect, useState } from 'react';
import { getAddress } from 'src/api/connection';
import { IProvince } from 'src/shared/interface/connection';

type IOpsProvince = {
  value: string;
  label: string;
};

type Props = {
  isDisabled: boolean;
}

const GetAddress = (props: Props) => {
  const { isDisabled } = props;
  const form = Form.useFormInstance();
  const [listProvince, setListProvince] = useState<IProvince[]>();
  const [otpsProvince, setOtpsProvince] = useState<IOpsProvince[]>();
  const [listDistrict, setListDistrict] = useState<IProvince[]>();
  const [otpsDistrict, setOtpsDistrict] = useState<IOpsProvince[]>();
  const [otpsWards, setOtpsWards] = useState<IOpsProvince[]>();

  useEffect(() => {
    const fetchListProvince = async () => {
      const res = await getAddress({
        level: 1,
      });

      if (res) {
        setListProvince(res);
        setOtpsProvince(renderOtps(res));
      }
    };

    fetchListProvince();
  }, []);

  const renderOtps = (listItems: IProvince[] | undefined) => {
    if (listItems) {
      const covertOtps = listItems.map((item: IProvince) => {
        const { id, code, name } = item;
        return {
          value: code,
          label: name,
        };
      });
      return covertOtps;
    }
  };

  const handleSearch = (newValue: string) => {
    if (newValue) {
      const searchList = listProvince?.filter((Province: IProvince) => {
        return Province?.name.includes(newValue);
      });

      setOtpsProvince(renderOtps(searchList));
    } else {
      setOtpsProvince(renderOtps(listProvince));
    }
  };

  const handleProvinceChange = (newValue: string) => {
    const currentProvince = listProvince?.find(province => province.code === newValue);
    const fetchListDistrict = async () => {
      const res = await getAddress({
        parentId: currentProvince?.id,
      });

      if (res) {
        setListDistrict(res);
        setOtpsDistrict(renderOtps(res));

        form.setFieldValue('district', undefined);
        form.setFieldValue('ward', undefined);
      }
    };

    fetchListDistrict();
  };

  const handleDistrictChange = (newValue: string) => {
    const currentDistrict = listDistrict?.find(district => district.code === newValue);
    const fetchListWards = async () => {
      const res = await getAddress({
        parentId: currentDistrict?.id,
      });

      if (res) {
        setOtpsWards(renderOtps(res));

        form.setFieldValue('ward', undefined);
      }
    };

    fetchListWards();
  };

  return (
    <div className='flex flex-wrap gap-2 justify-between'>
      <Form.Item
        className='flex-1 min-w-[350px]'
        name='province'
        label='Tỉnh/Thành phố'
        rules={[{ required: true, message: 'Vui lòng chọn Tỉnh/Thành phố !' }]}
      >
        <Select
          showSearch
          allowClear
          className='min-w-[100px]'
          placeholder='Vui lòng chọn'
          // onSearch={handleSearch}
          onChange={handleProvinceChange}
          options={otpsProvince}
          disabled={isDisabled}
          optionLabelProp={'label'}
          fieldNames={{
            label: 'label',
            value: 'value',
          }}
          filterOption={(input, option) => {
            return (option!.label as unknown as string).toString().toLowerCase().includes(input.toLowerCase());
          }}
        ></Select>
      </Form.Item>
      <Form.Item
        className='flex-1 min-w-[350px]'
        name='district'
        label='Quận/Huyện'
        rules={[{ required: true, message: 'Vui lòng chọn Quận/Huyện !' }]}
      >
        <Select
          showSearch
          allowClear
          className='min-w-[100px]'
          placeholder='Vui lòng chọn'
          onChange={handleDistrictChange}
          options={otpsDistrict}
          disabled={isDisabled}
          optionLabelProp={'label'}
          fieldNames={{
            label: 'label',
            value: 'value',
          }}
          filterOption={(input: any, option: any) => {
            return (option!.label as unknown as string).toString().toLowerCase().includes(input.toLowerCase());
          }}
        ></Select>
      </Form.Item>
      <Form.Item
        className='flex-1 min-w-[350px]'
        name='ward'
        label='Phường/Xã'
        rules={[{ required: true, message: 'Vui lòng chọn Phường/Xã !' }]}
      >
        <Select
          showSearch
          allowClear
          className='min-w-[100px]'
          placeholder='Vui lòng chọn'
          options={otpsWards}
          disabled={isDisabled}
          optionLabelProp={'label'}
          fieldNames={{
            label: 'label',
            value: 'value',
          }}
          filterOption={(input: any, option: any) => {
            return (option!.label as unknown as string).toString().toLowerCase().includes(input.toLowerCase());
          }}
        ></Select>
      </Form.Item>
    </div>
  );
};

export default GetAddress;
