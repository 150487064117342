import { Button, Modal, Spin } from 'antd';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  handleCancel: () => void;
  handleConnect: () => void;
  dataSuccess: any;
};

const ModalConfirmBatchConnect = (props: Props) => {
  const { isOpen, handleCancel, handleConnect, dataSuccess, isLoading } = props;
  const [result, setResult] = useState<string>();

  return (
    <div>
      <Modal open={isOpen} footer={null} onCancel={handleCancel} closable={!result}>
        <Spin spinning={isLoading}>
          <div className='pt-10 pb-5'>
            <p className='text-xl text-center'>
              {result ? result : `Bạn có muốn đấu nối ${dataSuccess.length} SIM?`}
            </p>
          </div>
          <div className='flex justify-end gap-2'>
            <Button disabled={isLoading} onClick={handleCancel}>
              Thoát
            </Button>
            <Button disabled={isLoading} onClick={handleConnect} type='primary'>
              Tiếp tục
            </Button>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
export default ModalConfirmBatchConnect;
