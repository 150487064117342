import { ReactNode } from 'react';

interface BoxWithLabelProps {
  title?: string;
  children: ReactNode;
  classNames?: string;
}
const BoxWithLabel = ({ title, children, classNames }: BoxWithLabelProps) => {
  return (
    <section
      className={`${classNames ?? ''} border border-solid border-[#05050566] relative px-4 py-6`}
    >
      <div className='absolute -top-3 left-4 font-bold bg-white px-1'>{title}</div>
      <div>{children}</div>
    </section>
  );
};

export default BoxWithLabel;
