import {Col, Input, Row, Select, DatePicker, Button, Typography, Form, Tooltip, message} from "antd";
import {SearchOutlined, QrcodeOutlined} from "@ant-design/icons";
import TableContent from "src/components/CTable";
import {IListColumn, ISortColumn} from "src/@types/search-params";
import {
  getStatusEsimLotOption,
  getStatusEsimLotTxt,
  getTransactionTypeLotOption,
  getTransactionTypeLotTxt
} from "../../utils";
import {useEffect, useState} from "react";
import {getAllPackage, getAllPackageByLCS} from "src/api/activeSim";
import {ValueCodeEsim} from "src/shared/interface/esimManagement.interface";
import {dataGet, exportExcelPost, formatDate, parseQueryString} from "src/utils";
import ModalPreviewQrCode from "../components/ModalPreviewQrCode";
import {StatusEsimLot, TransactionTypeLot, ValueAll} from "../../constants";
import {useLocation, useSearchParams} from "react-router-dom";
import {useAppSelector} from "src/redux/hook";
import {store} from "src/redux";
import {setEsimManagementState} from "src/redux/slice/esimManagementSlice";
import moment, {Moment} from "moment/moment";
import {useListEsim} from '../../hooks';

const { RangePicker } = DatePicker;

interface FormData {
  code?: string;
  status?: string;
  dateRange: [Moment, Moment];
  type?: string;
  dataPackage?: string;
}

const initFilters = {
  pageIndex: 1,
  pageSize: 10
}

export default function EsimManagement() {
  const location = useLocation();
  const [form] = Form.useForm();
  const [listPackages, setListPackages] = useState<ValueCodeEsim[]>([]);
  const [isOpenQrCode, setIsOpenQrCode] = useState(false);
  const [isQrCodeActive, setIsQrCodeActive] = useState<boolean>();
  const [filterParams, setFilterParams] = useState(initFilters);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedQrCode, setSelectedQrCode] = useState<any>();
  const { listEsimSelect } = useAppSelector(
    (state) => state.esimManagement
  );
  const {isLoading, data: eSimData} = useListEsim(filterParams);

  const newSelectedRowKeys = [...listEsimSelect].map((item: any) => item?.id);
  const rowSelection = {
    selectedRowKeys: newSelectedRowKeys,
    onSelect: (record: any, selected: boolean) => {
      let listEsimSelectedTmp = listEsimSelect;
      if (selected) {
        listEsimSelectedTmp = [...listEsimSelect, record];
      } else {
        listEsimSelectedTmp = listEsimSelectedTmp?.filter((item: any) => item?.id !== record?.id);
      }
      store.dispatch(setEsimManagementState({
        listEsimSelect: listEsimSelectedTmp
      }))
    },
    onSelectAll: (selected: boolean, selectedRows: any[], changeRows: any[]) => {
      let listEsimSelectedTmp = listEsimSelect;
      if (selected) {
        listEsimSelectedTmp = [...listEsimSelect, ...changeRows];
      } else {
        changeRows?.forEach((itemChange: any) => {
          listEsimSelectedTmp = listEsimSelectedTmp?.filter((item: any) => item?.id !== itemChange?.id);
        })
      }
      store.dispatch(setEsimManagementState({
        listEsimSelect: listEsimSelectedTmp
      }));
    },
  }

  const columns: IListColumn[] = [
    {
      name: 'Mã xuất lô',
      fieldName: 'transactionCode',
      isHideSort: true,
      align: 'left',
    },
    {
      name: 'Serial',
      fieldName: 'serial',
      isHideSort: true,
      align: 'left',
    },
    {
      name: 'Trạng thái',
      fieldName: 'statusInt',
      isHideSort: true,
      align: 'left',
      renderSpecialCard: record => {
        return (
          <span>{getStatusEsimLotTxt(record?.statusInt.toString())}</span>
        );
      }
    },
    {
      name: 'Loại giao dịch',
      fieldName: 'type',
      isHideSort: true,
      align: 'left',
      renderSpecialCard: (record: any) => {
        return (
          <Typography.Text>{getTransactionTypeLotTxt(record?.type)}</Typography.Text>
        );
      }
    },
    {
      name: 'Gói',
      fieldName: 'dataPackage',
      isHideSort: true,
      align: 'left',
    },
    {
      name: 'Ngày xuất lô',
      fieldName: 'exportAt',
      isHideSort: true,
      align: 'left',
      renderSpecialCard: (record: any) => {
        return (
          <Typography.Text>{dataGet(record, 'exportAt')
            ? formatDate(dataGet(record, 'exportAt', ''))
            : ''}</Typography.Text>
        );
      }
    },
    {
      name: 'Ngày hết hạn eSIM',
      fieldName: 'expireAt',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (record: any) => {
        return (
          <Typography.Text>{dataGet(record, 'expireAt')
            ? formatDate(dataGet(record, 'expireAt'))
            : ''}</Typography.Text>
        );
      }
    },
    {
      name: 'LPA',
      fieldName: 'lpa',
      isHideSort: true,
      align: 'left',
      renderSpecialCard: (record: any) => {
        return (
          <span className="cursor-pointer inline-block w-[200px]">
            {renderTruncate(record?.lpa)}
          </span>
        );
      }
    },
    {
      name: 'QR eSIM',
      fieldName: 'qrEsim',
      isHideSort: true,
      align: 'center',
      renderSpecialCard: (record: any) => {
        return (
          <QrcodeOutlined
            className={'cursor-pointer'}
            style={{fontSize: 24}}
            onClick={() => {
              setIsOpenQrCode(true)
              setSelectedQrCode(record?.qrEsim)
              setIsQrCodeActive(false)
            }}
          />
        );
      }
    },
    {
      name: 'Thao tác',
      fieldName: 'code',
      isHideSort: true,
      align: 'left',
      fixed: 'right',
      renderSpecialCard: (record: any) => {
        return (
          <Typography.Text
            className={
              'underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold'
            }
            onClick={() => onClickDownloadFile(record)}
          >
            Tải về
          </Typography.Text>
        );
      },
    },
  ];

  const renderTruncate = (text: string) => {
    return (
      <div className="truncate block">
        <Tooltip placement="topLeft" title={<span>{text}</span>}>
          {text}
        </Tooltip>
      </div>
    );
  };

  const disabledDate = (current: any) => {
    // Can not select days after today
    return current && current > moment().endOf('day');
  }
  
  const fetchListPackages = async () => {
    const packageTmp: any = [];
    const packages = await getAllPackageByLCS();
    if (packages) {
      packageTmp?.push({
        label: 'Tất cả',
        value: ValueAll.ALL
      });

      packages && packages?.map((item: any, index: number) => {
        packageTmp.push({
          label: item,
          value: item
        })
      });
      setListPackages(packageTmp)
    }
  }

  const handleSearch = (formValues: FormData) => {
    const currentQueryStr = parseQueryString(location.search);
    delete currentQueryStr.pageIndex;
    delete currentQueryStr.pageSize;
    setSearchParams({
      ...currentQueryStr
    })
    const {
      code,
      status,
      type,
      dataPackage,
      dateRange,
    } = formValues;
    const filterSearch = {
      code: code,
      status: status === StatusEsimLot.ALL ? undefined : status,
      type: type === TransactionTypeLot.ALL ? undefined : type,
      dataPackage: dataPackage === ValueAll.ALL ? undefined : dataPackage,
      dateFrom: dateRange ? dateRange[0]?.format('YYYY-MM-DD 00:00:00').toString() : undefined,
      dateTo: dateRange ? dateRange[1]?.format('YYYY-MM-DD 00:00:00').toString() : undefined,
      pageIndex: 1,
      pageSize: dataGet(currentQueryStr, 'pageSize', 10),
    }
    setFilterParams(filterSearch);
  }
  const onClickDownloadFile = async (record: any) => {
    const params = parseQueryString(location.search);
    if (record) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = `Danh_sach_esim_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
      exportExcelPost(
        '/api/inventory-esim/export-esim-manage-distributor',
        {
          ...params,
          lotDetailIds: [record?.id],
          channel: 'active'
        }
      ).then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
        link.click();
        message.success('Kết xuất file thành công!');
      });
    }
  }

  const onClickExportExcel = async () => {
    const listEsimExport: any = [];
    listEsimSelect?.forEach((item: any, index: number) => {
      listEsimExport.push(item?.id)
    });

    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `Danh_sach_esim_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    exportExcelPost(
      '/api/inventory-esim/export-esim-manage-distributor',
      {
        ...filterParams,
        lotDetailIds: listEsimExport,
        channel: 'active'
      }
    ).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
      store.dispatch(setEsimManagementState({
        listEsimSelect: []
      }));
      message.success('Kết xuất file thành công!');
    });
  }

  useEffect(() => {
    fetchListPackages();
  }, [location.search]);

  const renderOptions = (listOpts: any[]) => {
    return listOpts?.map(item => {
      return (
        <Select.Option value={item?.value} key={item?.value} label={item?.label}>
          {item?.label}
        </Select.Option>
      );
    });
  };

  useEffect(() => {
    const params = parseQueryString(location.search);
    const pageIndex = dataGet(params, 'pageIndex', 1);
    const pageSize = dataGet(params, 'pageSize', 10);
    setFilterParams({...filterParams, pageIndex, pageSize });
  }, [location.search]);

  return (
    <>
      <div>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={handleSearch}
        >
          <Row gutter={16} className={'py-2 items-end'}>
            <Col span={5}>
              <Form.Item
                name={'code'}
              >
                <Input
                  allowClear
                  placeholder={'Theo mã, serial'}
                  prefix={<SearchOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={'status'}
                label={'Trạng thái'}
              >
                <Select
                  defaultValue={StatusEsimLot.ALL}
                  showSearch
                  id={'esim-lot-status'}
                  className={'w-full'}
                  options={getStatusEsimLotOption()}
                  optionLabelProp={'label'}
                  filterOption={(input, option) => {
                    return (option!.label as unknown as string)
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label={'Loại giao dịch'}
                name={'type'}
              >
                <Select
                  showSearch
                  defaultValue={TransactionTypeLot.ALL}
                  id={'transaction-type-lot'}
                  className={'w-full'}
                  options={getTransactionTypeLotOption()}
                  optionLabelProp={'label'}
                  filterOption={(input, option) => {
                    return (option!.label as unknown as string)
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label={'Mã gói'}
                name={'dataPackage'}
              >
                <Select
                  showSearch
                  defaultValue={ValueAll.ALL}
                  id={'package-code'}
                  className={'w-full'}
                  optionLabelProp={'label'}
                  filterOption={(input, option) => {
                    return (option!.label as unknown as string)
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {renderOptions(listPackages)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={'Ngày xuất lô'}
                name={'dateRange'}
              >
                <RangePicker
                  id={'export-date-picker'}
                  placeholder={["Từ", "Đến"]}
                  format={'DD/MM/YYYY'}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type={'primary'} htmlType={'submit'}>
                  Tìm kiếm
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className={'mt-4'}>
          <TableContent
            bordered
            loading={isLoading}
            rowKey="id"
            scroll={{ x: 'max-content' }}
            locale={{ emptyText: 'Không tìm thấy kết quả' }}
            totalRecord={dataGet(eSimData, 'rowCount', 0)}
            dataSource={dataGet(eSimData, 'results', [])}
            columns={columns}
            isShowCheckBox={true}
            rowSelection={rowSelection}
            onSort={(sort: ISortColumn) => {
              const params = parseQueryString(location.search);
              setFilterParams({
                ...params,
                pageIndex: params.pageIndex ?? 1,
                pageSize: params.pageSize ?? 10,
              })
            }}
          />
        </div>

        <div className={'flex justify-center'}>
          <Button
            className={'ml-1'}
            onClick={onClickExportExcel}
          >
            Kết xuất file
          </Button>
        </div>

        {isOpenQrCode && <ModalPreviewQrCode
          open={isOpenQrCode}
          setIsOpenQrCode={setIsOpenQrCode}
          isQrCodeActive={isQrCodeActive}
          selectedQrCode={selectedQrCode}
          setSelectedQrCode={setSelectedQrCode}
        />}
      </div>
    </>
  );
}