import { ILocation } from 'src/shared/interface/location.interface';
import request from '../request';

export const getAllLocation = async (param?: any) => {
  const url = '/api/location';
  const res = await request<ILocation[]>('get', url, param);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getAllIssuePlace = async (param?: any) => {
  const url = '/api/issue-place/get-all';
  const res = await request<ILocation[]>('get', url, param);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getAllNationality = async () => {
  const url = '/api/nationality/get-all';
  const res = await request<ILocation[]>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
