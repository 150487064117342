import { Button, Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import { useState } from 'react';
import { reserveNumber } from 'src/api/connection';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { ConnectionType, documentTypeToText } from 'src/shared/enum/connection.enum';
import { formatDate, formatDateTime } from 'src/utils';
import { IPhoneInfo, IUserProfile } from 'src/shared/interface/connection';
import { useNavigate } from 'react-router-dom';
import ModalConfirmConnect from '../../ModalConfirmConnect';

type Props = {
  userProfile?: IUserProfile;
  phoneInfo?: IPhoneInfo;
};

const UserInforForm = (props: Props) => {
  const { userProfile, phoneInfo } = props;
  const [form] = Form.useForm();
  const [isOpenConnectModal, setIsOpenConnectModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConnectFail, setIsConnectFail] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleConnection = (e: any) => {
    e.preventDefault();
    setIsOpenConnectModal(true);
  };

  const handleCancelConnectModal = () => {
    setIsOpenConnectModal(false);
    if (isConnectFail) {
      handleReset();
    }
  };

  const handleReset = async () => {
    setIsLoading(true);
    try {
      const response = await reserveNumber(phoneInfo?.phoneNumber, phoneInfo?.pool);

      if (response) {
        navigate(0);
      }
    } catch (error) {
      console.log(error);
      navigate(0);
    }
  };

  return (
    <>
      <Form form={form} onFinish={handleConnection}>
        <BoxWithLabel title='Thông tin người sử dụng'>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item labelCol={{ span: 8 }} label='ID hồ sơ'>
                {userProfile?.id}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='ID hồ sơ DN'>
                {userProfile?.enterpriseId}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Tên DN'>
                {userProfile?.enterpriseName}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Họ và tên'>
                {userProfile?.userName}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Ngày sinh'>
                {userProfile?.birthDay && formatDate(userProfile?.birthDay)}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Giới tính'>
                {userProfile?.gender === 0 ? 'Nam' : 'Nữ'}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Quốc tịch'>
                {userProfile?.nationalityString}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item labelCol={{ span: 8 }} label='Loại giấy tờ'>
                {userProfile?.typeIdNumber && documentTypeToText(userProfile?.typeIdNumber)}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Số giấy tờ'>
                {userProfile?.idNumber}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Ngày cấp'>
                {userProfile?.issueDate && formatDate(userProfile?.issueDate)}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Nơi cấp'>
                {userProfile?.issuePlaceString}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Địa chỉ thường trú'>
                {userProfile?.address}, {userProfile?.wardString}, {userProfile?.districtString},{' '}
                {userProfile?.provinceString}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Số lượng TB active'>
                {userProfile?.countSimActive}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Trạng thái hoạt động'>
                {userProfile?.isActive ? 'Active' : 'Inactive'}
              </Form.Item>
              <Form.Item labelCol={{ span: 8 }} label='Trạng thái hồ sơ'>
                {userProfile?.statusString}
              </Form.Item>
            </Col>
          </Row>
        </BoxWithLabel>
        <div className='my-10'></div>
        <BoxWithLabel title='Hình ảnh'>
          <div className='flex gap-2 text-center w-full justify-between'>
            <div className='flex-1 w-[25%] max-w-[250px] relative pb-14 flex items-center justify-center'>
              <img className='max-w-full' src={userProfile?.frontImage} alt='Mặt trước CMND'></img>
              <p className='absolute left-0 bottom-0 w-full text-center'>Mặt trước CMND</p>
            </div>
            <div className='flex-1 w-[25%] max-w-[250px] relative pb-14 flex items-center justify-center'>
              <img className='max-w-full' src={userProfile?.backImage} alt='Mặt sau CMND'></img>
              <p className='absolute left-0 bottom-0 w-full text-center'>Mặt sau CMND</p>
            </div>
            <div className='flex-1 w-[25%] max-w-[250px] relative pb-14 flex items-center justify-center'>
              <img className='max-w-full' src={userProfile?.signature} alt='Chữ ký'></img>
              <p className='absolute left-0 bottom-0 w-full text-center'>Chữ ký</p>
            </div>
            <div className='flex-1 w-[25%] max-w-[250px] relative pb-14 flex items-center justify-center'>
              <img className='max-w-full' src={userProfile?.selfieImage} alt='Chân dung'></img>
              <p className='absolute left-0 bottom-0 w-full text-center'>Chân dung</p>
            </div>
          </div>
        </BoxWithLabel>
        <div className='flex gap-5 my-5'>
          <Button
            type='primary'
            htmlType='button'
            onClick={handleConnection}
            disabled={!userProfile}
          >
            Đấu nối
          </Button>
          <Button onClick={handleReset}>Làm mới</Button>
        </div>
      </Form>
      <ModalConfirmConnect
        connectionType={ConnectionType.INDIVIDUAL}
        isOpen={isOpenConnectModal}
        handleCancel={handleCancelConnectModal}
        setIsConnectFail={setIsConnectFail}
        userProfile={userProfile}
        phoneInfo={phoneInfo}
      />
    </>
  );
};

export default UserInforForm;
