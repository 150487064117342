import { Button, Image, Modal, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import styled from 'styled-components';

const StyledPdfContainer = styled.div`
  height: 100%;

  .react-pdf__Page__canvas {
    margin: 0 auto;
  }

  .react-pdf__Page__textContent {
    height: 100% !important;
    border: 1px solid #ddd;
    box-shadow: 5px 5px 5px 1px #eee;
    border-radius: 5px;
  }
`;

const StyledModal = styled(Modal)`
  top: 1.5rem;
  .ant-modal-body {
    padding: 1.5rem;
  }
`;

interface DataProps {
  pdf_source: string;
  img_url: string;
}

function Contract({ name, data }: { name: string; data?: DataProps }) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const onDownloadClick = () => {
    if (data?.pdf_source) {
      fetch(data.pdf_source).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = `${name}.pdf`;
          alink.click();
        });
      });
    }
  };

  return (
    <React.Fragment>
      {isModalOpen && (
        <StyledModal
          title={name}
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null}
          width='70%'
        >
          <StyledPdfContainer>
            <Document file={data?.pdf_source} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </StyledPdfContainer>
        </StyledModal>
      )}

      {data?.pdf_source ? (
        <Space direction={'vertical'} align={'center'}>
          <Typography.Text strong>{name}</Typography.Text>
          <div className={'h-[200px] overflow-y-hidden cursor-pointer'}>
            <Image
              preview={false}
              src={data.img_url}
              alt={name}
              onClick={() => setIsModalOpen(true)}
            />
          </div>
          <Button
            type={'link'}
            size={'small'}
            className='contract_download'
            onClick={onDownloadClick}
          >
            Tải về
          </Button>
        </Space>
      ) : null}
    </React.Fragment>
  );
}

export default Contract;
