import { Button, Col, message, Modal, ModalProps, Radio, Row, Spin } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { doPayment, getOrderDetail } from 'src/api/changeSim';
import { getBalance } from 'src/api/localpay';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { store } from 'src/redux';
import { useAppDispatch } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { resetModalState } from 'src/redux/slice/modal.store';
import { OrderSim, PaymentInput } from 'src/shared/interface/changeSim.interface';
import { formatDateTime, formatMoney } from 'src/utils';
import { simTypeToText } from 'src/utils/sim.enum';
import { setChangeSimState } from '../../../redux/slice/changeSimSlice';
interface ChangeSimInfoModal extends ModalProps {
  selectedChangeSim: string;
}

function ChangeSimInfoModal({ selectedChangeSim }: ChangeSimInfoModal) {
  const [openPayment, setOpenPayment] = useState(false);
  const userStore = localStorage.getItem('merchantInfo');
  let merchantInfo: any;
  if (userStore !== null) {
    try {
      merchantInfo = JSON.parse(userStore);
    } catch (error) {
      console.log(error);
    }
  }
  const [data, setData] = useState<OrderSim | null>(null);
  const [balance, setBalance] = useState<string>('');
  const [countEsimValid, setCountEsimValid] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchSimDetail(selectedChangeSim);
  }, [selectedChangeSim]);

  const fetchSimDetail = async (selectedChangeSim: string) => {
    try {
      setIsLoading(true);
      Promise.all([getOrderDetail(selectedChangeSim), getBalance()]).then((res) => {
        if (res && res[0]) {
          setData(res[0]);
          setCountEsimValid(res[0]?.countSerial - res[0]?.quantityFailed);
        }
        if (res && res[1]) {
          setBalance(res && res[1].balance);
        }
        setIsLoading(false);
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const payment = async () => {
    setOpenPayment(true);
    const data: PaymentInput = {
      accountNumber: merchantInfo.payAccountId,
      orderId: selectedChangeSim,
      type: 3,
      distibutorId: merchantInfo.merchantId,
      description: '',
      updatedBy: merchantInfo.merchantName ?? '',
    };
    try {
      const resPayment = await doPayment(data);
      if (resPayment) {
        dispatch(resetModalState());
        message.success('Thanh toán thành công');
      } else {
        dispatch(resetModalState());
        message.error('Thanh toán thất bại');
      }
      store.dispatch(
        setGlobalState({
          isOpenPayment: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setChangeSimState({
          isFetchDetailChangeSim: true,
        }),
      );
    }
  };
  return (
    <Fragment>
      <Spin spinning={isLoading}>
        <div className='flex justify-end'>
          <button
            onClick={() => dispatch(resetModalState())}
            className=' text-3xl cursor-pointer border-transparent bg-transparent'
          >
            <RxCross2 />
          </button>
        </div>
        <BoxWithLabel title='Thông tin thanh toán' classNames='mb-[1.5rem]'>
          <Row gutter={24} className='mb-[1rem]'>
            <Col span='12'>
              <Row>
                <Col span='12'>Mã giao dịch</Col>
                <Col span='12'>{data?.transactionId} </Col>
                <Col span='12'>Ngày thanh toán</Col>
                <Col span='12'>{data?.createdAt ? formatDateTime(new Date(Date.now())) : ''}</Col>
                <Col span='12'>Diễn giải</Col>
                <Col span='12'>Thanh toán đơn hàng đổi sim</Col>
              </Row>
            </Col>
            <Col span='12'>
              <Row>
                <Col span='12'>Tình trạng</Col>
                <Col span='12'>{data?.transactionStatusString}</Col>
                <Col span='12'>Người thanh toán</Col>
                <Col span='12'> {merchantInfo.merchantName}</Col>
                <Col span='12'>Đơn hàng tham chiếu</Col>
                <Col
                  span='12'
                  className='underline text-blue-700 cursor-pointer'
                  onClick={() => dispatch(resetModalState())}
                >
                  {data?.id}
                </Col>
              </Row>
            </Col>
          </Row>
          <p className='font-bold mb-[0.5rem]'>Chi tiết giao dịch:</p>
          <div className='normal-table'>
            <table width='100%' className='normal-table'>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Số lượng</th>
                  <th>Mã hàng</th>
                  <th>Thành tiền</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{countEsimValid ?? 0}</td>
                  <td>{simTypeToText(parseInt(data?.simType ?? ''))}</td>
                  <td>{formatMoney(data?.toTalAmount ?? 0)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </BoxWithLabel>
        <BoxWithLabel title='Hình thức thanh toán' classNames='mb-[1.5rem]'>
          <Radio checked className='mb-[0.5rem]'>
            Localpay
          </Radio>
          <Row gutter={24}>
            <Col span='4'>LocalPay ID:</Col>
            <Col span='4'>{merchantInfo.payAccountId}</Col>
            <Col span='4'>Tên chủ tài khoản:</Col>
            <Col span='4'>{merchantInfo?.merchantName}</Col>
            <Col span='4'>Số dư:</Col>
            <Col span='4'>{formatMoney(parseInt(balance), 'đ')}</Col>
          </Row>
        </BoxWithLabel>
        {/* <Row gutter={24}>
        <Col span='3'>Người tạo:</Col>
        <Col span='6'>{data?.createdBy}</Col>
        <Col span='3'>Ngày tạo:</Col>
        <Col span='6'> {data?.createdAt ? formatDateTime(new Date(data?.createdAt)) : ''}</Col>
      </Row> */}
        <Row gutter={24} className='pt-[16px] flex-row-reverse'>
          <Button
            type='primary'
            className='mx-[6px] w-[120px]'
            htmlType='submit'
            disabled={
              data?.statusString === 'Paid' ||
              data?.statusString === 'Completed' ||
              data?.statusString === 'Uncompleted' ||
              data?.statusString === 'Processing'
            }
            onClick={() => {
              setOpenPayment(true);
            }}
          >
            Thanh toán
          </Button>
        </Row>
        <Modal
          open={openPayment}
          title='Xác nhận thanh toán'
          onOk={() => payment()}
          onCancel={() => {
            setOpenPayment(false);
          }}
          closable={false}
          centered
          maskClosable={false}
          okText='Đồng ý'
          cancelText='Không'
          // confirmLoading={loading}
        >
          <span>{`Bạn có chắc chắn thanh toán ${formatMoney(data?.toTalAmount ?? 0)} cho ${
            countEsimValid ?? 0
          } số thuê bao?`}</span>
        </Modal>
      </Spin>
    </Fragment>
  );
}

export default ChangeSimInfoModal;
