import { Col, Form, Row, Space, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { cookCode } from 'src/shared/common/userProfile';
import { EnterpriseStatusEnum } from 'src/shared/enum/business_profile';
import { dataGet, formatDate } from 'src/utils';

function BusinessInfo() {
  const form = Form.useFormInstance();
  useWatch('enterpriseName', form);

  const renderStatus = () => {
    switch (form.getFieldValue('enterpriseStatus')) {
      case EnterpriseStatusEnum.NEW:
        return <div>NEW</div>;
      case EnterpriseStatusEnum.WAITING:
        return <div>WAITING</div>;
      case EnterpriseStatusEnum.EDITED:
        return <div>EDITED</div>;
      case EnterpriseStatusEnum.APPROVED:
        return <div>APPROVED</div>;
      case EnterpriseStatusEnum.REJECTED:
        return <div>REJECTED</div>;

      default:
        return <div></div>;
    }
  };

  const renderListPackage = (item: any): any => {
    if (form.getFieldValue(item)) {
      const listPackage: any[] = JSON.parse(form.getFieldValue(item));

      return listPackage && listPackage.length > 0 ? (
        listPackage.map((item, index) => {
          if (item !== null) {
            return (
              <tr key={index}>
                {item && item?.simType && <td>{item?.simType + ''}</td>}
                <td>
                  {item && item.mbfCodes ? (
                      <Space size={'small'} wrap>
                        {dataGet(item, 'mbfCodes', []).map((code: string, index: number) => (
                            <Typography.Text key={index}>{code}</Typography.Text>
                        ))}
                      </Space>
                  ) : <></>}
                </td>
              </tr>
            );
          } else {
            return <></>
          }
        })
      ) : (
        <></>
      );
    }
  };
  return (
    <BoxWithLabel title='Thông tin doanh nghiệp' classNames='mt-4'>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item labelCol={{ span: 12 }} label='Mã hồ sơ DN' name={'id'}>
            {form.getFieldValue('id') ? cookCode(form.getFieldValue('id'), 'DN') : ''}
          </Form.Item>
          <Form.Item labelCol={{ span: 12 }} label='Tên DN' name={'enterpriseName'}>
            {form.getFieldValue('enterpriseName')}
          </Form.Item>
          <Form.Item
            labelCol={{ span: 12 }}
            label='Loại khách hàng'
            name={'enterpriseCustomerType'}
          >
            {form.getFieldValue('enterpriseCustomerType')}
          </Form.Item>
          <Form.Item
            label='Số giấy chứng nhận pháp nhân'
            labelCol={{ span: 12 }}
            name='enterpriseTaxCode'
            className='col-span-6'
          >
            {form.getFieldValue('enterpriseTaxCode')}
          </Form.Item>
          <Form.Item
            label='Số giấy phép KD'
            labelCol={{ span: 12 }}
            name='foundedPermNo'
            className='col-span-6'
          >
            {form.getFieldValue('foundedPermNo')}
          </Form.Item>
          <div className='grid grid-cols-12'>
            <Form.Item
              label='Số lượng thuê bao'
              labelCol={{ span: 12 }}
              name='enterpriseTotalPhoneNumbers'
              className='col-span-6'
            >
              {form.getFieldValue('enterpriseTotalPhoneNumbers')}
            </Form.Item>
            <Form.Item
              label='Số lượng user'
              labelCol={{ span: 12 }}
              className='col-span-6'
              name='enterpriseTotalUsers'
            >
              {form.getFieldValue('enterpriseTotalUsers')}
            </Form.Item>
          </div>
          <Form.Item label='Loại DN' labelCol={{ span: 12 }} name='enterpriseType'>
            {form.getFieldValue('enterpriseType')}
          </Form.Item>
          <Form.Item
            label='Danh sách gói SIM vật lý'
            labelCol={{ span: 24 }}
            name='enterpriseSubscriptionPlan'
            className='block'
          >
            <div className='normal-table'>
              <table className='normal-table w-1/2'>
                <thead>
                <tr>
                  <th>Gói</th>
                </tr>
                </thead>
                <tbody>{renderListPackage('enterpriseSubscriptionPlan')}</tbody>
              </table>
            </div>
          </Form.Item>
          <Form.Item
              label='Danh sách gói ESIM'
              labelCol={{ span: 24 }}
              name='enterpriseSubscriptionPlanEsim'
              className='block'
          >
            <div className='normal-table'>
              <table className='normal-table w-1/2'>
                <thead>
                <tr>
                  <th>Gói</th>
                </tr>
                </thead>
                <tbody>{renderListPackage('enterpriseSubscriptionPlanEsim')}</tbody>
              </table>
            </div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className='grid grid-cols-12'>
            <Form.Item
              label='Cấp DN'
              labelCol={{ span: 12 }}
              name='enterpriseLevel'
              className='col-span-6'
            >
              {form.getFieldValue('enterpriseLevel')}
            </Form.Item>
            <Form.Item
              label='LCS account'
              labelCol={{ span: 12 }}
              name='localShopAccount'
              className='truncate col-span-6'
            >
              {form.getFieldValue('localShopAccount')}
            </Form.Item>
          </div>
          <Form.Item label='Ngày cấp GPKD' labelCol={{ span: 8 }} name='foundedPermDate'>
            {form.getFieldValue('foundedPermDate')
              ? formatDate(form.getFieldValue('foundedPermDate'))
              : ''}
          </Form.Item>
          <Form.Item label='Nơi cấp GPKD' labelCol={{ span: 8 }} name='enterpriseFoundingPermPlace'>
            {form.getFieldValue('enterpriseFoundingPermPlace')}
          </Form.Item>
          <Form.Item label='Tỉnh/ Thành phố' labelCol={{ span: 8 }} name='userProvince'>
            {form.getFieldValue('userProvince')}
          </Form.Item>
          <Form.Item label='Quận/ Huyện' labelCol={{ span: 8 }} name='userDistrict'>
            {form.getFieldValue('userDistrict')}
          </Form.Item>
          <Form.Item label='Phường/ Xã' labelCol={{ span: 8 }} name='userPrecinct'>
            {form.getFieldValue('userPrecinct')}
          </Form.Item>
          <Form.Item label='Địa chỉ' labelCol={{ span: 8 }} name='userStreetName'>
            {form.getFieldValue('userStreetName')}
          </Form.Item>
          <Form.Item label='Distributor ID' labelCol={{ span: 8 }} name='enterpriseDistributorId'>
            {form.getFieldValue('enterpriseDistributorId')}
          </Form.Item>
          <div className='grid grid-cols-12'>
            <Form.Item
              label='Mã giao dịch viên'
              labelCol={{ span: 12 }}
              name='enterpriseStaffId'
              className='col-span-6'
            >
              {form.getFieldValue('enterpriseStaffId')}
            </Form.Item>
            <Form.Item
              label='Tên giao dịch viên'
              labelCol={{ span: 12 }}
              name='enterpriseStaffName'
              className='col-span-6'
            >
              {form.getFieldValue('enterpriseStaffName')}
            </Form.Item>
          </div>
          <div className='grid grid-cols-12'>
            <Form.Item
              label='Trạng thái'
              labelCol={{ span: 12 }}
              name='enterpriseStatus'
              className='col-span-6'
            >
              {renderStatus()}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </BoxWithLabel>
  );
}

export default BusinessInfo;
