import { Button, Form, Input, message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkPickedSerialSim, reserveNumber } from 'src/api/connection';
import { PickedSerialSim } from 'src/shared/interface/connection';
import {formatPhoneNumber, formatPhoneNumberV2} from 'src/utils';
import { simStatusToText, simTypeToText } from 'src/utils/sim.enum';
import ModalConfirmConnect from '../../ModalConfirmConnect';
import ModalReChooseNumber from '../../ModalReChooseNumber';
import { ConnectionType } from 'src/shared/enum/connection.enum';
import {getStatusEsimLotTxt} from "../../../../pages/management/esimManagement/utils";

const ConnectionEnterprise = () => {
  const [form] = Form.useForm();
  const [serialNumber, setSerialNumber] = useState('');
  const [simData, setSimData] = useState<PickedSerialSim>();
  const [isVerify, setIsVerify] = useState<boolean>(false);
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  const [currNumber, setCurrNumber] = useState<string>();
  const [isOpenNumberModal, setIsOpenNumberModal] = useState<boolean>(false);
  const [isOpenConnectModal, setIsOpenConnectModal] = useState<boolean>(false);
  const [_, setIsLoading] = useState<boolean>(false);
  const [isConnectFail, setIsConnectFail] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleCancel = () => {
    setIsOpenNumberModal(false);
  };

  const handleCancelConnectModal = () => {
    setIsOpenConnectModal(false);
    if (isConnectFail) {
      handleReset();
    }
  };

  const handleReset = async () => {
    setIsLoading(true);
    try {
      const response = await reserveNumber(currNumber, simData?.pool);

      if (response) {
        navigate(0);
      }
    } catch (error) {
      console.log(error);
      navigate(0);
    }
  };

  const handleCheckSerial = async () => {
    if (!serialNumber || serialNumber.length < 16 || !!simData || isVerify) {
      return;
    }

    setIsVerify(true);

    try {
      const respon = await checkPickedSerialSim({
        distributorId: JSON.parse(merchantInfo as string)?.merchantId,
        serialNumber: serialNumber,
      });

      if (respon) {
        setSimData(respon);
        setCurrNumber(respon?.phoneNumber);
        setIsVerify(false);
      }
    } catch (error: any) {
      setIsVerify(false);
    }
  };

  const handleInputChange = (e: any) => {
    if (e.target.value.length <= 20) {
      form.setFieldValue('serialNumber', e.target.value);
      setSerialNumber(e.target.value);
    } else {
      form.setFieldValue('serialNumber', e.target.value.substring(0, 20));
      setSerialNumber(e.target.value.substring(0, 20));
    }
    e.preventDefault();
  };

  const handleOk = async (selectedNumber: string) => {
    try {
      setIsOpenNumberModal(false);
      setIsLoading(true);

      const response = await reserveNumber(currNumber, simData?.pool);

      if (response) {
        setCurrNumber(selectedNumber);
      }
    } catch (error) {
      message.error('Đá số thất bại , xin vui lòng thử lại!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBtnReChooseClick = () => {
    setIsOpenNumberModal(true);
  };

  return (
    <>
      <div className='flex flex-col gap-5 mb-6'>
        <div className='flex items-center gap-3'>
          <Form.Item
            name='serialNumber'
            label={<span className='font-semibold'>Số SIM serial</span>}
            className='my-0'
            rules={[
              {
                min: 16,
                message: 'Vui lòng nhập đủ số serial',
              },
            ]}
          >
            <Input
              type='number'
              maxLength={20}
              onChange={(e) => handleInputChange(e)}
              onKeyDown={(evt: any) => {
                if (!evt.ctrlKey && !evt.metaKey) {
                  if (
                    evt.key === 'e' ||
                    evt.key === '+' ||
                    evt.key === '-' ||
                    (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                    Number(evt.key) > 9 ||
                    Number(evt.key) < 0
                  ) {
                    evt.preventDefault();
                  } else if (evt.target.value?.length >= 20 && evt.key !== 'Backspace') {
                    evt.preventDefault();
                  }
                }
              }}
              disabled={!!simData}
              onPressEnter={handleCheckSerial}
              style={{ minWidth: '200px' }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              onClick={handleCheckSerial}
              disabled={!serialNumber || serialNumber.length < 16 || !!simData || isVerify}
            >
              Kiểm tra
            </Button>
          </Form.Item>
        </div>
        {simData && (
          <>
            <div className='flex items-center gap-10'>
              <p className='my-0 font-semibold'>
                Loại:{' '}
                <span className='font-normal'>{simData && simTypeToText(simData?.simType)}</span>
              </p>
              <p className='my-0 font-semibold'>
                NPP: <span className='font-normal'>{simData?.distributorName}</span>
              </p>
              <p className='my-0 font-semibold'>
                Trạng thái:{' '}
                <span className='font-normal'>
                  {simData?.status && getStatusEsimLotTxt(simData?.status?.toString())}
                </span>
              </p>
            </div>
            <p className='flex items-center m-0'>
              <span className='block min-w-[150px] font-semibold'>Mã gói: </span>
              <span>{simData?.mbfCode}</span>
            </p>
            <div className='mb-6 flex gap-10 items-center'>
              <p className='my-0 min-w-[300px] flex'>
                <span className='block min-w-[150px] font-semibold'>Số thuê bao: </span>
                <span>{currNumber && formatPhoneNumberV2(currNumber)}</span>
              </p>
              <Button onClick={handleBtnReChooseClick}>Chọn lại số</Button>
            </div>
            <div className='flex gap-5 my-5'>
              <Button type='primary' onClick={() => setIsOpenConnectModal(true)}>
                Đấu nối
              </Button>
              <Button onClick={handleReset}>Làm mới</Button>
            </div>
          </>
        )}
      </div>
      <ModalReChooseNumber
        isOpen={isOpenNumberModal}
        onCancel={handleCancel}
        onOk={handleOk}
        pool={simData?.pool}
      />
      <ModalConfirmConnect
        isOpen={isOpenConnectModal}
        handleCancel={handleCancelConnectModal}
        setIsConnectFail={setIsConnectFail}
        connectionType={ConnectionType.ENTERPRISE}
        userProfile={{
          createdBy: JSON.parse(merchantInfo as string)?.merchantName,
        }}
        phoneInfo={{
          phoneNumber: currNumber || '',
          serialNumber: serialNumber,
          pool: simData?.pool || 0,
          distributorId: JSON.parse(merchantInfo as string)?.merchantId,
          mbfCode: simData?.mbfCode || '',
          simType: simData?.simType
        }}
      />
    </>
  );
};

export default ConnectionEnterprise;
