import { Col, Form, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { formatDate } from 'src/utils';

function TraderInfo() {
  const form = Form.useFormInstance();
  useWatch('referralName', form);

  const renderType = () => {
    switch (form.getFieldValue('referralIdType')) {
      case '0':
        return <div>CMND/CCCD</div>;
      case '1':
        return <div>PASSPORT</div>;

      default:
        return '';
    }
  };

  return (
    <BoxWithLabel title='Thông tin người đến giao dịch' classNames='mt-4'>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item labelCol={{ span: 12 }} label='Họ và tên' name={'referralName'}>
            {form.getFieldValue('referralName')}
          </Form.Item>
          <Form.Item labelCol={{ span: 12 }} label='Loại giấy tờ' name={'referralIdType'}>
            {renderType()}
          </Form.Item>

          <Form.Item label='Số giấy tờ' labelCol={{ span: 12 }} name='referralIdNumber'>
            {form.getFieldValue('referralIdNumber')}
          </Form.Item>
          <Form.Item label='Ngày cấp' labelCol={{ span: 12 }} name='referralIssueDate'>
            {form.getFieldValue('referralIssueDate')
              ? formatDate(form.getFieldValue('referralIssueDate'))
              : ''}
          </Form.Item>
          <Form.Item label='Nơi cấp' labelCol={{ span: 12 }} name='referralIssuePlace'>
            {form.getFieldValue('referralIssuePlace')}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='Số giấy giới thiệu'
            labelCol={{ span: 12 }}
            name='referralCoverLetterNo'
          >
            {form.getFieldValue('referralCoverLetterNo')}
          </Form.Item>
          <Form.Item
            label='Ngày cấp giấy giới thiệu'
            labelCol={{ span: 12 }}
            name='referralCoverLetterDate'
          >
            {form.getFieldValue('referralCoverLetterDate')
              ? formatDate(form.getFieldValue('referralCoverLetterDate'))
              : ''}
          </Form.Item>
          <Form.Item label='Email' labelCol={{ span: 12 }} name='referralEmail'>
            {form.getFieldValue('referralEmail')}
          </Form.Item>
          <Form.Item label='Số điện thoại' labelCol={{ span: 12 }} name='referralPhoneNumber'>
            {form.getFieldValue('referralPhoneNumber')}
          </Form.Item>
          <Form.Item label='Liên hệ khác' labelCol={{ span: 12 }} name='referralOtherContact'>
            {form.getFieldValue('referralOtherContact')}
          </Form.Item>
        </Col>
      </Row>
    </BoxWithLabel>
  );
}

export default TraderInfo;
