import { Button, Form, message, Tabs } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllIssuePlace } from 'src/api/location';
import {
  approvedUserProfile,
  createUserProfile,
  getDetailUserProfile,
  insertHistoryEditActiveUserProfile,
  updateActiveUserProfile,
  updateUserProfile,
} from 'src/api/user_profile';
import ModalConfirm from 'src/components/modals/ModalComfirm';
import { useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { setUserProfileState } from 'src/redux/slice/userProfile';
import {
  DEFAULT_USER_PROFILE,
  handleCheckNewProfile,
  handleCheckUpdateProfile,
  handleMapFormValueUserProfile,
  IDTypeCodeParseNumber,
} from 'src/shared/common/userProfile';
import { ProfileEnum } from 'src/shared/enum/user_profile';
import {
  InsertActionEditUserProfileBody,
  UserProfileResponse,
  ValueCode,
} from 'src/shared/interface/user_profile';
import { parseQueryString } from 'src/utils';
import AttachmentUserProfile from '../../components/Attachment';
import TabHistory from '../../components/history/TableHistory';
import UserInfo from '../../components/UserInfo';

function UserProfileDetailPage() {
  const [form] = useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = parseQueryString(location.search);
  const { openTabs } = useAppSelector((state) => state.global);
  const { isRefresh, userProfile } = useAppSelector((state) => state.userProfile);
  const nationalityWatch = useWatch('nationality', form);

  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  const watchIsActive = useWatch('isActive', form);

  const [statusUserProfile, setStatusUserProfile] = useState<string>(ProfileEnum.New);
  const [openModalConfirmApproved, setOpenModalConfirmApproved] = useState(false);
  const [openModalConfirmActive, setOpenModalConfirmActive] = useState(false);

  useEffect(() => {
    const fetchGetPackageAssigned = async () => {
      const issuePlaceData = await getAllIssuePlace();
      const res = await getDetailUserProfile(id);
      if (res && issuePlaceData) {
        const mapIssuePlace: ValueCode[] = issuePlaceData.map((item) => {
          return {
            value: item.code.toString(),
            label: item.name,
          };
        });
        dispatch(setUserProfileState({ issuePlaceData: mapIssuePlace, userProfile: res }));
        handleMapFormValueUserProfile(form, res, issuePlaceData);
        setStatusUserProfile(res.statusString ? res.statusString : '');
      }
    };
    id && fetchGetPackageAssigned();
  }, [id, isRefresh]);

  const handleSubmit = async () => {
    if (id) {
      handleUpdateUser();
    } else {
      handleCreateUser();
    }
  };

  const handleUpdateUser = async () => {
    const isVi = nationalityWatch?.value === 'VNM';
    let dataZone = {};
    if (!isVi) {
      dataZone = {
        province: {
          label: 'province',
          value: 'HCM',
        },
        district: {
          label: 'district',
          value: 'TBI',
        },
        ward: {
          label: 'ward',
          value: '0004',
        },
      };
    }

    const data: UserProfileResponse = {
      ...form.getFieldsValue(),
      enterpriseName: JSON.parse(merchantInfo as string)?.merchantName ?? '',
      typeIdNumber: IDTypeCodeParseNumber(form.getFieldValue('typeIdNumber')),
      gender:
        form.getFieldValue('gender') === 'Nam'
          ? '0'
          : form.getFieldValue('gender') === 'Nữ'
          ? '1'
          : form.getFieldValue('gender'),
      ...dataZone,
    };

    try {
      if (userProfile) {
        const dataBody = await handleCheckUpdateProfile(data, userProfile);

        if (dataBody?.note && dataBody.data) {
          const res = await updateUserProfile(
            dataBody?.data,
            JSON.parse(merchantInfo as string)?.merchantId ?? '',
          );
          if (res) {
            message.success('Cập nhật hồ sơ người sử dụng thành công!');
            const insertAction: InsertActionEditUserProfileBody = {
              overFlowUseId: dataBody.data.id ?? '',
              content: dataBody.note ?? '',
            };
            insertHistoryEditActiveUserProfile(insertAction);
            dispatch(setUserProfileState({ isRefresh: !isRefresh }));
            setStatusUserProfile(ProfileEnum.New);
          }
        } else {
          message.warning('Vui lòng chỉnh sửa trước khi lưu!');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateUser = async () => {
    const isVi = nationalityWatch?.value === 'VNM';
    let dataZone = {};
    if (!isVi) {
      dataZone = {
        province: {
          label: 'province',
          value: 'HCM',
        },
        district: {
          label: 'district',
          value: 'TBI',
        },
        ward: {
          label: 'ward',
          value: '0004',
        },
      };
    }

    const data: UserProfileResponse = {
      ...form.getFieldsValue(),
      ...dataZone,
    };

    const dataBody = handleCheckNewProfile(data);

    const res = await createUserProfile(
      dataBody,
      JSON.parse(merchantInfo as string)?.merchantId ?? '',
    );
    if (res) {
      message.success('Tạo mới hồ sơ người sử dụng thành công!');
      const newPanes = openTabs.filter((pane) => pane.key !== 'user-profile/create');

      dispatch(
        setGlobalState({
          activeKeyMenu: `user-profile/detail?id=${res.id}`,
        }),
      );
      if (!newPanes.find((tab) => tab.key === 'user-profile/detail')) {
        dispatch(
          setGlobalState({
            openTabs: [
              ...newPanes,
              {
                label: 'Chi tiết hồ sơ cá nhân ' + res.id,
                children: '',
                key: `user-profile/detail?id=${res.id}`,
                closable: true,
              },
            ],
          }),
        );
      }
      navigate(`/management/user-profile/detail?id=${res.id}`);
    }
  };

  const handleApproved = async () => {
    try {
      const res = await approvedUserProfile(
        { id: id },
        JSON.parse(merchantInfo as string)?.merchantId ?? '',
      );
      if (res) {
        message.success('Submit thành công!');
        setOpenModalConfirmApproved(false);
        dispatch(setUserProfileState({ isRefresh: !isRefresh }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleActive = async () => {
    try {
      const res = await updateActiveUserProfile(
        { id: id, isActive: watchIsActive === 'Active' ? false : true },
        JSON.parse(merchantInfo as string)?.merchantId ?? '',
      );
      if (res) {
        message.success('Cập nhật trạng thái thành công!');
        setOpenModalConfirmApproved(false);
        dispatch(setUserProfileState({ isRefresh: !isRefresh }));
        setOpenModalConfirmActive(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckSubmit = async () => {
    try {
      await form.validateFields();

      setOpenModalConfirmApproved(true);
    } catch (error) {
      /* empty */
    }
  };
  return (
    <>
      <Tabs
        type='card'
        items={[
          {
            label: `Thông tin bộ ID`,
            key: '1',
            children: (
              <Form
                form={form}
                onFinish={handleSubmit}
                initialValues={DEFAULT_USER_PROFILE}
                disabled={
                  statusUserProfile === 'clickEdit' ||
                  (statusUserProfile === ProfileEnum.New && !id)
                    ? false
                    : true
                }
              >
                <UserInfo statusUserProfile={statusUserProfile} />
                <AttachmentUserProfile />
                <div className='absolute top-[-50px] right-0'>
                  <div className='row-button justify-end mb-[1.5rem]'>
                    <Button
                      type='primary'
                      onClick={() => {
                        setOpenModalConfirmActive(true);
                      }}
                      disabled={
                        (statusUserProfile === ProfileEnum.Approved &&
                          watchIsActive === 'Active') ||
                        (statusUserProfile === ProfileEnum.Rejected &&
                          watchIsActive === 'Active') ||
                        (statusUserProfile === ProfileEnum.New && watchIsActive === 'Active')
                          ? false
                          : true
                      }
                    >
                      Inactive
                    </Button>
                    <Button
                      type='primary'
                      onClick={() => {
                        setOpenModalConfirmActive(true);
                      }}
                      disabled={
                        statusUserProfile === ProfileEnum.Approved && watchIsActive === 'Inactive'
                          ? false
                          : true
                      }
                    >
                      Active
                    </Button>
                    <Button
                      type='primary'
                      onClick={handleCheckSubmit}
                      disabled={
                        (statusUserProfile === ProfileEnum.New && id) ||
                        statusUserProfile === ProfileEnum.EDIT ||
                        statusUserProfile === ProfileEnum.Rejected
                          ? false
                          : true
                      }
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={() => {
                        setStatusUserProfile('clickEdit');
                      }}
                      disabled={
                        (statusUserProfile === ProfileEnum.New && watchIsActive === 'Inactive') ||
                        statusUserProfile === ProfileEnum.EDIT ||
                        statusUserProfile === ProfileEnum.Rejected ||
                        (statusUserProfile === ProfileEnum.Approved && watchIsActive === 'Active')
                          ? false
                          : true
                      }
                    >
                      Sửa
                    </Button>
                    <Button
                      type='primary'
                      htmlType='submit'
                      disabled={
                        statusUserProfile === 'clickEdit' ||
                        (statusUserProfile === ProfileEnum.New && !id)
                          ? false
                          : true
                      }
                    >
                      Lưu
                    </Button>
                  </div>
                </div>
              </Form>
            ),
          },
          {
            label: `Lịch sử thay đổi`,
            key: '2',
            children: <TabHistory />,
          },
        ]}
      />
      <ModalConfirm
        open={openModalConfirmApproved}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Submit</div>
            <p>Bạn có muốn submit bộ ID cá nhân này không?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleApproved} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmApproved(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmActive}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>
              {watchIsActive === 'Inactive' ? 'Active' : 'Inactive'}
            </div>
            <p>
              Bạn có muốn {watchIsActive === 'Inactive' ? 'Active' : 'Inactive'} hồ sơ NSD này
              không?
            </p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleActive} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmActive(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
}

export default UserProfileDetailPage;
