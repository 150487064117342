import {Button, Modal} from "antd";

interface ModalCheckEsimExpiredProps {
  isOpen: boolean,
  setIsOpenModal: any
  listEsimExpired?:  any,
  listEsimValid?:  any,
  onClickConfirm: () => void,
  onClickCancel: () => void
}
export default function ModalCheckEsimExpired({isOpen, setIsOpenModal, listEsimExpired, listEsimValid, onClickConfirm, onClickCancel}: ModalCheckEsimExpiredProps) {
  return <>
    <Modal
      open={isOpen}
      onCancel={() => setIsOpenModal(false)}
      title={'Thông báo'}
      footer={false}
      maskClosable={false}
    >
      <div
        className={'py-5'}
      >
        <div>
          {'Đơn hàng có eSIM'}{' '}{<span className={'font-semibold'}>{listEsimExpired?.join(', ')}</span>}{' '}{'đã hết hạn, bạn có muốn tiếp tục thanh toán và bỏ qua các eSIM này?'}
        </div>
        <div className={'flex items-center justify-end mt-12'}>
          {listEsimValid?.length > 0 && <Button
            type='primary'
            onClick={onClickConfirm}
          >
            Xác nhận
          </Button>}
          <Button
            className={'ml-2'}
            onClick={onClickCancel}
          >
            Thoát
          </Button>
        </div>
      </div>
    </Modal>
  </>
}