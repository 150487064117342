import { Modal, ModalProps } from 'antd';
import { ReactNode } from 'react';

interface IConfirmModal {
  open: boolean;
  content: ReactNode;
  props: ModalProps;
  handleCancel(): void;
}
function ModalPreview({ open, content, handleCancel, props }: IConfirmModal) {
  return (
    <Modal
      open={open}
      maskClosable={true}
      footer={false}
      closable={false}
      {...props}
      onCancel={handleCancel}
    >
      {content}
    </Modal>
  );
}

export default ModalPreview;
