import { createSlice } from '@reduxjs/toolkit';
// import { LoginForm, LoginResponse, RegisterDataRequest, RegisterUser, RequestError } from "../../types/auth";
import { PayloadAction } from '@reduxjs/toolkit';
import { SimError, SimSuccess } from 'src/shared/interface/changeSim.interface';

export interface ChangeSimState {
  listSuccessStore: SimSuccess[] | [];
  listErrorsStore: SimError[] | [];
  isFetchDetailChangeSim: boolean;
}
const initialState: ChangeSimState = {
  listSuccessStore: [],
  listErrorsStore: [],
  isFetchDetailChangeSim: false
};
export const changeSimSlice = createSlice({
  name: 'changeSim',
  initialState,
  reducers: {
    setChangeSimState(state, action: PayloadAction<Partial<ChangeSimState>>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { actions: changeSimAction, reducer: changeSimReducer } = changeSimSlice;
export const { setChangeSimState } = changeSimSlice.actions;
