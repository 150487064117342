import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IListColumn } from 'src/@types/search-params';
import { getListHistory } from 'src/api/user_profile';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { InsertActionEditUserProfileBody } from 'src/shared/interface/user_profile';
import { parseQueryString } from 'src/utils';

function TabHistory() {
  const location = useLocation();
  const { id } = parseQueryString(location.search);
  const { isRefresh } = useAppSelector((state) => state.userProfile);

  const [data, setData] = useState<InsertActionEditUserProfileBody[]>([]);

  useEffect(() => {
    const fetchGetPackageAssigned = async () => {
      const res = await getListHistory(id);
      if (res) {
        setData(res);
      }
    };
    fetchGetPackageAssigned();
  }, [isRefresh]);

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    {
      name: 'Thời gian',
      fieldName: 'createdAt',
      isHideSort: false,
    },
    {
      name: 'Người thực hiện',
      fieldName: 'merchantId',
    },
    {
      name: 'Hành động',
      fieldName: 'action',
    },

    {
      name: 'Nội dung',
      fieldName: 'content',
      isHideSort: true,
    },
    {
      name: 'Hình ảnh',
      fieldName: 'imgUrl',
      isHideSort: true,
    },
  ];

  return (
    <TableContent
      bordered
      scroll={{ x: 'max-content' }}
      locale={{ emptyText: 'Không tìm thấy kết quả' }}
      dataSource={data ?? []}
      columns={columns}
      totalRecord={data?.length ?? 0}
      //   sortColumn={sortColumn}
      // onSort={(sort: ISortColumn) => {
      //   const pagination = parseQueryString(location.search);
      //   handleFormSearch({
      //     ...pagination,
      //     pageIndex: pagination.pageIndex ?? 1,
      //     pageSize: pagination.pageSize ?? 10,
      //     sortField: sort.fieldName,
      //     asc: sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? 'false' : undefined,
      //   });
      // }}
    />
  );
}

export default TabHistory;
