export enum IDTypeEnum {
  CMND = 'CMND',
  CCCD = 'CCCD',
  PASSPORT = 'Passport',
}
export enum IDTypeNumberEnum {
  CMND = '1',
  CCCD = '2',
  PASSPORT = '3',
}
export enum ProfileEnum {
  New = 'New',
  Waiting = 'WaitingForApproval',
  Approved = 'Approved',
  Rejected = 'Rejected',
  EDIT = 'Edited',
}
export enum ProfileBatchEnum {
  New = 'New',
  Waiting = 'WaitingForApproval',
  Completed = 'Completed',
  Uncompleted = 'Uncompleted',
}
