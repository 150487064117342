import { combineReducers } from '@reduxjs/toolkit';
import { globalReducer } from './slice/globalSlice';
import { modalReducer } from './slice/modal.store';
import { packageAssignedReducer } from './slice/packageAssigned';
import { changeSimReducer } from './slice/changeSimSlice';
import { connectionReducer } from './slice/connectionSlice';
import { userProfileReducer } from './slice/userProfile';
import { esimManagementReducer } from './slice/esimManagementSlice';

const rootReducer = combineReducers({
  global: globalReducer,
  modal: modalReducer,
  packageAssigned: packageAssignedReducer,
  changeSim: changeSimReducer,
  connection: connectionReducer,
  userProfile: userProfileReducer,
  esimManagement: esimManagementReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
