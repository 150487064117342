export const ucmCardTypeIds = {
  cmnd_cccd: 0,
  passport: 1,
};

export const mbfCardTypeIds = {
  cmnd_cccd: 1,
  passport: 2,
};

export enum TypeCardID {
  CMND = 1,
  CCCD = 2,
  Passport = 3,
}

export enum SubscriptionStatus {
  All = -1,
  BLOCK_1_WAY = 1,
  BLOCK_2_WAYS = 2,
  UNSUBSCRIPTION = 3,
  UNLOCK = 4,
}

export enum SimStatus {
  All = -1,
  ACTIVATION_SUCCESS = 4, //Inusage
  EXPIRED = 6,
}

export enum JointSubject {
  All = '-1',
  PERSONAL = 'personal',
  ENTERPRISE = 'enterprise',
}