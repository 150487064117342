import {useQuery} from '@tanstack/react-query';
import {getListEsim} from 'src/api/esimManagement';

export const eSimDataKeys = {
  all: () => ['esimData'] as const,
  list: (filters = {}) => [
    ...eSimDataKeys.all(), 'list', filters,
  ] as const,
};

export const useListEsim = (filters: any = {}, options = {}) => {
  return useQuery({
    queryKey: eSimDataKeys.list(filters),
    queryFn: () => getListEsim(filters),
    ...options,
  });
};