import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Row, Select, Tooltip } from 'antd';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IListColumn, ISortColumn, PaginationResponse } from 'src/@types/search-params';
import { getDetailBusinessProfile } from 'src/api/business_profile';
import { getAllIssuePlace, getAllNationality } from 'src/api/location';
import { getAllUserProfile } from 'src/api/user_profile';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { setUserProfileState } from 'src/redux/slice/userProfile';
import {
  cookCode,
  IDTypeStatusFilter,
  ProfileStatusFilter,
  StatusUserProfile,
  UserProfileStatusActiveFilter,
} from 'src/shared/common/userProfile';
import { IDTypeEnum } from 'src/shared/enum/user_profile';
import {
  UserProfileBodyData,
  UserProfileResponse,
  ValueCode,
} from 'src/shared/interface/user_profile';
import { exportExcel, formatDate, formatDateTime, parseQueryString } from 'src/utils';
import { useForm } from 'antd/lib/form/Form';

function UsersProfile() {
  const [form] = useForm();
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = location.search;
  const { openTabs } = useAppSelector((state) => state.global);
  const { issuePlaceData } = useAppSelector((state) => state.userProfile);
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');

  const [dataUserProfile, setDataUserProfile] = useState<PaginationResponse<UserProfileResponse>>();
  const [sortColumn, setSortColumn] = useState<ISortColumn>();
  const [nationalityData, setNationalityData] = useState<ValueCode[]>([]);

  useEffect(() => {
    const fetchBusinessProfile = async () => {
      const res = await getDetailBusinessProfile();
      if (res) {
        if (res.account_profile_values) {
          res.account_profile_values.forEach(
            (element: { table_attribute_field_name: string; value: any }) => {
              if (element.table_attribute_field_name === 'enterpriseName') {
                form.setFieldsValue({
                  enterpriseName: element.value ? element.value : '',
                  enterpriseDistributorId: element.value
                    ? cookCode(res.id, 'DN') + ' - ' + element.value
                    : '',
                });
              }
            },
          );
        }
      }
      const issuePlace = await getAllIssuePlace();
      fetchNationality();
      if (issuePlace) {
        const mapIssuePlace: ValueCode[] = issuePlace.map((item) => {
          return {
            value: item.code.toString(),
            label: item.name,
          };
        });
        dispatch(setUserProfileState({ issuePlaceData: mapIssuePlace }));
      }
    };
    fetchBusinessProfile();
  }, []);

  useEffect(() => {
    fetchListUserProfile();
    setSortColumn(undefined);
  }, [location.search]);

  const fetchNationality = async () => {
    const res = await getAllNationality();
    if (res) {
      const data = res.map((item: { code: any; name: any }) => {
        return { value: item.code, label: item.name };
      });
      setNationalityData(data);
    }
  };

  const fetchListUserProfile = async () => {
    const params = parseQueryString(
      location.search + `&EnterpriseId=${JSON.parse(merchantInfo as string)?.merchantId ?? ''} `,
    );
    const res = await getAllUserProfile(
      params,
      JSON.parse(merchantInfo as string)?.merchantId ?? '',
    );
    if (res) {
      setDataUserProfile(res);
    }
  };

  function handleExport() {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `danh_sach_HSNSD_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    const paramsExport = params
      ? params + `&Type=1&EnterpriseId=${JSON.parse(merchantInfo as string)?.merchantId ?? ''}`
      : `?Type=1&EnterpriseId=${JSON.parse(merchantInfo as string)?.merchantId ?? ''}`;

    exportExcel(
      process.env.REACT_APP_API_URL +
        '/api/user-profile/overflow-user-profile/export' +
        paramsExport,
    ).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
    });
  }

  function handleFormSearch(value: any) {
    let values = { ...value };
    values = {
      ...values,
      dateFrom:
        value.dateRange && value?.dateRange[0]
          ? value?.dateRange[0].format('YYYY-MM-DD')
          : undefined,
      dateTo:
        value.dateRange && value?.dateRange[1]
          ? value?.dateRange[1].format('YYYY-MM-DD')
          : undefined,
      dateRange: undefined,
    };
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        delete values[key];
      }
    });
    setSearchParams({ pageIndex: 1, pageSize: 10, ...values });
  }

  const handleDetailUserProfile = (userProfile: UserProfileResponse) => {
    if (userProfile) {
      dispatch(
        setGlobalState({
          activeKeyMenu: `user-profile/detail?id=${userProfile.id}`,
        }),
      );
      if (!openTabs.find((tab) => tab.key === `user-profile/detail?id=${userProfile.id}`)) {
        dispatch(
          setGlobalState({
            openTabs: [
              ...openTabs,
              {
                label: 'Chi tiết hồ sơ cá nhân ' + userProfile.id,
                children: '',
                key: `user-profile/detail?id=${userProfile.id}`,
                closable: true,
              },
            ],
          }),
        );
      }
      navigate(`/management/user-profile/detail?id=${userProfile.id}`);
    }
  };

  const renderIssuePlace = (record: UserProfileBodyData) => {
    const issuePlace = issuePlaceData?.find(
      (item) => item.value.toString() === record.issuePlace?.toString(),
    );
    const getName = nationalityData.find((item) => item.value === record.nationality);
    if (issuePlace) {
      return <div>{issuePlace?.label}</div>;
    } else {
      return getName ? getName.label : '';
    }
  };

  const renderIdNumber = (itemData: UserProfileResponse) => {
    switch (itemData.typeIdNumber + '') {
      case '1':
        return <div>{IDTypeEnum.CMND}</div>;
      case '2':
        return <div>{IDTypeEnum.CCCD}</div>;
      case '3':
        return <div>{IDTypeEnum.PASSPORT}</div>;

      default:
        return <div></div>;
    }
  };
  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    {
      name: 'ID cá nhân',
      fieldName: 'id',
      renderSpecialCard: (userProfile: UserProfileResponse) => (
        <button
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
          onClick={() => handleDetailUserProfile(userProfile)}
        >
          {userProfile.id ?? ''}
        </button>
      ),
    },
    {
      name: 'Số lô',
      fieldName: 'lotId',
      isHideSort: true,
    },
    {
      name: 'ID hồ sơ DN',
      fieldName: 'enterpriseId',
      isHideSort: true,
    },
    {
      name: 'Tên DN',
      fieldName: 'enterpriseName',
      isHideSort: true,
    },
    {
      name: 'Họ và tên',
      fieldName: 'userName',
      isHideSort: true,
      width: '10%',
      renderSpecialCard: (record: UserProfileResponse) => {
        return (
          <div className='w-[250px] truncate text-center'>
            <Tooltip placement='topLeft' title={<span>{record.userName}</span>}>
              <span>{record.userName}</span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      name: 'Ngày sinh',
      fieldName: 'birthDay',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileResponse) => {
        return <span>{record.birthDay ? formatDate(record.birthDay) : ''}</span>;
      },
    },
    {
      name: 'Giới tính',
      fieldName: 'gender',
      isHideSort: true,
      width: '5%',
      renderSpecialCard: (record: UserProfileResponse) => {
        return <span>{record.gender ? 'Nữ' : 'Nam'}</span>;
      },
    },
    {
      name: 'Quốc tịch',
      fieldName: 'nationality',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileResponse) => {
        return (
          <span>
            {record.issuePlace
              ? nationalityData?.find(
                  (item) => item.value.toString() === record.nationality?.toString(),
                )?.label ?? ''
              : ''}
          </span>
        );
      },
    },
    {
      name: 'Loại giấy tờ',
      fieldName: 'typeIdNumber',
      isHideSort: true,
      renderSpecialCard: renderIdNumber,
    },
    {
      name: 'Số giấy tờ',
      fieldName: 'idNumber',
      isHideSort: true,
    },
    {
      name: 'Ngày cấp',
      fieldName: 'issueDate',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileResponse) => {
        return <span>{record.issueDate ? formatDate(record.issueDate) : ''}</span>;
      },
    },
    {
      name: 'Nơi cấp',
      fieldName: 'issuePlace',
      isHideSort: true,
      width: '10%',
      renderSpecialCard: renderIssuePlace,
    },
    {
      name: 'Địa chỉ',
      fieldName: 'address',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileResponse) => {
        return (
          <div className='w-[200px] truncate text-center'>
            <Tooltip placement='topLeft' title={<span>{record.address}</span>}>
              <span>{record.address}</span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      name: 'Trạng thái hồ sơ',
      fieldName: 'statusString',
      isHideSort: true,
      renderSpecialCard: (record: UserProfileResponse) => {
        return <span>{record.statusString ? StatusUserProfile(record.statusString) : ''}</span>;
      },
    },
    {
      name: 'Trạng thái hoạt động',
      fieldName: 'isActive',
      renderSpecialCard: (record: UserProfileResponse) => {
        return <span>{record.isActive ? 'Active' : 'InActive'}</span>;
      },
    },
    {
      name: 'Ngày tạo',
      fieldName: 'createdAt',
      renderSpecialCard: (record: UserProfileResponse) => {
        return <span>{record.createdAt ? formatDateTime(record.createdAt) : ''}</span>;
      },
    },
    {
      name: 'Thao tác',
      fieldName: 'id',
      renderSpecialCard: (userProfile) => (
        <button
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
          onClick={() => handleDetailUserProfile(userProfile)}
        >
          Xem chi tiết
        </button>
      ),
      isHideSort: true,
    },
  ];

  function openCreateUserProfilePage() {
    dispatch(
      setGlobalState({
        activeKeyMenu: 'user-profile/create',
      }),
    );
    if (!openTabs.find((tab) => tab.key === 'user-profile/create')) {
      dispatch(
        setGlobalState({
          openTabs: [
            ...openTabs,
            {
              label: 'Tạo mới hồ sơ người sử dụng',
              children: '',
              key: 'user-profile/create',
              closable: true,
            },
          ],
        }),
      );
    }
    dispatch(setGlobalState({ order: undefined }));
    navigate(`/management/user-profile/create`);
  }

  return (
    <Fragment>
      <Form onFinish={handleFormSearch} layout='inline' form={form}>
        <Row className='gap-4'>
          <Form.Item name='Search'>
            <Input
              className='min-w-[480px]'
              prefix={<SearchOutlined />}
              placeholder='Tìm kiếm theo Mã ID cá nhân, số lô, họ và tên, số giấy tờ'
            />
          </Form.Item>
          {/* <Form.Item name='enterpriseId' label='ID hồ sơ DN'> */}
          <div className='flex gap-2'>
            <span className='flex items-center'>ID hồ sơ DN:</span>
            <Select
              showSearch
              className='min-w-[150px]'
              value={form.getFieldValue('enterpriseDistributorId')}
            />
          </div>
          {/* </Form.Item> */}
          <Form.Item name='typeIdNumber' label='Loại giấy tờ'>
            <Select
              showSearch
              className='min-w-[150px]'
              defaultValue={' '}
              options={IDTypeStatusFilter}
            ></Select>
          </Form.Item>
          <Form.Item name='isActive' label='Trạng thái hoạt động'>
            <Select
              showSearch
              className='min-w-[150px]'
              defaultValue={' '}
              options={UserProfileStatusActiveFilter}
            ></Select>
          </Form.Item>
          <Form.Item name='status' label='Trạng thái hồ sơ'>
            <Select
              showSearch
              className='min-w-[200px]'
              defaultValue={' '}
              options={ProfileStatusFilter}
            ></Select>
          </Form.Item>
          <Form.Item name='dateRange' label='Ngày tạo'>
            <DatePicker.RangePicker
              disabledDate={(current) => current && current >= moment().endOf('day')}
              placeholder={['từ', 'đến']}
              format={'DD/MM/YYYY'}
            />
          </Form.Item>
        </Row>

        <div className='row-button' style={{ justifyContent: 'flex-end', margin: '1.5rem 0' }}>
          <Button type='primary' htmlType='submit'>
            Tìm kiếm
          </Button>
          <Button onClick={openCreateUserProfilePage}>Tạo mới</Button>
          <Button onClick={handleExport}>Export</Button>
        </div>
      </Form>

      <TableContent
        bordered
        scroll={{ x: 'max-content' }}
        locale={{ emptyText: 'Không tìm thấy kết quả' }}
        dataSource={dataUserProfile?.results ?? []}
        columns={columns}
        totalRecord={dataUserProfile?.rowCount ?? 0}
        sortColumn={sortColumn}
        onSort={(sort: ISortColumn) => {
          const pagination = parseQueryString(location.search);
          handleFormSearch({
            ...pagination,
            pageIndex: pagination.pageIndex ?? 1,
            pageSize: pagination.pageSize ?? 10,
            sortField: sort.fieldName,
            asc: sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? 'false' : undefined,
          });
        }}
      />
    </Fragment>
  );
}

export default UsersProfile;
