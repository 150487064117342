import { IListColumn } from 'src/@types/search-params';
import TableContent from 'src/components/CTable';
import { IOverFlowOrderDetailResponse } from 'src/shared/interface/package_assigned';

interface InputError {
  dataFailed: IOverFlowOrderDetailResponse[];
}

function InputError({ dataFailed, ...props }: InputError) {
  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20 },
    { name: 'SIM serial', fieldName: 'serial' },
    { name: 'Mã lỗi', fieldName: 'code' },
    { name: 'Lý do', fieldName: 'message' },
  ];

  return (
    <TableContent
      bordered
      scroll={{ x: 'max-content' }}
      locale={{ emptyText: 'Không tìm thấy kết quả' }}
      dataSource={dataFailed}
      columns={columns}
      isHidePaging={true}
    />
  );
}

export default InputError;
