/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Table, TableProps } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IListColumn, ISortColumn } from 'src/@types/search-params';
import { parseQueryString } from 'src/utils';
import ListPagination from './CPagination';

interface TableCustomProps<T extends object> extends TableProps<T> {
  columns: T[];
  dataSource: any[];
  isHidePaging?: boolean;
  defaultPage?: number;
  defaultPageSize?: number;
  onPageChange?: (pageNumber: number, pageSize: number) => void;
  totalRecord?: number;
  optionType?: boolean;
  isShowCheckBox?: boolean;
  sortColumn?: ISortColumn;
  onSort?: (sort: any) => void;
  rowSelection?: TableRowSelection<T>;
}

const listOrderType = ['asc', 'desc', 'null'];

const TableContent = <T extends object = object>({
  dataSource,
  columns,
  isHidePaging = false,
  optionType = false,
  locale,
  isShowCheckBox = false,
  rowSelection,
  ...rest
}: TableCustomProps<T>) => {
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState<number>(rest.defaultPage ?? 1);
  const [pageSize, setPageSize] = useState<number>(rest.defaultPageSize ?? 10);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderType, setOrderType] = useState('null');
  const [sortColumn, setSortColumn] = useState<ISortColumn | undefined>(rest.sortColumn);

  useEffect(() => {
    if (sortColumn) {
      rest.onSort && rest.onSort(sortColumn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumn]);

  const handleChangePage = (page_number: number, page_size: number) => {
    setPageSize(page_size);
    setPageNumber(page_number);
    const params = parseQueryString(location.search);

    setSearchParams({
      ...searchParams,
      ...params,
      pageIndex: page_number.toString(),
      pageSize: page_size.toString(),
    });
    // rest.onPageChange!(page_number, page_size);
  };

  useEffect(() => {
    const params = parseQueryString(location.search);
    if (params && params.pageIndex) {
      setPageNumber(parseInt(params.pageIndex));
    } else {
      setPageNumber(1);
    }
    if (params && params.pageSize) {
      setPageSize(parseInt(params.pageSize));
    } else {
      setPageSize(10);
    }
  }, [location.search]);

  return (
    <>
      <Table
        dataSource={dataSource?.map((item, index) => {
          return { ...item, index: index + 1 + (pageNumber - 1) * pageSize };
        })}
        locale={locale ?? undefined}
        className='app-table-component shadow-md'
        columns={[
          ...columns.map((item: IListColumn, index: number) => {
            const { sort, fieldName, isHideSort, fixed, ...otherColumnProps } = item;
            if (!isHideSort) {
              return {
                ...otherColumnProps,
                title: (): JSX.Element => {
                  const isSortASC =
                    item.fieldName === sortColumn?.fieldName && sortColumn?.orderType === 'asc';
                  const isSortDESC =
                    item.fieldName === sortColumn?.fieldName && sortColumn?.orderType === 'desc';

                  return (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                      className='ant-table-column-sorters'
                      key={index}
                      onClick={() => {
                        setOrderType(
                          orderType !== 'null'
                            ? listOrderType[listOrderType.findIndex((x) => x === orderType) + 1]
                            : 'asc',
                        );
                        setSortColumn({
                          fieldName: item.fieldName!,
                          orderType:
                            orderType !== 'null'
                              ? listOrderType[listOrderType.findIndex((x) => x === orderType) + 1]
                              : 'asc',
                        });
                      }}
                    >
                      <span className='col-name font-bold'>{item.name}</span>
                      {!!item?.fieldName && (
                        <>
                          <span className='ant-table-column-sorter-inner'>
                            <span
                              role='img'
                              aria-label='caret-up'
                              className={
                                'anticon anticon-caret-up ant-table-column-sorter-up' +
                                (isSortASC && ' active')
                              }
                            >
                              <AiFillCaretUp></AiFillCaretUp>
                            </span>
                            <span
                              role='img'
                              aria-label='caret-down'
                              className={
                                'anticon anticon-caret-down ant-table-column-sorter-down' +
                                (isSortDESC && ' active')
                              }
                            >
                              <AiFillCaretDown></AiFillCaretDown>
                            </span>
                          </span>
                        </>
                      )}
                    </div>
                  );
                },
                dataIndex: item.fieldName,
                align: item.align || 'center',
                width: item.width || 120,
                fieldName,
                fixed: fixed,
                sorter: sort,
                render(value: any, record: any) {
                  return item.renderSpecialCard
                    ? item.renderSpecialCard(record, index, 1, 20)
                    : value;
                },
              };
            }
            return {
              title: <span className='font-bold'>{item.name}</span>,
              dataIndex: item.fieldName,
              fixed: fixed,
              align: item.align || 'center',
              width: item.width || 120,
              render(value: any, record: any) {
                return item.renderSpecialCard
                  ? item.renderSpecialCard(record, index, 1, 20)
                  : value;
              },
            };
          }),
        ]}
        rowSelection={isShowCheckBox ? rowSelection : undefined}
        scroll={{ x: 1000 }}
        pagination={!isHidePaging ? false : undefined}
        {...rest}
      />
      {!isHidePaging && (
        <ListPagination
          pageNumber={pageNumber}
          total={rest.totalRecord ?? 0}
          pageSize={pageSize}
          onShowSizeChange={handleChangePage}
          handleChangePage={handleChangePage}
          length={dataSource ? dataSource.length : 0}
          optionType={optionType}
        />
      )}
    </>
  );
};
export default TableContent;
