import { Button, Col, message, Radio, Row } from 'antd';
import {Fragment, useEffect, useState} from 'react';
import { RxCross2 } from 'react-icons/rx';
import { paymentPackageAssigned } from 'src/api/package_assigned';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import ModalConfirm from 'src/components/modals/ModalComfirm';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { resetModalState, setModalState } from 'src/redux/slice/modal.store';
import { OrderStatusEnum } from 'src/shared/enum/package_assigned';
import { OrderRequest } from 'src/shared/interface/package_assigned';
import { formatDateTime, formatMoney } from 'src/utils';

function BillingInfoModal() {
  const dispatch = useAppDispatch();
  const { order, refresh, balance } = useAppSelector((state) => state.global);
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [listEsimValid, setListEsimValid] = useState([]);
  const [countEsimValid, setCountEsimValid] = useState(0);

  async function handlePayBill() {
    setIsModalLoading(true);
    if (merchantInfo) {
      const data: OrderRequest = {
        accountNumber: JSON.parse(merchantInfo).payAccountId ?? '',
        orderId: order?.id ? order?.id : '',
        type: 2,
        distibutorId: merchantInfo ? JSON.parse(merchantInfo).merchantId : '',
        description: '',
        updatedBy: merchantInfo ? JSON.parse(merchantInfo).merchantName : '',
      };
      try {
        const res = await paymentPackageAssigned(data);
        if (res) {
          dispatch(setGlobalState({ refresh: !refresh }));
          dispatch(setModalState({ open: false }));
          message.success('Thanh toán thành công');
        }
      } catch (error) {
        console.log(error);
        setIsModalLoading(false);
      }
    }
    dispatch(setGlobalState({ refresh: !refresh }));
  }

  useEffect(() => {
    if (order) {
      const listEsimValidTmp: any = [];
      order?.overFlowOrderDetailResponses?.forEach((item: any) => {
        if (item?.statusString === 'New') {
          listEsimValidTmp.push(item);
        }
      });
      setListEsimValid(listEsimValidTmp);
      setCountEsimValid((order?.countSerial ?? 0) - (order?.quantityFailed ?? 0));
    }
  }, [order]);

  return (
    <Fragment>
      <div className='flex justify-end'>
        <button
          onClick={() => dispatch(resetModalState())}
          className=' text-3xl cursor-pointer border-transparent bg-transparent'
        >
          <RxCross2 />
        </button>
      </div>
      <BoxWithLabel title='Thông tin thanh toán' classNames='mb-[1.5rem]'>
        <Row gutter={[12, 0]} className='mb-[1rem]'>
          <Col span='12'>
            <Row>
              <Col span='8'>Mã giao dịch</Col>
              <Col span='16'>{order?.transactionId}</Col>
              <Col span='8'>Ngày:</Col>
              <Col span='16'>
                {order?.transactionDate && new Date(order?.transactionDate).getFullYear() > 1900
                  ? formatDateTime(order.transactionDate)
                  : formatDateTime(new Date())}
              </Col>
              <Col span='8'>Diễn giải:</Col>
              <Col span='16'>Thanh toán đơn hàng gán gói</Col>
            </Row>
          </Col>
          <Col span='12'>
            <Row>
              <Col span='8'>Tình trạng</Col>
              <Col span='16'>{order?.transactionStatusString}</Col>
              <Col span='8'>Người thanh toán:</Col>
              <Col span='16'>{merchantInfo ? JSON.parse(merchantInfo).merchantName : '-'}</Col>
              <Col span='8'>Đơn hàng tham chiếu:</Col>
              <Col span='16'>{order?.id}</Col>
            </Row>
          </Col>
        </Row>
        <p className='font-bold mb-[0.5rem]'>Chi tiết hóa đơn:</p>
        <div className='normal-table'>
          <table width='100%' className='normal-table'>
            <thead>
              <tr>
                <th>STT</th>
                <th>Số lượng</th>
                <th>Mã hàng</th>
                <th>Thành tiền</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{countEsimValid ?? 0}</td>
                <td>
                  {order?.overFlowOrderDetailResponses
                    ? order?.overFlowOrderDetailResponses[0].mbfCode
                    : ''}
                </td>
                <td>{order?.toTalAmount ? formatMoney(order?.toTalAmount, 'đ') : '-'}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Tổng cộng</td>
                <td>{order?.toTalAmount ? formatMoney(order?.toTalAmount, 'đ') : '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BoxWithLabel>
      <BoxWithLabel title='Hình thức thanh toán' classNames='mb-[1.5rem]'>
        <Radio checked className='mb-[0.5rem]'>
          Localpay
        </Radio>
        <Row gutter={8}>
          <Col span='4'>LocalPay ID:</Col>
          <Col span='4'>{merchantInfo ? JSON.parse(merchantInfo).payAccountId : '-'}</Col>
          <Col span='4'>Tên chủ tài khoản:</Col>
          <Col span='4'>{merchantInfo ? JSON.parse(merchantInfo).merchantName : '-'}</Col>
          <Col span='4'>Số dư:</Col>
          <Col span='4'>{balance ? formatMoney(balance, 'đ') : '-'}</Col>
        </Row>
      </BoxWithLabel>
      <div className='row-button justify-end'>
        <Button
          onClick={() => setOpenModalConfirm(true)}
          disabled={
            order?.statusString !== OrderStatusEnum.PAYMENT_FAIL &&
            order?.statusString !== OrderStatusEnum.New
          }
        >
          Thanh toán
        </Button>
      </div>
      <ModalConfirm
        open={openModalConfirm}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Xác nhận thanh toán?</div>
            <p>
              Bạn có chắc chắn muốn thanh toán{' '}
              {order?.toTalAmount ? formatMoney(order?.toTalAmount, 'đ') : '-'} cho{' '}
              {countEsimValid ?? 0} gói{' '}
              {order?.overFlowOrderDetailResponses
                ? order?.overFlowOrderDetailResponses[0].mbfCode
                : ''}
              ?
            </p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handlePayBill} className='h-full w-full' disabled={isModalLoading}>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirm(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
    </Fragment>
  );
}

export default BillingInfoModal;
