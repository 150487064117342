import { PlusOutlined } from '@ant-design/icons';
import { Form, Modal, Upload, message } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import React, { useState } from 'react';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

type UploadImagesProps = {
  name: string;
  label?: string;
  images: UploadFile[];
  required?: boolean;
  rules?: any;
  maxCount?: number;
  children?: Element;
  className?: string;
};

const UploadImages: React.FC<UploadImagesProps> = ({ images, maxCount = 0, ...props }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [isReview, setIsReview] = useState(false);

  const [fileList, setFileList] = useState<UploadFile[]>(images);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (isReview) {
      setFileList(newFileList);
    }
  };

  const uploadButton = (
    <div className='rounded-full w-[140px]'>
      <PlusOutlined />
    </div>
  );

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const { label, ...rest } = props;

  const propsUpload: UploadProps = {
    beforeUpload: (file) => {
      const isFixSize = file.size < 2000000;

      if (!isFixSize) {
        message.error(
          'File ảnh phải có dung lượng bé hơn hoặc bằng 2Mb, vui lòng chọn hình ảnh khác!',
        );
        setIsReview(false);
        return false;
      }

      setIsReview(true);
      return false;
    },
    onChange: handleChange,
  };

  return (
    <div className='flex-1'>
      <Form.Item
        {...rest}
        getValueFromEvent={getFile}
        rules={[{ required: true, message: `Vui lòng nhập của bạn!` }]}
        help={label}
      >
        <Upload
          listType='picture-card'
          accept='.jpg,.png'
          onPreview={handlePreview}
          fileList={fileList}
          // onChange={handleChange}
          {...propsUpload}
        >
          {fileList.length >= maxCount ? null : uploadButton}
        </Upload>
      </Form.Item>

      <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
        <img src={previewImage} alt='avatar' className='w-full' />
      </Modal>
    </div>
  );
};

export default UploadImages;
