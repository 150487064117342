import { Image, Typography } from 'antd';
import { DEFAULT_IMAGE_FALLBACK } from 'src/shared/constants';

const ImageProfileItem = ({ image = '', title = '' }: any) => {
  return (
    <div className='flex flex-col items-center'>
      <Image className='aspect-[3/2]' src={image} alt={title} fallback={DEFAULT_IMAGE_FALLBACK} />
      <Typography.Text className={'text-center mt-2'}>{title}</Typography.Text>
    </div>
  );
};

export default ImageProfileItem;
