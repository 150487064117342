import { Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import dayjs from 'dayjs';
import {dataGet, formatDateDash} from 'src/utils';
import { getUcmCardTypeIDOption, isValidUcmCardTypeID } from 'src/pages/management/activeSim/utils';
import ImageProfileItem from './ImageProfileItem';
import {FORMAT_DATE_DASH} from 'src/shared/constants';
import { JointSubject } from 'src/pages/management/activeSim/constants';

export default function SimUserInfoBox({ simDetail }: any) {
  const [form] = Form.useForm();
  const { custProfile } = simDetail || {};

  const userInfo = dataGet(simDetail, 'enterpriseUserProfile');

  const getDefaultValue = () => {
    if (dataGet(simDetail, 'jointSubject') === JointSubject.ENTERPRISE) {
      const dob = dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactDob');
      const issueDate = dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactIssueDate');
      return {
        ...userInfo,
        full_name: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactName'),
        dob: dob ? dayjs(dob) : null,
        gender: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactGender'),
        nationality: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactNationalityName'),
        idType: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactIdType'),
        idNumber: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactIdNumber'),
        issueDate: issueDate ? dayjs(issueDate) : null,
        issuePlace: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactIssuePlaceName'),
        address: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactAddress'),
        ward: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactWardName'),
        district: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactDistrictName'),
        province: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactProvinceName'),
        idFrontImage: dataGet(simDetail, 'enterpriseProfile.accountAttribute.frontDocumentFile'),
        idBackImage: dataGet(simDetail, 'enterpriseProfile.accountAttribute.backDocumentFile'),
        signatureImage: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactSignatureImage'),
        selfieImage: dataGet(simDetail, 'enterpriseProfile.accountAttribute.contactSelfieImage'),
      };
    }

    return {
      ...userInfo,
      idType: isValidUcmCardTypeID(userInfo?.idType?.toString())
        ? userInfo?.idType?.toString()
        : null,
      dob: userInfo?.dob ? dayjs(userInfo?.dob) : null,
      issueDate: userInfo?.issueDate ? dayjs(userInfo?.issueDate) : null,
    };
  };

  const initialValues = getDefaultValue();

  return (
    <BoxWithLabel title='Thông tin người sử dụng' classNames='mt-4'>
      <Form
        form={form}
        labelWrap={true}
        initialValues={initialValues}
        disabled={true}
        labelCol={{ span: 6 }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label='Họ và tên'>
              {custProfile?.ekyc?.fullname || dataGet(initialValues, 'full_name')}
            </Form.Item>
            <Form.Item label='Ngày sinh' name={'dob'}>
              {formatDateDash(custProfile?.ekyc?.dob) ||
                <DatePicker className={'w-full'} placeholder={''} format={FORMAT_DATE_DASH} />
              }
            </Form.Item>
            <Form.Item label='Giới tính' name={'gender'}>
              {custProfile?.ekyc ? custProfile?.ekyc?.gender === 0 ? 'Nam' : 'Nữ' : (
                <Select>
                  <Select.Option value={'0'}>Nam</Select.Option>
                  <Select.Option value={'1'}>Nữ</Select.Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label='Quốc tịch'>
              {custProfile?.ekyc?.nationalityName || dataGet(initialValues, 'nationality')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Loại giấy tờ' name='idType'>
              {custProfile?.ekyc ? (custProfile?.ekyc?.idType === 0 ? 'CMND/CCCD' : 'Passport') : (
                <Select options={getUcmCardTypeIDOption()} />
              )}
            </Form.Item>
            <Form.Item label='Số giấy tờ'>
              {custProfile?.ekyc?.idNumber || dataGet(initialValues, 'idNumber')}
            </Form.Item>
            <Form.Item label='Ngày cấp' name='issueDate'>
              {formatDateDash(custProfile?.ekyc?.issueDate) || ""}
            </Form.Item>
            <Form.Item label='Nơi cấp' name='issuePlace'>
              {custProfile?.ekyc?.issuePlaceName}
            </Form.Item>
            <Form.Item label='Địa chỉ TT'>
              {custProfile?.ekyc ? (
                `${custProfile?.ekyc?.address} ${custProfile?.ekyc?.wardName} ${custProfile?.ekyc?.districtName} ${custProfile?.ekyc?.provinceName}`
              ) : (
                [
                  initialValues?.address,
                  initialValues?.ward,
                  initialValues?.district,
                  initialValues?.province,
                ]
                  .filter((item) => !!item)
                  .join(', ')
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <ImageProfileItem image={custProfile?.ekyc?.idFrontImg || initialValues?.idFrontImage} title={'Mặt trước giấy tờ'} />
        <ImageProfileItem image={custProfile?.ekyc?.idBackImg || initialValues?.idBackImage} title={'Mặt sau giấy tờ'} />
        <ImageProfileItem image={custProfile?.ekyc?.signatureImg || initialValues?.signatureImage} title={'Chữ ký'} />
        <ImageProfileItem image={custProfile?.ekyc?.selfieImg || initialValues?.selfieImage} title={'Chân dung'} />
      </div>
    </BoxWithLabel>
  );
}
