import React from 'react';

const SimIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.1426 3.91992C16.7681 3.91992 17.3663 4.17492 17.799 4.62607L22.0457 9.04928C22.4554 9.47645 22.6853 10.0464 22.6853 10.6392V19.9128C22.6853 22.2143 20.8197 24.0799 18.5182 24.0799H10.4651C8.1636 24.0799 6.29907 22.2132 6.29907 19.9117V8.08705C6.29907 5.78554 8.16469 3.91992 10.4662 3.91992H16.1426Z'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.9394 14.0035L12.2883 13.1655C12.8799 12.6431 13.656 12.3267 14.5073 12.3267C16.3594 12.3267 17.861 13.8282 17.861 15.6804'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.0769 17.357L16.728 18.195C16.1364 18.7175 15.3603 19.0339 14.509 19.0339C12.6569 19.0339 11.1553 17.5322 11.1553 15.6802'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SimIcon;
