import {StatusEsimLot, TransactionTypeLot} from "../constants";

export const getTransactionTypeLotTxt = (type: any) => {
  switch (type) {
    case TransactionTypeLot.ALL:
      return "Tất cả";
    case TransactionTypeLot.DataPackage:
      return "Gán gói";
    case TransactionTypeLot.None:
      return "SIM trắng";
    default:
      return "Unknown";
  }
}

export const getTransactionTypeLotOption = () => {
  return Object.values(TransactionTypeLot)?.map((actionKey: string) => ({
    label: getTransactionTypeLotTxt(actionKey),
    value: actionKey
  }));
}

export const getStatusEsimLotTxt = (status: string) => {
  switch (status) {
    case StatusEsimLot.ALL:
      return "Tất cả";
    case StatusEsimLot.NOT_SALE:
      return "Not Sale";
    case StatusEsimLot.ERROR:
      return "Error";
    case StatusEsimLot.EXPIRED:
      return "Expired";
    case StatusEsimLot.ACTIVE:
      return "Active";
    case StatusEsimLot.INACTIVE:
      return "Inactive";
    case StatusEsimLot.CANCEL:
      return "Cancel";
    default:
      return "";
  }
}

export const getStatusEsimLotOption = () => {
  return Object.values(StatusEsimLot)?.map((actionKey: string) => ({
    label: getStatusEsimLotTxt(actionKey),
    value: actionKey
  }));
}