import { PaginationResponse, TransactionHistoryParams } from 'src/@types/search-params';
import { TransactionHistory } from 'src/shared/interface/localpay.interface';
import request from '../request';

export const getBalance = async () => {
  const res = await request('get', `/api/Provider/get-balance/localpay`);

  if (res.isSucceeded) {
    return res.data.data;
  }
};
export const getTransactionHistory = async (
  searchParams: TransactionHistoryParams,
  distributorId: string,
) => {
  const res = await request<PaginationResponse<TransactionHistory>>(
    'get',
    '/api/Provider/get-all/localpay',
    { ...searchParams, distributorId },
  );

  if (res.isSucceeded) {
    return res.data;
  }
};
