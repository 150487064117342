import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

export interface EsimManagementState {
  listEsimSelect: any;
}
const initialState: EsimManagementState = {
  listEsimSelect: [],
};
export const esimManagementSlice = createSlice({
  name: 'esimManagement',
  initialState,
  reducers: {
    setEsimManagementState(state, action: PayloadAction<Partial<EsimManagementState>>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { actions: esimManagementAction, reducer: esimManagementReducer } = esimManagementSlice;
export const { setEsimManagementState } = esimManagementSlice.actions;
