import { Button, Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIssuePlace, getNationality, reserveNumber, uploadImages } from 'src/api/connection';
import { REGEX_COMMON } from 'src/shared/common/regex';
import {
  IIssuePlace,
  INationality,
  IPhoneInfo,
  IUserProfile,
} from 'src/shared/interface/connection';
import BoxWithLabel from '../../../BoxWithLabel/BoxWithLabel';
import GetAddress from '../../GetAddress';
import UploadImages from '../../UploadImages';
import ModalConfirmConnect from '../../ModalConfirmConnect';
import { ConnectionType } from 'src/shared/enum/connection.enum';

type Props = {
  phoneInfo: IPhoneInfo;
};

export const uploadFiles = async (files: any): Promise<string[]> => {
  if (_.isEmpty(files)) {
    return [];
  }

  const getImages =
    _.map(_.flatten(files), (file: any) => {
      const formData = new FormData();
      formData.append('file', file.originFileObj);

      return uploadImages(formData);
      // return MediaAPI.uploadFileMedia(file.originFileObj);
    }) || [];

  const responses = await Promise.all(getImages);

  return responses;
};

const UserInforEmptyForm = (props: Props) => {
  const { phoneInfo } = props;
  const [userProfile, setUserProfile] = useState<IUserProfile>();
  const [form] = Form.useForm();
  const [issuePlace, setIssuePlace] = useState<IIssuePlace[]>();
  const [otpsIssuePlace, setOtpsIssuePlace] = useState<{ value: string; label: string }[]>();
  const [nationality, setNationality] = useState<INationality[]>();
  const [isOpenConnectModal, setIsOpenConnectModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isConnectFail, setIsConnectFail] = useState<boolean>(false);
  const [isVi, setIsVi] = useState<boolean>(true);
  const [isPassport, setIsPassport] = useState<boolean>(false);
  const [opsIdNumber, setOptsIdNumber] = useState<{ value: number; label: string }[]>([
    {
      value: 1,
      label: 'CMND',
    },
    {
      value: 2,
      label: 'CCCD',
    },
    {
      value: 3,
      label: 'Passport',
    },
  ]);

  const fetchIssuePlace = async () => {
    try {
      const response = await getIssuePlace();
      setIssuePlace(response);

      const otps = response?.map((issue: IIssuePlace) => {
        const { code, name } = issue;
        return {
          value: code,
          label: name,
        };
      });
      setOtpsIssuePlace(otps);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = async () => {
    try {
      const response = await reserveNumber(phoneInfo?.phoneNumber, phoneInfo?.pool);

      if (response) {
        navigate(0);
      }
    } catch (error) {
      console.log(error);
      navigate(0);
    }
  };

  const fetchNationality = async () => {
    try {
      const response = await getNationality();
      setNationality(response);
      form.setFieldValue('nationality', 'VNM');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchIssuePlace();
    fetchNationality();
  }, []);

  const handleConnection = async (values: any) => {
    const {
      frontImage,
      backImage,
      signature,
      selfieImage,
      issuePlace,
      address,
      province,
      district,
      ward,
      ...rest
    } = values;

    const results = await uploadFiles([frontImage, backImage, signature, selfieImage]);
    const payload = {
      ...rest,
      issueDate: values.issueDate.format('YYYY-MM-DD'),
      birthDay: values.birthDay.format('YYYY-MM-DD'),
      frontImage: results[0],
      backImage: results[1],
      signature: results[2],
      selfieImage: results[3],
      issuePlace: !isVi
        ? nationality && nationality.find((item) => item.name === issuePlace)?.code
        : issuePlace,
      address: !isVi ? '18 Nguyễn Văn Mại' : address,
      province: !isVi ? 'HCM' : province,
      district: !isVi ? 'TBI' : district,
      ward: !isVi ? '0004' : ward,
    };

    setUserProfile(payload);
    setIsOpenConnectModal(true);
  };

  const handleCancelConnectModal = () => {
    setIsOpenConnectModal(false);
    if (isConnectFail) {
      handleReset();
    }
  };

  const handleNationalChange = (value: string) => {
    if (value === 'VNM') {
      setIsVi(true);
      form.setFieldsValue({
        issuePlace: undefined,
        address: undefined,
        province: undefined,
        district: undefined,
        ward: undefined,
        idNumber: undefined,
        issueDate: undefined,
      });
    } else {
      setIsVi(false);
      form.setFieldsValue({
        issuePlace: nationality && nationality.find((item) => item.code === value)?.name,
        address: '18 Nguyễn Văn Mại',
        province: 'Thành Phố Hồ Chí Minh',
        district: 'Tân Bình',
        ward: '4',
        idNumber: undefined,
        issueDate: undefined,
      });
    }
  };

  const checkPassport = (text: string) => {
    const array = text.split('');
    let hasAlphabet = 0;
    array.map((letter) => {
      if (/^[a-zA-Z()]+$/.test(letter)) {
        hasAlphabet += 1;
      }
    });

    if (hasAlphabet === 2) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    let optionsIdNumber;
    let optionsIssuePlace;

    if (isVi) {
      optionsIdNumber = [
        {
          value: 1,
          label: 'CMND',
        },
        {
          value: 2,
          label: 'CCCD',
        },
        {
          value: 3,
          label: 'Passport',
        },
      ];

      optionsIssuePlace = issuePlace?.map((issue: IIssuePlace) => {
        const { code, name } = issue;
        return {
          value: code,
          label: name,
        };
      });

      form.setFieldValue('typeIdNumber', undefined);
    } else {
      optionsIdNumber = [
        {
          value: 3,
          label: 'Passport',
        },
      ];

      optionsIssuePlace = [
        {
          value: 'CCC',
          label: 'Cục Cảnh sát quản lý hành chính về trật tự xã hội',
        },
        {
          value: 'XNC',
          label: 'Cục Quản lý xuất nhập cảnh',
        },
      ];

      form.setFieldValue('typeIdNumber', 3);
    }

    setOptsIdNumber(optionsIdNumber);
    setOtpsIssuePlace(optionsIssuePlace);
  }, [isVi]);

  const handleIdNumberChange = (value: number) => {
    setIsPassport(value === 3);
    form.setFieldValue('idNumber', undefined);

    if (!isVi) {
      return;
    }

    if (value === 1) {
      const otps = issuePlace
        ?.filter((issue: IIssuePlace) => {
          const { code } = issue;
          if (code === 'CCD' || code === 'CCC' || code === 'XNC') {
            return false;
          }
          return true;
        })
        .map((issue: IIssuePlace) => {
          const { code, name } = issue;
          return {
            value: code,
            label: name,
          };
        });
      setOtpsIssuePlace(otps);
      form.setFieldValue('issuePlace', undefined);
    } else if (value === 2) {
      setOtpsIssuePlace([
        {
          value: 'CCD',
          label: 'Cục Trưởng cục cảnh sát ĐKQL cư trú và DLQG về dân cư',
        },
        {
          value: 'CCC',
          label: 'Cục Cảnh sát quản lý hành chính về trật tự xã hội',
        },
      ]);
      form.setFieldValue('issuePlace', undefined);
    } else {
      setOtpsIssuePlace([
        {
          value: 'XNC',
          label: 'Cục Quản lý xuất nhập cảnh',
        },
      ]);
      form.setFieldValue('issuePlace', 'XNC');
    }
  };

  return (
    <>
      <Form
        onFinish={handleConnection}
        autoComplete='off'
        form={form}
        // initialValues={initialValues}
      >
        <BoxWithLabel title='Thông tin người sử dụng'>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label='Họ và tên'
                name='userName'
                rules={[
                  { required: true, message: 'Vui lòng nhập Họ và tên của bạn!' },
                  {
                    pattern: REGEX_COMMON.letterVietnamese,
                    message: 'Tên không hợp lệ!',
                  },
                ]}
                labelCol={{ span: 8 }}
              >
                <Input maxLength={30} placeholder='Nhập họ và tên'></Input>
              </Form.Item>
              <Form.Item
                label='Ngày sinh'
                name='birthDay'
                validateFirst={true}
                rules={[
                  { required: true, message: 'Vui lòng nhập Ngày sinh của bạn!' },
                  {
                    validator: (r, birthday) => {
                      const currentDate = dayjs();
                      const validateDate = dayjs(birthday).add(18, 'year');

                      if (validateDate.isBefore(currentDate)) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Người sử dụng chưa đủ 18');
                    },
                    message: 'Người sử dụng chưa đủ 18',
                  },
                ]}
                labelCol={{ span: 8 }}
              >
                <DatePicker
                  format={'DD-MM-YYYY'}
                  placeholder={'Chọn ngày'}
                  disabledDate={(current) => {
                    const yesterday = dayjs();
                    return current && current > yesterday;
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Giới tính'
                name='gender'
                rules={[{ required: true, message: 'Vui lòng chọn Giới tính của bạn!' }]}
                labelCol={{ span: 8 }}
                initialValue={0}
              >
                <Radio.Group>
                  <Radio value={0}>Nam</Radio>
                  <Radio value={1}>Nữ</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label='Quốc tịch'
                name='nationality'
                rules={[{ required: true, message: 'Vui lòng chọn Quốc tịch của bạn!' }]}
                labelCol={{ span: 8 }}
              >
                <Select
                  placeholder='Vui lòng chọn'
                  showSearch
                  onChange={handleNationalChange}
                  defaultValue={'Viet nam'}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={nationality?.map((national: INationality) => {
                    const { code, name } = national;
                    return {
                      value: code,
                      label: name,
                    };
                  })}
                ></Select>
              </Form.Item>
              <Form.Item
                label='Loại giấy tờ'
                name='typeIdNumber'
                rules={[{ required: true, message: 'Vui lòng chọn Loại giấy tờ của bạn!' }]}
                labelCol={{ span: 8 }}
              >
                <Select
                  placeholder='Vui lòng chọn'
                  onChange={handleIdNumberChange}
                  options={opsIdNumber}
                  disabled={!isVi}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                label='Số giấy tờ'
                name='idNumber'
                rules={[
                  { required: true, message: 'Vui lòng nhập Số giấy tờ của bạn!' },
                  {
                    validator: (r, value) => {
                      if (isPassport || !isVi) {
                        if (value.length >= 8 && /^[a-zA-Z0-9()]+$/.test(value)) {
                          return Promise.resolve();
                        }
                      } else {
                        if (form.getFieldValue('typeIdNumber') === 1 && value.length >= 9) {
                          return Promise.resolve();
                        }

                        if (form.getFieldValue('typeIdNumber') !== 1 && value.length >= 12) {
                          return Promise.resolve();
                        }
                      }

                      return Promise.reject('Số giấy tờ không hợp lệ');
                    },
                    message: 'Số giấy tờ không hợp lệ',
                  },
                ]}
                labelCol={{ span: 6 }}
              >
                {isPassport || !isVi ? (
                  <Input
                    placeholder='Nhập số giấy tờ'
                    type={'text'}
                    maxLength={9}
                    onChange={(e) => {
                      if (e.target.value.length <= 9) {
                        form.setFieldValue('idNumber', e.target.value);
                      } else {
                        form.setFieldValue('idNumber', e.target.value.substring(0, 9));
                      }
                      e.preventDefault();
                    }}
                  ></Input>
                ) : (
                  <Input
                    placeholder='Nhập số giấy tờ'
                    type={'number'}
                    maxLength={form.getFieldValue('typeIdNumber') === 1 ? 9 : 12}
                    onChange={(e) => {
                      if (
                        e.target.value.length <= (form.getFieldValue('typeIdNumber') === 1 ? 9 : 12)
                      ) {
                        form.setFieldValue('idNumber', e.target.value);
                      } else {
                        form.setFieldValue(
                          'idNumber',
                          e.target.value.substring(
                            0,
                            form.getFieldValue('typeIdNumber') === 1 ? 9 : 12,
                          ),
                        );
                      }
                      e.preventDefault();
                    }}
                    onKeyDown={(evt) => {
                      if (!evt.ctrlKey && !evt.metaKey) {
                        if (
                          evt.key === 'e' ||
                          evt.key === '+' ||
                          evt.key === '-' ||
                          (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                          Number(evt.key) > 9 ||
                          Number(evt.key) < 0 ||
                          (!REGEX_COMMON.digit.test(evt.key) && evt.key !== 'Backspace')
                        ) {
                          evt.preventDefault();
                        }
                      }
                    }}
                  ></Input>
                )}
              </Form.Item>
              <Form.Item
                label='Ngày cấp'
                name='issueDate'
                dependencies={['birthDay']}
                rules={[
                  { required: true, message: 'Vui lòng nhập Ngày cấp của bạn!' },
                  {
                    validator: (r, issueDate) => {
                      const birthDay = form.getFieldValue('birthDay');
                      const validateDate = dayjs(birthDay).add(14, 'year');

                      if (validateDate.isBefore(dayjs(issueDate))) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Should be 18 years');
                    },
                    message: 'Ngấy cấp phải lớn hơn ngày sinh ít nhất 14 năm',
                  },
                ]}
                labelCol={{ span: 6 }}
              >
                <DatePicker
                  disabledDate={(current) => {
                    const yesterday = dayjs().add(-1, 'day');
                    return current && current > yesterday;
                  }}
                  format={'DD-MM-YYYY'}
                  placeholder={'Chọn ngày'}
                />
              </Form.Item>
              <Form.Item
                label='Nơi cấp'
                name='issuePlace'
                rules={[{ required: true, message: 'Vui lòng chọn Nơi cấp của bạn!' }]}
                labelCol={{ span: 6 }}
              >
                <Select
                  placeholder='Vui lòng chọn'
                  options={otpsIssuePlace}
                  disabled={!isVi}
                ></Select>
              </Form.Item>
              <Form.Item
                label='Địa chỉ thường trú'
                name='address'
                rules={[
                  { required: true, message: 'Vui lòng nhập Địa chỉ thường trú của bạn!' },
                  {
                    pattern: REGEX_COMMON.letterVietnameseAddress,
                    message: 'Địa chỉ không hợp lệ!',
                  },
                ]}
                labelCol={{ span: 6 }}
              >
                <Input placeholder='Nhập địa chỉ của bạn' disabled={!isVi} maxLength={100} />
              </Form.Item>
              <GetAddress isDisabled={!isVi} />
            </Col>
          </Row>
        </BoxWithLabel>
        <div className='my-10'></div>
        <BoxWithLabel title='Hình ảnh'>
          <div className='flex gap-2 text-center'>
            <UploadImages name='frontImage' images={[]} maxCount={1} label='Mặt trước giấy tờ' />
            <UploadImages name='backImage' images={[]} maxCount={1} label='Mặt sau giấy tờ' />
            <UploadImages name='signature' images={[]} maxCount={1} label='Chữ ký' />
            <UploadImages name='selfieImage' images={[]} maxCount={1} label='Chân dung' />
          </div>
        </BoxWithLabel>
        <div className='flex gap-5 my-5'>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Đấu nối
            </Button>
          </Form.Item>
          <Button onClick={handleReset}>Làm mới</Button>
        </div>
      </Form>
      <ModalConfirmConnect
        connectionType={ConnectionType.INDIVIDUAL}
        isOpen={isOpenConnectModal}
        handleCancel={handleCancelConnectModal}
        setIsConnectFail={setIsConnectFail}
        userProfile={userProfile}
        phoneInfo={phoneInfo}
      />
    </>
  );
};

export default UserInforEmptyForm;
