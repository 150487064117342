import { Button, Form, Input, message } from 'antd';
import { useState } from 'react';
import { passwordRules } from 'src/utils';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { parseJwt } from 'src/utils';
import request from 'src/api/request';

const ChangePasswordPage = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();

  // const { submittable } = useFormSubmittable(form);

  // const passwordRules = [
  //   {
  //     required: true,
  //     message: 'Mật khẩu không hợp lệ, vui lòng kiểm tra lại.',
  //   },
  //   {
  //     min: 10,
  //     message: 'Mật khẩu không hợp lệ, vui lòng kiểm tra lại.',
  //   },
  //   {
  //     pattern: regexPassword(),
  //     message: 'Mật khẩu không hợp lệ, vui lòng kiểm tra lại.',
  //   },
  // ];

  const handleFinish = async (data) => {
    setIsLoading(true);
    const accessToken = localStorage.getItem('token');
    const accountId = parseJwt(accessToken)?.sub;

    try {
      const response = await request('post', '/api/ucm/change-password', {
        userId: accountId,
        newPassword: data?.new_password
      })

      if (response) {
        message.success('Bạn đã thay đổi mật khẩu thành công! Vui lòng đăng nhập lại');
        setTimeout(() => {
          localStorage.clear();
          navigate('/login');
        }, 3000);
      } else {
        message.error('Bạn đã thay đổi mật khẩu thất bại! Vui lòng thử lại');
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className='p-8'>
      <Button className='absolute top-2 left-0 border-none shadow-none' onClick={handleBack}>
        <IoArrowBack />
      </Button>
      <h2 className='my-5 text-center'>
        Đổi mật khẩu
      </h2>
      <Form form={form} layout='vertical' size='large' autoComplete='off' onFinish={handleFinish}>
        <Form.Item label='Mật khẩu mới' name='new_password' rules={passwordRules}>
          <Input.Password placeholder='Mật khẩu mới'></Input.Password>
        </Form.Item>
        <Form.Item
          label='Xác minh mật khẩu'
          dependencies={['new_password']}
          name='repeat_password'
          rules={[
            {
              required: true,
              message: 'Thông tin này không được để trống',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Mật khẩu mới không khớp.'));
              },
            }),
          ]}
        >
          <Input.Password placeholder='Xác minh mật khẩu'></Input.Password>
        </Form.Item>
        <div className='text-center pt-10'>
          <Form.Item noStyle>
            <Button
              htmlType='submit'
              type='primary'
              loading={isLoading}
            // disabled={!submittable}
            // {...rest}
            >
              Hoàn thành
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ChangePasswordPage;
