import { useQuery } from '@tanstack/react-query';
import { getAllPackage, getAllPackageByLCS, getInfoActiveSim } from 'src/api/activeSim';

export const activeSimDataKeys = {
  all: () => ['active-sim'] as const,
  list: (filters = {}) => [...activeSimDataKeys.all(), 'list', filters] as const,
  listPackage: () => [...activeSimDataKeys.all(), 'listPackage'] as const,
};

export const useActiveSimInfo = (queryParam: string, options = {}) => {
  return useQuery({
    queryKey: activeSimDataKeys.list({ query: queryParam }),
    queryFn: () => getInfoActiveSim(queryParam),
    ...options,
  });
};

export const useAllPackage = (options = {}) => {
  return useQuery({
    queryKey: activeSimDataKeys.listPackage(),
    queryFn: () => getAllPackageByLCS(),
    ...options,
  });
};
