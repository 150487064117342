import { Col, Row, Form, Input, Select, DatePicker, Radio } from 'antd';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { getUcmCardTypeIDOption, isValidUcmCardTypeID } from 'src/pages/management/activeSim/utils';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from 'src/shared/constants';

export default function AuthorizedInfoBox({ accountAttribute }: any) {
  const [form] = Form.useForm();

  const defaultValue = {
    ...accountAttribute,
    authorizedIsNegotiator: accountAttribute?.authorizedIsNegotiator || 'false',
    authorizedIdType: isValidUcmCardTypeID(accountAttribute?.authorizedIdType)
      ? accountAttribute.authorizedIdType
      : null,
    authorIdIssueDate: accountAttribute?.authorIdIssueDate
      ? dayjs(accountAttribute?.authorIdIssueDate)
      : null,
    authorEndDate: accountAttribute?.authorEndDate ? dayjs(accountAttribute?.authorEndDate) : null,
    authorStartDate: accountAttribute?.authorStartDate
      ? dayjs(accountAttribute?.authorStartDate)
      : null,
  };

  return (
    <BoxWithLabel title='Thông tin người ủy quyền (nếu có)' classNames='mt-4'>
      <Form
        form={form}
        labelWrap={true}
        initialValues={defaultValue}
        disabled={true}
        labelCol={{ span: 6 }}
      >
        <Row gutter={[14, 14]}>
          <Col span={12}>
            <Form.Item
              label='Họ và tên'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'authorSubName'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Loại giấy tờ'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'authorOption'}
            >
              <Select options={getUcmCardTypeIDOption()} />
            </Form.Item>
            <Form.Item
              label='Số giấy tờ'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'authorIdNo'}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='Ngày cấp'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'authorIdIssueDate'}
            >
              <DatePicker className={'w-full'} format={DEFAULT_DATE_FORMAT} placeholder={''} />
            </Form.Item>
            <Form.Item
              label='Nơi cấp'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'authorIdIssuePlaceName'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Số giấy ủy quyền'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name='authorNo'
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Ngày cấp giấy UQ'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'authorStartDate'}
            >
              <DatePicker className={'w-full'} format={DEFAULT_DATE_FORMAT} placeholder={''} />
            </Form.Item>
            <Form.Item
              label='Ngày hết hạn giấy UQ'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'authorEndDate'}
            >
              <DatePicker className={'w-full'} format={DEFAULT_DATE_FORMAT} placeholder={''} />
            </Form.Item>
            <Form.Item
              label='Người ký giấy UQ'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'authorPowerOfAttorneyGrantor'}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Chức vụ'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'authorRole'}
            >
              <Input />
            </Form.Item>
            <Form.Item label='Email' labelCol={{ span: 5 }} labelAlign={'left'} name={'emailMbf'}>
              <Input />
            </Form.Item>
            <Form.Item
              label='Số điện thoại'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'authorPhoneNumber'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Liên hệ khác'
              labelCol={{ span: 5 }}
              labelAlign={'left'}
              name={'authorOtherContact'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Là người đến giao dịch?'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              name={'authorIsNegotiator'}
            >
              <Radio.Group>
                <Radio value={'true'}>Có</Radio>
                <Radio value={'false'}>Không</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BoxWithLabel>
  );
}
