import { Navigate, useLocation } from 'react-router-dom';
const PrivateRoute = ({ children, roles }: { children: JSX.Element; roles?: Array<string> }) => {
  const location = useLocation();

  const token = localStorage.getItem('token');
  const userStore = localStorage.getItem('merchantInfo');
  let user;

  if (userStore !== null) {
    try {
      user = JSON.parse(userStore);
    } catch (error) {
      console.log(error);
    }
  }
  let userHasRequiredRole = true;
  if (roles) {
    userHasRequiredRole = user && roles.includes(user.role) ? true : false;
  }
  if (!token || !userHasRequiredRole) {
    return <Navigate to='/login' state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;
