import { Pagination } from 'antd';
import React from 'react';

interface Props {
  pageNumber: number;
  total: number;
  pageSize: number;
  handleChangePage: (page_number: number, size: number) => void;
  onShowSizeChange: (current: number, size: number) => void;
  length: number;
  optionType: boolean;
}
const ListPagination: React.FC<Props> = (props) => {
  const startIndex = (props.pageNumber - 1) * props.pageSize + 1;
  const endIndex = (props.pageNumber - 1) * props.pageSize + props.length;
  const pageSizeOptions = ['10', '20', '50', '100'];

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  props?.optionType ? pageSizeOptions.unshift('5') : pageSizeOptions;

  return (
    <div className='listing-paging-box flex gap-x-2 justify-center md:justify-between py-4 items-center'>
      <div className='d-md-block d-none' style={{ color: '#AFAFAF', fontSize: 12 }}>
        <span>{`${endIndex ? startIndex : 0} - ${endIndex} trong ${props.total} bản ghi`}</span>
      </div>
      <Pagination
        showSizeChanger={true}
        onShowSizeChange={(page_number, size) => {
          props.onShowSizeChange(page_number, size);
        }}
        onChange={(page_number, size) => {
          props.handleChangePage(page_number, size);
        }}
        current={props.pageNumber}
        total={props.total}
        pageSize={props.pageSize ?? 10}
        pageSizeOptions={pageSizeOptions}
      />
    </div>
  );
};

export default ListPagination;
