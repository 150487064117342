import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { ILocation } from 'src/shared/interface/location.interface';
import {
  DataEnterprise,
  DetailUserProfileBatch,
  UserProfileBodyData,
  UserProfileResponse,
  ValueCode,
} from 'src/shared/interface/user_profile';

export interface UserProfileState {
  loading: boolean;
  locationData: ILocation[];
  issuePlaceData: ValueCode[];
  userProfile?: UserProfileResponse;
  isRefresh: boolean;
  listSuccessBatch?: UserProfileBodyData[];
  listFailBatch?: UserProfileBodyData[];
  userProfileBatch?: DetailUserProfileBatch;
  dataEnterprise?: DataEnterprise;
}
const initialState: UserProfileState = {
  loading: false,
  locationData: [],
  issuePlaceData: [],
  userProfile: undefined,
  isRefresh: false,
  listSuccessBatch: [],
  listFailBatch: [],
  userProfileBatch: undefined,
  dataEnterprise: undefined,
};
export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setUserProfileState(state, action: PayloadAction<Partial<UserProfileState>>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { actions: userProfileAction, reducer: userProfileReducer } = userProfileSlice;
export const { setUserProfileState } = userProfileSlice.actions;
